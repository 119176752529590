import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import * as JSON5 from 'json5';
import { ApiErrors } from './error-messages';

@Injectable({
  providedIn: 'root',
})
export class ErrorInterceptorService implements HttpInterceptor {
  constructor() {}

  handleError(error: HttpErrorResponse) {
    if (error.error && error.error.message) {
      const apiErrorMsg: string = error.error.message;

      const parsedErrorMsg =
        violationMessages(apiErrorMsg) || translateErrorMessage(apiErrorMsg) || error.error.message;

      return throwError(parsedErrorMsg);
    }

    return throwError(error);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({
      withCredentials: true,
    });

    return next.handle(req).pipe(catchError(this.handleError));
  }
}

export function translateErrorMessage(apiErrorMsg: string): string {
  const apiErrorKey = extractApiErrorKey(apiErrorMsg) || apiErrorMsg;

  // Use the key to fetch errors from the error-messages.json file.
  const matchingErrors = ApiErrors.mappings.filter(e => {
    return e.key === apiErrorKey;
  });
  if (matchingErrors.length > 0) {
    return matchingErrors[0].message;
  }

  return toEnglish(apiErrorKey);
}

export function extractApiErrorKey(apiErrorMsg: string): string {
  const xmlTagsToConsider: string[] = ['key', 'messageParams', 'additionalinfo'];

  let errorKey = '';
  xmlTagsToConsider.forEach(tag => {
    const startTag = `<${tag}>`;
    const endTag = `</${tag}>`;
    if (apiErrorMsg.includes(startTag) && apiErrorMsg.includes(endTag)) {
      errorKey = apiErrorMsg.substring(apiErrorMsg.indexOf(startTag) + startTag.length, apiErrorMsg.indexOf(endTag));
    }
  });

  return errorKey;
}

export function violationMessages(errorMsg: string): string {
  try {
    const json = JSON5.parse(errorMsg);
    const violations = json['com.tangoe.prime.data.ApplicationException'].mViolationDetails;
    let message = '';

    violations.forEach(element => {
      let entry = element.entry;

      if (!Array.isArray(entry)) {
        entry = [entry];
      }

      entry.forEach(x => {
        const field = x.string;
        const list = x.list;

        if (list[0].string[0] === 'SIZE') {
          message += `Data provided for field ${field} is not within the size limit. `;
        }
      });
    });

    return message;
  } catch (error) {}
}

export function toEnglish(text: string): string {
  // If the text is all upper case or all lower case then it is mostly a key.
  if (text.toUpperCase() === text || text.toLowerCase() === text) {
    let englishText = text
      .toLowerCase()
      .replace(/[',._-]/g, ' ')
      .trim();
    englishText = englishText.charAt(0).toUpperCase() + englishText.slice(1);

    return englishText;
  }

  return text;
}
