import { LogService } from './../logger/log.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { PermissionResourceMapping } from '../shared/models/authentication/PermissionResourceMapping';
import { NavAppDrawerItem } from '@tangoe/goponents';
import { sessionContext, ApplicationInfo } from '../authentication/session-details';
import { SaasMenuProvider } from './saas.menus';

@Injectable({
  providedIn: 'root',
})
export class MenuProvider {
  constructor(
    private authenticationService: AuthenticationService,
    protected logService: LogService,
    private saasMenuProvider: SaasMenuProvider
  ) {}

  private masterMenuList: any = {
    subRoutes: [
      {
        route: '/dashboard',
        routeTitle: 'Dashboard',
        routeIcon: 'dashboard',
        description: 'Dashboard',
        resource: PermissionResourceMapping.CloudAnalyticsViewer,
      },
      {
        route: '/notification',
        routeTitle: 'Notifications',
        routeIcon: 'timeline',
        description: 'Notifications',
        resource: PermissionResourceMapping.CloudAnalyticsViewer,
      },
      {
        route: '/',
        routeTitle: 'Administration',
        routeIcon: 'settings',
        description: 'Administration',
        subRoutes: [
          {
            routeTitle: 'Data Management',
            subRoutes: [
              { routeTitle: 'DMU Jobs', route: '/external-resource/dmu-jobs', resource: 'DMU' },
              { routeTitle: 'DMU Job Templates', route: '/external-resource/dmu-job-templates', resource: 'TEMPLATE' },
              { routeTitle: 'Metadata Details', route: '/metadata-details', resource: 'METADATA' },
              {
                routeTitle: 'Usage Allocation Business Rules',
                route: '/allocation-business-rule',
                resource: 'ALLOCATION_BUSINESS_RULE',
              },
              {
                routeTitle: 'Cloud Pricing Catalog',
                route: '/private-invoice-pricing',
                resource: 'PRIVATE_CLOUD_PRICING',
              },
            ],
          },
          {
            routeTitle: 'Employees',
            subRoutes: [
              { routeTitle: 'Employee Groups', route: '/employee-groups', resource: 'EMPLOYEE_GROUPS' },
              { routeTitle: 'Employees', route: '/employees', resource: 'EMPLOYEES' },
            ],
          },
          {
            routeTitle: 'Enterprise Data',
            description: 'Enterprise Data',
            subRoutes: [
              { routeTitle: 'Accounting Systems', route: '/accounting-systems', resource: 'ACCOUNTING_SYSTEM' },
              { routeTitle: 'Accounts', route: '/accounts', resource: 'ACCOUNTS' },
              { routeTitle: 'Addresses', route: '/addresses', resource: 'ADDRESSES' },
              { routeTitle: 'Business Units', route: '/business-units', resource: 'BUSINESS_UNIT_USER' },
              { routeTitle: 'Companies', route: '/companies', resource: 'COMPANY' },
              { routeTitle: 'Cost Centers', route: '/cost-centers', resource: 'COST_CENTER' },
              { routeTitle: 'Cost Center Owners', route: '/cost-center-owners', resource: 'COST_CENTER' },
              { routeTitle: 'Departments', route: '/departments', resource: 'DEPARTMENTS_USER' },
              { routeTitle: 'GL Expense Codes', route: '/gl-expense-codes', resource: 'GL_EXPENSE_CODE' },
              { routeTitle: 'HR Roles', route: '/hr-roles', resource: 'HR_ROLE' },
              { routeTitle: 'Locations', route: '/locations', resource: 'LOCATIONS' },
            ],
          },
          {
            routeTitle: 'Security',
            description: 'Security',
            subRoutes: [
              { routeTitle: 'Groups', route: '/user-groups', resource: 'GROUP' },
              { routeTitle: 'Privilege Policies', route: '/privilege-policies', resource: 'PRIVILEGE_POLICY' },
              { routeTitle: 'Roles', route: '/roles', resource: 'SC_ROLE' },
              { routeTitle: 'Users', route: '/users', resource: 'USER' },
            ],
          },
          {
            routeTitle: 'Tenants',
            route: '/tenants',
            description: 'Tenants',
            resource: 'GLOBAL_TENANT',
          },
          {
            routeTitle: 'Vendors',
            route: '/tenant-vendors',
            description: 'Vendors',
            resource: 'GLOBAL_VENDOR',
          },
          {
            routeTitle: 'Vendor Onboarding',
            route: '/vendor-onboarding',
            resource: 'VENDOR_ONBOARDING',
          },
        ],
      },
      {
        route: '/',
        routeTitle: 'Analytics',
        routeIcon: 'timeline',
        description: 'Analytics',
        subRoutes: [
          { routeTitle: 'Designer', route: '/designer', resource: PermissionResourceMapping.CloudAnalyticsDesigner },
        ],
      },
      {
        route: '/',
        routeTitle: 'Bill Import',
        routeIcon: 'library_books',
        description: 'Bill Import',
        subRoutes: [
          {
            routeTitle: 'Bill Load',
            route: '/external-resource/bill-loads',
            resource: 'BILL_IMPORT_LIST',
          },
          {
            routeTitle: 'Charge Rules Extract',
            route: '/external-resource/charge-rules-extract',
            resource: 'CHARGE_RULES_EXTRACT',
          },
          {
            routeTitle: 'Recurring Bills',
            route: '/external-resource/recurring-bills',
            resource: 'E_RECURRING_BILL',
          },
          {
            routeTitle: 'Self Service Bill Import',
            route: '/external-resource/self-service-bill-imports',
            resource: 'E_BILL_SELF_SERVICE_FILE_IMPORT',
          },
          {
            routeTitle: 'Unclassified Charges',
            route: '/external-resource/unclassified-charges',
            resource: 'RULE_MISSES_SUB',
          },
        ],
      },
      {
        route: '/',
        routeTitle: 'Budgeting',
        routeIcon: 'payment',
        description: 'Budgeting',
        subRoutes: [
          {
            routeTitle: 'Configuration',
            route: '/budget-configuration',
            resource: 'BUDGET_CONFIGURATION',
          },
          {
            routeTitle: 'Analysis',
            route: '/budget-dashboard',
            resource: 'BUDGET_ANALYSIS',
          },
        ],
      },
      {
        route: '/iaas',
        routeTitle: 'IaaS Optimization',
        routeIcon: 'library_books',
        description: 'IaaS Optimization',
        resource: this.getPermissionResourceForIaas(),
      },
      {
        route: '/contracts',
        routeTitle: 'Contracts',
        routeIcon: 'list',
        description: 'Contracts',
        resource: 'CONTRACT',
      },
      {
        routeTitle: 'Expense',
        routeIcon: 'local_atm',
        description: 'Expense',
        subRoutes: [
          {
            routeTitle: 'Allocation Strings',
            route: '/allocation-string-formats',
            resource: 'ALLOCATION_CONFIGURATIONS',
          },
          { routeTitle: 'Invoices', route: '/invoices', resource: 'BILL_VIEWS' },
          // { routeTitle: 'Journal Entries', route: '/external-resource/journal-entries', resource: 'JOURNAL_ENTRIES' },
          { routeTitle: 'Recurring Invoices', route: '/recurring-invoices', resource: 'RECURRING_INVOICE' },
        ],
      },
      {
        routeTitle: 'Workflow',
        routeIcon: 'loop',
        description: 'Workflow',
        subRoutes: [
          {
            routeTitle: 'Management Details',
            route: '/external-resource/management-details',
            resource: 'WORKFLOW_MANAGEMENT_DETAILS',
          },
          { routeTitle: 'Templates', route: '/external-resource/templates', resource: 'WORKFLOW_TEMPLATE' },
        ],
      },
      {
        route: '/',
        routeTitle: 'SaaS',
        routeIcon: 'library_books',
        description: 'Saas',
        subRoutes: this.saasMenuProvider.getSaasMenusBasedOnRole(),
      },
      {
        route: environment.oldUiBaseUrl + '/main.htm',
        routeTitle: 'Cloud Core',
        routeIcon: 'settings',
        resource: 'CLOUD_ADMIN',
        isExternalLink: true,
        externalLinkTarget: '_blank',
      },
    ],
  };

  getPermissionResourceForIaas() {
    const resourceList = this.authenticationService.getResourceList();
    if (resourceList !== null || resourceList !== '') {
      const tenantDetails = sessionContext.tenantInfo;
      const isIaasOptimized = tenantDetails !== null ? tenantDetails.isIaasOptimized : false;
      if (!isIaasOptimized) {
        return '';
      }
    }
    return resourceList;
  }

  public buildApplicationDrawer(): NavAppDrawerItem[] {
    // App Drawer can be disabled or enabled per instance.
    if (!environment.appDrawer.enabled) {
      return [];
    }

    const appList: NavAppDrawerItem[] = [] as NavAppDrawerItem[];

    sessionContext.userApps
      .filter(item => {
        return !this.isExcludedApplication(item.name); // Exclude certain applications (current and possibly few others).
      })
      .forEach((item: ApplicationInfo) => {
        const drawerItem = {} as NavAppDrawerItem;
        drawerItem.icon = item.icon;
        drawerItem.url = item.url;
        drawerItem.displayName = item.name || '';

        appList.push(drawerItem);
      });

    appList.sort((a, b) => {
      return a.displayName.localeCompare(b.displayName);
    });

    return appList.length > 0 ? appList : null;
  }

  public secureMenuItems(): any {
    // Doing a deep copy and passing it for building the menu.
    const copyMenuList = JSON.parse(JSON.stringify(this.masterMenuList));

    return this.buildSecureMenu(copyMenuList).subRoutes;
  }

  private buildSecureMenu(navGroup: any): any {
    navGroup.subRoutes = navGroup.subRoutes.filter(item => {
      if (item.subRoutes) {
        item = this.buildSecureMenu(item);
      }

      return this.isAllowed(item);
    });

    return navGroup;
  }

  private isAllowed(navItem: any): boolean {
    return (
      (navItem.subRoutes && navItem.subRoutes.length > 0) || this.authenticationService.hasAccess(navItem.resource)
    );
  }

  // Function to check if the app name being passed is in the excluded list specified in environment file.
  private isExcludedApplication(appName: string): boolean {
    return environment.appDrawer.excludedApps.some(element => {
      return element.toLowerCase() === appName.toLowerCase();
    });
  }
}
