import { BaseEntity } from '../../base/base-entity';

/**
 * This class represent the InvoiceSummaryByChargeType entity.
 */
export class InvoiceSummaryByChargeType extends BaseEntity {
  invoice_summary_by_charge_type_row_id: number;

  charge_type: string;
  total_charge_value: { value: number; indicator: string };
  previous_invoice_amount_value: { value: number; indicator: string };
  diff_amount_value: { value: number; indicator: string };
}
