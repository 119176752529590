import { Pipe, PipeTransform } from '@angular/core';
import { Money } from 'src/app/base/common';
import { CurrencyPipe } from '@angular/common';

@Pipe({ name: 'monetize' })
export class MonetizePipe extends CurrencyPipe implements PipeTransform {
  transform(input: Money | number) {
    const money = this.convertToMoneyObj(input);

    return super.transform(money.value, money.indicator);
  }

  // Converts a number to Money type. Ensures moeny value is default to 0 and indicator is default to USD
  convertToMoneyObj(input: Money | number): Money {
    const money: Money = { value: 0, indicator: 'USD' } as Money;

    if (typeof input === 'number') {
      money.value = input;
    } else {
      money.value = input.value || 0;
      money.indicator = input.indicator || 'USD';
    }
    return money;
  }
}

@Pipe({ name: 'monetizeWithIndicator' })
export class MonetizeWithIndicatorPipe extends MonetizePipe implements PipeTransform {
  transform(input: Money | number) {
    const money: Money = this.convertToMoneyObj(input);

    return super.transform(money) + ' ' + money.indicator;
  }
}
