import { Component, OnInit, ViewChild } from '@angular/core';
import { GoToasterService, GoTableComponent, GoModalService } from '@tangoe/goponents';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BaseModalEditComponent } from '../../base/base-modal-edit.component';
import { Result } from '../../base/result';
import { ItemSelector, EmbeddedTableContext } from '../../base/common';
import * as utils from '../../base/utils';
import { CustomValidators } from '../../base/validators';
import { ManagePortalForTenantService } from './manage-portal-for-tenant.service';
import { VendorPortal } from './manage-portal-for-tenant';

@Component({
  selector: 'app-manage-portal-for-tenant-modal',
  templateUrl: './manage-portal-for-tenant-modal.component.html',
  styleUrls: ['./manage-portal-for-tenant-modal.component.scss'],
})
export class ManagePortalForTenantModalComponent extends BaseModalEditComponent<VendorPortal, 'vendor_portal'>
  implements OnInit {
  master_vendor_selection: number;

  constructor(
    public managePortalForTenantService: ManagePortalForTenantService,
    protected toasterService: GoToasterService,
    protected modalService: GoModalService,
    protected router: Router,
    protected route: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {
    super(managePortalForTenantService, toasterService, modalService, router, route);
    this.listContextPath = '/manage-portal-for-tenants';
    this.editContextPath = '/manage-portal-for-tenants';
    this.entityLocator = 'vendor_portal';
  }

  ngOnInit() {
    this.loadRecord();
  }

  populateForm(): void {
    this.formData = this.formBuilder.group({
      master_vendor: [utils.extractLookupId(this.entity.master_vendor), [Validators.required]],

      access_type: [utils.extractDynamicValueId(this.entity.access_type), [Validators.required]],

      vendor_portalusername: [this.entity.vendor_portalusername, [Validators.required, Validators.maxLength(128)]],

      vendor_portalpassword: [this.entity.vendor_portalpassword, [Validators.required, Validators.maxLength(128)]],

      security_question: [this.entity.security_question, [Validators.maxLength(256)]],

      security_answer: [this.entity.security_answer, [Validators.maxLength(128)]],

      web_address: [this.entity.web_address, [Validators.required, Validators.maxLength(256)]],
    });

    this.master_vendor_selection = utils.extractLookupId(this.entity.master_vendor);
  }

  extractFormData(): VendorPortal {
    const entity: VendorPortal = new VendorPortal();

    const {
      master_vendor,
      access_type,
      vendor_portalusername,
      vendor_portalpassword,
      security_question,
      security_answer,
      web_address,
    } = this.formData.value;

    entity.vendor_portalusername = vendor_portalusername;
    entity.vendor_portalpassword = vendor_portalpassword;
    entity.security_question = security_question;
    entity.security_answer = security_answer;
    entity.web_address = web_address;

    entity.addDynamicListValue('access_type', access_type);

    entity.addAssociation('masterVendor', 'vendor_row_id', master_vendor);

    if (this.isAddMode) {
      entity.addAssociation('tenant', 'tenant_row_id', this.parentId);
    } else {
      entity.id = this.id;
      entity.vendor_portal_row_id = this.id;
    }

    return entity;
  }
}
