import { Component, OnInit } from '@angular/core';
import { BaseFieldComponent } from '../field-base';

@Component({
  selector: 'app-field-toggle',
  templateUrl: './field-toggle.component.html',
  styleUrls: ['./field-toggle.component.scss'],
})
export class FieldToggleComponent extends BaseFieldComponent implements OnInit {
  ngOnInit(): void {
    super.ngOnInit();
  }
}
