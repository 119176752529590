import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Environment } from '../shared/environment';
import { ISisenseFolder } from '../shared/models/sisense/ISisenseFolder';
import { ISisenseDashboard } from '../shared/models/sisense/ISisenseDashboard';
import { ISisenseUser } from '../shared/models/sisense/ISisenseUser';
import { ISisenseUserAuthorized } from '../shared/models/sisense/ISisenseUserAuthorized';
import { ISisenseNavver } from '../shared/models/sisense/ISisenseNavver';

@Injectable({
  providedIn: 'root',
})
export class SisenseApiService {
  readonly sisenseApiV1Url = Environment.getSisenseApiV1Url();
  readonly sisenseApiV0Url = Environment.getSisenseApiV0Url();

  // list of fields to return with any data relating to dashboards
  private readonly dashboardFields: string = 'fields=title,oid,parentFolder';

  constructor(private http: HttpClient) {}

  isUserAuthorized(): Observable<ISisenseUserAuthorized> {
    return this.http.get<ISisenseUserAuthorized>(this.sisenseApiV0Url + 'auth/isauth');
  }

  getFolders(): Observable<ISisenseFolder[]> {
    return this.http.get<ISisenseFolder[]>(this.sisenseApiV1Url + 'folders');
  }

  getAllUserDashboardsInFolder(parentFolderId): Observable<ISisenseDashboard[]> {
    return this.http.get<ISisenseDashboard[]>(
      this.sisenseApiV1Url + `dashboards?parentFolder=${parentFolderId}&${this.dashboardFields}`
    );
  }

  getAllUserDashboards(): Observable<ISisenseDashboard[]> {
    return this.http.get<ISisenseDashboard[]>(this.sisenseApiV1Url + `dashboards?${this.dashboardFields}`);
  }

  getNavver(): Observable<ISisenseNavver> {
    return this.http.get<ISisenseNavver>(this.sisenseApiV1Url + 'navver');
  }

  /**
   * Retrieves all current user details within sisense
   */
  getUser(): Observable<ISisenseUser> {
    const url: string = this.sisenseApiV0Url + 'users/loggedIn';

    return this.http.get<ISisenseUser>(url);
  }
}
