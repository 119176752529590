import { GoTableConfig, GoTableComponent, GoToasterService, GoModalService } from '@tangoe/goponents';
import { ViewChild, ElementRef, Input, OnInit, Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription, fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { BaseEntity, AssignmentEntity } from './base-entity';
import { BaseService } from './base-service';
import { Result } from './result';
import {
  ModalTableContext,
  ErrorItem,
  selectedTableRows,
  resetTableRowSelections,
  hasTableConfigChanged,
} from './common';
import { BaseModal } from './base-modal';
import * as utils from './utils';

@Component({
  template: '',
})
export abstract class BaseModalListComponent<T extends BaseEntity, K extends string> extends BaseModal
  implements OnInit {
  public modalTableContext: ModalTableContext;

  public showButtonLoading: boolean;
  public errors: ErrorItem = new ErrorItem();

  @Input() parentId: number;
  @Input() mode: string;
  @Input() rowData: any = {};
  @Input() appendTo: string;
  @Input() modalCallback: () => void;

  constructor(
    protected toasterService: GoToasterService,
    protected entityService: BaseService<T, K>,
    protected modalService: GoModalService,
    protected el: ElementRef
  ) {
    super(modalService);
  }

  @ViewChild('modalEntityRefTable', { static: false }) modalEntityRefTable: GoTableComponent;

  abstract linkedEntity(): string;
  abstract primaryEntity(): string;

  abstract otherEntity(): string;
  abstract otherEntitySearch(): string;
  abstract otherEntitySort(): string;

  primaryEntityName(): string {
    return utils.lowerFirst(utils.attributize(this.primaryEntity()));
  }

  primaryEntityRef(): string {
    return utils.snakeCase(this.primaryEntity());
  }

  primaryEntityRowId(): string {
    return utils.lowerFirst(utils.attributize(this.primaryEntity()) + 'RowId');
  }

  otherEntityName(): string {
    return utils.lowerFirst(utils.attributize(this.otherEntity()));
  }

  otherEntityRef(): string {
    return utils.snakeCase(this.otherEntity());
  }

  otherEntityRowId(): string {
    return utils.lowerFirst(utils.attributize(this.otherEntity()) + 'RowId');
  }

  addAssignmentMethod(): string {
    return 'insertAssignment' + utils.upperFirst(utils.attributize(this.linkedEntity())) + 'Records';
  }

  listUnassignedMethod(): string {
    return 'findUnassigned' + utils.upperFirst(utils.attributize(this.linkedEntity())) + 'Records';
  }

  ngOnInit() {
    this.modalTableContext = new ModalTableContext(
      this.linkedEntity(),
      this.otherEntitySearch(),
      this.otherEntitySort()
    );

    this.modalTableContext.parentId = this.parentId;
    this.loadModalTableData();
  }

  selectedRows() {
    return selectedTableRows(this.modalEntityRefTable);
  }

  renderTableData<K1 extends string>(result: Result<K1, any[]>) {
    this.modalTableContext.showLoader = false;
    this.modalTableContext.tableConfig.totalCount = result.count;
    this.modalTableContext.tableConfig.tableData = result[this.otherEntityRef()];

    if (result[this.otherEntityRef()].length > 0) {
      this.modalTableContext.tableConfig.searchConfig.searchable = true;
    }

    this.modalTableContext.tableConfig = { ...this.modalTableContext.tableConfig };
  }

  onModalTableChange($event: GoTableConfig) {
    if (!hasTableConfigChanged($event, this.modalTableContext.pageContext)) {
      return;
    }

    this.modalTableContext.tableConfig = $event;

    this.modalTableContext.pageContext.pageParam.perPage = this.modalTableContext.tableConfig.pageConfig.perPage;
    this.modalTableContext.pageContext.pageParam.offset = this.modalTableContext.tableConfig.pageConfig.offset;

    if (this.modalTableContext.tableConfig.sortConfig) {
      this.modalTableContext.pageContext.pageParam.sortDir = this.modalTableContext.tableConfig.sortConfig.direction;
      this.modalTableContext.pageContext.pageParam.sortAttr = this.modalTableContext.tableConfig.sortConfig.column;
    }

    // Reset the offset only if the search term has changed so that we get the searched data from first page.
    if (
      this.modalTableContext.pageContext.simpleFilter.searchValue !==
      this.modalTableContext.tableConfig.searchConfig.searchTerm
    ) {
      this.modalTableContext.pageContext.simpleFilter.searchValue = this.modalTableContext.tableConfig.searchConfig.searchTerm;

      this.modalTableContext.pageContext.pageParam.offset = 0;
      this.modalTableContext.tableConfig.pageConfig.offset = 0;
    }

    this.loadModalTableData();
  }

  loadModalTableData() {
    this.modalTableContext.showLoader = true;
    this.modalTableContext.tableConfig.noDataText = 'No data';

    if (this.modalTableContext.parentId === 0) {
      this.modalTableContext.showLoader = false;
      this.modalTableContext.tableConfig.noDataText = `Could not find any data to load`;

      this.modalTableContext.tableConfig = { ...this.modalTableContext.tableConfig };

      return;
    }

    resetTableRowSelections(this.modalEntityRefTable);

    this.entityService[this.listUnassignedMethod()](
      this.modalTableContext.parentId,
      this.modalTableContext.pageContext.pageParam,
      this.modalTableContext.pageContext.simpleFilter.searchValue
    ).subscribe(
      result => {
        this.renderTableData(result);
        this.scrollToTop();
      },
      error => {
        this.modalTableContext.showLoader = false;
        this.modalTableContext.tableConfig.noDataText = 'Error loading data';
        this.modalTableContext.tableConfig = { ...this.modalTableContext.tableConfig };
      }
    );
  }

  assign() {
    const idsToAssign = this.selectedRows();
    if (idsToAssign.length === 0) {
      this.toasterService.toastInfo({
        header: 'Alert!',
        message: 'No records selected to assign.',
      });
      return;
    }

    this.showButtonLoading = true;
    this.modalTableContext.showLoader = true;

    const assignmentList: any[] = [];

    idsToAssign.forEach(selectedId => {
      const assignment: AssignmentEntity = new AssignmentEntity();
      assignment.addAssociation(this.primaryEntityName(), this.primaryEntityRowId(), this.parentId);
      assignment.addAssociation(this.otherEntityName(), this.otherEntityRowId(), selectedId);

      assignmentList.push(assignment);
    });

    this.entityService[this.addAssignmentMethod()](assignmentList, idsToAssign.join(','), this.parentId).subscribe(
      result => {
        this.errors.clear();

        this.toasterService.toastSuccess({
          header: 'Success!',
          message: 'Successfully assigned records.',
        });

        this.showButtonLoading = false;
        this.modalTableContext.showLoader = false;

        this.closeAndRefresh();
      },
      error => {
        this.errors.set(error);

        this.showButtonLoading = false;
        this.modalTableContext.showLoader = false;
      }
    );
  }

  cancel() {
    this.modalService.toggleModal(false);
  }

  closeAndRefresh() {
    this.modalService.toggleModal(false);
    this.modalCallback();
  }

  scrollToTop() {
    const divElem = document.querySelectorAll(
      'div.go-modal__container-xl > .ng-star-inserted > go-table > .go-table-container > div.go-table'
    )[0];
    if (divElem) {
      divElem.scrollTop = 0;
    }
  }
}
