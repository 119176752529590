import { BaseEntity } from '../../base/base-entity';

/**
 * This class represent the InvoiceBilledAsset entity.
 */
export class InvoiceBilledAsset extends BaseEntity {
  invoice_billed_asset_row_id: number;

  asset_identifier: string;
  asset_id: string;
  asset_type_name: string;
  owner: string;
  owner_status: string;
  total_charges: { value: number; indicator: string };
  prev_invoice_amount_value: { value: number; indicator: string };
  difference_from_previous_invoice: { value: number; indicator: string };
  account_number: string;
  in_discovery: string;
}
