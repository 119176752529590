import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { GoModalService, GoToasterService } from '@tangoe/goponents';
import { BaseModalEditComponent } from '../../../base/base-modal-edit.component';
import { ActivatedRoute, Router } from '@angular/router';
import * as utils from '../../../base/utils';
import { RemittanceInfo } from '../remittance';
import { RemittanceInfoService } from '../remittance.service';
@Component({
  selector: 'app-manage-remittance-info-modal',
  templateUrl: './manage-remittance-info-modal.component.html',
  styleUrls: ['./manage-remittance-info-modal.component.scss'],
})
export class ManageRemittanceInfoModalComponent extends BaseModalEditComponent<RemittanceInfo, 'remittance_info'>
  implements OnInit {
  remit_address_selection: number;
  remit_code_selection: number;

  vendor_id_selection: number;
  vendor_name_selection: string;

  entityName: string;

  constructor(
    public remittanceInfoService: RemittanceInfoService,
    protected toasterService: GoToasterService,
    protected modalService: GoModalService,
    protected router: Router,
    protected route: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {
    super(remittanceInfoService, toasterService, modalService, router, route);
    this.entityLocator = 'recurring_invoice';
  }

  ngOnInit() {
    this.id = this.rowData.id;

    this.entity = new RemittanceInfo();
    this.entity.vendor = this.rowData.vendor || this.rowData.tenant_vendor;
    this.entity.remittance_address = this.rowData.remittance_address;
    this.entity.vendor_remittance_code = this.rowData.vendor_remittance_code;

    if (this.entityName === 'invoiceHeaders') {
      this.entityLocator = 'invoice_header';
    }

    this.populateForm();
  }

  populateForm(): void {
    this.formData = this.formBuilder.group({
      remit_address: [utils.extractLookupId(this.entity.remittance_address), [Validators.required]],
      remit_code: [utils.extractLookupId(this.entity.vendor_remittance_code), []],
    });

    this.remit_address_selection = utils.extractLookupId(this.entity.remittance_address);
    this.remit_code_selection = utils.extractLookupId(this.entity.vendor_remittance_code);

    this.vendor_name_selection = this.entity.vendor ? this.entity.vendor.name : this.vendor_name_selection;
    this.vendor_id_selection = this.entity.vendor ? this.entity.vendor.id : this.vendor_id_selection;

    this.remitCodeDisplayHandler(this.remit_address_selection);

    this.formData.get('remit_address').valueChanges.subscribe(value => {
      this.remitCodeDisplayHandler(value);
    });
  }

  remitCodeDisplayHandler(value: any) {
    if (value) {
      setTimeout(() => {
        this.formData.get('remit_code').enable();
        if (value !== this.remit_address_selection) {
          this.remit_address_selection = value;
          this.formData.get('remit_code').reset();
        }
      }, 100);
    } else {
      this.formData.get('remit_code').reset();
      this.formData.get('remit_code').disable();
    }
  }

  extractFormData(): RemittanceInfo {
    const entity: RemittanceInfo = new RemittanceInfo();

    const { remit_address, remit_code } = this.formData.value;

    entity.addAssociation('remittanceAddress', 'remittance_address_row_id', remit_address);
    entity.addAssociation('vendorRemittanceCode', 'vendor_remittance_code_row_id', remit_code);

    if (this.entityName === 'invoiceHeaders') {
      entity.invoice_id = this.rowData.id;
    }
    entity.id = this.rowData.id;

    return entity;
  }

  cancel() {
    super.cancel(this.entity);
  }

  saveRecord() {
    this.remittanceInfoService.setUrlPath(this.entityName);
    super.saveRecord();
  }

  closeAndRefresh() {
    super.closeAndRefresh(this.entity);
  }

  isDirty() {
    if (utils.extractLookupId(this.entity.remittance_address) !== this.formData.get('remit_address').value) {
      return false;
    }

    if (utils.extractLookupId(this.entity.vendor_remittance_code) !== this.formData.get('remit_code').value) {
      return false;
    }

    return false;
  }
}
