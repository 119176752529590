import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppShellComponent } from './app-shell.component';
import {
  GoActionSheetModule,
  GoFooterModule,
  GoHeaderModule,
  GoIconModule,
  GoLayoutModule,
  GoSideNavModule,
  GoIconButtonModule,
  GoLoaderModule,
} from '@tangoe/goponents';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [AppShellComponent],
  imports: [
    CommonModule,
    GoLayoutModule,
    GoHeaderModule,
    GoIconModule,
    GoActionSheetModule,
    GoSideNavModule,
    GoFooterModule,
    GoIconButtonModule,
    SharedModule,
    GoLoaderModule,
  ],
})
export class AppShellModule {}
