export function labelize(text: string) {
  const result = text.replace('_', ' ').replace(/([A-Z])/g, ' $1');
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
}

export function snakeCase(text: string): string {
  return text.replace(/([A-Z])/g, '_$1').toLowerCase();
}

export function lowerFirst(text: string) {
  return text.charAt(0).toLowerCase() + text.slice(1);
}

export function upperFirst(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function attributize(text: string) {
  const result = text.replace(/([-_][a-z])/gi, $1 => {
    return $1
      .toUpperCase()
      .replace('-', '')
      .replace('_', '');
  });
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export function dateToString(date: Date): string {
  if (!date) {
    return null;
  }
  // Convert the date to the YYYY-MM-DD format
  return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
}

export function formatDate(date: Date): string {
  if (!date) {
    return;
  }

  return date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
}

export function formatDateTime(dateTime: Date): string {
  if (!dateTime) {
    return;
  }

  return formatDate(dateTime) + ' ' + dateTime.getHours() + ':' + dateTime.getMinutes() + ':' + dateTime.getSeconds();
}

export function stringToDate(text: string): Date {
  if (!text) {
    return;
  }

  // Assume the incoming date is in this format - YYYY-MM-DD or YYYY-MM-DDTHH:mm:ss.ZZZZ
  const dateTimeParts: string[] = text.split('T');
  const dateParts: string[] = dateTimeParts[0].split('-');

  if (dateTimeParts.length === 2) {
    const timeParts: string[] = dateTimeParts[1].split(':');

    // YYYY-MM-DDTHH:mm:ss.ZZZ
    return new Date(
      Number(dateParts[0]),
      Number(dateParts[1]) - 1, // Month index starts from 0.
      Number(dateParts[2]),
      Number(timeParts[0]),
      Number(timeParts[1]),
      Number(timeParts[2].split('.')[0])
    );
  } else {
    // YYYY-MM-DD
    return new Date(Number(dateParts[0]), Number(dateParts[1]) - 1, Number(dateParts[2]));
  }
}

export function extractLookupId(lkupVal: { id: number }): number | undefined {
  return lkupVal ? lkupVal.id : undefined;
}

export function extractDynamicValueId(dlv: { value_id: number }): number | undefined {
  return dlv ? dlv.value_id : undefined;
}

export function extractDynamicValue(dlv: { value: string }): string | undefined {
  return dlv ? dlv.value : undefined;
}

export function extractCurrencyValue(currency: { value: number; indicator: string }): number | undefined {
  return currency ? currency.value : undefined;
}

export function formatCurrency(currency: number): string | undefined {
  if (!currency) {
    return undefined;
  }

  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  return '$ ' + currency.toLocaleString('en', options);
}

export function extractAndFormatCurrency(currency: { value: number; indicator: string }): string | undefined {
  return formatCurrency(extractCurrencyValue(currency));
}

export function toBoolean(value: string | boolean | number): string {
  return value === 'true' || value === true || value === '1' || value === 1 ? '1' : '0';
}

export function isTrue(value: string | boolean | number): boolean {
  return value === 'true' || value === true || value === '1';
}

export function isNotNull(value: string | number): boolean {
  return value !== undefined && value !== '' && value !== 'null' && value !== null;
}

export function compareAlphaNumericOnly(s1: string, s2: string): boolean {
  return s1 !== undefined && s2 !== undefined && s1.replace(/[^A-Za-z0-9]/g, '') === s2.replace(/[^A-Za-z0-9]/g, '');
}

/**
 * Use to convert a hexidecimal string to an RGB interface
 * @param hex hexidecimal string to convert
 */
export function RGBInterfaceToRGBString(result) {
  let rgbVal = [];
  if (result) {
    rgbVal = [result.r, result.g, result.b];
    return 'rgb(' + rgbVal.join(', ') + ')';
  } else {
    throw new Error(
      '\n\n    GoConfigService: Unable to convert RGB interface to RGB string, invalid RGB code.\n\n    RGB: ' +
        result +
        '\n    '
    );
  }
}
