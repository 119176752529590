import { Routes } from '@angular/router';
import { AllocationInfoListComponent } from './allocation-info-list/allocation-info-list.component';
import { AllocationInfoEditComponent } from './allocation-info-edit/allocation-info-edit.component';

export const ALLOCATIONINFO_ROUTES: Routes = [
  {
    path: '',
    component: AllocationInfoListComponent,
  },
  {
    path: ':id',
    component: AllocationInfoEditComponent,
  },
];
