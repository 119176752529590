import { BaseEntity } from '../../base/base-entity';

/**
 * This class represent the LocationAddress entity.
 */
export class LocationAddress extends BaseEntity {
  location_address_row_id: number;

  address_id: string;
  type: string;
  country: { id: number; name: string };
  state_province: { id: number; name: string };
  street1: string;
  street2: string;
  street3: string;
  city: string;
  zip_postal_code: string;
  is_primary: string;
}
