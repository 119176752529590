import { Component, OnInit, ViewChild } from '@angular/core';
import { GoToasterService, GoTableComponent, GoModalService } from '@tangoe/goponents';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BaseModalEditComponent } from '../../base/base-modal-edit.component';
import { Result } from '../../base/result';
import { ItemSelector, EmbeddedTableContext } from '../../base/common';
import * as utils from '../../base/utils';
import { CustomValidators } from '../../base/validators';
import { ManageContactForTenantService } from './manage-contact-for-tenant.service';
import { TenantContact } from './manage-contact-for-tenant';

@Component({
  selector: 'app-manage-contact-for-tenant-modal',
  templateUrl: './manage-contact-for-tenant-modal.component.html',
  styleUrls: ['./manage-contact-for-tenant-modal.component.scss'],
})
export class ManageContactForTenantModalComponent extends BaseModalEditComponent<TenantContact, 'tenant_contact'>
  implements OnInit {
  categorySelectionList: any = [];
  contactTypeSelectionList: any = [];

  constructor(
    public manageContactForTenantService: ManageContactForTenantService,
    protected toasterService: GoToasterService,
    protected modalService: GoModalService,
    protected router: Router,
    protected route: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {
    super(manageContactForTenantService, toasterService, modalService, router, route);
    this.listContextPath = '/manage-contact-for-tenants';
    this.editContextPath = '/manage-contact-for-tenants';
    this.entityLocator = 'tenant_contact';
  }

  ngOnInit() {
    this.loadRecord();
  }

  buildAnyStaticDropdowns(): void {
    // Populate Category Selection
    this.categorySelectionList = [
      { value: 'INTERNAL', name: 'Internal' },
      { value: 'CLIENT', name: 'Client' },
      { value: 'VENDOR', name: 'Vendor' },
    ];

    // Populate Contact Type Selection
    this.contactTypeSelectionList = [
      { value: 'MAIN', name: 'Main' },
      { value: 'EMERGENCY', name: 'Emergency' },
      { value: 'ESCALATION', name: 'Escalation' },
      { value: 'AUDIT', name: 'Audit' },
      { value: 'OPTIMIZATION', name: 'Optimization' },
      { value: 'INVOICE_PROCESSING', name: 'Invoice Processing' },
      { value: 'INVENTORY', name: 'Inventory' },
      { value: 'EXECUTIVE_MANAGEMENT', name: 'Executive Management' },
      { value: 'SALES', name: 'Sales' },
      { value: 'OTHER', name: 'Other' },
      { value: 'All', name: 'All' },
    ];
  }

  populateForm(): void {
    this.formData = this.formBuilder.group({
      name: [this.entity.name, [Validators.required, Validators.maxLength(64)]],

      email: [this.entity.email, [Validators.required, Validators.maxLength(64), Validators.email]],

      category: [this.entity.category, [Validators.required, Validators.maxLength(64)]],

      contact_type: [this.entity.contact_type, [Validators.required, Validators.maxLength(64)]],

      desk_phone: [this.entity.desk_phone, [Validators.maxLength(20)]],

      mobile_phone: [this.entity.mobile_phone, [Validators.maxLength(20)]],
    });
  }

  extractFormData(): TenantContact {
    const entity: TenantContact = new TenantContact();

    const { name, email, category, contact_type, desk_phone, mobile_phone } = this.formData.value;

    entity.name = name;
    entity.email = email;
    entity.category = category;
    entity.contact_type = contact_type;
    entity.desk_phone = desk_phone;
    entity.mobile_phone = mobile_phone;

    if (this.isAddMode) {
      entity.addAssociation('tenant', 'tenant_row_id', this.parentId);
    } else {
      entity.id = this.id;
      entity.tenant_contact_row_id = this.id;
    }

    return entity;
  }
}
