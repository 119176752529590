import { Component, OnInit, ElementRef } from '@angular/core';
import { GoToasterService, GoModalService } from '@tangoe/goponents';
import { BaseModalListComponent } from '../../base/base-modal-list.component';
import { EmployeeGroupService } from '../employee-group.service';
import { EmployeeGroup } from '../employee-group';

@Component({
  selector: 'app-assign-employee-to-group-modal',
  templateUrl: './assign-employee-to-group-modal.component.html',
  styleUrls: ['./assign-employee-to-group-modal.component.scss'],
})
export class AssignEmployeeToGroupModalComponent extends BaseModalListComponent<EmployeeGroup, 'employee_group'> {
  constructor(
    protected toasterService: GoToasterService,
    protected employeeGroupService: EmployeeGroupService,
    protected modalService: GoModalService,
    protected el: ElementRef
  ) {
    super(toasterService, employeeGroupService, modalService, el);
  }

  linkedEntity(): string {
    return 'employee_group_membership';
  }

  primaryEntity(): string {
    return 'employee_group';
  }

  otherEntity(): string {
    return 'employee';
  }

  otherEntitySearch(): string {
    return 'employee_id,first_name,last_name';
  }

  otherEntitySort(): string {
    return 'first_name';
  }
}
