import { Component, OnInit, ViewChild } from '@angular/core';
import { GoToasterService, GoTableComponent, GoModalService } from '@tangoe/goponents';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BaseModalEditComponent } from '../../base/base-modal-edit.component';
import { Result } from '../../base/result';
import { ItemSelector, EmbeddedTableContext } from '../../base/common';
import * as utils from '../../base/utils';
import { CustomValidators } from '../../base/validators';
import { ManageVendorCodeService } from './manage-vendor-code.service';
import { TenantVendorCode } from './manage-vendor-code';

@Component({
  selector: 'app-manage-vendor-code-modal',
  templateUrl: './manage-vendor-code-modal.component.html',
  styleUrls: ['./manage-vendor-code-modal.component.scss'],
})
export class ManageVendorCodeModalComponent extends BaseModalEditComponent<TenantVendorCode, 'tenant_vendor_code'>
  implements OnInit {
  constructor(
    public manageVendorCodeService: ManageVendorCodeService,
    protected toasterService: GoToasterService,
    protected modalService: GoModalService,
    protected router: Router,
    protected route: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {
    super(manageVendorCodeService, toasterService, modalService, router, route);
    this.listContextPath = '/manage-vendor-codes';
    this.editContextPath = '/manage-vendor-codes';
    this.entityLocator = 'tenant_vendor_code';
  }

  ngOnInit() {
    this.loadRecord();
  }

  populateForm(): void {
    this.formData = this.formBuilder.group({
      tenant_vendor_code: [this.entity.tenant_vendor_code, [Validators.required, Validators.maxLength(32)]],
    });
  }

  extractFormData(): TenantVendorCode {
    const entity: TenantVendorCode = new TenantVendorCode();

    const { tenant_vendor_code } = this.formData.value;

    entity.tenant_vendor_code = tenant_vendor_code;

    if (this.isAddMode) {
      entity.addAssociation('vendor', 'vendor_row_id', this.parentId);
    } else {
      entity.id = this.id;
      entity.tenant_vendor_code_row_id = this.id;
    }

    return entity;
  }
}
