import { Component, OnInit, ViewChild } from '@angular/core';
import { GoToasterService, GoTableComponent, GoModalService } from '@tangoe/goponents';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BaseModalEditComponent } from '../../base/base-modal-edit.component';
import { Result } from '../../base/result';
import { ItemSelector, EmbeddedTableContext } from '../../base/common';
import * as utils from '../../base/utils';
import { CustomValidators } from '../../base/validators';
import { ManageAssociatedRecurringInvoicesService } from './manage-associated-recurring-invoices.service';
import { InvoiceHeader } from './manage-associated-recurring-invoices';

@Component({
  selector: 'app-manage-associated-recurring-invoices-modal',
  templateUrl: './manage-associated-recurring-invoices-modal.component.html',
  styleUrls: ['./manage-associated-recurring-invoices-modal.component.scss'],
})
export class ManageAssociatedRecurringInvoicesModalComponent
  extends BaseModalEditComponent<InvoiceHeader, 'invoice_header'>
  implements OnInit {
  constructor(
    public manageAssociatedRecurringInvoicesService: ManageAssociatedRecurringInvoicesService,
    protected toasterService: GoToasterService,
    protected modalService: GoModalService,
    protected router: Router,
    protected route: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {
    super(manageAssociatedRecurringInvoicesService, toasterService, modalService, router, route);
    this.listContextPath = '/manage-associated-recurring-invoiceses';
    this.editContextPath = '/manage-associated-recurring-invoiceses';
    this.entityLocator = 'invoice_header';
  }

  ngOnInit() {
    this.loadRecord();
  }

  populateForm(): void {
    this.formData = this.formBuilder.group({});
  }

  extractFormData(): InvoiceHeader {
    const entity: InvoiceHeader = new InvoiceHeader();

    const {} = this.formData.value;

    if (this.isAddMode) {
      entity.addAssociation('recurring_invoice', 'recurring_invoice_row_id', this.parentId);
    } else {
      entity.id = this.id;
      entity.invoice_header_row_id = this.id;
    }

    return entity;
  }
}
