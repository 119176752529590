import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GoModalService, GoToasterService } from '@tangoe/goponents';
import { BaseModalEditComponent } from 'src/app/base/base-modal-edit.component';
import { Export } from '../export';
import { ExportModalService } from '../export-modal.service';
import { DatePipe } from '@angular/common';
import { PageContext } from 'src/app/base/common';
import { delay } from 'rxjs/operators';
import fileSaver from 'file-saver';

@Component({
  selector: 'app-export-modal',
  templateUrl: './export-modal.component.html',
  styleUrls: ['./export-modal.component.scss'],
  providers: [DatePipe],
})
export class ExportModalComponent extends BaseModalEditComponent<Export, 'export'> implements OnInit {
  fileTypes: any[];
  submitButtonLoading = false;
  procesingDateFormat = new RegExp(/^(0?[1-9]|1[0-2])\/([0-9]{4})$/);

  @Input() pageContext: PageContext;
  @Input() exportLayout: any;
  @Input() baseUrl: string;
  @Input() defaultExportAttr: string;
  @Input() dateSelectionList: { name: string; value: string }[];

  constructor(
    public exportModalService: ExportModalService,
    protected toasterService: GoToasterService,
    protected modalService: GoModalService,
    protected router: Router,
    protected route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe
  ) {
    super(exportModalService, toasterService, modalService, router, route);
  }

  ngOnInit(): void {
    this.fileTypes = [
      { name: 'xlsx', value: 'xlsx' },
      { name: 'csv', value: 'csv' },
    ];

    this.populateForm();
  }

  populateForm(): void {
    this.formData = this.formBuilder.group({
      fileType: [undefined, Validators.required],
      single_processing_Date: [undefined, Validators.required],
    });

    this.handlesingleProcessingDataValueChanges();
  }

  handlesingleProcessingDataValueChanges(): void {
    this.formData.get('single_processing_Date').valueChanges.subscribe(value => {
      if (this.procesingDateFormat.test(value) || !value) {
        // this.formData.controls['single_processing_Date'].setErrors(null);
      } else {
        this.formData.controls['single_processing_Date'].setErrors(this.getDateValidationErrorMessage());
      }
    });
  }

  getDateValidationErrorMessage() {
    return [
      {
        type: 'ERROR:',
        message: 'Please enter valid MM/YYYY format',
      },
    ];
  }

  transformDate(entityDate) {
    return entityDate ? this.datePipe.transform(entityDate, 'MM/yyyy') : undefined;
  }

  extractFormData(): Export {
    const entity: Export = new Export();
    const { fileType, single_processing_Date } = this.formData.value;
    entity.fileType = fileType;
    const firstDateOfMonth = this.getFirstDateOfMonth(single_processing_Date);
    entity.single_processing_Date = this.datePipe.transform(firstDateOfMonth, 'yyyy-MM-dd');

    return entity;
  }

  closeModal() {
    this.modalService.toggleModal(false);
  }

  exportRecord() {
    if (!this.validateFormData()) {
      this.raiseValidationError();
      this.showButtonLoading = false;

      return;
    }
    this.submitButtonLoading = true;
    this.entity = this.extractFormData();
    this.entity.baseUrl = this.baseUrl;
    this.entity.defaultExportAttr = this.defaultExportAttr;

    this.toasterService.toastInfo({
      header: 'Preparing Export',
      message: 'Your export is currently being prepared. Your download will begin shortly.',
    });

    // API Call for Export
    this.exportModalService
      .triggerExport(
        this.pageContext.simpleFilter,
        this.pageContext.advanceFilter,
        this.pageContext.pageParam,
        this.exportLayout,
        this.entity
      )
      .pipe(delay(1000))
      .subscribe(
        data => {
          const fileToDownload = data.file_key;
          this.entityService.downloadExport(fileToDownload).subscribe(result => {
            const fileType = this.formData.controls['single_processing_Date'].value;
            const filename = result.headers.get('filename') || `export.${fileType}`;
            this.saveFile(result.body, filename);

            this.toasterService.toastSuccess({
              header: 'Success!',
              message: 'Records have been exported successfully.',
            });
            this.submitButtonLoading = false;
            this.closeModal();
          });
        },
        error => {
          this.toasterService.toastError({
            header: 'Error!',
            message: 'Error occurred while exporting records.',
          });
          this.closeModal();
        }
      );
  }

  saveFile(data: any, filename: string) {
    const blob = new Blob([data], { type: 'application/vnd.ms-excel' });
    fileSaver.saveAs(blob, filename);
  }

  getFirstDateOfMonth(entityDate) {
    if (!entityDate) {
      return;
    }
    const month = entityDate.split('/')[0];
    const year = entityDate.split('/')[1];
    const firstDateOfMonth = new Date(year, month - 1, 1);
    return firstDateOfMonth;
  }
}
