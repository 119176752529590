import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-step-modal',
  templateUrl: './step-modal.component.html',
  styleUrls: ['./step-modal.component.scss'],
})
export class WorkflowStepModalComponent implements OnInit {
  @Input() steps: string;
  stepList: any = [];
  constructor() {}

  ngOnInit() {
    if (this.steps) {
      const steps = this.steps.split('\n');
      if (steps.length > 0) {
        this.stepList = steps;
      }
    }
  }
}
