import {
  AfterViewInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AdvanceFilter } from '../../../base/base-filter';
import { FilterInputComponent } from '../filter-input/filter-input.component';
import { FilterSelectComponent } from '../filter-select/filter-select.component';
import { FilterLookupComponent } from '../filter-lookup/filter-lookup.component';
import { FilterDynamicListComponent } from '../filter-dynamiclist/filter-dynamiclist.component';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit, AfterViewInit {
  @Input() callback: (filter: AdvanceFilter) => void;
  @Output() filtered = new EventEmitter();

  @ContentChildren(FilterInputComponent) filterInputs: QueryList<FilterInputComponent>;
  @ContentChildren(FilterSelectComponent) filterSelects: QueryList<FilterSelectComponent>;
  @ContentChildren(FilterLookupComponent) filterLookups: QueryList<FilterLookupComponent>;
  @ContentChildren(FilterDynamicListComponent) filterDynamicLists: QueryList<FilterDynamicListComponent>;

  @ViewChild('advancedFilterForm', { static: false, read: TemplateRef }) template: any;
  formGroup = new FormGroup({});

  constructor() {}

  ngOnInit() {}

  updateValues(data) {
    this.formGroup.reset();
    this.formGroup.patchValue(data);
  }

  ngAfterViewInit(): void {
    // TODO: figure out if there is a way to query them with only one @ContentChildren
    [
      ...this.filterInputs.toArray(),
      ...this.filterSelects.toArray(),
      ...this.filterLookups.toArray(),
      ...this.filterDynamicLists.toArray(),
    ].forEach(input => {
      this.formGroup.addControl(input.name, input.control);
    });
  }

  onSubmit() {
    this.filtered.emit(this.formGroup.value);
  }
}
