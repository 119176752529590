import { BaseEntity } from '../../base/base-entity';

/**
 * This class represent the InvoiceUsageDetail entity.
 */
export class InvoiceUsageDetail extends BaseEntity {
  invoice_usage_detail_row_id: number;

  account_number: string;
  usage_description: string;
  asset_type: string;
  asset_identifier: string;
  charge_amount_value: { value: number; indicator: string };
  duration: string;
  unit_of_measure: string;
  usage_date: string;
}
