import { BaseEntity } from '../../base/base-entity';

/**
 * This class represent the TenantContact entity.
 */
export class TenantContact extends BaseEntity {
  tenant_contact_row_id: number;

  name: string;
  category: string;
  contact_type: string;
  email: string;
  desk_phone: string;
  mobile_phone: string;
}
