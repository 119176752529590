import { sessionContext } from '../authentication/session-details';
import { isNotNull } from '../base/utils';

// ******* Method to check if a resource is having an action denied or not *********
// entityName   ->  entity locator name of an entity.
// action       ->  CREATE/UPDATE/DELETE.
// metaData     ->  metaData from {module}-metadata file.
// returns true ->  readOnly access
// returns false->  write access

export function isActionDenied(entityName: string, action: string, metaData: any) {
  let isDenied = false;
  if (sessionContext.deniedPermissions.length > 0) {
    const fullClassName = (metaData != null && isNotNull(metaData.fullClassName)) ? metaData.fullClassName : '';
    const resourceName = (metaData != null && isNotNull(metaData.resource)) ? metaData.resource : '';
    const desiredClass = '{' + fullClassName + '}.' + action;
    const desiredResource = '{' + resourceName + '}.VIEW';
    // Filter denied permissions for given resource.
    const deniedPermissionsForResource = sessionContext.deniedPermissions.filter(object => {
      return object['denied_list'] === desiredClass;
    });
    // Execute the logic only if Denied permissions found for given resource.
    if (deniedPermissionsForResource.length > 0) {
      // Filter allowed permissions for given resource.
      let allowedPrivilegePolicy = sessionContext.privilegePolicies.filter(object => {
        return object['allowed_list'] === desiredResource;
      });

      allowedPrivilegePolicy = allowedPrivilegePolicy.map(item => item.pp_name)
        .filter((value, index, self) => self.indexOf(value) === index && value !== undefined);
      isDenied = decidePrecedence(deniedPermissionsForResource, allowedPrivilegePolicy);
    }}
  return isDenied;
}

// ** Method to decide precedence. Write is preferred over read if  resource is not denied against all allowedPrivilegePolicies **
function decidePrecedence(deniedPermissionsForResource: string[], allowedPrivilegePolicy: string[]) {
  let isReadAccess = true;

  for (const allowedPP of allowedPrivilegePolicy) {
    if (isReadAccess === false) {
      break;
    }
    for (const deniedObject of deniedPermissionsForResource) {
      if (deniedObject['pp_name'] === allowedPP) {
        isReadAccess = true;
      } else {
        isReadAccess = false;
        break;
      }
    }
  }
  return isReadAccess;
}
