import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AllocationStringFormat } from './allocation-string-format';
import { BaseService } from '../base/base-service';
import { SimpleFilter } from '../base/base-filter';
import { environment } from '../../environments/environment';
import { Result, DeleteResult, CountResult } from '../base/result';
import { Observable } from 'rxjs';
import { QueryParam } from '../base/common';
import { metaData } from './allocation-string-format-metadata';

@Injectable({
  providedIn: 'root',
})
export class AllocationStringFormatService extends BaseService<AllocationStringFormat, 'allocation_string_format'> {
  constructor(http: HttpClient) {
    super(http);
  }

  getServiceUrl(): string {
    return environment.enterpriseDataUrl;
  }

  getMetaData(): any {
    return metaData;
  }

  getBaseUrl(): string {
    return this.getServiceUrl() + '/allocationStringFormats';
  }

  // ************************************ START:CUSTOM METHODS **************************

  // Custom method to be used by search and select for Vendor.
  findVendors(q?: string, include?: string, exclude?: string): Observable<Result<'vendor', any[]>> {
    const simpleFilter = new SimpleFilter('name', q, include, exclude);
    const queryParam = new QueryParam();
    queryParam.perPage = 25;
    queryParam.sortAttr = 'name';

    return this.listExternalEntities(environment.masterDataUrl + '/vendors/list', simpleFilter, queryParam);
  }

  // ************************************ END:CUSTOM METHODS **************************

  // ************************************ START:FETCH CHILD METHODS **************************

  // Methods to handle child records for Allocation String Part
  findChildAllocationStringPartRecords(
    parentId: number,
    queryParam: QueryParam,
    q?: string
  ): Observable<Result<'allocation_string_part', any[]>> {
    const simpleFilter = new SimpleFilter('field_mapping', q);

    return this.listExternalEntities(
      environment.enterpriseDataUrl + `/stringFormats/${parentId}/allocationStringParts`,
      simpleFilter,
      queryParam
    );
  }

  deleteChildAllocationStringPartRecordsById(id: string): Observable<DeleteResult> {
    return this.deleteExternalEntitiesById(environment.enterpriseDataUrl + '/allocationStringParts', id);
  }

  insertChildAllocationStringPartRecords(entity: any): Observable<Result<'allocation_string_part', any>> {
    return this.insertExternalEntity(environment.enterpriseDataUrl + `/allocationStringParts`, entity);
  }

  // ************************************ END:FETCH CHILD METHODS **************************

  // ************************************ START:COUNT METHODS **************************

  // ************************************ END:COUNT METHODS **************************
}
