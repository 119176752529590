import { BaseEntity } from '../../base/base-entity';

/**
 * This class represent the RemittanceAddress entity.
 */
export class RemittanceAddress extends BaseEntity {
  remittance_address_row_id: number;

  address_line1: string;
  address_line2: string;
  address_line3: string;
  city: string;
  country: { id: number; name: string };
  state_province_region: string;
  zip_postal_code: string;
  payment_type: { value_id: number; value?: string; dynamic_list_name?: string };
}
