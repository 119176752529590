import { Component, OnInit, ViewChild } from '@angular/core';
import { GoToasterService, GoTableComponent, GoModalService } from '@tangoe/goponents';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BaseModalEditComponent } from '../../base/base-modal-edit.component';
import { Result } from '../../base/result';
import { ItemSelector, EmbeddedTableContext } from '../../base/common';
import * as utils from '../../base/utils';
import { CustomValidators } from '../../base/validators';
import { ManageVendorNoteService } from './manage-vendor-note.service';
import { VendorNote } from './manage-vendor-note';

@Component({
  selector: 'app-manage-vendor-note-modal',
  templateUrl: './manage-vendor-note-modal.component.html',
  styleUrls: ['./manage-vendor-note-modal.component.scss'],
})
export class ManageVendorNoteModalComponent extends BaseModalEditComponent<VendorNote, 'vendor_note'>
  implements OnInit {
  constructor(
    public manageVendorNoteService: ManageVendorNoteService,
    protected toasterService: GoToasterService,
    protected modalService: GoModalService,
    protected router: Router,
    protected route: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {
    super(manageVendorNoteService, toasterService, modalService, router, route);
    this.listContextPath = '/manage-vendor-notes';
    this.editContextPath = '/manage-vendor-notes';
    this.entityLocator = 'vendor_note';
  }

  ngOnInit() {
    this.loadRecord();
  }

  populateForm(): void {
    this.formData = this.formBuilder.group({
      info: [this.entity.info, [Validators.required, Validators.maxLength(4000)]],

      note_type: [utils.extractDynamicValueId(this.entity.note_type), []],
    });
  }

  extractFormData(): VendorNote {
    const entity: VendorNote = new VendorNote();

    const { info, note_type } = this.formData.value;

    entity.info = info;

    entity.addDynamicListValue('note_type', note_type);

    if (this.isAddMode) {
      entity.addAssociation('vendor', 'vendor_row_id', this.parentId);
    } else {
      entity.id = this.id;
      entity.vendor_note_row_id = this.id;
    }

    return entity;
  }
}
