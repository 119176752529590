import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Pipe({
  name: 'withLoading',
})
export class WithLoadingPipe implements PipeTransform {
  transform(val): Observable<boolean> {
    return val.pipe(
      map((value: any) => false),
      startWith(true)
    );
  }
}
