export enum PermissionResourceMapping {
  CloudAnalyticsAdmin = 'CLOUDANALYTICS.ADMIN',
  CloudAnalyticsDesigner = 'CLOUDANALYTICS.DESIGNER',
  CloudAnalyticsViewer = 'CLOUDANALYTICS.VIEWER',
  IaasOptimization = 'IAASOPTIMIZATION',
  SaasOptimization = 'SAAS',

  HelpTangoeAdmin = 'CLOUDHELP.TANGOE_ADMIN',
  HelpCustomerAdmin = 'CLOUDHELP.CUSTOMER_ADMIN',
  HelpReportDesigner = 'CLOUDHELP.DESIGNER',
  HelpReportViewer = 'CLOUDHELP.VIEWER',

  // TODO: map all resources here
}
