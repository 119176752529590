import { environment } from '../../environments/environment';

/**
 * Class manages common environment variables to avoid populating actual
 * environment file with static variables.
 */
export class Environment {
  static getSisenseMainAppUrl(): string {
    return environment.sisenseUrl + 'app/main#/';
  }

  /**
   * Version 1 of sisense api
   */
  static getSisenseApiV1Url(): string {
    return environment.sisenseUrl + 'api/v1/';
  }

  /**
   * Version 0.9 of sisense api
   */
  static getSisenseApiV0Url(): string {
    return environment.sisenseUrl + 'api/';
  }
}
