import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Privileges } from './manage-grant-privileges';
import { BaseService } from '../../base/base-service';
import { SimpleFilter } from '../../base/base-filter';
import { environment } from '../../../environments/environment';
import { Result, DeleteResult, CountResult } from '../../base/result';
import { Observable } from 'rxjs';
import { QueryParam } from '../../base/common';

@Injectable({
  providedIn: 'root',
})
export class ManageGrantPrivilegesService extends BaseService<Privileges, 'privileges'> {
  constructor(http: HttpClient) {
    super(http);
  }

  getServiceUrl(): string {
    return environment.securityServiceUrl;
  }
  getMetaData(): any {
  }

  getBaseUrl(): string {
    return this.getServiceUrl() + '/privilegeses';
  }

  // ************************************ START:CUSTOM METHODS **************************

  // ************************************ END:CUSTOM METHODS **************************

  // ************************************ START:FETCH CHILD METHODS **************************

  // ************************************ END:FETCH CHILD METHODS **************************

  // ************************************ START:COUNT METHODS **************************

  // ************************************ END:COUNT METHODS **************************
}
