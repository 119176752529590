import { BaseEntity } from '../../base/base-entity';

/**
 * This class represent the SourceSystem entity.
 */
export class SourceSystem extends BaseEntity {
  source_system_row_id: number;

  platform_name: string;
  environment_name: string;
  tenant_number: string;
}
