import { catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

@Injectable()
export class SisenseAuthInterceptorService implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (
      !req.url.startsWith(environment.sisenseUrl) || // Ignore all non-sisense url's
      req.url.endsWith('logout') // don't alter logout calls to prevent any issues with logout
    ) {
      return next.handle(req);
    }

    // required for authentication
    req = req.clone({
      withCredentials: true,
    });

    return next.handle(req).pipe(
      /**
       * tap() here is only required here so we can handle an error. For more information on tap():
       *
       *  - http://reactivex.io/rxjs/function/index.html#static-function-tap
       */
      tap(),
      catchError((error: HttpErrorResponse) => {
        // Sisense returns 403 when they mean 401. Adding 401 check in case it changes
        if (error.status === 401 || error.status === 403) {
          window.open(environment.ssoLoginUrl, '_self');
        }

        return throwError(error);
      })
    );
  }
}
