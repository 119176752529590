import { BaseEntity } from '../base/base-entity';
import { AllocationInfo } from './allocation-info';

export class SaveAllocationInfoDto extends AllocationInfo {
  allocation_info_row_id: number | string;

  string_format_row_id: number;

  /**
   * Choose from:
   * - LOAD_ALLOCATION_TEMPLATE_ID
   * - DEFINE_ALLOCATION_STRING_ID
   * - DEFINE_ALLOCATION_STRING_PER_CHARGE
   * - MANUAL_ALLOCATION_STRING_ID
   * - AUTOMATIC_ALLOCATION_BASED_ON_CHARGE
   */
  allocation_method_type: string;

  /**
   * Choose from:
   * - ALLOCATE_PROPORTIONALLY
   * - ALLOCATE_EQUALLY_TO_ALL_EXISTING_ALLOCATIONS
   * - ALLOCATE_ALL_REMAINING_AMOUNT
   */
  remaining_amount_allocation_type: string = '';

  /**
   * If remaining_amount_allocation_type ALLOCATE_ALL_REMAINING_AMOUNT
   * then this column should be set to a string.
   */
  remaining_amount: string = '';

  /**
   * Choose from:
   * - account
   * - invoice
   * - recurring_invoice
   */
  allocation_execution_type: string;

  charge_element: string = '';

  /**
   * Value of `id` for allocation_execution_type
   * e.g. If the allocation_execution_type is invoice, `entity_row_id` will be invoiceId
   */
  entity_row_id: number;

  /**
   * When set to true, all child allocation strings will be removed.
   */
  is_change_request: boolean = false;
}
