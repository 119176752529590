import { BaseEntity } from '../../base/base-entity';

/**
 * This class represent the AllocationStatusHistory entity.
 */
export class AllocationStatusHistory extends BaseEntity {
  allocation_status_history_row_id: number;

  created_by: string;
  created_on: string;
  allocation_status: string;
}
