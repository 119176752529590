export interface LogEntry {
  timestamp: string;
  type: LogType;
  data: string;
  url: string;
  user: string;
  tenant: string;
  ip_address: string;
}

export enum LogType {
  'TRACE' = 'TRACE',
  'DEBUG' = 'DEBUG',
  'INFO' = 'INFO',
  'WARN' = 'WARN',
  'ERROR' = 'ERROR',
  'FATAL' = 'FATAL',
}
