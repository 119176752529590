import * as utils from './utils';

export abstract class BaseEntity {
  id: number;
  created_by: string;
  created_on: string; // TODO timestamp
  modified_by: string;
  modified_on: string; // TODO timestamp
  nullifiedAttrs: string[];

  version: number;
  deleted: boolean;
  bulk_load_complete: boolean;

  assocDetails: {
    assocDetail: any[];
  } = {
    assocDetail: [],
  };

  addTenantAssociation(tenant: string) {
    this.assocDetails.assocDetail.push({
      attrName: 'tenant',
      bizKeyValueTypeMap: {
        entry: {
          key: 'shortname',
          value: tenant,
        },
      },
    });
  }

  addCurrencyValue(attrName: string, attrVal: string) {
    if (attrVal) {
      attrVal = attrVal.replace(/[$,\s]/g, '');
      this[attrName] = { value: Number(attrVal), indicator: 'USD' };
    }
  }

  addDynamicListValue(attrName: string, attrVal: string, val?: string) {
    if (val && attrVal) {
      this[attrName] = { value: val, valueId: Number(attrVal) };
      return;
    }
    if (attrVal) {
      this[attrName] = { valueId: Number(attrVal) };
    }
  }

  addAssociation(entityAttr: string, rowIdAttr: string, rowIdVal: number) {
    this.assocDetails.assocDetail.push({
      attrName: entityAttr,
      bizKeyValueTypeMap: {
        entry: {
          key: rowIdAttr,
          value: rowIdVal,
        },
      },
    });
  }

  addDateValue(attrName: string, dateVal: Date) {
    this[attrName] = utils.dateToString(dateVal);
    if (dateVal === null) {
      this.addNullifiedAttribute(attrName);
    }
  }

  addNullifiedAttribute(attrName: string) {
    if (!this.nullifiedAttrs) {
      this.nullifiedAttrs = [];
    }

    this.nullifiedAttrs.push(utils.lowerFirst(utils.attributize(attrName)));
  }
}

export class AssignmentEntity extends BaseEntity {}
