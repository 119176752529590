import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldEmptyComponent } from './field-empty/field-empty.component';
import {
  GoSelectModule,
  GoTextAreaModule,
  GoDatepickerModule,
  GoCheckboxModule,
  GoInputModule,
  GoSwitchToggleModule,
} from '@tangoe/goponents';
import { DynamicListService } from './field-dynamiclist/dynamiclist.service';
import { FieldLookupComponent } from './field-lookup/field-lookup.component';
import { FieldInputComponent } from './field-input/field-input.component';
import { FieldBooleanComponent } from './field-boolean/field-boolean.component';
import { FieldTextAreaComponent } from './field-textarea/field-textarea.component';
import { FieldDateComponent } from './field-date/field-date.component';
import { FieldCheckboxComponent } from './field-checkbox/field-checkbox.component';
import { FieldDynamicListComponent } from './field-dynamiclist/field-dynamiclist.component';
import { FieldSelectComponent } from './field-select/field-select.component';
import { FieldPasswordComponent } from './field-password/field-password.component';
import { FieldLabelComponent } from './field-label/field-label.component';
import { DisableFieldDirective } from './disable-field.directive';
import { FieldToggleComponent } from './field-toggle/field-toggle.component';

@NgModule({
  declarations: [
    FieldEmptyComponent,
    FieldLookupComponent,
    FieldInputComponent,
    FieldBooleanComponent,
    FieldTextAreaComponent,
    FieldDateComponent,
    FieldCheckboxComponent,
    FieldDynamicListComponent,
    FieldSelectComponent,
    FieldPasswordComponent,
    FieldLabelComponent,
    DisableFieldDirective,
    FieldToggleComponent,
  ],
  imports: [
    CommonModule,
    GoSelectModule,
    GoTextAreaModule,
    GoDatepickerModule,
    GoCheckboxModule,
    GoInputModule,
    GoSwitchToggleModule,
  ],
  exports: [
    FieldEmptyComponent,
    FieldLookupComponent,
    FieldInputComponent,
    FieldBooleanComponent,
    FieldTextAreaComponent,
    FieldDateComponent,
    FieldCheckboxComponent,
    FieldDynamicListComponent,
    FieldSelectComponent,
    FieldPasswordComponent,
    FieldLabelComponent,
    DisableFieldDirective,
    FieldToggleComponent,
  ],
  providers: [DynamicListService],
})
export class FieldTypesModule {}
