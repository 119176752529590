import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AllocationInfoListComponent } from './allocation-info-list/allocation-info-list.component';
import { AllocationInfoEditComponent } from './allocation-info-edit/allocation-info-edit.component';
import { AllocationInfoService } from './allocation-info.service';
import { ALLOCATIONINFO_ROUTES } from './allocation-info.routes';
import { ListModule } from '../shared/list/list.module';
import { HeaderBarModule } from '../shared/header-bar/header-bar.module';
import { FieldTypesModule } from '../shared/field-types/field-types.module';
import { EmbeddedListModule } from '../shared/embedded-list/embedded-list.module';
import { CommonPipesModule } from '../shared/common-pipes/common-pipes.module';

import {
  GoButtonModule,
  GoTableModule,
  GoIconButtonModule,
  GoInputModule,
  GoActionSheetModule,
  GoIconModule,
  GoCardModule,
  GoSelectModule,
  GoDatepickerModule,
  GoRadioModule,
  GoTextAreaModule,
  GoCheckboxModule,
  GoSwitchToggleModule,
  GoHeaderBarModule,
  GoBadgeModule,
  GoToastModule,
  GoAccordionModule,
  GoFileUploadModule,
  GoLoaderModule,
} from '@tangoe/goponents';
import { AllocationConfigurationModule } from '../shared/components/allocation-configuration/allocation-configuration.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    GoTableModule,
    GoButtonModule,
    GoIconButtonModule,
    GoInputModule,
    GoActionSheetModule,
    GoIconModule,
    GoCardModule,
    GoSelectModule,
    GoDatepickerModule,
    GoRadioModule,
    GoTextAreaModule,
    GoCheckboxModule,
    GoSwitchToggleModule,
    GoHeaderBarModule,
    GoBadgeModule,
    GoToastModule,
    GoAccordionModule,
    ListModule,
    HeaderBarModule,
    FieldTypesModule,
    EmbeddedListModule,
    CommonPipesModule,
    RouterModule.forChild(ALLOCATIONINFO_ROUTES),
    GoFileUploadModule,
    GoLoaderModule,
    AllocationConfigurationModule,
  ],
  declarations: [AllocationInfoListComponent, AllocationInfoEditComponent],
  providers: [AllocationInfoService],
  exports: [],
})
export class AllocationInfoModule {}
