import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-filter-off-canvas',
  templateUrl: './filter-off-canvas.component.html',
  styleUrls: ['./filter-off-canvas.component.scss'],
})
export class FilterOffCanvasComponent implements OnInit {
  @Input() template: TemplateRef<any>;

  constructor() {}

  ngOnInit() {}
}
