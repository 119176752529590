import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { VendorPortal } from './manage-portal-for-tenant';
import { BaseService } from '../../base/base-service';
import { SimpleFilter } from '../../base/base-filter';
import { environment } from '../../../environments/environment';
import { Result, DeleteResult, CountResult } from '../../base/result';
import { Observable } from 'rxjs';
import { QueryParam } from '../../base/common';

@Injectable({
  providedIn: 'root',
})
export class ManagePortalForTenantService extends BaseService<VendorPortal, 'vendor_portal'> {
  constructor(http: HttpClient) {
    super(http);
  }

  getServiceUrl(): string {
    return environment.masterDataUrl;
  }
  getMetaData(): any {
  }

  getBaseUrl(): string {
    return this.getServiceUrl() + '/vendorPortals';
  }

  // ************************************ START:CUSTOM METHODS **************************

  // Custom method to be used by search and select for MasterVendor.
  findMasterVendors(
    q?: string,
    include?: string,
    exclude?: string,
    filter?: string
  ): Observable<Result<'vendor', any[]>> {
    const simpleFilter = new SimpleFilter('name', q, include, exclude);
    const queryParam = new QueryParam();
    queryParam.perPage = 100;
    queryParam.sortAttr = 'name';

    return this.listExternalEntities(environment.masterDataUrl + '/vendors/list', simpleFilter, queryParam);
  }

  // Custom method to be used by dynamic list values for Portal Access Type.
  findPortalAccessTypeListValues(): Observable<Result<'dynamic_list_value', any[]>> {
    return this.fetchDynamicListValues('Portal Access Type');
  }

  // ************************************ END:CUSTOM METHODS **************************

  // ************************************ START:FETCH CHILD METHODS **************************

  // ************************************ END:FETCH CHILD METHODS **************************

  // ************************************ START:COUNT METHODS **************************

  // ************************************ END:COUNT METHODS **************************
}
