import { BaseEntity } from '../../base/base-entity';

/**
 * This class represent the VendorNote entity.
 */
export class VendorNote extends BaseEntity {
  vendor_note_row_id: number;

  info: string;
  note_type: { value_id: number; value?: string; dynamic_list_name?: string };
}
