import { Component, OnInit, ViewChild } from '@angular/core';
import { GoToasterService, GoTableComponent, GoModalService } from '@tangoe/goponents';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BaseModalEditComponent } from '../../base/base-modal-edit.component';
import { Result } from '../../base/result';
import { ItemSelector, EmbeddedTableContext } from '../../base/common';
import * as utils from '../../base/utils';
import { CustomValidators } from '../../base/validators';
import { ManageAddressForEmployeeService } from './manage-address-for-employee.service';
import { EmployeeAddress } from './manage-address-for-employee';

@Component({
  selector: 'app-manage-address-for-employee-modal',
  templateUrl: './manage-address-for-employee-modal.component.html',
  styleUrls: ['./manage-address-for-employee-modal.component.scss'],
})
export class ManageAddressForEmployeeModalComponent extends BaseModalEditComponent<EmployeeAddress, 'employee_address'>
  implements OnInit {
  country_selection: string;
  state_province_region_selection: string;

  constructor(
    public manageAddressForEmployeeService: ManageAddressForEmployeeService,
    protected toasterService: GoToasterService,
    protected modalService: GoModalService,
    protected router: Router,
    protected route: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {
    super(manageAddressForEmployeeService, toasterService, modalService, router, route);
    this.listContextPath = '/manage-address-for-employees';
    this.editContextPath = '/manage-address-for-employees';
    this.entityLocator = 'employee_address';
  }

  ngOnInit() {
    this.loadRecord();
  }

  populateForm(): void {
    this.formData = this.formBuilder.group({
      type: [utils.extractDynamicValueId(this.entity.type), [Validators.required]],

      country: [this.entity.country, [Validators.required, Validators.maxLength(128)]],

      address_line1: [this.entity.address_line1, [Validators.required, Validators.maxLength(128)]],

      address_line2: [this.entity.address_line2, [Validators.maxLength(128)]],

      city: [this.entity.city, [Validators.required, Validators.maxLength(128)]],

      state_province_region: [this.entity.state_province_region, []],

      zip_postal_code: [this.entity.zip_postal_code, []],

      is_primary_address: [utils.toBoolean(this.entity.is_primary_address), []],
    });

    this.country_selection = this.entity.country;
    this.state_province_region_selection = this.entity.state_province_region;

    this.stateProvinceRegionDisplayHandler(this.country_selection);

    this.formData.get('country').valueChanges.subscribe(value => {
      this.stateProvinceRegionDisplayHandler(value);
    });
  }

  stateProvinceRegionDisplayHandler(value: any) {
    if (value) {
      setTimeout(() => {
        this.formData.get('state_province_region').enable();
        if (value !== this.country_selection) {
          this.country_selection = value;
          this.formData.get('state_province_region').reset();
        }
      }, 100);
    } else {
      this.formData.get('state_province_region').reset();
      this.formData.get('state_province_region').disable();
    }
  }

  extractFormData(): EmployeeAddress {
    const entity: EmployeeAddress = new EmployeeAddress();

    const {
      type,
      country,
      address_line1,
      address_line2,
      city,
      state_province_region,
      zip_postal_code,
      is_primary_address,
    } = this.formData.value;

    entity.country = country;
    entity.address_line1 = address_line1;
    entity.address_line2 = address_line2;
    entity.city = city;
    entity.state_province_region = state_province_region;
    entity.zip_postal_code = zip_postal_code;
    entity.is_primary_address = is_primary_address;

    entity.addDynamicListValue('type', type);

    if (this.isAddMode) {
      entity.addAssociation('employee', 'employee_row_id', this.parentId);
    } else {
      entity.id = this.id;
      entity.employee_address_row_id = this.id;
    }

    return entity;
  }
}
