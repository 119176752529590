import { BaseEntity } from '../../base/base-entity';

/**
 * This class represent the AllocationAdjustment entity.
 */
export class AllocationAdjustment extends BaseEntity {
  allocation_adjustment_row_id: number;

  created_on: string;
  created_by: string;
  adjustment_reason: string;
  adjustment_amount_value: { value: number; indicator: string };
  adjustment_amount_indicator: string;
  from_allocation_result_code: string;
  to_allocation_result_code: string;
}
