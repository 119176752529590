import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../../base/base-service';
import { environment } from '../../../environments/environment';
import { BaseEntity } from 'src/app/base/base-entity';
import { Observable } from 'rxjs';
import { RemittanceInfo } from './remittance';
import { Result } from 'src/app/base/result';
import { SimpleFilter } from 'src/app/base/base-filter';
import { QueryParam } from 'src/app/base/common';

@Injectable({
  providedIn: 'root',
})
export class RemittanceInfoService extends BaseService<RemittanceInfo, 'remittance_info'> {
  constructor(http: HttpClient) {
    super(http);
  }
  private urlPath: string;

  setUrlPath(path: string): void {
    this.urlPath = path;
  }

  getMetaData(): any {
  }
  getServiceUrl(): string {
    return environment.expenseDataUrl;
  }

  getBaseUrl(): string {
    return this.getServiceUrl() + '/' + this.urlPath;
  }

  findRemitCodeById(id: number): Observable<any> {
    const url = environment.masterDataUrl + '/vendorRemittanceCodes';
    return super.findExternalById(url, id);
  }

  findRemitAddressById(id: number): Observable<any> {
    const url = environment.masterDataUrl + '/remittanceAddresses';
    return super.findExternalById(url, id);
  }

  findRemitAddresses(
    q?: string,
    include?: string,
    exclude?: string,
    filter?: string
  ): Observable<Result<'remittance_address', any[]>> {
    const simpleFilter = new SimpleFilter('address_line1', q, include, exclude);
    const queryParam = new QueryParam();
    queryParam.perPage = 100;
    queryParam.sortAttr = 'address_line1';

    return this.listExternalEntities(
      environment.masterDataUrl + `/vendors/${filter}/remittanceAddresses`,
      simpleFilter,
      queryParam
    );
  }

  findRemitCodes(
    q?: string,
    include?: string,
    exclude?: string,
    filter?: string
  ): Observable<Result<'remittance_address', any[]>> {
    const simpleFilter = new SimpleFilter('vendor_remittance_code', q, include, exclude);
    const queryParam = new QueryParam();
    queryParam.perPage = 100;
    queryParam.sortAttr = 'vendor_remittance_code';

    return this.listExternalEntities(
      environment.masterDataUrl + `/remittanceAddresses/${filter}/vendorRemittanceCodes`,
      simpleFilter,
      queryParam
    );
  }
}
