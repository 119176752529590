import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Result } from '../base/result';
import { sessionContext } from './session-details';
import { PermissionResourceMapping } from '../shared/models/authentication/PermissionResourceMapping';
import { AuthenticationService } from './authentication.service';
import { LogService } from '../logger/log.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationLocalService extends AuthenticationService {
  constructor(http: HttpClient, logService: LogService) {
    super(http, logService);
  }

  hasAccess(resource: string | PermissionResourceMapping): boolean {
    return true;
  }

  getMetaData(): any {
  }

  loggedInUserTenants(): Observable<Result<'tenant', any[]>> {
    const loggedInUserTenants = {
      tenant: [
        {
          short_name: 'DEV',
          name: 'Developers Inc.',
          id: 61101,
        },
        {
          short_name: 'TNG',
          name: 'Tangoe - TNG',
          id: 1500,
        },
      ],
      count: 1,
      message: 'SUCCESS',
      status: 'success',
      code: 200,
    };

    console.log('Calling loggedInUserTenants() ->', loggedInUserTenants);

    return of(loggedInUserTenants);
  }

  loggedInUserDetails(): Observable<Result<'user_details', any>> {
    const loggedInUserDetails = {
      user_details: {
        user: {
          id: 86500,
          identifier: 'local.account@tangoe.com',
        },
        tenant: {
          id: 1500,
          short_name: 'TNG',
          name: 'Tangoe - TNG',
        },
        employee: {},
      },
      message: 'SUCCESS',
      status: 'success',
      count: 1,
      code: 200,
    };

    console.log('calling loggedInUserDetails() -> ', loggedInUserDetails);

    return of(loggedInUserDetails);
  }

  loggedInUserPermissions(): Observable<Result<'privilege_details', any[]>> {
    const loggedInUserPermissions = {
      user: 'local.account@tangoe.com',
      privilege_details: [],
      message: 'SUCCESS',
      status: 'success',
      count: 82,
      code: 200,
    };

    console.log('Calling loggedInUserPermissions() ->', loggedInUserPermissions);

    return of(loggedInUserPermissions);
  }
}
