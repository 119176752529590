import { Component, Input } from '@angular/core';
import { BaseFieldComponent } from '../field-base';

@Component({
  selector: 'app-field-select',
  templateUrl: './field-select.component.html',
  styleUrls: ['./field-select.component.scss'],
})
export class FieldSelectComponent extends BaseFieldComponent {
  @Input() items: { name: string; value: string }[];
  @Input() multiple: boolean = false;
  @Input() hints: string[];
  @Input() labelType: string = 'standard';

  @Input() bindLabel: string = 'name';
  @Input() bindValue: string = 'value';
  @Input() clearable: boolean = true;
  @Input() loading: boolean = false;
  @Input() appendTo: string = '';
}
