import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DynamicListService } from '../../field-types/field-dynamiclist/dynamiclist.service';

@Component({
  selector: 'app-filter-dynamiclist',
  templateUrl: './filter-dynamiclist.component.html',
  styleUrls: ['./filter-dynamiclist.component.scss'],
})
export class FilterDynamicListComponent implements OnInit {
  @Input() name: string;
  @Input() label: string;
  @Input() listName: string;
  @Input() bindValue: string = 'value';
  @Input() bindLabel: string = 'value';

  control = new FormControl('');

  items: any[];
  loading: boolean = false;
  placeholder: string;

  constructor(protected dynamicListService: DynamicListService) {}

  ngOnInit() {
    this.placeholder = `Select ${this.label}`;

    this.loadDynamicListValues();
  }

  loadDynamicListValues() {
    this.loading = true;

    this.dynamicListService.fetchDynamicListValues(this.listName).subscribe(data => {
      this.items = data.dynamic_list_value;
      this.loading = false;
    });
  }
}
