import { Component, OnInit, ViewChild } from '@angular/core';
import { GoToasterService, GoTableComponent, GoModalService } from '@tangoe/goponents';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BaseModalEditComponent } from '../../base/base-modal-edit.component';
import { Result } from '../../base/result';
import { ItemSelector, EmbeddedTableContext } from '../../base/common';
import * as utils from '../../base/utils';
import { CustomValidators } from '../../base/validators';
import { ManageVendorRemitCodeService } from './manage-vendor-remit-code.service';
import { VendorRemittanceCode } from './manage-vendor-remit-code';

@Component({
  selector: 'app-manage-vendor-remit-code-modal',
  templateUrl: './manage-vendor-remit-code-modal.component.html',
  styleUrls: ['./manage-vendor-remit-code-modal.component.scss'],
})
export class ManageVendorRemitCodeModalComponent
  extends BaseModalEditComponent<VendorRemittanceCode, 'vendor_remittance_code'>
  implements OnInit {
  remittance_address_selection: number;

  constructor(
    public manageVendorRemitCodeService: ManageVendorRemitCodeService,
    protected toasterService: GoToasterService,
    protected modalService: GoModalService,
    protected router: Router,
    protected route: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {
    super(manageVendorRemitCodeService, toasterService, modalService, router, route);
    this.listContextPath = '/manage-vendor-remit-codes';
    this.editContextPath = '/manage-vendor-remit-codes';
    this.entityLocator = 'vendor_remittance_code';
  }

  ngOnInit() {
    this.loadRecord();
  }

  populateForm(): void {
    this.formData = this.formBuilder.group({
      remittance_address: [utils.extractLookupId(this.entity.remittance_address), [Validators.required]],

      vendor_remittance_code: [this.entity.vendor_remittance_code, [Validators.required, Validators.maxLength(32)]],
    });

    this.remittance_address_selection = utils.extractLookupId(this.entity.remittance_address);
  }

  extractFormData(): VendorRemittanceCode {
    const entity: VendorRemittanceCode = new VendorRemittanceCode();

    const { remittance_address, vendor_remittance_code } = this.formData.value;

    entity.vendor_remittance_code = vendor_remittance_code;

    entity.addAssociation('remittanceAddress', 'remittance_address_row_id', remittance_address);

    if (this.isAddMode) {
      entity.addAssociation('vendor', 'vendor_row_id', this.parentId);
    } else {
      entity.id = this.id;
      entity.vendor_remittance_code_row_id = this.id;
    }

    return entity;
  }
}
