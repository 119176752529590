import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AllocationString } from './allocation-string';
import { BaseService } from '../../base/base-service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { Result } from '../../base/result';
import { SimpleFilter } from '../../base/base-filter';
import { QueryParam } from '../../base/common';
import { SaveAllocationStringDto } from '../../allocation-string/save-allocation-string-dto';

@Injectable({
  providedIn: 'root',
})
export class AllocationStringService extends BaseService<AllocationString, 'allocation_string'> {
  constructor(http: HttpClient) {
    super(http);
  }

  getServiceUrl(): string {
    return environment.enterpriseDataUrl;
  }
  getMetaData(): any {
  }
  getBaseUrl(): string {
    return this.getServiceUrl() + '/allocationStrings';
  }

  // ************************************ START:CUSTOM METHODS **************************

  insert(entity: SaveAllocationStringDto): Observable<Result<'allocation_string', AllocationString>> {
    const headers = this.buildHeaders();

    return this.http.post<Result<'allocation_string', AllocationString>>(
      environment.expenseDataUrl + '/allocations/customCreateAllocationString',
      entity,
      {
        headers,
      }
    );
  }

  update(entity: AllocationString): Observable<Result<'allocation_string', AllocationString>> {
    const headers = this.buildHeaders();

    return this.http.post<Result<'allocation_string', AllocationString>>(
      environment.expenseDataUrl + '/allocations/customCreateAllocationString',
      entity,
      {
        headers,
      }
    );
  }

  // Custom method to be used by search and select for AccountingSystem.
  findAccountingSystems(
    q?: string,
    include?: string,
    exclude?: string,
    filter?: string
  ): Observable<Result<'accounting_system,', any[]>> {
    const simpleFilter = new SimpleFilter('name,code', q, include, exclude);
    const queryParam = new QueryParam();
    queryParam.perPage = 100;
    queryParam.sortAttr = 'code';

    return this.listExternalEntities(environment.masterDataUrl + '/accountingSystems/list', simpleFilter, queryParam);
  }

  // Custom method to be used by search and select for BusinessUnit.
  findBusinessUnits(
    q?: string,
    include?: string,
    exclude?: string,
    filter?: string
  ): Observable<Result<'business_unit', any[]>> {
    const simpleFilter = new SimpleFilter('name,code', q, include, exclude);
    const queryParam = new QueryParam();
    queryParam.perPage = 100;
    queryParam.sortAttr = 'code';

    return this.listExternalEntities(environment.masterDataUrl + '/businessUnits/list', simpleFilter, queryParam);
  }

  // Custom method to be used by search and select for Company.
  findCompanies(q?: string, include?: string, exclude?: string, filter?: string): Observable<Result<'company', any[]>> {
    const simpleFilter = new SimpleFilter('name,code', q, include, exclude);
    const queryParam = new QueryParam();
    queryParam.perPage = 100;
    queryParam.sortAttr = 'code';

    return this.listExternalEntities(environment.masterDataUrl + '/companies/list', simpleFilter, queryParam);
  }

  // Custom method to be used by search and select for GlExpenseCode.
  findGlExpenseCodes(
    q?: string,
    include?: string,
    exclude?: string,
    filter?: string
  ): Observable<Result<'gl_expense_code', any[]>> {
    const simpleFilter = new SimpleFilter('name,code', q, include, exclude);
    const queryParam = new QueryParam();
    queryParam.perPage = 100;
    queryParam.sortAttr = 'code';

    return this.listExternalEntities(environment.masterDataUrl + '/glExpenseCodes/list', simpleFilter, queryParam);
  }

  // Custom method to be used by search and select for CostCenter.
  findCostCenters(
    q?: string,
    include?: string,
    exclude?: string,
    filter?: string
  ): Observable<Result<'cost_center', any[]>> {
    const simpleFilter = new SimpleFilter('name,code', q, include, exclude);
    const queryParam = new QueryParam();
    queryParam.perPage = 100;
    queryParam.sortAttr = 'code';

    return this.listExternalEntities(environment.masterDataUrl + '/costCentersForAllocationString/list', simpleFilter, queryParam);
  }

  // Custom method to be used by search and select for Department.
  findDepartments(
    q?: string,
    include?: string,
    exclude?: string,
    filter?: string
  ): Observable<Result<'department', any[]>> {
    const simpleFilter = new SimpleFilter('name,code', q, include, exclude);
    const queryParam = new QueryParam();
    queryParam.perPage = 100;
    queryParam.sortAttr = 'code';

    return this.listExternalEntities(environment.masterDataUrl + '/departments/list', simpleFilter, queryParam);
  }

  // Custom method to be used by search and select for Location.
  findLocations(
    q?: string,
    include?: string,
    exclude?: string,
    filter?: string
  ): Observable<Result<'location', any[]>> {
    const simpleFilter = new SimpleFilter('name,code', q, include, exclude);
    const queryParam = new QueryParam();
    queryParam.perPage = 100;
    queryParam.sortAttr = 'code';

    return this.listExternalEntities(environment.masterDataUrl + '/locations/list', simpleFilter, queryParam);
  }

  // ************************************ END:CUSTOM METHODS **************************

  // ************************************ START:FETCH CHILD METHODS **************************

  // ************************************ END:FETCH CHILD METHODS **************************

  // ************************************ START:COUNT METHODS **************************

  // ************************************ END:COUNT METHODS **************************
}
