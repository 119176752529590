import { Component, OnInit } from '@angular/core';
import { GoToasterService, GoModalService } from '@tangoe/goponents';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { BaseModalEditComponent } from '../../base/base-modal-edit.component';
import { AllocationStringPartService } from './allocation-string-part.service';
import { AllocationStringPart } from './allocation-string-part';
import { CustomValidators } from '../../base/validators';
import * as utils from '../../base/utils';

@Component({
  selector: 'app-allocation-string-part-modal',
  templateUrl: './allocation-string-part-modal.component.html',
  styleUrls: ['./allocation-string-part-modal.component.scss'],
})
export class AllocationStringPartModalComponent
  extends BaseModalEditComponent<AllocationStringPart, 'allocation_string_part'>
  implements OnInit {
  fieldMappingSelectionList: any = [];

  constructor(
    public allocationStringPartService: AllocationStringPartService,
    protected toasterService: GoToasterService,
    protected modalService: GoModalService,
    protected router: Router,
    protected route: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {
    super(allocationStringPartService, toasterService, modalService, router, route);
    this.listContextPath = '/allocation-string-parts';
    this.editContextPath = '/allocation-string-parts';
    this.entityLocator = 'allocation_string_part';
  }

  ngOnInit() {
    this.loadRecord();
  }

  buildAnyStaticDropdowns(): void {
    // Populate Field Mapping Selection
    this.fieldMappingSelectionList = [
      { value: 'ACCOUNTING_SYSTEM', name: 'Accounting System' },
      { value: 'BUSINESS_UNIT', name: 'Business Unit' },
      { value: 'COMPANY', name: 'Company' },
      { value: 'GL_EXPENSE_CODE', name: 'Gl Expense Code' },
      { value: 'COST_CENTER', name: 'Cost Center' },
      { value: 'DEPARTMENT', name: 'Department' },
      { value: 'LOCATION', name: 'Location' },
      // { value: 'REPORTING_CODE', name: 'REPORTING_CODE' },
      // { value: 'LEGAL_ENTITY', name: 'LEGAL_ENTITY' },
    ];
  }

  populateForm(): void {
    this.formData = this.formBuilder.group({
      field_mapping: [this.entity.field_mapping, [Validators.required]],

      place_holder_string: [this.entity.place_holder_string, [Validators.maxLength(1)]],

      length: [this.entity.length, [Validators.required, CustomValidators.digits, Validators.max(128)]],

      position: [
        this.entity.position,
        [Validators.required, CustomValidators.digits, Validators.min(1), Validators.max(36)],
      ],

      allow_any_value: [utils.toBoolean(this.entity.allow_any_value), []],
    });
  }

  extractFormData(): AllocationStringPart {
    const entity: AllocationStringPart = new AllocationStringPart();

    const { field_mapping, place_holder_string, length, position, allow_any_value } = this.formData.value;

    entity.field_mapping = field_mapping;
    entity.place_holder_string = place_holder_string;
    entity.length = length;
    entity.position = position;
    entity.allow_any_value = allow_any_value;

    if (this.isAddMode) {
      entity.addAssociation('stringFormat', 'allocation_string_format_row_id', this.parentId);
    } else {
      entity.id = this.id;
      entity.allocation_string_part_row_id = this.id;
    }

    return entity;
  }
}
