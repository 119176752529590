import { Component, Input } from '@angular/core';
import { BaseFieldComponent } from '../field-base';

@Component({
  selector: 'app-field-label',
  templateUrl: './field-label.component.html',
  styleUrls: ['./field-label.component.scss'],
})
export class FieldLabelComponent extends BaseFieldComponent {
  @Input() value: any;
}
