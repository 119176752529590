import { BaseEntity } from '../../base/base-entity';

/**
 * This class represent the Privileges entity.
 */
export class Privileges extends BaseEntity {
  privileges_row_id: number;

  category_display: string;
  sub_category_display: string;
  actions_displays: string;
}
