import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SafePipe } from './safe.pipe';
import { EmptyTableComponent } from './components/empty-table/empty-table.component';
import { GoAccordionModule, GoBadgeModule, GoIconButtonModule, GoIconModule, GoTableModule } from '@tangoe/goponents';
import { ErrorMessagePageComponent } from './error-message-page/error-message-page.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';

@NgModule({
  declarations: [SafePipe, EmptyTableComponent, ErrorMessagePageComponent, PageHeaderComponent],
  exports: [SafePipe, EmptyTableComponent, ErrorMessagePageComponent, PageHeaderComponent],
  imports: [CommonModule, GoAccordionModule, GoBadgeModule, GoTableModule, GoIconButtonModule, GoIconModule],
})
export class SharedModule {}
