import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoPillComponent } from './go-pill.component';
import { GoIconButtonModule, GoIconModule } from '@tangoe/goponents';

@NgModule({
  declarations: [GoPillComponent],
  imports: [CommonModule, GoIconButtonModule, GoIconModule],
  exports: [GoPillComponent],
})
export class GoPillModule {}
