import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedPageStateService {
  private pageStateBehavior: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readOnly: boolean = false;
  public notify = new BehaviorSubject<any>('');
  notifyObservable$ = this.notify.asObservable();
  constructor() {}

  subscribeToPageStateChange(callback: (readonly: boolean) => void) {
    this.pageStateBehavior.subscribe(callback);
  }

  public notifyOther(data: any) {
    if (data) {
      this.notify.next(data);
    }
  }

  emitPageStateChange(readonly: boolean) {
    this.readOnly = readonly;
    this.pageStateBehavior.next(readonly);
  }

  isReadOnly(): boolean {
    return this.readOnly;
  }
}
