import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseEditComponent } from '../../../../base/base-edit.component';
import { AllocationInfo } from '../../../../allocation-info/allocation-info';
import { AllocationInfoService } from '../../../../allocation-info/allocation-info.service';
import { GoModalService, GoToasterService } from '@tangoe/goponents';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import * as utils from '../../../../base/utils';
import { mergeMap } from 'rxjs/operators';
import { AllocationConfigurationService } from '../allocation-configuration.service';
import { LogService } from 'src/app/logger/log.service';

@Component({
  selector: 'app-allocation-configuration-create',
  templateUrl: './allocation-configuration-create.component.html',
  styleUrls: ['./allocation-configuration-create.component.scss'],
})
export class AllocationConfigurationCreateComponent extends BaseEditComponent<AllocationInfo, 'allocation_info'>
  implements OnInit {
  @Input() showAutomaticAllocationOption: boolean = true;
  @Input() showChargeAllocationOption: boolean = true;
  @Input() assocDetailAttr: string;
  @Input() assocDetailField: string;
  @Input() assocDetailId: number;
  @Input() renderBoxShadows: boolean = true;
  @Input() isAllocAddConfigEditable = true;

  @Output() created = new EventEmitter();

  string_format_selection: number;

  allocationTypeSelectionList: any = [];

  allocationMethodSelectionList: Array<{ value: string; name: string }>;
  automaticAllocationDefinitionSelection: Array<{ value: string; name: string }>;

  constructor(
    public allocationInfoService: AllocationInfoService,
    protected allocationConfigurationService: AllocationConfigurationService,
    protected toasterService: GoToasterService,
    protected modalService: GoModalService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected logService: LogService,
    private formBuilder: FormBuilder
  ) {
    super(allocationInfoService, toasterService, modalService, router, route, logService);
  }

  ngOnInit() {
    this.loadRecord();
  }

  buildAnyStaticDropdowns(): void {
    this.allocationTypeSelectionList = [
      { value: 'AMOUNTS', name: 'Amounts' },
      { value: 'PERCENTAGES', name: 'Percentages' },
    ];

    this.allocationMethodSelectionList = [
      { value: 'TEMPLATE_ALLOCATION', name: 'Template Allocation' },
      { value: 'STRING_ALLOCATION', name: 'String Allocation' },
    ];

    if (this.showChargeAllocationOption) {
      this.allocationMethodSelectionList.push({ value: 'CHARGE_ALLOCATION', name: 'Charge Allocation' });
    }

    if (this.showAutomaticAllocationOption) {
      this.allocationMethodSelectionList.push({ value: 'AUTOMATIC_ALLOCATION', name: 'Automatic Allocation' });
    }

    this.automaticAllocationDefinitionSelection = [
      { value: 'AUTOACCOUNT', name: 'Account' },
      { value: 'AUTOASSET', name: 'Asset' },
      { value: 'AUTOASSETCAPACITYASSIGNEDPROPORTION', name: 'Asset Capacity (Assigned Portion)' },
      { value: 'AUTOASSETCAPACITYSPREADAMOUNT', name: 'Asset Capacity (Spread Amount)' },
      { value: 'AUTOASSETLOCATION', name: 'Asset Location' },
      { value: 'AUTOASSETLOCATIONHEADCOUNT', name: 'Asset Location Head Count' },
      { value: 'AUTOASSETTYPE', name: 'Asset Type' },
      { value: 'AUTOCHARGETYPE', name: 'Charge Type' },
      { value: 'AUTOEMPLOYEE', name: 'Employee' },
      { value: 'AUTOMASTERSERVICETYPE', name: 'Master Service Type' },
      { value: 'AUTOVENDOR', name: 'Vendor' },
    ];
  }

  populateForm(): void {
    this.formData = this.formBuilder.group({
      string_format: [undefined, [Validators.required]],

      allocation_type: [undefined, [Validators.required]],

      allocation_method: [undefined, [Validators.required]],

      allocation_charge_elements: [],
    });

    this.string_format_selection = utils.extractLookupId(this.entity.string_format);
  }

  saveRecord() {
    this.showButtonLoading = true;

    if (!this.validateFormData()) {
      this.showButtonLoading = false;

      return;
    }

    const formData = this.extractFormData();

    return this.entityService.insert(formData).subscribe(
      data => {
        this.logService.debug(
          `Allocation-Config-Create: Created allocation info [id=${data.allocation_info.id}] for ${this.assocDetailAttr}[id=${this.assocDetailId}]`
        );

        this.clearErrors();
        this.showButtonLoading = false;
        this.raiseSaveSuccess();

        this.created.emit(data);
      },
      error => {
        this.logService.error(
          `Allocation-Config-Create: Error occurred when trying to create allocation configuration for ${this.assocDetailAttr}[id=${this.assocDetailId}]`,
          error
        );
        this.showButtonLoading = false;
        this.raiseSaveError(error);
      }
    );
  }

  extractFormData(): AllocationInfo {
    const allocation_method = this.formData.get('allocation_method').value;
    if (allocation_method === 'CHARGE_ALLOCATION' || allocation_method === 'AUTOMATIC_ALLOCATION') {
      this.formData.value.allocation_type = 'AMOUNTS';
    }
    return this.allocationConfigurationService.extractFormData(
      this.formData.value,
      this.assocDetailAttr,
      this.assocDetailId
    );
  }
}
