import { Component, OnInit, Input, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { BaseService } from 'src/app/base/base-service';
import { BaseEntity } from 'src/app/base/base-entity';
import {
  GoTableConfig,
  GoTableDataSource,
  GoTableSortConfig,
  GoTableSearchConfig,
  GoTableComponent,
  GoModalService,
  GoToasterService,
} from '@tangoe/goponents';
import {
  PageContext,
  hasTableConfigChanged,
  EmbeddedTableContext,
  selectedTableRows,
  resetTableRowSelections,
} from 'src/app/base/common';
import { ConfirmActionComponent } from '../../../shared/confirm-action/confirm-action.component';
@Component({
  selector: 'app-embedded-list',
  templateUrl: './embedded-list.component.html',
  styleUrls: ['../embedded-list.component.scss'],
})
export class EmbeddedListComponent implements OnInit, OnChanges {
  @Input() name: string;
  @Input() title: string;
  @Input() entityService: BaseService<BaseEntity, 'entity'>;
  @Input() entityId: number;
  @Input() expanded: boolean = false;
  @Input() isAddMode: boolean = true;
  @Input() isApproverListReadOnly: boolean = true;

  @Input() defaultSortAttr: string;
  @Input() defaultSearchAttrs: string;
  @Input() isSelectable: boolean;

  @Input() serviceMethod: string;
  @Input() entityLocator: string;

  loading: boolean = false;
  tableConfig: GoTableConfig;
  pageContext: PageContext;

  public embeddedTableRefName: string;

  constructor(
    protected el: ElementRef,
    protected toasterService: GoToasterService,
    protected modalService: GoModalService
  ) {}

  ngOnInit() {
    this.initializeTableConfig();
  }
  initializeTableConfig() {
    if (!this.tableConfig) {
      this.loading = false;
      this.pageContext = new PageContext(this.defaultSearchAttrs, this.defaultSortAttr);
      this.tableConfig = new GoTableConfig({
        tableData: [],
        dataMode: GoTableDataSource.server,
        totalCount: 0,
        selectBy: 'id',
        selectable: this.isSelectable,
        sortConfig: new GoTableSortConfig({
          column: this.pageContext.pageParam.sortAttr,
          direction: this.pageContext.pageParam.sortDir,
        }),
        searchConfig: new GoTableSearchConfig({
          searchable: false,
          searchTerm: this.pageContext.simpleFilter.searchValue,
          debounce: 1000,
        }),
      });

      this.embeddedTableRefName = `${this.name}EmbeddedTableRef`;
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.initializeTableConfig();
    if (!this.isAddMode) {
      this.loadEmbeddedTableData();
    }
  }

  loadEmbeddedTableData() {
    this.loading = true;
    this.tableConfig.noDataText = `No ${this.name} data`;

    // Parent Id cannot be 0.
    if (this.entityId === 0) {
      this.loading = false;
      this.tableConfig.noDataText = `Could not find any ${this.name} data to load`;

      return;
    }

    this.entityService[this.serviceMethod](
      this.entityId,
      this.pageContext.simpleFilter,
      this.pageContext.pageParam
    ).subscribe(
      result => {
        this.renderEmbeddedTableData(result);
      },
      error => {
        this.loading = false;
        this.tableConfig.noDataText = `Error loading ${this.name} data`;
        this.tableConfig = { ...this.tableConfig };
      }
    );
  }

  renderEmbeddedTableData(result) {
    this.loading = false;

    this.tableConfig.totalCount = result.count;
    this.tableConfig.tableData = result[this.entityLocator];

    if (result[this.entityLocator] && result[this.entityLocator].length > 0) {
      this.tableConfig.searchConfig.searchable = true;
    }

    this.tableConfig = { ...this.tableConfig };
  }

  onEmbeddedTableChange($event: GoTableConfig) {
    if (!hasTableConfigChanged($event, this.pageContext)) {
      return;
    }

    this.tableConfig = $event;

    this.pageContext.pageParam.perPage = this.tableConfig.pageConfig.perPage;
    this.pageContext.pageParam.offset = this.tableConfig.pageConfig.offset;

    if (this.tableConfig.sortConfig) {
      this.pageContext.pageParam.sortDir = this.tableConfig.sortConfig.direction;
      this.pageContext.pageParam.sortAttr = this.tableConfig.sortConfig.column;
    }

    // Reset the offset only if the search term has changed so that we get the searched data from first page.
    if (this.pageContext.simpleFilter.searchValue !== this.tableConfig.searchConfig.searchTerm) {
      this.pageContext.simpleFilter.searchValue = this.tableConfig.searchConfig.searchTerm;

      this.pageContext.pageParam.offset = 0;
      this.tableConfig.pageConfig.offset = 0;
    }

    this.loadEmbeddedTableData();
  }

  selectedChildRows(tableComponent: GoTableComponent) {
    return selectedTableRows(tableComponent);
  }

  deleteChildRecord(tableComponent: GoTableComponent, child: EmbeddedTableContext) {
    const idsToDelete = this.selectedChildRows(tableComponent);
    if (idsToDelete.length === 0) {
      this.toasterService.toastInfo({
        header: 'Alert!',
        message: 'No records selected to delete.',
      });
      return;
    }

    this.modalService.openModal(ConfirmActionComponent, {
      modalTitle: 'Delete',
      message: 'You are about to delete the selected records.',
      question: 'Are you sure?',
      cancelAction: 'No',
      confirmAction: 'Yes',
      confirmCallback: () => {
        this.entityService['deleteApproverRecords'](idsToDelete.join(','), this.entityId).subscribe(
          data => {
            this.toasterService.toastSuccess({
              header: 'Success!',
              message: 'Successfully deleted records.',
            });

            // // If on last page and deleting all records then move to previous page.
            // if (tableComponent.isLastPage()) {
            //   if (idsToDelete.length === tableComponent.tableConfig.tableData.length) {
            //     child.pageContext.pageParam.goToPrevious();
            //     child.tableConfig.pageConfig.offset = child.pageContext.pageParam.offset;
            //   }
            // }

            // resetTableRowSelections(tableComponent);

            this.modalService.toggleModal(false);
            this.loadEmbeddedTableData();
          },
          error => {
            this.modalService.toggleModal(false);

            this.toasterService.toastError({
              header: 'Error!',
              message: 'Error occurred while deleting records.',
            });
          }
        );
      },
    });
  }
}
