import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../base/base-service';
import { environment } from '../../environments/environment';
import { BaseEntity } from 'src/app/base/base-entity';
import { LogEntry } from './log-entry';

@Injectable({
  providedIn: 'root',
})
export class LogWriterService {
  constructor(protected http: HttpClient) {}

  writeLog(logEntry: LogEntry[]) {
    const url = environment.securityServiceUrl + '/logger/writeLog';
    const jsonbody = [];

    logEntry.forEach(e => {
      jsonbody.push({
        level: e.type,
        url: e.url,
        user: e.user,
        tenant: e.tenant,
        timestamp: e.timestamp,
        message: e.data,
        ip_address: e.ip_address,
      });
    });

    this.http.post(url, jsonbody, {}).subscribe(
      data => {},
      error => {}
    );
  }
}
