import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, debounceTime } from 'rxjs/operators';
import { BaseEntity } from 'src/app/base/base-entity';
import { BaseService } from 'src/app/base/base-service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-filter-lookup',
  templateUrl: './filter-lookup.component.html',
  styleUrls: ['./filter-lookup.component.scss'],
})
export class FilterLookupComponent implements OnInit, OnChanges {
  @Input() name: string;
  @Input() label: string;

  @Input() bindValue: string;
  @Input() bindLabel: string;
  @Input() entityLocator: string;
  @Input() lookupMethod: string;
  @Input() entityService: BaseService<BaseEntity, 'entity'>;

  @Input() selectedId: string | number;
  @Input() filter: string;

  placeholder: string;
  control = new FormControl('');
  items: Observable<any>;
  loading: boolean = false;

  userInput: Subject<string> = new Subject<string>();
  searchValue: string;

  constructor() {}
  ngOnInit() {
    this.placeholder = `Select ${this.label}`;

    this.registerUserInput();
    this.lookupInitialSelection();
  }

  ngOnChanges(): void {
    this.control.valueChanges.subscribe((value: string | number) => {
      if (value) {
        this.selectedId = value;
        this.lookupInitialSelection();
      }
    });
  }

  registerUserInput() {
    this.userInput
      .pipe(
        debounceTime(600), // Delay user input
        map(input => {
          this.searchValue = input;
        })
      )
      .subscribe(() => {
        this.selectedId = undefined;

        this.lookupItemSelections();
      });
  }

  // Load the lookup dropdown with just one value that is currently selected.
  lookupInitialSelection() {
    this.searchValue = undefined;
    this.lookupItemSelections();
    this.clearInitialSelectionItems();
  }

  lookupItemSelections() {
    if (this.searchValue || this.selectedId) {
      this.loading = true;

      this.items = this.entityService[this.lookupMethod](
        this.searchValue,
        this.selectedId,
        undefined,
        this.filter
      ).pipe(
        map(data => {
          this.loading = false;
          return this.enhanceLookupItems(data[this.entityLocator]);
        })
      );
    } else {
      this.loading = false;
      // this.items = Observable.of([]);
    }
  }

  enhanceLookupItems(list: any[]): any[] {
    if (this.bindLabel.includes('/')) {
      const primaryBind: string = this.bindLabel.split('/')[0];
      const secondaryBind: string = this.bindLabel.split('/')[1];

      list.map(element => {
        element[this.bindLabel] = element[primaryBind] + ' (' + element[secondaryBind] + ')';
        return element;
      });
    }

    return list;
  }

  // To clear the selection items (if any)
  clearInitialSelectionItems(): void {
    if (this.selectedId) {
      // If the items are still loading then retry in 1000 ms
      if (this.loading) {
        setTimeout(() => {
          this.clearInitialSelectionItems();
        }, 1000);
      } else {
        // Items already loaded, wait for 500 ms and clear the selections.
        setTimeout(() => {
          this.items = Observable.of([]);
        }, 500);
      }
    }
  }
}
