import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Employee } from './employee';
import { metaData } from '../employee/employee-metadata';

import { EmployeeAddress } from './employee';

import { EmployeeCostCenter } from './employee';

import { EmployeeGroupMembership } from './employee';

import { BaseService } from '../base/base-service';
import { SimpleFilter } from '../base/base-filter';
import { environment } from '../../environments/environment';
import { Result, DeleteResult, CountResult } from '../base/result';
import { Observable } from 'rxjs';
import { QueryParam } from '../base/common';

@Injectable({
  providedIn: 'root',
})
export class EmployeeService extends BaseService<Employee, 'employee'> {
  constructor(http: HttpClient) {
    super(http);
  }

  getServiceUrl(): string {
    return environment.masterDataUrl;
  }

  getBaseUrl(): string {
    return this.getServiceUrl() + '/employees';
  }

  getMetaData(): any {
    return metaData;
  }
  // ************************************ START:CUSTOM METHODS **************************

  // Custom method to be used by search and select for BusinessUnit.
  findBusinessUnits(
    q?: string,
    include?: string,
    exclude?: string,
    filter?: string
  ): Observable<Result<'business_unit', any[]>> {
    const simpleFilter = new SimpleFilter('name,code', q, include, exclude);
    const queryParam = new QueryParam();
    queryParam.perPage = 25;
    queryParam.sortAttr = 'name';

    return this.listExternalEntities(environment.masterDataUrl + '/businessUnits/list', simpleFilter, queryParam);
  }

  // Custom method to be used by search and select for Company.
  findCompanies(q?: string, include?: string, exclude?: string, filter?: string): Observable<Result<'company', any[]>> {
    const simpleFilter = new SimpleFilter('name,code', q, include, exclude);
    const queryParam = new QueryParam();
    queryParam.perPage = 25;
    queryParam.sortAttr = 'name';

    return this.listExternalEntities(environment.masterDataUrl + '/companies/list', simpleFilter, queryParam);
  }

  // Custom method to be used by search and select for CostCenter.
  findCostCenters(
    q?: string,
    include?: string,
    exclude?: string,
    filter?: string
  ): Observable<Result<'cost_center', any[]>> {
    const simpleFilter = new SimpleFilter('name,code', q, include, exclude);
    const queryParam = new QueryParam();
    queryParam.perPage = 25;
    queryParam.sortAttr = 'name';

    return this.listExternalEntities(environment.masterDataUrl + '/costCenters/list', simpleFilter, queryParam);
  }

  // Custom method to be used by search and select for GlExpenseCode.
  findGlExpenseCodes(
    q?: string,
    include?: string,
    exclude?: string,
    filter?: string
  ): Observable<Result<'gl_expense_code', any[]>> {
    const simpleFilter = new SimpleFilter('name,code', q, include, exclude);
    const queryParam = new QueryParam();
    queryParam.perPage = 25;
    queryParam.sortAttr = 'name';

    return this.listExternalEntities(environment.masterDataUrl + '/glExpenseCodes/list', simpleFilter, queryParam);
  }

  // Custom method to be used by search and select for Manager.
  findManagers(q?: string, include?: string, exclude?: string, filter?: string): Observable<Result<'employee', any[]>> {
    const simpleFilter = new SimpleFilter('first_name,last_name', q, include, exclude);
    const queryParam = new QueryParam();
    queryParam.perPage = 25;
    queryParam.sortAttr = 'presentation_name';

    return this.listExternalEntities(environment.masterDataUrl + '/employees/list', simpleFilter, queryParam);
  }

  // Custom method to be used by search and select for Department.
  findDepartments(
    q?: string,
    include?: string,
    exclude?: string,
    filter?: string
  ): Observable<Result<'department', any[]>> {
    const simpleFilter = new SimpleFilter('name,code', q, include, exclude);
    const queryParam = new QueryParam();
    queryParam.perPage = 25;
    queryParam.sortAttr = 'name';

    return this.listExternalEntities(environment.masterDataUrl + '/departments/list', simpleFilter, queryParam);
  }

  // Custom method to be used by search and select for CountryOfCitizenship.
  findCountryOfCitizenships(
    q?: string,
    include?: string,
    exclude?: string,
    filter?: string
  ): Observable<Result<'country', any[]>> {
    const simpleFilter = new SimpleFilter('name', q, include, exclude);
    const queryParam = new QueryParam();
    queryParam.perPage = 25;
    queryParam.sortAttr = 'name';

    return this.listExternalEntities(environment.extEnterpriseDataUrl + '/countries/list', simpleFilter, queryParam);
  }

  // Custom method to be used by search and select for ResidentLocation.
  findResidentLocations(
    q?: string,
    include?: string,
    exclude?: string,
    filter?: string
  ): Observable<Result<'country', any[]>> {
    const simpleFilter = new SimpleFilter('name', q, include, exclude);
    const queryParam = new QueryParam();
    queryParam.perPage = 25;
    queryParam.sortAttr = 'name';

    return this.listExternalEntities(environment.extEnterpriseDataUrl + '/countries/list', simpleFilter, queryParam);
  }

  // Custom method to be used by search and select for Location.
  findLocations(
    q?: string,
    include?: string,
    exclude?: string,
    filter?: string
  ): Observable<Result<'location', any[]>> {
    const simpleFilter = new SimpleFilter('name,code', q, include, exclude);
    const queryParam = new QueryParam();
    queryParam.perPage = 25;
    queryParam.sortAttr = 'name';

    return this.listExternalEntities(environment.masterDataUrl + '/locations/list', simpleFilter, queryParam);
  }

  // Custom method to be used by search and select for HrRole.
  findHrRoles(q?: string, include?: string, exclude?: string, filter?: string): Observable<Result<'hr_role', any[]>> {
    const simpleFilter = new SimpleFilter('name', q, include, exclude);
    const queryParam = new QueryParam();
    queryParam.perPage = 25;
    queryParam.sortAttr = 'name';

    return this.listExternalEntities(environment.masterDataUrl + '/hrRoles/list', simpleFilter, queryParam);
  }

  // Custom method to be used by dynamic list values for Employee Status.
  findEmployeeStatusListValues(): Observable<Result<'dynamic_list_value', any[]>> {
    return this.fetchDynamicListValues('Employee Status');
  }

  // Custom method to be used by dynamic list values for Group.
  findGroupListValues(): Observable<Result<'dynamic_list_value', any[]>> {
    return this.fetchDynamicListValues('Group');
  }

  // ************************************ END:CUSTOM METHODS **************************

  // ************************************ START:FETCH CHILD METHODS **************************

  // Methods to handle child records for Employee Address
  findChildEmployeeAddressRecords(
    parentId: number,
    queryParam: QueryParam,
    q?: string
  ): Observable<Result<'employee_address', any[]>> {
    const simpleFilter = new SimpleFilter(
      'type.value,address_line1,address_line2,city,state_province_region,country',
      q
    );

    return this.listExternalEntities(
      environment.masterDataUrl + `/employees/${parentId}/employeeAddresses`,
      simpleFilter,
      queryParam
    );
  }

  deleteChildEmployeeAddressRecordsById(ids: string, parentId?: string): Observable<DeleteResult> {
    return this.deleteExternalEntitiesById(environment.masterDataUrl + '/employeeAddresses', ids);
  }

  insertChildEmployeeAddressRecords(entity: any): Observable<Result<'employee_address', any>> {
    return this.insertExternalEntity(environment.masterDataUrl + `/employeeAddresses`, entity);
  }

  // Methods to handle child records for Assign Cost Center
  findChildEmployeeCostCenterRecords(
    parentId: number,
    queryParam: QueryParam,
    q?: string
  ): Observable<Result<'employee_cost_center', any[]>> {
    const simpleFilter = new SimpleFilter('cost_center.code,cost_center.name', q);

    return this.listExternalEntities(
      environment.masterDataUrl + `/employees/${parentId}/employeeCostCenters`,
      simpleFilter,
      queryParam
    );
  }

  deleteChildEmployeeCostCenterRecordsById(ids: string, parentId?: string): Observable<DeleteResult> {
    return this.deleteExternalEntitiesById(environment.masterDataUrl + '/employeeCostCenters', ids);
  }

  findUnassignedEmployeeCostCenterRecords(
    parentId: number,
    queryParam: QueryParam,
    q?: string
  ): Observable<Result<'cost_center', any[]>> {
    const simpleFilter = new SimpleFilter('code,name', q);

    return this.listExternalEntities(
      environment.masterDataUrl + `/costCenters/employeeCostCenters/unassigned/${parentId}`,
      simpleFilter,
      queryParam
    );
  }

  insertAssignmentEmployeeCostCenterRecords(assignments: any[], ids?: string, parentId?: string): Observable<any[]> {
    return this.insertExternalEntities(environment.masterDataUrl + `/employeeCostCenters`, assignments);
  }

  // Methods to handle child records for Assign Group
  findChildEmployeeGroupMembershipRecords(
    parentId: number,
    queryParam: QueryParam,
    q?: string
  ): Observable<Result<'employee_group_membership', any[]>> {
    const simpleFilter = new SimpleFilter('employee_group.email,employee_group.name', q);

    return this.listExternalEntities(
      environment.masterDataUrl + `/employees/${parentId}/employeeGroupMemberships`,
      simpleFilter,
      queryParam
    );
  }

  deleteChildEmployeeGroupMembershipRecordsById(ids: string, parentId?: string): Observable<DeleteResult> {
    return this.deleteExternalEntitiesById(environment.masterDataUrl + '/employeeGroupMemberships', ids);
  }

  findUnassignedEmployeeGroupMembershipRecords(
    parentId: number,
    queryParam: QueryParam,
    q?: string
  ): Observable<Result<'employee_group', any[]>> {
    const simpleFilter = new SimpleFilter('email,name', q);

    return this.listExternalEntities(
      environment.masterDataUrl + `/employeeGroups/employeeGroupMemberships/unassigned/${parentId}`,
      simpleFilter,
      queryParam
    );
  }

  insertAssignmentEmployeeGroupMembershipRecords(
    assignments: any[],
    ids?: string,
    parentId?: string
  ): Observable<any[]> {
    return this.insertExternalEntities(environment.masterDataUrl + `/employeeGroupMemberships`, assignments);
  }



  // ************************************ END:FETCH CHILD METHODS **************************

  // ************************************ START:COUNT METHODS **************************

  // ************************************ END:COUNT METHODS **************************
}
