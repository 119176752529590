import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { EmbeddedListComponent } from '../base/embedded-list.component';
import { EmbeddedTableContext } from 'src/app/base/common';
import { GoToasterService, GoTableComponent, GoModalService } from '@tangoe/goponents';
import { ManageApproverModalComponent } from './manage-approver-modal/manage-approver-modal.component';
@Component({
  selector: 'app-approver-list',
  templateUrl: './approver-list.component.html',
  styleUrls: ['../embedded-list.component.scss'],
})
export class ApproverListComponent extends EmbeddedListComponent {
  approverTableContext: EmbeddedTableContext;
  insertMethod: string = 'insertApproverRecords';
  updateMethod: string = 'updateApproverRecords';
  deleteMethod: string = 'deleteApproverRecords';
  approverId: number;
  constructor(
    protected el: ElementRef,
    protected modalService: GoModalService,
    protected toasterService: GoToasterService
  ) {
    super(el, toasterService, modalService);

    this.name = 'approver';
    this.title = 'Approvers';
    this.serviceMethod = 'approvers';
    this.defaultSearchAttrs =
      'approver__email,approver__presentation_name,approver__cellphone_number,approver_type.value,from_amount.value,to_amount.value';
    this.defaultSortAttr = 'approver__presentation_name';
    this.isSelectable = true;
  }
  @ViewChild('childApproverRefTable', { static: false })
  childApproverRefTable: GoTableComponent;
  addApproverRecord() {
    this.modalService.openModal(ManageApproverModalComponent, {
      appendTo: 'body',
      approverId: this.approverId,
      parentId: this.entityId,
      entityLocator: this.entityLocator,
      entityService: this.entityService,
      mode: 'add',
      modalTitle: 'Add Approver',
      modalSize: 'xl',
      modalCallback: () => {
        this.loadEmbeddedTableData();
      },
    });
  }
  editApproverRecord(id) {
    this.approverId = id;
    this.modalService.openModal(ManageApproverModalComponent, {
      appendTo: 'body',
      approverId: this.approverId,
      parentId: this.entityId,
      entityLocator: this.entityLocator,
      entityService: this.entityService,
      mode: 'edit',
      modalTitle: 'Edit Approver',
      modalSize: 'xl',
      isParentReadOnly: this.isApproverListReadOnly,
      modalCallback: () => {
        this.loadEmbeddedTableData();
      },
    });
  }
}
