import { Observable, forkJoin, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Result, DeleteResult, CountResult, HistoryResult, NoteResult, ApproverResult } from '../base/result';
import { BaseEntity } from './base-entity';
import { SimpleFilter, AdvanceFilter } from './base-filter';
import { QueryParam, DynamicListValue } from './common';
import { isNumber } from 'util';
import { sessionContext } from '../authentication/session-details';
import { timeout, repeatWhen, filter as repeatFilter, take, delay } from 'rxjs/operators';

export abstract class BaseService<T extends BaseEntity, K extends string> {
  EXPORT_TIMEOUT: number = 10 * 60 * 1000; // 10 minutes timeout.

  public dynamicListCache = new Map();
  private metaData: any;

  constructor(protected http: HttpClient) {}

  abstract getServiceUrl(): string;

  abstract getBaseUrl(): string;

  // A way to override list page URL in case there is a custom endpoint (example - invoice list page)
  // Will impact list page, simple search, advance search, sorting and exporting functionalities.
  getListPageUrl(): string {
    return this.getBaseUrl();
  }

  // Find a single record by ID
  findById(id: number | Observable<number>): Observable<Result<K, T>> {
    const headers = this.buildHeaders();
    const params: any = {};
    const url = this.getBaseUrl() + '/' + id;

    return this.http.get<Result<K, T>>(url, {
      headers,
      params,
    });
  }

  // Simple Search for matching records
  simpleSearch(simpleFilter: SimpleFilter, queryParams: QueryParam): Observable<Result<K, T[]>> {
    const headers = this.buildHeaders();

    const params: any = {};
    params.o = queryParams.sortClause();
    params.limit = queryParams.perPage;
    params.page = queryParams.page();
    params.f = simpleFilter.searchAttrs;
    params.q = simpleFilter.searchValue || '%';

    const url = this.getListPageUrl();

    return this.http.get<Result<K, T[]>>(url, {
      headers,
      params,
    });
  }

  // Get Dynamic List Values for a given list
  fetchDynamicListValues(list: string): Observable<Result<'dynamic_list_value', DynamicListValue[]>> {
    const cachedDynamicList = this.dynamicListCache.get(list);
    if (cachedDynamicList) {
      return of(cachedDynamicList);
    }

    const headers = this.buildHeaders();

    const params: any = {};
    params.o = 'value ASC, id ASC';
    params.limit = 1000;
    params.page = 1;

    const url = environment.dynamicListUrl + '/dynamicListValues/query';

    const filter: any = {};
    filter['dynamicList.name'] = list;

    const response = this.http.post<Result<'dynamic_list_value', DynamicListValue[]>>(url, filter, {
      headers,
      params,
    });

    response.subscribe(dl => this.dynamicListCache.set(list, dl));

    return response;
  }

  // Advance Search for matching records
  advanceSearch(filter: AdvanceFilter, queryParams: QueryParam): Observable<Result<K, T[]>> {
    const headers = this.buildHeaders();

    const params: any = {};
    params.o = queryParams.sortClause();
    params.limit = queryParams.perPage;
    params.page = queryParams.page();

    const url = this.getListPageUrl() + '/query';

    return this.http.post<Result<K, T[]>>(url, filter, {
      headers,
      params,
    });
  }

  // Create a new record
  insert(entity: T): Observable<Result<K, T>> {
    const headers = this.buildHeaders();
    const params: any = {};
    const url = this.getBaseUrl();

    return this.http.post<Result<K, T>>(url, entity, {
      headers,
      params,
    });
  }

  // Update an existing record
  update(entity: T): Observable<Result<K, T>> {
    const headers = this.buildHeaders();
    const params: any = {};
    const url = this.getBaseUrl() + '/' + entity.id.toString();

    return this.http.put<Result<K, T>>(url, entity, {
      headers,
      params,
    });
  }

  history(parentId: number, simpleFilter: SimpleFilter, queryParams: QueryParam): Observable<HistoryResult> {
    const headers = this.buildHeaders();

    const params: any = {};
    params.o = queryParams.sortClause();
    params.limit = queryParams.perPage;
    params.page = queryParams.page();
    params.f = simpleFilter.searchAttrs;
    params.q = simpleFilter.searchValue || '%';

    const url = this.getBaseUrl() + '/' + parentId + '/history';

    return this.http.get<HistoryResult>(url, { headers, params });
  }

  notes(parentId: number, simpleFilter: SimpleFilter, queryParams: QueryParam): Observable<NoteResult> {
    const headers = this.buildHeaders();

    const params: any = {};
    params.o = queryParams.sortClause();
    params.limit = queryParams.perPage;
    params.page = queryParams.page();
    params.f = simpleFilter.searchAttrs;
    params.q = simpleFilter.searchValue || '%';

    const url = this.getBaseUrl() + '/' + parentId + '/notes';

    return this.http.get<NoteResult>(url, { headers, params });
  }

  approvers(id: number, simpleFilter: SimpleFilter, queryParams: QueryParam): Observable<ApproverResult> {
    const headers = this.buildHeaders();

    const params: any = {};
    params.o = queryParams.sortClause();
    params.limit = queryParams.perPage;
    params.page = queryParams.page();
    params.f = simpleFilter.searchAttrs;
    params.q = simpleFilter.searchValue || '%';

    const url = this.getBaseUrl() + '/' + id + '/approvers';

    return this.http.get<ApproverResult>(url, { headers, params });
  }

  // Find a single record by ID
  findApproverById(id: number | Observable<number>): Observable<Result<K, T>> {
    const headers = this.buildHeaders();
    const params: any = {};
    const url = this.buildApproverUrl() + '/' + id;
    return this.http.get<Result<K, T>>(url, {
      headers,
      params,
    });
  }

  // Create a new approver record
  addApprover(entity: T): Observable<Result<K, T>> {
    const headers = this.buildHeaders();
    const params: any = {};
    const url = this.buildApproverUrl() + '/';
    return this.http.post<Result<K, T>>(url, entity, {
      headers,
      params,
    });
  }

  // Update an existing approver record
  updateApprover(entity: T): Observable<Result<K, T>> {
    const headers = this.buildHeaders();
    const params: any = {};
    const url = this.buildApproverUrl() + '/' + entity.id.toString();
    return this.http.put<Result<K, T>>(url, entity, {
      headers,
      params,
    });
  }

  deleteApproverRecords(ids: any, parentId?: any): Observable<DeleteResult> {
    return this.deleteExternalEntitiesById(this.buildApproverUrl(), ids);
  }

  buildApproverUrl() {
    return this.getBaseUrl().slice(0, -1) + 'Approvers';
  }

  export(
    simpleFilter: SimpleFilter,
    advanceFilter: AdvanceFilter,
    queryParams: QueryParam,
    exportLayout: any,
    url?: string
  ): Observable<any> {
    const headers = this.buildHeaders();

    const params: any = {};
    params.o = queryParams.sortClause();

    const exportRequest = {};
    exportRequest['export_layout'] = exportLayout;

    // Advance Filter available then use it, else it will be simple search.
    if (Object.keys(advanceFilter).length !== 0) {
      exportRequest['query'] = advanceFilter;
    } else {
      params.f = simpleFilter.searchAttrs;
      params.q = simpleFilter.searchValue || '%';
    }

    url = (url || this.getListPageUrl()) + '/export';

    return this.http
      .post<any>(url, exportRequest, {
        headers,
        params,
        responseType: 'blob' as 'json',
        observe: 'response',
      })
      .pipe(timeout(this.EXPORT_TIMEOUT));
  }

  triggerExport(
    simpleFilter: SimpleFilter,
    advanceFilter: AdvanceFilter,
    queryParams: QueryParam,
    exportLayout: any,
    url?: string
  ): Observable<any> {
    const headers = this.buildHeaders();

    const params: any = {};
    params.o = queryParams.sortClause();

    const exportRequest = {};
    exportRequest['export_layout'] = exportLayout;

    // Advance Filter available then use it, else it will be simple search.
    if (Object.keys(advanceFilter).length !== 0) {
      exportRequest['query'] = advanceFilter;
    } else {
      params.f = simpleFilter.searchAttrs;
      params.q = simpleFilter.searchValue || '%';
    }

    url = (url || this.getListPageUrl()) + '/async-export';

    return this.http.post<any>(url, exportRequest, {
      headers,
      params,
    });
  }

  downloadExport(fileKey: string): Observable<any> {
    const exportFileKey = { file_key: fileKey };
    const url = this.getServiceUrl() + '/async-export-result';
    const retryDelay: number = 10000;

    const downloader = this.http.post<any>(url, exportFileKey, {
      responseType: 'blob' as 'json',
      observe: 'response',
    });

    return downloader.pipe(
      repeatWhen(obs => obs.pipe(delay(retryDelay))),
      repeatFilter(data => {
        return data.body.type !== 'application/json';
      }),
      take(1) // Required to break the loop
    );
  }

  // Delete a record by ID
  deleteById(id: string): Observable<DeleteResult> {
    const headers = this.buildHeaders();
    const params: any = {};

    const url = this.getBaseUrl() + '/' + id;

    return this.http.delete<DeleteResult>(url, { headers, params });
  }

  // Delete an external record by ID
  deleteExternalEntitiesById(url: string, id: string): Observable<DeleteResult> {
    const headers = this.buildHeaders();
    const params: any = {};

    return this.http.delete<DeleteResult>(`${url}/${id}?h=true`, { headers, params });
  }

  // Count records.
  countExternalEntities(url: string, simpleFilter?: SimpleFilter, queryParams?: QueryParam): Observable<CountResult> {
    const headers = this.buildHeaders();
    const params: any = {};
    if (simpleFilter && simpleFilter.searchValue) {
      params.f = simpleFilter.searchAttrs;
      params.q = simpleFilter.searchValue || '%';
    }

    return this.http.get<CountResult>(url, {
      headers,
      params,
    });
  }

  // Query entity by URL.
  advanceSearchForExternalEntities<K1 extends string, T1>(
    url: string,
    filter: AdvanceFilter,
    queryParams?: QueryParam
  ): Observable<Result<K1, T1[]>> {
    const headers = this.buildHeaders();

    const params: any = {};
    params.o = queryParams.sortClause();
    params.limit = queryParams.perPage;
    params.page = queryParams.page();

    return this.http.post<Result<K1, T1[]>>(url, filter, {
      headers,
      params,
    });
  }

  // Search and Select entity by URL.
  listExternalEntities<K1 extends string, T1>(
    url: string,
    simpleFilter?: SimpleFilter,
    queryParams?: QueryParam
  ): Observable<Result<K1, T1[]>> {
    const headers = this.buildHeaders();

    const params: any = {};

    if (simpleFilter) {
      if (simpleFilter.includeRows) {
        params.i = simpleFilter.includeRows;
      }

      if (simpleFilter.excludeRows) {
        params.x = simpleFilter.excludeRows;
      }

      if (simpleFilter.searchValue) {
        params.f = simpleFilter.searchAttrs;
        params.q = simpleFilter.searchValue || '%';
      }
    }

    if (queryParams) {
      params.o = queryParams.sortClause();
      params.limit = queryParams.perPage;
      params.page = queryParams.page();
    }

    return this.http.get<Result<K1, T1[]>>(url, {
      headers,
      params,
    });
  }

  // Insert single record by URL
  insertExternalEntity<K1 extends string>(url: string, entity: any): Observable<Result<K1, any>> {
    const headers = this.buildHeaders();
    const params: any = {};

    return this.http.post<Result<K1, any>>(url, entity, {
      headers,
      params,
    });
  }

  // Update single record by URL
  updateExternalEntity<K1 extends string>(url: string, entity: any): Observable<Result<K1, any>> {
    const headers = this.buildHeaders();
    const params: any = {};

    return this.http.put<Result<K1, any>>(url, entity, {
      headers,
      params,
    });
  }
  // Insert records by URL ( multiple records)
  insertExternalEntities<K1 extends string>(url: string, entities: any[]): Observable<any[]> {
    const headers = this.buildHeaders();
    const params: any = {};

    const observables = [];

    entities.forEach(entity => {
      observables.push(this.http.post(url, entity, { headers, params }));
    });

    return forkJoin(observables);
  }

  simplePost(url: string, jsonbody: any): Observable<any> {
    const headers = this.buildHeaders();

    return this.http.post(url, jsonbody, { headers });
  }

  // Find a single record by ID
  findExternalById<K1 extends string, T1>(url: string, id: number): Observable<Result<K1, T1>> {
    const headers = this.buildHeaders();
    const params: any = {};

    return this.http.get<Result<K1, T1>>(url + '/' + id, {
      headers,
      params,
    });
  }

  // Build the Headers
  protected buildHeaders(): HttpHeaders {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Headers': 'Content-Type',
      'X-TNGO-Tenant': environment.x_tngo_tenant,
      'X-TNGO-User': environment.x_tngo_user,
      'X-TNGO-Login-Key': environment.x_tngo_login_key,
      'X-TNGO-Direct-Cookie': environment.x_tngo_direct_cookie,
    });
    return headers;
  }

  loggedInUser(): string {
    return sessionContext.userInfo.userIdentifier;
  }

  loggedInUserAuthStrategy(): string {
    return sessionContext.userInfo.authStrategy;
  }

  stateProvinceCustomLookup(
    q?: string,
    include?: string,
    exclude?: string,
    country?: string
  ): Observable<Result<'state_province', any[]>> {
    const filter: any = {};

    // Convert the search term to LIKE operator.

    if (q) {
      filter['name'] = `*${q}*`;
    }

    // Country being passed can either be the country name or the country id
    if (country) {
      if (typeof country === 'number') {
        filter['country.id'] = String(country);
      } else {
        filter['country.name'] = String(country);
      }
    }

    if (include) {
      filter['id'] = String(include);
    }

    if (exclude) {
      filter['id'] = `!${exclude}`;
    }

    const headers = this.buildHeaders();

    const params: any = {};
    params.o = 'name ASC, id ASC';
    params.limit = 100;
    params.page = 1;

    const url = environment.enterpriseDataUrl + '/stateProvinces/query';

    return this.http.post<Result<'state_province', any[]>>(url, filter, { headers, params });
  }

  abstract getMetaData(): any;
}
