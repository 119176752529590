import { Component, OnInit } from '@angular/core';
import { BaseFieldComponent } from '../field-base';

@Component({
  selector: 'app-field-textarea',
  templateUrl: './field-textarea.component.html',
  styleUrls: ['./field-textarea.component.scss'],
})
export class FieldTextAreaComponent extends BaseFieldComponent implements OnInit {
  ngOnInit() {
    super.ngOnInit();
    this.placeholder = `${this.label}`;
  }
}
