import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild, Input } from '@angular/core';

@Component({
  selector: 'app-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.scss'],
})
export class HeaderBarComponent implements OnInit {
  @Input() title: string;
  @Input() isAddMode: boolean;
  @Input() showButtonLoading: boolean;
  @Input() isEditable: boolean = true;
  @Input() isCancelable: boolean = true;
  @Input() showBackArrow: boolean;

  @Output() list = new EventEmitter();
  @Output() save = new EventEmitter();

  public addRecordHeader: string;
  public editRecordHeader: string;

  listRecords() {
    this.list.emit();
  }

  saveRecord() {
    this.save.emit();
  }
  constructor() { }

  ngOnInit() {
    this.showBackArrow = this.showBackArrow !== undefined ? this.showBackArrow : true;
    this.addRecordHeader = `Add ${this.title}`;
    this.editRecordHeader = `Edit ${this.title}`;
  }
}
