import { Injectable } from '@angular/core';
import { AllocationInfoService } from '../../../allocation-info/allocation-info.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Result } from '../../../base/result';
import { environment } from '../../../../environments/environment';
import { Vendor } from '../../../vendor/vendor';
import { Account } from '../../../account/account';
import { SaveAllocationInfoDto } from '../../../allocation-info/save-allocation-info-dto';
import { AllocationInfo } from '../../../allocation-info/allocation-info';
import fileSaver from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class AllocationConfigurationService extends AllocationInfoService {
  constructor(
    private httpClient: HttpClient) {
    super(httpClient);
  }

  updateVendor(allocationInfoId: number, vendorId: number): Observable<Vendor> {
    const vendor = new Vendor();
    vendor.id = vendorId;
    vendor.addAssociation('allocationInfo', 'allocation_info_row_id', allocationInfoId);

    return this.httpClient.put<Result<'vendor', Vendor>>(
      environment.masterDataUrl + '/vendors/' + vendorId,
      vendor,
      {
        headers: this.buildHeaders(),
      },
    ).pipe(
      map((result) => {
        return result.vendor;
      })
    );
  }

  updateAccount(allocationInfoId: number, accountId: number): Observable<Account> {
    const account = new Account();
    account.id = accountId;
    account.addAssociation('allocationInfo', 'allocation_info_row_id', allocationInfoId);

    return this.httpClient.put<Result<'account', Account>>(
      environment.masterDataUrl + '/accounts/' + accountId,
      account,
      {
        headers: this.buildHeaders(),
      },
    ).pipe(
      map((result) => {
        return result.account;
      })
    );
  }

  extractFormData(
    formData: any,
    assocDetailAttr: string,
    assocDetailId: number,
    isChangeRequest: boolean = false,
    id?: number,
  ): SaveAllocationInfoDto {
    const isAddMode = !id;

    const valueMap = {
      TEMPLATE_ALLOCATION: 'LOAD_ALLOCATIONS_FROM_TEMPLATE',
      STRING_ALLOCATION: 'DEFINE_ALLOCATION_STRING',
      CHARGE_ALLOCATION: 'DEFINE_AN_ALLOCATION_STRING_PER_CHANGE',
      AUTOMATIC_ALLOCATION: 'AUTOMATIC_ALLOCATION_BASED_ON_CHARGE_ELEMENT',

      PROPORTIONALLY_TO_ALL: 'ALLOCATE_PROPORTIONALLY_BASED_ON_EXISTING_ALLOCATIONS',
      EQUALLY_TO_ALL: 'ALLOCATE_EQUALLY_TO_ALL_EXISTING_ALLOCATIONS',
      PARTICULAR_STRING: 'ALLOCATE_ALL_REMAINING_AMOUNTS_TO',
      ALLOW_REMAINING_AMOUNTS_TO_BE_OVER_OR_UNDER_ALLOCATED: 'ALLOW_REMAINING_AMOUNTS_TO_BE_OVER_OR_UNDER_ALLOCATED'
    };

    const entity: SaveAllocationInfoDto = new SaveAllocationInfoDto();

    const {
      string_format,
      allocation_type,
      allocation_method,
      allocation_charge_elements,
      remain_amt_config,
      remain_amt_string
    } = formData;

    entity.allocation_type = allocation_type;
    entity.allocation_method_type = valueMap[allocation_method];
    entity.string_format_row_id = string_format;
    entity.entity_row_id = assocDetailId;
    entity.allocation_execution_type = assocDetailAttr;
    entity.remaining_amount_allocation_type = remain_amt_config ? valueMap[remain_amt_config] : '';
    entity.remaining_amount = remain_amt_string || '';
    entity.is_change_request = isChangeRequest;
    entity.allocation_info_row_id = '';

    if (remain_amt_config === 'NULL') {
      entity.remaining_amount_allocation_type = null;
    }

    if (allocation_charge_elements) {
      entity.charge_element = allocation_charge_elements.join(',');
    }
    if (formData.remaining_amount) {
      entity.remaining_amount = formData.remaining_amount;
    }
    if (!isAddMode) {
      entity.id = id;
      entity.allocation_info_row_id = id;
    }

    return entity;
  }


  downloadTemplate(entity: AllocationInfo, currencyIndicatorRequired = false) {
    const headers = this.buildHeaders();
    const filename = `${entity.string_format.name}-${entity.allocation_type}.csv`;

    this.httpClient.post(environment.enterpriseDataUrl + '/buildAllocationTemplate', {
      allocationInfoRowId: entity.id,
      currencyIndicatorRequired
    }, { headers, responseType: 'text' }).subscribe(data => {
      const blob = new Blob([data], { type: 'application/vnd.ms-excel' });
      fileSaver.saveAs(blob, filename);
    });
  }
  getMetaData(): any {
  }
  uploadTemplate(file: File, entity: AllocationInfo) {
    const headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Credentials': 'true',
      'Access-Control-Allow-Headers': 'Content-Type',
      'X-TNGO-Tenant': environment.x_tngo_tenant,
      'X-TNGO-User': environment.x_tngo_user,
      'X-TNGO-Login-Key': environment.x_tngo_login_key,
      'X-TNGO-Direct-Cookie': environment.x_tngo_direct_cookie,
    });

    const formData = new FormData();
    formData.append('allocationInfoRowId', entity.id.toString());
    formData.append('currency', 'USD');
    formData.append('file', file);
    formData.append('invoiceRowId', '0');

    return this.httpClient.post<{ status: string, code: number, message?: string }>(environment.expenseDataUrl + '/allocations/fileUpload', formData, { headers });
  }

  allocateCharges(invoiceHeaderId: number): Observable<any> {
    const headers = this.buildHeaders();

    return this.httpClient.post(environment.expenseDataUrl + '/allocateCharges', {
      invoiceRowId: invoiceHeaderId
    }, { headers });
  }
}
