import { AfterViewInit, Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { GoToasterService, GoModalService } from '@tangoe/goponents';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { BaseModalEditComponent } from '../../base/base-modal-edit.component';
import { AllocationStringService } from './allocation-string.service';
import { AllocationString } from './allocation-string';
import { HttpClient } from '@angular/common/http';
import { AllocationStringFormatService } from '../../allocation-string-format/allocation-string-format.service';
import { QueryParam } from '../../base/common';
import { AllocationStringPart } from '../../allocation-string-format/allocation-string-format';
import { map } from 'rxjs/operators';
import { FieldLookupComponent } from '../../shared/field-types/field-lookup/field-lookup.component';
import { AllocationInfo } from '../allocation-info';
import { environment } from '../../../environments/environment';
import { LineItem } from '../../invoice/invoice-detail/line-items/line-item';
import { CustomValidators } from 'src/app/base/validators';
import * as utils from '../../base/utils';
import { SaveAllocationStringDto } from '../../allocation-string/save-allocation-string-dto';
@Component({
  selector: 'app-allocation-string-modal',
  templateUrl: './allocation-string-modal.component.html',
  styleUrls: ['./allocation-string-modal.component.scss'],
})
export class AllocationStringModalComponent extends BaseModalEditComponent<AllocationString, 'allocation_string'>
  implements OnInit, AfterViewInit {
  constructor(
    private httpClient: HttpClient,
    public allocationStringService: AllocationStringService,
    public allocationStringFormatService: AllocationStringFormatService,
    protected toasterService: GoToasterService,
    protected modalService: GoModalService,
    protected router: Router,
    protected route: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {
    super(allocationStringService, toasterService, modalService, router, route);
    this.listContextPath = '/allocation-strings';
    this.editContextPath = '/allocation-strings';
    this.entityLocator = 'allocation_string';
  }

  @ViewChildren('allocationStringPartFields') allocationStringPartLookups: QueryList<FieldLookupComponent>;
  @Input() isReadOnly: boolean;
  @Input() isParentReadOnly: boolean;
  loading = true;
  parent: AllocationInfo;
  parentCharge: LineItem;
  childAllocationStringParts: AllocationStringPart[] = [];
  storeDataOfIndex: any = new Array();
  stringCodeParts: Array<string>;
  stringNameParts: Array<string>;

  serviceDefinitions: {
    [key: string]: {
      entityLocator: any;
      lookupMethod: string;
      url: string;
    };
  } = {
      ACCOUNTING_SYSTEM: {
        lookupMethod: 'findAccountingSystems',
        entityLocator: 'accounting_system',
        url: 'accountingSystems',
      },
      BUSINESS_UNIT: {
        lookupMethod: 'findBusinessUnits',
        entityLocator: 'business_unit',
        url: 'businessUnits',
      },
      COMPANY: {
        lookupMethod: 'findCompanies',
        entityLocator: 'company',
        url: 'companies',
      },
      GL_EXPENSE_CODE: {
        lookupMethod: 'findGlExpenseCodes',
        entityLocator: 'gl_expense_code',
        url: 'glExpenseCodes',
      },
      COST_CENTER: {
        lookupMethod: 'findCostCenters',
        entityLocator: 'cost_center',
        url: 'costCenters',
      },
      DEPARTMENT: {
        lookupMethod: 'findDepartments',
        entityLocator: 'department',
        url: 'departments',
      },
      LOCATION: {
        lookupMethod: 'findLocations',
        entityLocator: 'location',
        url: 'locations',
      },
      /*
          REPORTING_CODE: {
            lookupMethod: 'findReportingCodes',
            entityLocator: 'reporting_code',
            url: 'reportingCodes'
          },
          LEGAL_ENTITY: {
            lookupMethod: 'findLegalEntities',
            entityLocator: 'legal_entity',
            url: 'legalEntities'
          }
          */
    };

  ngOnInit() {
    this.loadRecord();

    this.formData = this.formBuilder.group({
      ACCOUNTING_SYSTEM: [undefined, []],
      BUSINESS_UNIT: [undefined, []],
      COMPANY: [undefined, []],
      GL_EXPENSE_CODE: [undefined, []],
      COST_CENTER: [undefined, []],
      DEPARTMENT: [undefined, []],
      LOCATION: [undefined, []],
      REPORTING_CODE: [undefined, []],
      LEGAL_ENTITY: [undefined, []],
      amount: ['', []],
      percentage: ['', []],
    });

    if (this.parentCharge) {
      this.formData.patchValue({ amount: this.parentCharge.amount.value });
      this.formData.controls.amount.disable();
    }
    this.allocationStringFormatService
      .findChildAllocationStringPartRecords(this.parent.string_format.id, new QueryParam())
      .subscribe(d => {
        this.childAllocationStringParts = d.allocation_string_part;
        this.stringCodeParts = new Array(d.allocation_string_part.length);
        this.stringNameParts = new Array(d.allocation_string_part.length);
        // sort by allocation position
        this.childAllocationStringParts.sort((a, b) => {
          return a.position - b.position;
        });
        this.childAllocationStringParts.forEach(a => {
          let fc;
          if (!a.allow_any_value) {
            fc = new FormControl(utils.extractLookupId(undefined), Validators.required);
          } else {
            const textLength: number = Number(a['length']);
            fc = new FormControl('', [Validators.required, Validators.maxLength(textLength)]);
          }

          this.formData.addControl(a.field_mapping + '_' + a.id, fc);
          const control = this.formData.get(a.field_mapping + '_' + a.id);
          const def = this.serviceDefinitions[a.field_mapping];

          if (this.parent.allocation_type === 'AMOUNTS') {
            this.formData.controls['amount'].setValidators([
              Validators.required,
              Validators.maxLength(13),
              Validators.pattern('[0-9]+(.[0-9][0-9]?)?'),
              Validators.pattern('^(0*[1-9][0-9]*(.[0-9]+)?|0+.[0-9]*[1-9][0-9]*)$'), // More than zero
            ]);
          }

          if (this.parent.allocation_type === 'PERCENTAGES') {
            this.formData.controls['percentage'].setValidators([
              Validators.required,
              Validators.maxLength(10),
              Validators.pattern('[0-9]+(.[0-9][0-9]?)?'), // Support only two decimal number
              Validators.pattern('^(0*[1-9][0-9]*(.[0-9]+)?|0+.[0-9]*[1-9][0-9]*)$'), // More than zero
            ]);
          }

          control.valueChanges.subscribe(v => {
            if (a.allow_any_value) {
              if (a.position) {
                this.stringCodeParts[a.position - 1] = v;
                this.stringNameParts[a.position - 1] = null;
              } else {
                this.stringCodeParts[a.position - 1] = v;
                this.stringNameParts[a.position - 1] = v;
              }

              return;
            }

            this.allocationStringService
              .simplePost(environment.masterDataUrl + '/' + def.url + '/query', { code: v })
              .pipe(
                map(r => r[def.entityLocator]),
                map((r: Array<any>) => r.shift())
              )
              .subscribe(r => {
                if (a.position) {
                  const code = r ? r.code : null;
                  const name = r ? r.name : null;
                  this.stringCodeParts[a.position - 1] = code;
                  this.stringNameParts[a.position - 1] = name;
                  if (!a.allow_any_value) {
                    a['selectedId'] = r ? r.id : a['selectedId'];
                  }
                } else {
                  this.stringCodeParts[a.position - 1] = r.code;
                  this.stringNameParts[a.position - 1] = r.name;
                }
              });
          });
        });

        this.loading = false;
      });
  }

  ngAfterViewInit(): void { }

  populateForm(): void {
    if (this.rowData) {
      const queryParam = new QueryParam();
      queryParam.sortAttr = 'position';

      const stringCode = this.rowData.string_code || '';
      const stringName = this.rowData.string_name || '';

      const codes = stringCode.split(this.parent.string_format.separator);
      const names = stringName.split(this.parent.string_format.separator);

      this.allocationStringFormatService
        .findChildAllocationStringPartRecords(this.parent.string_format.id, queryParam)
        .subscribe(stringPart => {
          const data: any = {};
          if (this.rowData.amount) {
            data.amount = this.rowData.amount.value;
          }

          data.percentage = this.rowData.percentage;
          this.storeDataOfIndex = [];
          stringPart.allocation_string_part.forEach((part, index) => {
            const fl = this.allocationStringPartLookups.find(
              cmp => cmp.entityLocator === part.field_mapping.toLowerCase()
            );
            if (!this.isAddMode) {
              [...Array(part.position)].forEach((_, i) => {
                if (part.position === i + 1) {
                  this.storeDataOfIndex.push(codes[index]);
                } else if (!this.storeDataOfIndex[i]) {
                  this.storeDataOfIndex.push('No Data');
                }
              });
            }

            const code = this.storeDataOfIndex[part.position - 1];
            if (fl) {
              fl.searchValue = code;
              fl.loadItems();
            }

            data[part.field_mapping + '_' + part.id] = code;
          });
          this.formData.patchValue(data);
        });
    }
  }

  customselectedId(value) {
    return value;
  }
  extractFormData(): AllocationString {
    const entity: SaveAllocationStringDto = new SaveAllocationStringDto();

    const { amount, percentage } = this.formData.value;

    entity.string_code = this.stringCodeParts.filter(i => !!i).join(this.parent.string_format.separator);
    entity.string_name = this.stringNameParts.filter(i => !!i).join(this.parent.string_format.separator);
    entity.allocation_info_row_id = this.parent.id;
    entity.amount_ind = '';
    entity.amount_val = 0;
    entity.line_item_row_id = 0;
    entity.percentage = 0;

    if (this.parent.allocation_type === 'AMOUNTS') {
      entity.amount_val = amount;
      entity.amount_ind = 'USD';
    }

    if (this.parent.allocation_type === 'PERCENTAGES') {
      entity.percentage = percentage;
    }

    if (this.parentCharge) {
      entity.amount_val = this.parentCharge.amount.value;
      entity.amount_ind = this.parentCharge.amount.indicator;
      entity.line_item_row_id = this.parentCharge.id;
    }

    if (!this.isAddMode) {
      entity.id = this.id;
      entity.allocation_string_row_id = this.id;
    }

    return entity;
  }
}
