import { Component, OnInit, ViewChild } from '@angular/core';
import { GoToasterService, GoTableComponent, GoModalService } from '@tangoe/goponents';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BaseModalEditComponent } from '../../base/base-modal-edit.component';
import { Result } from '../../base/result';
import { ItemSelector, EmbeddedTableContext } from '../../base/common';
import * as utils from '../../base/utils';
import { CustomValidators } from '../../base/validators';
import { ManageAddressForLocationService } from './manage-address-for-location.service';
import { LocationAddress } from './manage-address-for-location';

@Component({
  selector: 'app-manage-address-for-location-modal',
  templateUrl: './manage-address-for-location-modal.component.html',
  styleUrls: ['./manage-address-for-location-modal.component.scss'],
})
export class ManageAddressForLocationModalComponent extends BaseModalEditComponent<LocationAddress, 'location_address'>
  implements OnInit {
  country_selection: number;
  state_province_selection: number;

  constructor(
    public manageAddressForLocationService: ManageAddressForLocationService,
    protected toasterService: GoToasterService,
    protected modalService: GoModalService,
    protected router: Router,
    protected route: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {
    super(manageAddressForLocationService, toasterService, modalService, router, route);
    this.listContextPath = '/manage-address-for-locations';
    this.editContextPath = '/manage-address-for-locations';
    this.entityLocator = 'location_address';
  }

  ngOnInit() {
    this.loadRecord();
  }

  populateForm(): void {
    this.formData = this.formBuilder.group({
      address_id: [this.entity.address_id, [Validators.required, Validators.maxLength(128)]],

      type: [this.entity.type, [Validators.required, Validators.maxLength(32)]],

      country: [utils.extractLookupId(this.entity.country), [Validators.required]],

      street1: [this.entity.street1, [Validators.required, Validators.maxLength(128)]],

      street2: [this.entity.street2, [Validators.maxLength(128)]],

      street3: [this.entity.street3, [Validators.maxLength(128)]],

      city: [this.entity.city, [Validators.required, Validators.maxLength(128)]],

      state_province: [utils.extractLookupId(this.entity.state_province), []],

      zip_postal_code: [this.entity.zip_postal_code, [Validators.maxLength(10), CustomValidators.zipcode]],

      is_primary: [utils.toBoolean(this.entity.is_primary), []],
    });

    this.country_selection = utils.extractLookupId(this.entity.country);
    this.state_province_selection = utils.extractLookupId(this.entity.state_province);

    this.stateProvinceDisplayHandler(this.country_selection);

    this.formData.get('country').valueChanges.subscribe(value => {
      this.stateProvinceDisplayHandler(value);
    });
  }

  stateProvinceDisplayHandler(value: any) {
    if (value) {
      setTimeout(() => {
        this.formData.get('state_province').enable();
        if (value !== this.country_selection) {
          this.country_selection = value;
          this.formData.get('state_province').reset();
        }
      }, 100);
    } else {
      this.formData.get('state_province').reset();
      this.formData.get('state_province').disable();
    }
  }

  extractFormData(): LocationAddress {
    const entity: LocationAddress = new LocationAddress();

    const {
      address_id,
      type,
      country,
      street1,
      street2,
      street3,
      city,
      state_province,
      zip_postal_code,
      is_primary,
    } = this.formData.value;

    entity.address_id = address_id;
    entity.type = type;
    entity.street1 = street1;
    entity.street2 = street2;
    entity.street3 = street3;
    entity.city = city;
    entity.zip_postal_code = zip_postal_code;
    entity.is_primary = is_primary;

    entity.addAssociation('country', 'country_row_id', country);
    entity.addAssociation('stateProvince', 'state_province_row_id', state_province);

    if (this.isAddMode) {
      entity.addAssociation('location', 'location_row_id', this.parentId);
    } else {
      entity.id = this.id;
      entity.location_address_row_id = this.id;
    }

    return entity;
  }
}
