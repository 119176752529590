import { Component, OnInit, Input } from '@angular/core';
import { BaseModalEditComponent } from '../../../../base/base-modal-edit.component';
import { Approver } from './manage-approver';
import { GoToasterService, GoModalService } from '@tangoe/goponents';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { ApproverService } from '../approver.service';
import * as utils from '../../../../base/utils';
import { CustomValidators } from '../../../../base/validators';

@Component({
  selector: 'app-manage-approver-modal',
  templateUrl: './manage-approver-modal.component.html',
  styleUrls: ['./manage-approver-modal.component.scss'],
})
export class ManageApproverModalComponent extends BaseModalEditComponent<Approver, 'approver'> implements OnInit {
  @Input() approverId: number;
  @Input() entityLocator: string;
  @Input() entityService: any;

  approverTypeList: any = [];

  findMethod: string = 'findApproverById';

  approverData: any = {};
  approverTypeData: any;
  employees_selection: number;

  constructor(
    public approverService: ApproverService,
    protected toasterService: GoToasterService,
    protected modalService: GoModalService,
    protected router: Router,
    protected route: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {
    super(approverService, toasterService, modalService, router, route);
  }

  ngOnInit() {
    this.approverService.fetchDynamicListValues('Approver Type').subscribe(data => {
      this.approverTypeList = data.dynamic_list_value;
    });

    this.populateForm();
  }

  populateForm(): void {
    this.formData = this.formBuilder.group({
      employees: [undefined, [Validators.required]],

      approver_type: [undefined, [Validators.required]],

      from_amount: ['', []],

      to_amount: ['', []],
    });

    if (this.mode === 'add') {
      this.isAddMode = true;
    } else {
      this.isAddMode = false;
      this.loadApproverRecord();
    }

    this.amountFieldDisplayHandler();

    this.formData.get('approver_type').valueChanges.subscribe(value => {
      this.amountFieldDisplayHandler();
    });
  }

  loadApproverRecord() {
    this.entityService[this.findMethod](this.approverId).subscribe(
      result => {
        this.approverData = result[this.entityLocator];

        this.formData.controls['approver_type'].setValue(this.approverData.approver_type.value_id);
        this.formData.controls['employees'].setValue(utils.extractLookupId(this.approverData.approver));
        this.employees_selection = utils.extractLookupId(this.approverData.approver);

        this.amountFieldDisplayHandler();

        this.formData.controls['from_amount'].setValue(utils.extractAndFormatCurrency(this.approverData.from_amount));
        this.formData.controls['to_amount'].setValue(utils.extractAndFormatCurrency(this.approverData.to_amount));
      },
      error => {}
    );
  }

  amountFieldDisplayHandler() {
    const approver_type_id = this.valueOf('approver_type') || 0;

    if (this.isApproverTypeBanded(approver_type_id)) {
      this.formData.get('from_amount').enable();
      this.formData.get('to_amount').enable();
      this.formData.controls['from_amount'].setValidators([
        Validators.required,
        Validators.maxLength(20),
        CustomValidators.currency,
      ]);
      this.formData.controls['to_amount'].setValidators([
        Validators.required,
        Validators.maxLength(20),
        CustomValidators.currency,
      ]);
    } else {
      this.formData.get('from_amount').disable();
      this.formData.get('to_amount').disable();
      this.formData.controls['from_amount'].setValidators(null);
      this.formData.controls['to_amount'].setValidators(null);
      this.formData.controls['from_amount'].setValue(null);
      this.formData.controls['to_amount'].setValue(null);
    }
    this.formData.controls['from_amount'].updateValueAndValidity();
    this.formData.controls['to_amount'].updateValueAndValidity();
  }

  extractFormData(): Approver {
    const entity: Approver = new Approver();

    const { employees, from_amount, to_amount, approver_type } = this.formData.value;

    entity.addDynamicListValue('approver_type', approver_type);
    entity.addAssociation('approver', 'employeeRowId', employees);

    entity.addCurrencyValue('from_amount', from_amount);
    entity.addCurrencyValue('to_amount', to_amount);

    if (this.entityLocator === 'cost_center_approver') {
      entity.addAssociation('costCenter', 'costCenterRowId', this.parentId);
    } else if (this.entityLocator === 'account_approver') {
      entity.addAssociation('account', 'accountRowId', this.parentId);
    }

    if (!this.isAddMode) {
      entity.id = this.approverId;

      if (!this.isApproverTypeBanded(approver_type)) {
        entity.addNullifiedAttribute('from_amount');
        entity.addNullifiedAttribute('to_amount');
      }
    }
    return entity;
  }

  saveApproverRecord() {
    this.showButtonLoading = true;

    if (!this.validateFormData()) {
      this.raiseValidationError();
      this.showButtonLoading = false;

      return;
    }

    this.entity = this.extractFormData();

    if (this.isAddMode) {
      this.entityService.addApprover(this.entity).subscribe(
        data => {
          this.entity = this.extractEntity(data);
          this.id = this.entity.id;

          this.clearErrors();
          this.showButtonLoading = false;

          this.raiseSaveSuccess();
          this.closeAndRefresh();
        },
        error => {
          this.showButtonLoading = false;
          this.raiseSaveError(error);
        }
      );
    } else {
      this.entityService.updateApprover(this.entity).subscribe(
        data => {
          this.clearErrors();
          this.showButtonLoading = false;

          this.entity = this.extractEntity(data);
          this.id = this.entity.id;

          this.raiseSaveSuccess();
          this.closeAndRefresh();
        },
        error => {
          this.showButtonLoading = false;
          this.raiseSaveError(error);
        }
      );
    }
  }

  isApproverTypeBanded(approver_type_id: number): boolean {
    const selectedElement = this.approverTypeList.find(x => {
      return x.id === approver_type_id;
    });

    if (selectedElement && selectedElement.value.includes('Banded')) {
      return true;
    }
    return false;
  }
}
