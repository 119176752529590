import { BaseEntity } from '../base/base-entity';
import { AllocationInfo } from '../allocation-info/allocation-info';

/**
 * This class represent the Account entity.
 */
export class Account extends BaseEntity {
  account_row_id: number;

  account_number: string;
  account_name: string;
  parent_account: { id: number; account_number: string };
  vendor: { id: number; name: string };
  account_status: { value_id: number; value?: string; dynamic_list_name?: string };
  account_tier: { value_id: number; value?: string; dynamic_list_name?: string };
  service_category: { value_id: number; value?: string; dynamic_list_name?: string };
  servicing_carrier: string;
  description: string;
  account_source: { value_id: number; value?: string; dynamic_list_name?: string };
  street1: string;
  street2: string;
  street3: string;
  city: string;
  state_province_region: string;
  zip_postal_code: string;
  country: { id: number; name: string };
  redirect_requested_date: string;
  redirected_date: string;
  billing_start_date: string;
  billing_end_date: string;
  paper_cancelled_date: string;
  excludefrom_allocation: string;
  redirect_ineligible: string;
  out_source_ineligible: string;
  excludefrom_alloc_spreading: string;
  in_discovery: string;
  allocation_info: AllocationInfo;
}
