import { Component, OnInit, ViewChild } from '@angular/core';
import { GoToasterService, GoTableComponent, GoModalService } from '@tangoe/goponents';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BaseModalEditComponent } from '../../base/base-modal-edit.component';
import { Result } from '../../base/result';
import { ItemSelector, EmbeddedTableContext } from '../../base/common';
import * as utils from '../../base/utils';
import { CustomValidators } from '../../base/validators';
import { InvoiceUsageDetailService } from './invoice-usage-detail.service';
import { InvoiceUsageDetail } from './invoice-usage-detail';

@Component({
  selector: 'app-invoice-usage-detail-modal',
  templateUrl: './invoice-usage-detail-modal.component.html',
  styleUrls: ['./invoice-usage-detail-modal.component.scss'],
})
export class InvoiceUsageDetailModalComponent extends BaseModalEditComponent<InvoiceUsageDetail, 'invoice_usage_detail'>
  implements OnInit {
  constructor(
    public invoiceUsageDetailService: InvoiceUsageDetailService,
    protected toasterService: GoToasterService,
    protected modalService: GoModalService,
    protected router: Router,
    protected route: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {
    super(invoiceUsageDetailService, toasterService, modalService, router, route);
    this.listContextPath = '/invoice-usage-details';
    this.editContextPath = '/invoice-usage-details';
    this.entityLocator = 'invoice_usage_detail';
  }

  ngOnInit() {
    this.loadRecord();
  }

  populateForm(): void {
    this.formData = this.formBuilder.group({});
  }

  extractFormData(): InvoiceUsageDetail {
    const entity: InvoiceUsageDetail = new InvoiceUsageDetail();

    const {} = this.formData.value;

    if (this.isAddMode) {
      entity.addAssociation('invoice_header', 'invoice_header_row_id', this.parentId);
    } else {
      entity.id = this.id;
      entity.invoice_usage_detail_row_id = this.id;
    }

    return entity;
  }
}
