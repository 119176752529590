import { Component, OnInit, ViewChild } from '@angular/core';
import { GoToasterService, GoTableComponent, GoModalService } from '@tangoe/goponents';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BaseModalEditComponent } from '../../base/base-modal-edit.component';
import { Result } from '../../base/result';
import { ItemSelector, EmbeddedTableContext } from '../../base/common';
import * as utils from '../../base/utils';
import { CustomValidators } from '../../base/validators';
import { ManageGrantPrivilegesService } from './manage-grant-privileges.service';
import { Privileges } from './manage-grant-privileges';

@Component({
  selector: 'app-manage-grant-privileges-modal',
  templateUrl: './manage-grant-privileges-modal.component.html',
  styleUrls: ['./manage-grant-privileges-modal.component.scss'],
})
export class ManageGrantPrivilegesModalComponent extends BaseModalEditComponent<Privileges, 'privileges'>
  implements OnInit {
  constructor(
    public manageGrantPrivilegesService: ManageGrantPrivilegesService,
    protected toasterService: GoToasterService,
    protected modalService: GoModalService,
    protected router: Router,
    protected route: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {
    super(manageGrantPrivilegesService, toasterService, modalService, router, route);
    this.listContextPath = '/manage-grant-privilegeses';
    this.editContextPath = '/manage-grant-privilegeses';
    this.entityLocator = 'privileges';
  }

  ngOnInit() {
    this.loadRecord();
  }

  populateForm(): void {
    this.formData = this.formBuilder.group({
      category_display: [this.entity.category_display, [Validators.required]],

      sub_category_display: [this.entity.sub_category_display, [Validators.required]],

      actions_displays: [this.entity.actions_displays, [Validators.required]],
    });
  }

  extractFormData(): Privileges {
    const entity: Privileges = new Privileges();

    const { category_display, sub_category_display, actions_displays } = this.formData.value;

    entity.category_display = category_display;
    entity.sub_category_display = sub_category_display;
    entity.actions_displays = actions_displays;

    if (this.isAddMode) {
      entity.addAssociation('privilege_policy', 'privilege_policy_row_id', this.parentId);
    } else {
      entity.id = this.id;
      entity.privileges_row_id = this.id;
    }

    return entity;
  }
}
