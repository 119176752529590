import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BaseEditComponent } from '../../../../base/base-edit.component';
import { AllocationInfo, AllocationString } from '../../../../allocation-info/allocation-info';
import { EmbeddedTableContext, selectedTableRows } from '../../../../base/common';
import { InvoiceService } from '../../../../invoice/invoice.service';
import { AllocationInfoService } from '../../../../allocation-info/allocation-info.service';
import { GoModalService, GoTableComponent, GoToasterService } from '@tangoe/goponents';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import * as utils from '../../../../base/utils';
import { AllocationStringModalComponent } from '../../../../allocation-info/allocation-string-modal/allocation-string-modal.component';
import { AllocationAmountModalComponent } from '../../../../allocation-info/allocation-amount-modal/allocation-amount-modal.component';
import { BehaviorSubject, Observable } from 'rxjs';
import { LineItem } from '../../../../invoice/invoice-detail/line-items/line-item';
import { FieldLookupComponent } from '../../../field-types/field-lookup/field-lookup.component';
import { SaveAllocationInfoDto } from '../../../../allocation-info/save-allocation-info-dto';
import { AllocationConfigurationService } from '../allocation-configuration.service';
import { ConfirmActionComponent } from '../../../confirm-action/confirm-action.component';
import { DeleteResult } from '../../../../base/result';
import { LogService } from 'src/app/logger/log.service';
import { SharedPageStateService } from 'src/app/service/shared-page-state.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-allocation-configuration-edit',
  templateUrl: './allocation-configuration-edit.component.html',
  styleUrls: ['./allocation-configuration-edit.component.scss'],
})
export class AllocationConfigurationEditComponent extends BaseEditComponent<AllocationInfo, 'allocation_info'>
  implements OnInit {
  @Input() allocationInfoId: number;
  @Input() showAllocateButtons: boolean = false;
  @Input() showHandleRemainingAmounts: boolean = false;
  @Input() showAutomaticAllocationOption: boolean = true;
  @Input() showChargeAllocationOption: boolean = true;
  @Input() assocDetailId: number;
  @Input() assocDetailAttr: string;
  @Input() renderBoxShadows: boolean = true;
  @Input() isAllocEditConfigEditable = true;

  @Input() pageStateService: SharedPageStateService;
  @Output() refreshAllocation = new EventEmitter();
  @Input() invoiceStatus: string;
  @Input() disableAllocateButtons: boolean;
  @Input() showBanner: boolean;

  isReadOnly: boolean = false;

  amountEditMode: boolean = true;
  string_format_selection: number;

  changeRequest = false;

  allocationTypeSelectionList: any = [];
  allocationStringTableContext: EmbeddedTableContext;
  lineItemsTableContext: EmbeddedTableContext;
  allocationMethodSelectionList: { value: string; name: string }[];

  isStringAllocation = new BehaviorSubject<boolean>(false);
  isTemplateAllocation = new BehaviorSubject<boolean>(false);
  isChargeAllocation = new BehaviorSubject<boolean>(false);
  isAutomaticAllocation = new BehaviorSubject<boolean>(false);

  handleRemainingAmountsSelectionList: { name: string; value: string }[];
  fileUploadLoading: boolean = false;
  allocationRunning = false;
  sendingAllocateChargesRequest = false;
  setEntitydata: any;
  protected exportLayout = [
    {
      field: 'invoiceLineItemRowId',
      type: 'string',
      title: 'invoiceLineItemRowId',
    },
    {
      field: 'subAccount__accountNumber',
      type: 'string',
      title: 'subAccount__accountNumber',
    },
    {
      field: 'chargeType__name',
      type: 'string',
      title: 'chargeType__name',
    },
    {
      field: 'chargeDescription',
      type: 'string',
      title: 'chargeDescription',
    },
    {
      field: 'quantity',
      type: 'string',
      title: 'quantity',
    },
    {
      field: 'unitOfMeasure',
      type: 'string',
      title: 'unitOfMeasure',
    },
    {
      field: 'amount',
      type: 'string',
      title: 'amount',
    },
    {
      field: 'allocString__code',
      type: 'string',
      title: 'allocString__code',
    },
    {
      field: 'invoiceHeader__invoiceHeaderRowId',
      type: 'string',
      title: 'invoiceHeader__invoiceHeaderRowId',
    },
  ];
  constructor(
    public allocationConfigurationService: AllocationConfigurationService,
    public invoiceHeaderService: InvoiceService,
    public allocationInfoService: AllocationInfoService,
    protected toasterService: GoToasterService,
    protected modalService: GoModalService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected logService: LogService,
    private formBuilder: FormBuilder
  ) {
    super(allocationInfoService, toasterService, modalService, router, route, logService);
  }
  @ViewChild('childAllocationStringRefTable', { static: false }) childAllocationStringRefTable: GoTableComponent;
  @ViewChild('lineItemsRefTable', { static: false }) lineItemsRefTable: GoTableComponent;
  @ViewChild('allocationStringFormatLookupField', { static: true })
  allocationStringFormatLookupField: FieldLookupComponent;

  fileUploadControl = new FormControl();

  allocationMethodControl = new FormControl('', Validators.required);

  allocationStringFormatControl = new FormControl('', Validators.required);

  allocationTypeControl = new FormControl('', Validators.required);
  automaticAllocationDefinitionSelection: Array<{ value: string; name: string }>;
  fileUploaded: boolean = false;

  ngOnInit() {
    this.allocationStringFormatControl.valueChanges.subscribe(value => {
      const control = this.allocationStringFormatControl;
      const oldValue = this.string_format_selection;

      if (!utils.isNotNull(value)) {
        return;
      }

      if (value === oldValue) {
        this.changeRequest = this.isChangeRequest();
        return;
      }

      this.modalService.openModal(ConfirmActionComponent, {
        appendTo: 'body',
        modalTitle: 'Alert',
        message: 'Changing or removing the string format will remove all the strings.',
        question: 'Do you want to continue?',
        hideCloseButton: true,
        confirmAction: 'Yes',
        cancelAction: 'No',
        confirmCallback: () => {
          this.changeRequest = true;
          this.formData.get('remain_amt_config').reset();
          this.saveRecord(true, (data, error) => {
            if (error) {
              return;
            }
            if (data.allocation_info) {
              this.entity.allocation_method = data.allocation_info.allocation_method;
            }
            this.loadChildTables();
            this.refreshAllocation.emit();
          });

          this.modalService.toggleModal(false);
        },
        cancelCallback: () => {
          control.reset();
          this.setStringFormatField(oldValue);
          this.modalService.toggleModal(false);

          this.loadChildTables();
        },
      });
    });

    this.allocationTypeControl.valueChanges.subscribe(value => {
      const message = 'Changing or removing the allocation type will remove all the strings.';
      this.confirmRemoveChildAllocationStrings(this.allocationTypeControl, value, this.entity.allocation_type, message);
    });

    this.allocationMethodControl.valueChanges.subscribe(value => {
      const message = 'Changing or removing the allocation method will remove all the strings.';
      this.confirmRemoveChildAllocationStrings(
        this.allocationMethodControl,
        value,
        this.entity.allocation_method,
        message
      );
    });

    this.allocationStringTableContext = new EmbeddedTableContext(
      'allocation_string',
      'string_code,string_name',
      'string_code',
      true
    );

    const lineItemsTableContext = new EmbeddedTableContext(
      'invoice_line_item',
      'charge_type.name,charge_description,invoice_account.account_number',
      'id',
      true
    );

    lineItemsTableContext.entityService = this.invoiceHeaderService;
    lineItemsTableContext.parentId = this.assocDetailId;
    lineItemsTableContext.tableName = 'lineItemsRefTable';
    lineItemsTableContext.deleteMethod = 'deleteChildLineItemStringAllocationRecords';

    this.entityLocator = 'allocation_info';

    this.lineItemsTableContext = lineItemsTableContext;

    this.embeddedTableContexts.push(this.allocationStringTableContext);
    this.embeddedTableContexts.push(this.lineItemsTableContext);

    this.loadRecord(this.allocationInfoId);

    this.formData.controls.allocation_method.valueChanges.subscribe(value => {
      this.isStringAllocation.next(false);
      this.isTemplateAllocation.next(false);
      this.isChargeAllocation.next(false);
      this.isAutomaticAllocation.next(false);

      if (value === 'STRING_ALLOCATION') {
        this.isStringAllocation.next(true);
      }

      if (value === 'TEMPLATE_ALLOCATION') {
        this.isTemplateAllocation.next(true);
      }

      if (value === 'CHARGE_ALLOCATION' || value === 'AUTOMATIC_ALLOCATION') {
        this.isChargeAllocation.next(true);
      }

      if (value === 'AUTOMATIC_ALLOCATION') {
        this.isAutomaticAllocation.next(true);
      }
    });

    this.fileUploadControl.valueChanges.subscribe((value: Array<{ file: File }>) => {
      if (value.length === 0) {
        return;
      }

      this.uploadFile(value[0].file);
    });
  }

  confirmRemoveChildAllocationStrings(control: FormControl, newValue: any, oldValue: any, message?: any) {
    if (control.pristine) {
      return;
    }

    if (newValue === oldValue) {
      this.changeRequest = this.isChangeRequest();
      return;
    }

    this.modalService.openModal(ConfirmActionComponent, {
      appendTo: 'body',
      message: `${message}`,
      question: 'Do you want to continue?',
      modalTitle: 'Alert',
      hideCloseButton: true,
      confirmAction: 'Yes',
      cancelAction: 'No',
      confirmCallback: () => {
        this.changeRequest = true;

        if (newValue === 'CHARGE_ALLOCATION') {
          this.allocationTypeControl.setValue('AMOUNTS', { emitEvent: false });
        }

        this.saveRecord(true, (data, error) => {
          if (error) {
            return;
          }

          if (data.allocation_info) {
            this.entity.allocation_method = data.allocation_info.allocation_method;
          }

          this.loadChildTables();
          this.refreshAllocation.emit();
        });

        this.modalService.toggleModal(false);
      },
      cancelCallback: () => {
        control.reset();
        control.setValue(oldValue);
        this.changeRequest = this.isChangeRequest();
        this.modalService.toggleModal(false);

        this.loadChildTables();
      },
    });
  }

  setStringFormatField(id?: number) {
    id = id || utils.extractLookupId(this.entity.string_format);

    if (!id) {
      return;
    }
    this.allocationStringFormatControl.setValue(id);
    this.allocationStringFormatLookupField.selectedId = id.toString();
    this.allocationStringFormatLookupField.loadItems();
  }

  isChangeRequest(): boolean {
    if (!this.entity.id) {
      return false;
    }

    return (
      this.string_format_selection !== this.entity.string_format.id ||
      this.formData.get('allocation_type').value !== this.entity.allocation_type ||
      this.formData.get('allocation_method').value !== this.entity.allocation_method
    );
  }

  loadChildTables() {
    if (this.entity.allocation_method === 'STRING_ALLOCATION') {
      this.allocationStringTableContext.parentId = this.id;
      this.loadChildTableData(this.allocationStringTableContext);
    }

    if (this.entity.allocation_method === 'CHARGE_ALLOCATION') {
      this.lineItemsTableContext.parentId = this.assocDetailId;
      this.loadChildTableData(this.lineItemsTableContext);
    }
    if (this.entity.allocation_method === 'TEMPLATE_ALLOCATION') {
      this.allocationStringTableContext.parentId = this.id;
      this.loadChildTableData(this.allocationStringTableContext);
    }
  }

  buildAnyStaticDropdowns(): void {
    // Populate Allocation Type Selection
    this.allocationTypeSelectionList = [
      { value: 'AMOUNTS', name: 'Amounts' },
      { value: 'PERCENTAGES', name: 'Percentages' },
    ];

    this.allocationMethodSelectionList = [
      { value: 'TEMPLATE_ALLOCATION', name: 'Template Allocation' },
      { value: 'STRING_ALLOCATION', name: 'String Allocation' },
    ];

    if (this.showChargeAllocationOption) {
      this.allocationMethodSelectionList.push({ value: 'CHARGE_ALLOCATION', name: 'Charge Allocation' });
    }

    if (this.showAutomaticAllocationOption) {
      this.allocationMethodSelectionList.push({ value: 'AUTOMATIC_ALLOCATION', name: 'Automatic Allocation' });
    }

    this.automaticAllocationDefinitionSelection = [
      { value: 'AUTOACCOUNT', name: 'Account' },
      { value: 'AUTOASSET', name: 'Asset' },
      { value: 'AUTOASSETCAPACITYASSIGNEDPROPORTION', name: 'Asset Capacity (Assigned Portion)' },
      { value: 'AUTOASSETCAPACITYSPREADAMOUNT', name: 'Asset Capacity (Spread Amount)' },
      { value: 'AUTOASSETLOCATION', name: 'Asset Location' },
      { value: 'AUTOASSETLOCATIONHEADCOUNT', name: 'Asset Location Head Count' },
      { value: 'AUTOASSETTYPE', name: 'Asset Type' },
      { value: 'AUTOCHARGETYPE', name: 'Charge Type' },
      { value: 'AUTOEMPLOYEE', name: 'Employee' },
      { value: 'AUTOMASTERSERVICETYPE', name: 'Master Service Type' },
      { value: 'AUTOVENDOR', name: 'Vendor' },
    ];

    this.handleRemainingAmountsSelectionList = [
      {
        value: 'ALLOW_REMAINING_AMOUNTS_TO_BE_OVER_OR_UNDER_ALLOCATED',
        name: 'Allow remaining amounts to be over/under allocated',
      },
      { value: 'PROPORTIONALLY_TO_ALL', name: 'Allocate proportionally based on existing allocations' },
      { value: 'EQUALLY_TO_ALL', name: 'Allocate equally based on existing allocations' },
      { value: 'PARTICULAR_STRING', name: 'Allocate all remaining amounts to' },
    ];
  }

  populateForm(): void {
    this.allocationMethodControl.setValue(this.entity.allocation_method);
    this.allocationTypeControl.setValue(this.entity.allocation_type);

    // Default Remaining Amounts value
    let defaultRemainingAmounts;
    if (this.handleRemainingAmountsSelectionList) {
      defaultRemainingAmounts = this.handleRemainingAmountsSelectionList[0].value;
    }

    this.formData = this.formBuilder.group({
      string_format: this.allocationStringFormatControl,

      allocation_type: this.allocationTypeControl,

      allocation_method: this.allocationMethodControl,

      allocation_charge_elements: [this.entity.allocation_charge_elements, []],

      remain_amt_config: [this.entity.remain_amt_config || defaultRemainingAmounts],

      remain_amt_string: [{ value: this.entity.remain_amt_string, disabled: true }, []],
    });

    this.string_format_selection = utils.extractLookupId(this.entity.string_format);
    this.setStringFormatField(utils.extractLookupId(this.entity.string_format));
    this.handlePageState();
    if (this.entity.remain_amt_string) {
      this.amountEditMode = false;
      this.remainAmountValidation();
    }
    this.formData.get('remain_amt_config').valueChanges.subscribe(() => {
      this.amountEditMode = true;
      this.formData.get('remain_amt_string').reset();
      this.formData.get('remain_amt_string').setErrors(null);
      this.remainAmountValidation();
    });
    if (this.invoiceStatus === 'Exported') {
      this.convertToReadOnlyScreen();
    }
    if (!this.isAllocEditConfigEditable) {
      this.convertToReadOnlyScreen();
    }
  }

  extractEntity(
    result: { code: number; count: number; message: string; status: string } & Record<'allocation_info', AllocationInfo>
  ): AllocationInfo {
    this.logService.debug(
      `Allocation-Config-Edit: Response for allocation info [id=${this.id}] for ${this.assocDetailAttr}[id=${this.assocDetailId}]`,
      result
    );

    if (
      result.allocation_info.allocation_charge_elements &&
      typeof result.allocation_info.allocation_charge_elements === 'string'
    ) {
      result.allocation_info.allocation_charge_elements = result.allocation_info.allocation_charge_elements
        .split(',')
        .filter(i => !!i);
    }

    return super.extractEntity(result);
  }

  extractFormData(): SaveAllocationInfoDto {
    this.setEntitydata = { ...this.entity };
    const allocation_method = this.formData.get('allocation_method').value;
    if (allocation_method === 'CHARGE_ALLOCATION' || allocation_method === 'AUTOMATIC_ALLOCATION') {
      this.formData.value.allocation_type = 'AMOUNTS';
    }
    if (!this.formData.controls['remain_amt_string'].value) {
      this.formData.controls['remain_amt_string'].setErrors([
        {
          type: 'ERROR:',
          message: 'This is a required field.',
        },
      ]);
    } else {
      this.formData.value.remaining_amount = this.formData.controls['remain_amt_string'].value;
    }

    return this.allocationConfigurationService.extractFormData(
      this.formData.value,
      this.assocDetailAttr,
      this.assocDetailId,
      this.changeRequest,
      this.id
    );
  }

  addAllocationStringRecord(lineItem?: LineItem) {
    if (!this.entity.string_format) {
      this.entity.string_format = this.setEntitydata['string_format'];
    }
    this.modalService.openModal(AllocationStringModalComponent, {
      appendTo: 'body',
      parentId: this.allocationInfoId,
      parent: this.entity,
      parentCharge: lineItem,
      mode: 'add',
      modalTitle: 'Add Allocation String',
      modalSize: 'xl',
      modalCallback: () => {
        this.allocationStringTableContext.parentId = this.allocationInfoId;
        this.loadChildTables();
      },
    });
  }

  editAllocationStringRecord(item: AllocationString, lineItem?: LineItem) {
    this.modalService.openModal(AllocationStringModalComponent, {
      appendTo: 'body',
      parentId: this.allocationInfoId,
      parent: this.entity,
      parentCharge: lineItem,
      mode: 'edit',
      modalTitle: 'Edit Allocation String',
      modalSize: 'xl',
      rowData: item,
      isReadOnly: this.isReadOnly,
      isParentReadOnly: this.isAllocEditConfigEditable,
      modalCallback: () => {
        this.allocationStringTableContext.parentId = this.allocationInfoId;
        this.loadChildTables();
      },
    });
  }
  addAllocationAmount() {
    if (!this.entity.string_format) {
      this.entity = this.setEntitydata;
    }
    this.modalService.openModal(AllocationAmountModalComponent, {
      appendTo: 'body',
      modalTitle: 'Add Allocation String',
      parentId: this.allocationInfoId,
      parent: this.entity,
      mode: 'add',
      modalSize: 'xl',
      modalCallback: value => {
        this.formData.controls['remain_amt_string'].setErrors(null);
        this.formData.controls['remain_amt_string'].setValue(value);
        this.amountEditMode = false;
        this.modalService.toggleModal(false);
      },
    });
  }
  editAllocationAmount() {
    const remainAmountString = this.formData.controls['remain_amt_string'].value;
    this.modalService.openModal(AllocationAmountModalComponent, {
      appendTo: 'body',
      modalTitle: 'Edit Allocation String',
      parentId: this.allocationInfoId,
      parent: this.entity,
      mode: 'edit',
      modalSize: 'xl',
      rowData: remainAmountString,
      modalCallback: value => {
        this.formData.controls['remain_amt_string'].setValue(value);
        this.amountEditMode = false;
        this.modalService.toggleModal(false);
      },
    });
  }
  selectedRows(tableComponent) {
    return selectedTableRows(tableComponent);
  }

  deleteAllocationString(tableComponent: GoTableComponent, child: EmbeddedTableContext) {
    const idsToDelete = this.selectedRows(tableComponent);
    if (idsToDelete.length === 0) {
      this.toasterService.toastInfo({
        header: 'Alert!',
        message: 'No records selected to delete.',
      });
      return;
    }
    this.modalService.openModal(ConfirmActionComponent, {
      modalTitle: 'Delete',
      message: 'You are about to delete the selected records.',
      question: 'Are you sure?',
      cancelAction: 'No',
      confirmAction: 'Yes',
      cancelCallback: () => {
        this.modalService.toggleModal(false);
      },
      confirmCallback: () => {
        const selectedData = tableComponent.allData;
        const intersections = selectedData.filter(e => idsToDelete.indexOf(e.id) !== -1);
        if (intersections.length > 0) {
          const selectedWithAllocString = intersections.filter(s => !!s.alloc_string && !!s.alloc_string.id);
          const ids = selectedWithAllocString.map(s => s.alloc_string.id);
          if (ids.length > 0) {
            this.allocationConfigurationService.deleteChildAllocationStringRecordsById(ids.join(',')).subscribe(
              result => {
                this.toasterService.toastSuccess({ message: result.count + ' records deleted' });
                this.modalService.toggleModal(false);
                this.lineItemsRefTable.targetedRows = [];
                this.loadChildTables();
              },
              error => {
                this.toasterService.toastError({ header: 'Error', message: error });
              }
            );
          } else {
            this.toasterService.toastSuccess({ message: ids.length + ' records deleted' });
            this.modalService.toggleModal(false);
            this.lineItemsRefTable.targetedRows = [];
            this.loadChildTables();
          }
        }
      },
    });
  }

  downloadTemplate($event: boolean) {
    const currencyIndicatorRequired = ['account', 'recurring_invoice'].includes(this.assocDetailAttr);

    this.allocationConfigurationService.downloadTemplate(this.entity, currencyIndicatorRequired);
  }

  uploadFile(file: File) {
    this.fileUploadLoading = true;
    this.allocationConfigurationService.uploadTemplate(file, this.entity).subscribe(response => {
      this.fileUploadLoading = false;

      if (response.status !== 'success') {
        this.toasterService.toastError({ message: 'upload failed: ' + response.message });
        return;
      }

      this.toasterService.toastSuccess({ message: 'File uploaded successfully!' });
      this.fileUploaded = false;

      this.fileUploadControl.setValue([]);
      this.loadChildTableData(this.allocationStringTableContext);
    });
  }

  allocateCharges() {
    if (this.assocDetailAttr !== 'invoiceHeader' && this.assocDetailAttr !== 'invoice') {
      this.toasterService.toastError({ header: 'Error', message: 'Cannot allocate charges' });
      return;
    }

    this.sendingAllocateChargesRequest = true;

    this.allocationConfigurationService.allocateCharges(this.assocDetailId).subscribe(result => {
      this.pageStateService.emitPageStateChange(true);
      this.handlePageState();
      this.scrollToTop();
      this.allocationRunning = true;
      this.sendingAllocateChargesRequest = false;
    });
  }

  scrollToTopOnError(): void {
    // Do nothing. Overridden here to prevent the base class logic that scrolls the user to top on error
  }

  convertToReadOnlyScreen() {
    this.formData.get('string_format').disable();
    this.formData.get('allocation_type').disable();
    this.formData.get('allocation_method').disable();
    this.formData.get('remain_amt_config').disable();
    this.formData.get('allocation_charge_elements').disable();
    this.fileUploadLoading = true;
    this.changeRequest = true;
    this.isReadOnly = true;
    this.disableAllocateButtons = true;
  }

  handlePageState() {
    if (!this.pageStateService) {
      return;
    }

    // To take care of case when the record is already in read-only mode when viewed.
    if (this.pageStateService.isReadOnly()) {
      this.convertToReadOnlyScreen();
    }

    // To take care of the case when the record was editable but later changed to read-only as part of a user action elsewhere
    this.pageStateService.subscribeToPageStateChange((readonly: boolean) => {
      if (readonly) {
        this.convertToReadOnlyScreen();
      }
    });
  }
  remainAmountValidation() {
    if (this.formData.get('remain_amt_config').value === 'PARTICULAR_STRING') {
      this.formData.controls['remain_amt_string'].setValidators([Validators.required]);
    } else {
      this.formData.get('remain_amt_string').setValidators(null);
    }
  }
  exportChargeAllocationToExcel(lineItemsTableContext: EmbeddedTableContext) {
    const stringFormatName = this.entity.string_format.name;
    const url =
      environment.expenseDataUrl +
      `/invoiceHeaders/${this.assocDetailId}/stringformat/${stringFormatName}/allocationCharges/true`;
    lineItemsTableContext.export(url, this.exportLayout, this.toasterService);
  }

  scrollToTop(): void {
    const scrollPage = document.getElementsByClassName('go-layout__route-container')[0];
    if (scrollPage) {
      scrollPage.scrollTop = 0;
    }
  }
}
