import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';

import { environment } from '../../environments/environment';
import { Environment } from '../shared/environment';
import { SisenseApiService } from '../service/sisense-api.service';
import { ISisenseUserAuthorized } from '../shared/models/sisense/ISisenseUserAuthorized';
import { isTrue, isNotNull } from '../base/utils';
import { LogService } from '../logger/log.service';
import { sessionContext } from '../authentication/session-details';
import { AuthenticationService } from '../authentication/authentication.service';

@Component({
  selector: 'app-post-login',
  templateUrl: './post-login.component.html',
  styleUrls: ['./post-login.component.scss'],
})
export class PostLoginComponent implements OnInit {
  @ViewChild('logoutIframe', { static: true })
  logoutIframe: ElementRef;

  @ViewChild('clearCookiesIframe', { static: true })
  clearCookiesIframe: ElementRef;

  @ViewChild('jwtIframe', { static: true })
  jwtIframe: ElementRef;

  jwt: string;

  loadDbId: string;
  loadPulse: string;

  sisenseLogoutUrl: string = '';
  sisenseMainAppUrl: string = '';
  sisenseJWTUrl: string = '';
  redirectPath: string;

  constructor(
    private route: ActivatedRoute,
    private sisenseApiService: SisenseApiService,
    private logService: LogService,
    private router: Router,
    protected authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.getJWT();
  }

  getAnalyticsToken(key: string): string {
    const arr = document.cookie.split(';').map(cookieString => {
      return cookieString.trim().split('=');
    });
    for (const s of arr) {
      if (s[0].includes(key)) {
        return s[1];
      }
    }
    return null;
  }
  getJWT(): void {
    // Not using Angular route because dependency will be a non-routed component
    const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
    this.jwt = this.getAnalyticsToken(`analytics_token_${environment.instanceIdentifier}`);

    // Query string parameters that can be passed via a direct link to a specific dashboard or pulse.
    this.loadDbId = urlParams.get('dbid');
    this.loadPulse = urlParams.get('loadpulse');

    if (this.jwt && this.jwt !== 'NONE' && sessionStorage.getItem('tenantSwitched') === null) {
      // assuming user is logging in (begin step 1)
      // this.logoutCurrentUser();
      this.addSisenseJWTEndpoint();
    } else {
      // if no JWT, assuming non-sisense user - so redirect to home. Sisense login will still be checked in can-activate-sisense
      this.navigateUser();
    }
  }

  /**
   * Part I - Logout User
   *
   * Invalidate current session if one exists. This prevents the previous logged
   * in user from being used. This does NOT cleanup sisense cookies if the
   * session no longer exists on the sisense server.
   */
  logoutCurrentUser(): void {
    this.sisenseLogoutUrl = Environment.getSisenseApiV1Url() + 'authentication/logout';

    /**
     * If (load) event is added to iframe via template, it will trigger change
     * detection twice. Once on page load and another time for loading the url
     * into the iframe. This works fine in Chrome, but causes a lot of issues
     * with IE and Firefox.
     */
    this.logoutIframe.nativeElement.addEventListener('load', this.clearSisenseRelatedCookies.bind(this));
  }

  /**
   * Part II - Clear out all expired/invalid Sisense cookies
   *
   * Clears all sisense related cookies that were invalidated by
   * PostLoginComponent.logoutCurrentUser. This method redirects the iframe back
   * to login page, but it's the only known method at the time to remove sisense
   * related cookies.
   *
   * If the cookies are not removed, then sisense does NOT override the existing
   * cookies, which means the user would be thrown back to the login page since
   * current cookies have been invalidated.
   */
  clearSisenseRelatedCookies(): void {
    this.sisenseMainAppUrl = Environment.getSisenseMainAppUrl();

    this.clearCookiesIframe.nativeElement.addEventListener('load', this.addSisenseJWTEndpoint.bind(this));
  }

  /**
   * Part III - Obtain auth cookie from Sisense
   *
   * Send JWT to sisense. If valid, it will create the necessary cookies on the
   * users machine.
   */
  addSisenseJWTEndpoint(): void {
    this.sisenseJWTUrl = `${environment.sisenseUrl}jwt?jwt=${this.jwt}&redirect_to=${encodeURIComponent('app/main#/')}`; // required to prevent redirect to login again

    this.jwtIframe.nativeElement.addEventListener('load', this.validateLogin.bind(this));
  }

  /**
   * Part IV - validate login
   *
   * If request returns a 200, then we can assume the auth cookie created is valid
   */
  validateLogin(): void {
    this.sisenseApiService.isUserAuthorized().subscribe(
      (userAuthorized: ISisenseUserAuthorized) => {
        if (userAuthorized.isAuthenticated) {
          const quickLinkPath = this.authenticationService.validateQuickLinkPath();
          if (quickLinkPath) {
            this.router.navigate([quickLinkPath]);
          } else if (isTrue(this.loadPulse)) {
            this.router.navigate(['notification'], { queryParams: { pulse: this.loadPulse } });
          } else if (isNotNull(this.loadDbId)) {
            this.router.navigate(['dashboard'], { queryParams: { dbid: this.loadDbId } });
          } else {
            this.navigateUser();
          }
        } else {
          this.logService.error(`PostLogin:: User is not authenicated on Sisense, hence redirecting to SSO.`);
          this.redirectToSSOLogin();
        }
      },
      (error: HttpErrorResponse) => {
        this.logService.error(
          `PostLogin:: Error while trying to authenicated the user on Sisense, hence redirecting to SSO.`,
          error
        );
        this.redirectToSSOLogin();
      }
    );
  }

  redirectToSSOLogin(): void {
    const url = new URL(environment.ssoLoginUrl);
    if (isTrue(this.loadPulse)) {
      url.searchParams.append('loadpulse', 'true');
    } else if (isNotNull(this.loadDbId)) {
      url.searchParams.append('dbid', this.loadDbId);
    }
    window.open(url.toString(), '_self');
  }

  navigateUser() {
    this.authenticationService.handleTenantShortName();
    this.authenticationService.loggedInUserDetails().subscribe(
      (userDetails: any) => {
        this.authenticationService.handleUserDetails(userDetails);
        const currentUserRole = sessionContext.roleInfo;
        if (currentUserRole) {
          this.getRedirectState();
        }
      },
      error => {
        window.open(environment.ssoLoginUrl, '_self');
      }
    );
  }

  getRedirectState() {
    let path: string;
    const userRole = sessionContext.roleInfo;
    if (userRole.length) {
      path = this.authenticationService.getDefaultLandingPath(userRole);
    }
    this.router.navigate([path ? path : 'home']);
  }
}
