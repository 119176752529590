import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListComponent } from './list/list.component';
import { FieldComponent } from './field/field.component';
import {
  GoActionSheetModule,
  GoButtonModule,
  GoIconButtonModule,
  GoInputModule,
  GoSelectModule,
  GoTableModule,
  GoPillModule,
  GoIconModule,
  GoLoaderModule,
} from '@tangoe/goponents';
import { EditButtonComponent } from './edit-button/edit-button.component';
import { FiltersComponent } from './filters/filters.component';
import { GridComponent } from './grid/grid.component';
import { FilterOffCanvasComponent } from './filter-off-canvas/filter-off-canvas.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FilterInputComponent } from './filter-input/filter-input.component';
import { FilterSelectComponent } from './filter-select/filter-select.component';
import { FilterLookupComponent } from './filter-lookup/filter-lookup.component';
import { WithLoadingPipe } from './filter-select/with-loading.pipe';
import { FilterDynamicListComponent } from './filter-dynamiclist/filter-dynamiclist.component';
import { ColumnConfigurationComponent } from './column-configuration/column-configuration.component';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  declarations: [
    ListComponent,
    FieldComponent,
    EditButtonComponent,
    FiltersComponent,
    GridComponent,
    FilterOffCanvasComponent,
    FilterInputComponent,
    FilterSelectComponent,
    FilterLookupComponent,
    FilterDynamicListComponent,
    WithLoadingPipe,
    ColumnConfigurationComponent,
  ],
  imports: [
    CommonModule,
    GoTableModule,
    GoPillModule,
    GoButtonModule,
    GoIconButtonModule,
    GoInputModule,
    GoActionSheetModule,
    ReactiveFormsModule,
    GoSelectModule,
    DragDropModule,
    GoIconModule,
    GoLoaderModule,
  ],
  exports: [
    ListComponent,
    FieldComponent,
    EditButtonComponent,
    FiltersComponent,
    GridComponent,
    FilterInputComponent,
    FilterSelectComponent,
    FilterLookupComponent,
    FilterDynamicListComponent,
    ColumnConfigurationComponent,
  ],
})
export class ListModule {}
