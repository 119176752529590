import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { sessionContext } from '../authentication/session-details';
import { LogType, LogEntry } from './log-entry';
import { LogWriterService } from './log-writer.service';

@Injectable({
  providedIn: 'root',
})
export class LogService {
  private buffer: LogEntry[] = [];
  private flush = new Subject<void>();

  constructor(protected logWriter: LogWriterService) {
    // flush the buffer every 5 seconds (assuming there is data to be flushed)
    this.flush.pipe(debounceTime(5000)).subscribe(() => this.flushBuffer());
  }

  trace(...args: any[]): void {
    this.log(LogType.TRACE, ...args);
  }

  debug(...args: any[]): void {
    this.log(LogType.DEBUG, ...args);
  }

  info(...args: any[]): void {
    this.log(LogType.INFO, ...args);
  }

  warn(...args: any[]): void {
    this.log(LogType.WARN, ...args);
  }

  error(...args: any[]): void {
    this.log(LogType.ERROR, ...args);
  }

  fatal(...args: any[]): void {
    this.log(LogType.FATAL, ...args);
  }

  clear(): void {
    this.buffer = [];
  }

  flushAll(): void {
    this.flushBuffer();
  }

  private log(type: LogType, ...args: any[]): void {
    const url: string = window.location.href;
    const style: string = this.getStyle(type);
    const ip_address: string = '0.0.0.0'; // TODO

    const timestamp: string = JSON.stringify(new Date());
    const data: string = args.map(el => JSON.stringify(el)).join(', ');

    const isDebugSession: string = sessionStorage.getItem('tngo-cloud-debug');

    const user: string = sessionContext.userInfo.userIdentifier;
    const tenant: string = sessionContext.tenantInfo.shortName;

    // display to console (local dev)
    if (!environment.production || isDebugSession === '1') {
      console.log(`%c[${timestamp}][${type}][${user}][${tenant}]: ${data}`, style);
    }

    // queue up for service
    this.buffer.push({
      timestamp,
      type,
      data,
      url,
      user,
      tenant,
      ip_address,
    });

    // inform subscription that new log entry has been added
    this.flush.next();
  }

  private flushBuffer() {
    // send the logs to be written on the server side.
    this.logWriter.writeLog(this.buffer);

    // clear buffer after **successfully** sending data
    this.clear();
  }

  private getStyle(type: LogType): string {
    switch (type) {
      case LogType.TRACE:
        return 'color: #5cbc63';

      case LogType.DEBUG:
        return 'color: #089000';

      case LogType.INFO:
        return 'color: #0000FF';

      case LogType.WARN:
        return 'color: #ffaf42';

      case LogType.ERROR:
        return 'color: #ff0000';

      case LogType.FATAL:
        return 'color: #800000';

      default:
        return 'color: #78909c';
    }
  }
}
