import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TenantInfo, UserInfo } from 'src/app/authentication/session-details';

@Component({
  selector: 'app-tenant-switcher',
  templateUrl: './tenant-switcher.component.html',
  styleUrls: ['./tenant-switcher.component.scss'],
})
export class TenantSwitcherComponent implements OnInit, AfterViewInit {
  searchTenantName = new FormControl();

  @Input() userInfo: UserInfo;
  @Input() tenantInfo: TenantInfo;
  @Input() tenantList: TenantInfo[] = [];
  @Input() callback: any;

  filteredTenants: TenantInfo[] = [];

  constructor() {}

  ngOnInit() {
    this.filteredTenants = this.tenantList;
  }

  ngAfterViewInit() {
    this.searchTenantName.valueChanges.subscribe(value => setTimeout(() => this.performSearch(value), 500));
  }

  performSearch(value: any) {
    if (value === undefined) {
      return;
    }

    if (value === '') {
      this.filteredTenants = this.tenantList;
      return;
    }

    this.filteredTenants = this.tenantList.filter(tenant => {
      return tenant.tenantName.toUpperCase().includes(value.toUpperCase());
    });
  }

  switchTenant(tenant: TenantInfo) {
    this.callback(tenant);
  }
}
