import {
  AfterContentInit,
  AfterViewInit,
  Component,
  ContentChild,
  ContentChildren,
  Input,
  OnInit,
  OnDestroy,
  QueryList,
  ViewChild,
  Output,
  EventEmitter
} from '@angular/core';
import {
  GoOffCanvasService,
  GoTableComponent,
  GoTableConfig,
  GoToasterService,
  GoTableSortConfig,
  GoModalService,
  GoTablePageConfig,
  GoTableSearchConfig,
  RowSelectionEvent,
  SelectionState
} from '@tangoe/goponents';
import { ActivatedRoute, Router } from '@angular/router';
import { Result } from '../../../base/result';
import { FieldComponent } from '../field/field.component';
import { BaseService } from '../../../base/base-service';
import { FiltersComponent } from '../filters/filters.component';
import { FilterOffCanvasComponent } from '../filter-off-canvas/filter-off-canvas.component';
import { PageContext, selectedTableRows, resetTableRowSelections, hasTableConfigChanged } from '../../../base/common';
import { map, filter, delay } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';
import { AdvanceFilter } from '../../../base/base-filter';
import { omit, pick } from 'lodash';
import { ConfirmActionComponent } from '../../confirm-action/confirm-action.component';
import { FilterInputComponent } from '../filter-input/filter-input.component';
import { FilterSelectComponent } from '../filter-select/filter-select.component';
import { FilterLookupComponent } from '../filter-lookup/filter-lookup.component';
import { FilterDynamicListComponent } from '../filter-dynamiclist/filter-dynamiclist.component';
import fileSaver from 'file-saver';
import { attributize, lowerFirst } from 'src/app/base/utils';
import { isActionDenied } from 'src/app/base/entity-access';
import {
  ColumnConfig,
  ColumnConfigurationComponent,
  ColumnsConfig,
} from '../column-configuration/column-configuration.component';
import { ExportModalComponent } from '../../export-modal/export-modal/export-modal.component';
import { SharedPageStateService } from 'src/app/service/shared-page-state.service';

export interface ActionSheetConfig {
  panelTitle: string;
  // tslint:disable-next-line:ban-types
  action?: Function;
}

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent<K extends string, T, S extends BaseService<any, any>>
  implements OnInit, OnDestroy, AfterViewInit, AfterContentInit {
  @Input() pageTitle: string;
  @Input() tableTitle: string;
  @Input() entityService: S;
  @Input() entityLocation: K;
  @Input() advanceFilterProps: string[];
  @Input() defaultSort: string;
  @Input() defaultSortDirection: string;
  @Input() defaultSearchAttrs: string;
  @Input() searchPlaceholder: string;
  @Input() isSelectable = true;
  @Output() rowSelectionEvent = new EventEmitter<RowSelectionEvent>();
  @Output() selectAllEvent = new EventEmitter<SelectionState>();
  @Output() handleLockedRecord: EventEmitter<any> = new EventEmitter();
  @Output() isDisabled = new EventEmitter<boolean>();
  @Input() useUnlockedRows: boolean = false;
  @Input() pageActions: string[] = [];
  @Input() isDeletable: boolean = true;
  @Input() isAddable: boolean = true;
  @Input() isListEditable: boolean = true;
  @Input() isExportable: boolean = true;
  @Input() isExportableModal: boolean = false;
  @Input() addToExportLayout = [];
  @Input() isColumnsConfig: boolean = false;
  @Input() baseUrl: string = '';
  @Input() defaultExportAttr: string = '';
  @Input() dateSelectionList: { name: string; value: string }[];
  @Input() lockIcon: boolean = false;
  // Column Configuration
  @Input() enableColumnConfiguration = true;
  @Input() disabled: any[];
  // Table Client/Server Mode
  @Input() dataMode = 1;
  @Input() sortable = false;
  @ContentChildren(FieldComponent) columns: QueryList<FieldComponent>;
  @ContentChild(FiltersComponent, { static: false }) filtersComponent: FiltersComponent;
  @ViewChild(GoTableComponent, { static: false }) mainEntityRefTable: GoTableComponent;

  isLockIconDisabled: boolean = true;
  // Column input related
  displayColumns: ColumnsConfig = {
    added: [],
    removed: [],
    static: [],
  };

  preSaveColumns: ColumnsConfig = {
    added: [],
    removed: [],
    static: [],
  };
  lockedRows: any[] = [];
  hasFilters = false;
  showLoader = true;
  tableConfig: GoTableConfig;
  pageContext: PageContext;
  selectedFilters: any = [];
  offCanvasSubscription: Subscription;

  constructor(
    protected toasterService: GoToasterService,
    protected offCanvas: GoOffCanvasService,
    protected modalService: GoModalService,
    private router: Router,
    private route: ActivatedRoute,
    private sharedPageStateService: SharedPageStateService
  ) {
    // When the off canvas closes, reset the form to match current filters.
    // Otherwise, if the user selected an filter and then closed the
    // off canvas without pressing filter, when he reopens it that value will
    // be selected, even though it is not a selected filter.
    this.offCanvasSubscription = this.offCanvas.offCanvasOpen.pipe(filter(open => !open)).subscribe(() => {
      this.filtersComponent.updateValues(this.pageContext.advanceFilter);
    });
  }

  get columnsToRender() {
    return [...this.displayColumns.static, ...this.displayColumns.added];
  }

  ngOnInit(): void {
    this.pageContext = new PageContext(this.defaultSearchAttrs, this.defaultSort, this.defaultSortDirection);
    this.setDefaultTableConfig(this.pageContext);
    this.isAddable = !isActionDenied(this.entityLocation, 'CREATE', this.entityService.getMetaData());
    this.isDeletable = !isActionDenied(this.entityLocation, 'DELETE', this.entityService.getMetaData());
    this.sharedPageStateService.notifyObservable$.subscribe(res => {
      if (res?.refresh?.toString()) {
        this.isLockIconDisabled = res.refresh;
      }
    });
  }

  ngOnDestroy(): void {
    this.offCanvasSubscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.queryTableData();
  }

  ngAfterContentInit(): void {
    if (this.filtersComponent) {
      this.hasFilters = true;
      this.filtersComponent.filtered.subscribe(filteredData => {
        this.setFilters(filteredData);
      });
    }
    this.processColumns();
  }

  lockedRecords() {
    this.handleLockedRecord.emit();
  }

  extractEntityArray(data: Result<K, T[]>) {
    return data[this.entityLocation];
  }

  getAdvanceFilterProps() {
    return Object.keys(this.filtersComponent.formGroup.controls);
  }

  openAdvanceFilter(): void {
    this.setAdvanceFilterRecord();
    this.offCanvas.openOffCanvas({
      component: FilterOffCanvasComponent,
      bindings: {
        template: this.filtersComponent.template,
      },
      header: 'Filter ' + this.pageTitle,
    });
  }
  setAdvanceFilterRecord(): void {
    Object.keys(this.filtersComponent.formGroup.controls).forEach(controlKey => {
      const controlValue = this.filtersComponent.formGroup.controls[controlKey].value;
      if (controlValue && !isNaN(controlValue)) {
        this.filtersComponent.formGroup.controls[controlKey].setValue(Number(controlValue));
      }
    });
  }

  getSelectedFilters(
    filters: (FilterInputComponent | FilterSelectComponent | FilterLookupComponent | FilterDynamicListComponent)[],
    filterState
  ) {
    return Object.keys(filterState)
      .filter(key => !!filterState[key] && filters.some(x => x.name === key))
      .map(key => {
        const selectedFilter = filters.find(x => x.name === key);

        if (selectedFilter instanceof FilterInputComponent) {
          return {
            name: key,
            title: selectedFilter.label,
            value: of(filterState[key].toString()),
          };
        }

        if (selectedFilter instanceof FilterSelectComponent) {
          const selectedOption = of(selectedFilter.items).pipe(
            map(options => {
              return options.find(
                option => option[selectedFilter.bindValue].toString() === filterState[key].toString()
              );
            }),
            map(option => {
              return option[selectedFilter.bindLabel];
            })
          );

          return {
            name: key,
            title: selectedFilter.label,
            value: selectedOption,
          };
        }

        if (selectedFilter instanceof FilterDynamicListComponent) {
          const selectedOption = of(selectedFilter.items).pipe(
            map(options => {
              return options.find(
                option => option[selectedFilter.bindValue].toString() === filterState[key].toString()
              );
            }),
            map(option => {
              return option[selectedFilter.bindLabel];
            })
          );

          return {
            name: key,
            title: selectedFilter.label,
            value: selectedOption,
          };
        }

        if (selectedFilter instanceof FilterLookupComponent) {
          const selectedOption = selectedFilter.items.pipe(
            map(options => {
              return options.find(
                option => option[selectedFilter.bindValue].toString() === filterState[key].toString()
              );
            }),
            map(option => {
              return option[selectedFilter.bindLabel];
            })
          );

          return {
            name: key,
            title: selectedFilter.label,
            value: selectedOption,
          };
        }
      });
  }

  saveFile(data: any, filename: string) {
    const blob = new Blob([data], { type: 'application/vnd.ms-excel' });
    fileSaver.saveAs(blob, filename);
  }

  exportToExcel() {
    let exportLayout = [];
    const addColumn: any[] = [...this.displayColumns.static, ...this.displayColumns.added];
    addColumn.forEach(column => {
      if (column.exportable) {
        let fieldToExport = column.field;
        if (fieldToExport.endsWith('.value')) {
          fieldToExport = fieldToExport.substring(0, fieldToExport.length - 6);
        }

        exportLayout.push({ field: lowerFirst(attributize(fieldToExport)), title: column.title });
      }
    });

    if (this.addToExportLayout.length > 0) {
      exportLayout = [...exportLayout, ...this.addToExportLayout];
    }

    // Export from Modal
    if (this.isExportableModal) {
      this.modalService.openModal(ExportModalComponent, {
        modalTitle: 'Export Settings',
        pageContext: this.pageContext,
        exportLayout,
        baseUrl: this.baseUrl,
        defaultExportAttr: this.defaultExportAttr,
        dateSelectionList: this.dateSelectionList,
      });
    } else {
      this.toasterService.toastInfo({
        header: 'Preparing Export',
        message: 'Your export is currently being prepared. Your download will begin shortly.',
      });

      this.entityService
        .triggerExport(
          this.pageContext.simpleFilter,
          this.pageContext.advanceFilter,
          this.pageContext.pageParam,
          exportLayout
        )
        .pipe(delay(1000))
        .subscribe(
          data => {
            const fileToDownload = data.file_key;
            this.entityService.downloadExport(fileToDownload).subscribe(result => {
              const filename = result.headers.get('filename') || 'export.xlsx';
              this.saveFile(result.body, filename);

              this.toasterService.toastSuccess({
                header: 'Success!',
                message: 'Records have been exported successfully.',
              });
            });
          },
          error => {
            this.toasterService.toastError({
              header: 'Error!',
              message: 'Error occurred while exporting records.',
            });
          }
        );
    }
  }

  setDefaultTableConfig(pageContext: PageContext) {
    this.tableConfig = new GoTableConfig({
      tableData: [],
      dataMode: this.dataMode,
      totalCount: 0,
      selectBy: 'id',
      selectable: this.isSelectable,
      sortable: false,
      sortConfig: new GoTableSortConfig({
        column: pageContext.pageParam.sortAttr,
        direction: pageContext.pageParam.sortDir,
      }),
      pageConfig: new GoTablePageConfig({
        pageSizes: pageContext.pageParam.pageSizes,
        perPage: pageContext.pageParam.perPage,
      }),
      searchConfig: new GoTableSearchConfig({
        searchable: true,
        debounce: 1000,
      }),
    });
  }

  selectedRows() {
    return selectedTableRows(this.mainEntityRefTable);
  }

  addRecord() {
    this.router.navigate([0], { relativeTo: this.route });
  }

  // ------------------ START: Delete records by ID -------------
  deleteRecord() {
    let idsToDelete: any;
    idsToDelete = this.getUnlockedRowsIds();

    if (!idsToDelete || idsToDelete.length === 0) {
      this.toasterService.toastInfo({
        header: 'Alert!',
        message: 'No records selected to delete.',
      });
      this.isDisabled.emit(false);
      return;
    }
    this.isDisabled.emit(false);
    this.modalService.openModal(ConfirmActionComponent, {
      modalTitle: 'Delete',
      message: 'You are about to delete the selected records.',
      question: 'Are you sure?',
      cancelAction: 'No',
      confirmAction: 'Yes',
      confirmCallback: () => {
        this.entityService.deleteById(idsToDelete.join(',')).subscribe(
          data => {
            this.toasterService.toastSuccess({
              header: 'Success!',
              message: 'Successfully deleted records.',
            });
            // If on last page and deleting all records then move to previous page.
            if (this.mainEntityRefTable.isLastPage()) {
              if (idsToDelete.length === this.mainEntityRefTable.tableConfig.tableData.length) {
                this.pageContext.pageParam.goToPrevious();
                this.tableConfig.pageConfig.offset = this.pageContext.pageParam.offset;
              }
            }
            // To trigger a forced refresh
            this.pageContext.pageParam.refresh = this.pageContext.pageParam.refresh + 1;
            this.router.navigate([], {
              queryParams: {
                ...this.pageContext.pageParam,
                ...this.pageContext.simpleFilter,
                ...this.pageContext.advanceFilter,
              },
              replaceUrl: true,
            });

            resetTableRowSelections(this.mainEntityRefTable);
            sessionStorage.setItem('lockedInvoicesDeleted', 'true');
            this.modalService.toggleModal(false);
          },
          error => {
            this.modalService.toggleModal(false);
            if (
              error === 'Constraint voilation exception delete' ||
              error === 'Constraint voilation exception child record found' ||
              error === 'Delete child record found'
            ) {
              this.toasterService.toastError({
                header: 'Error!',
                message:
                  'This deletion cannot be completed as it is linked to other data. Please remove the linked relationship first.',
              });
            } else {
              this.toasterService.toastError({
                header: 'Error!',
                message: 'Error occurred while deleting records.',
              });
            }
          }
        );
      },
    });
  }

 getUnlockedRowsIds() {
    const { selectAllControl, targetedRows, localTableConfig } = this.mainEntityRefTable;
    if (selectAllControl.value) {
      return localTableConfig.tableData
        .filter(row => !row.is_invoice_locked)
        .map(row => row.id);
    }
    const selectedUnlockedRows = targetedRows.filter(row => !row.is_invoice_locked);
    return selectedUnlockedRows.map(row => row.id);
  }
  // ------------------ END: Delete records by ID ---------------

 // ------------------- START: Load table data -----------------

  renderTableData(result: Result<K, T[]>) {
    this.showLoader = false;

    resetTableRowSelections(this.mainEntityRefTable);
    this.tableConfig.searchConfig.searchTerm = this.pageContext.simpleFilter.searchValue;
    const newTableConfig = this.tableConfig;
    this.tableConfig = new GoTableConfig({
      ...newTableConfig,
      totalCount: result.count,
      tableData: this.extractEntityArray(result),
    });

    this.restoreSearchValue();
  }

  onTableChange($event: GoTableConfig) {
    if (!hasTableConfigChanged($event, this.pageContext)) {
      return;
    }
    this.tableConfig = $event;
    this.pageContext.pageParam.perPage = this.tableConfig.pageConfig.perPage;
    this.pageContext.pageParam.offset = this.tableConfig.pageConfig.offset;

    if (this.tableConfig.sortConfig) {
      this.pageContext.pageParam.sortDir = this.tableConfig.sortConfig.direction;
      this.pageContext.pageParam.sortAttr = this.tableConfig.sortConfig.column;
    }
    if (this.pageContext.simpleFilter.searchValue !== this.tableConfig.searchConfig.searchTerm) {
      this.pageContext.simpleFilter.searchValue = this.tableConfig.searchConfig.searchTerm;

      this.pageContext.pageParam.offset = 0;
      this.tableConfig.pageConfig.offset = 0;

      this.pageContext.advanceFilter = {};
    }
    this.router.navigate([], {
      queryParams: {
        ...this.pageContext.pageParam,
        ...this.pageContext.simpleFilter,
        ...this.pageContext.advanceFilter,
      },
    });

    this.scrollToLeft();
  }

  setFilters(filters: AdvanceFilter) {
    this.scrollToLeft();
    // Ignore empty filter values
    this.pageContext.advanceFilter = Object.keys(filters)
      .filter(key => !!filters[key])
      .reduce((all, key) => {
        all[key] = filters[key];
        return all;
      }, {});

    delete this.pageContext.simpleFilter.searchValue;

    this.offCanvas.closeOffCanvas();

    this.pageContext.pageParam.offset = 0;
    this.tableConfig.pageConfig.offset = 0;

    this.router.navigate([], {
      queryParams: {
        ...this.pageContext.pageParam,
        ...this.pageContext.simpleFilter,
        ...this.pageContext.advanceFilter,
      },
      replaceUrl: true,
    });
  }

  removeFilter($e: { name: string; value: string }): void {
    this.setFilters(omit(this.pageContext.advanceFilter, $e.name));
  }
  // ------------------- END: Load table data -----------------

  buildAdvanceFilter(filters): AdvanceFilter {
    return pick(filters, this.getAdvanceFilterProps());
  }
  getLoaderValue(): boolean {
    return this.showLoader;
  }

  queryTableData() {
    this.route.queryParams.subscribe(q => {
      const { sortDir, sortAttr, perPage, offset, searchValue, ...filters } = q;

      this.showLoader = true;
      this.tableConfig.noDataText = 'No data';

      if (searchValue) {
        this.pageContext.simpleFilter.searchValue = searchValue;
      }

      const {
        offset: defaultOffset,
        perPage: defaultPerPage,
        sortAttr: defaultSortAttr,
        sortDir: defaultSortDir,
      } = this.pageContext.pageParam;

      if (Object.keys(q).length !== 0) {
        this.pageContext.pageParam.offset = offset ? offset : defaultOffset;
        this.pageContext.pageParam.perPage = perPage ? perPage : defaultPerPage;
        this.pageContext.pageParam.sortAttr = sortAttr ? sortAttr : defaultSortAttr;
        this.pageContext.pageParam.sortDir = sortDir ? sortDir : defaultSortDir;

        this.tableConfig.pageConfig.offset = Number(offset);
      }

      this.pageContext.advanceFilter = this.buildAdvanceFilter(filters);
      this.filtersComponent.updateValues(this.pageContext.advanceFilter);

      if (Object.keys(this.pageContext.advanceFilter).length === 0) {
        this.entityService.simpleSearch(this.pageContext.simpleFilter, this.pageContext.pageParam).subscribe(
          result => {
            this.renderTableData(result);
          },
          error => {
            this.showLoader = false;
            this.tableConfig.noDataText = 'Error loading data';
          }
        );
      } else {
        this.entityService
          .advanceSearch(this.pageContext.advanceFilter, this.pageContext.pageParam)
          .subscribe(result => {
            this.renderTableData(result);
          });
      }
      this.selectedFilters = this.getSelectedFilters(
        [
          ...this.filtersComponent.filterInputs.toArray(),
          ...this.filtersComponent.filterSelects.toArray(),
          ...this.filtersComponent.filterLookups.toArray(),
          ...this.filtersComponent.filterDynamicLists.toArray(),
        ],
        this.pageContext.advanceFilter
      );
    });
  }

  processColumns(): void {
    const passedColumns: FieldComponent[] = this.columns.toArray();
    passedColumns.forEach((column: FieldComponent, index) => {
      const columnConfig: ColumnConfig = {
        field: column.field,
        title: column.title,
        sortable: column.sortable,
        searchable: column.searchable,
        static: column.static,
        staticColumn: column.staticColumn,
        hiddenColumn: column.hiddenColumn,
        template: column.template,
        width: column.width,
        exportable: column.exportable,
      };

      // Check if the column has been added already
      const columnExists =
        this.displayColumns.added.some(displayColumn => displayColumn.field === columnConfig.field) ||
        this.displayColumns.removed.some(displayColumn => displayColumn.field === columnConfig.field) ||
        this.displayColumns.static.some(displayColumn => displayColumn.field === columnConfig.field);

      if (!columnExists) {
        if (columnConfig.staticColumn || columnConfig.static) {
          this.displayColumns.static.push(columnConfig);
        } else if (columnConfig.hiddenColumn) {
          this.displayColumns.removed.push(columnConfig);
        } else {
          this.displayColumns.added.push(columnConfig);
        }
      }
    });

    this.preSaveColumns = {
      added: Object.assign([], this.displayColumns.added),
      static: Object.assign([], this.displayColumns.static),
      removed: Object.assign([], this.displayColumns.removed),
    };
  }

  openColumnsConfiguration() {
    this.offCanvas.openOffCanvas({
      component: ColumnConfigurationComponent,
      bindings: {
        columns: this.displayColumns,
        preSaveColumns: this.preSaveColumns,
        onSubmit: (newColumns: ColumnsConfig) => {
          this.displayColumns = newColumns;
          this.closeOffCanvas();
        },
      },
      offCanvasOptions: {
        header: 'Customize Columns',
      },
    });
  }

  closeOffCanvas() {
    this.offCanvas.closeOffCanvas();
  }

  scrollToLeft(): void {
    const scrollPage = document.getElementsByClassName('go-table')[0];
    if (scrollPage) {
      scrollPage.scrollLeft = 0;
    }
  }

  restoreSearchValue(): void {
    setTimeout(() => {
      const searchdata = document.getElementsByClassName('go-table__search-input')[0];
      if (searchdata) {
        searchdata['value'] = this.tableConfig.searchConfig.searchTerm || '';
      }
    });
  }
}
