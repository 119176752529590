import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RedistributedCharge } from './redistributed-charge';
import { BaseService } from '../../base/base-service';
import { SimpleFilter } from '../../base/base-filter';
import { environment } from '../../../environments/environment';
import { Result, DeleteResult, CountResult } from '../../base/result';
import { Observable } from 'rxjs';
import { QueryParam } from '../../base/common';

@Injectable({
  providedIn: 'root',
})
export class RedistributedChargeService extends BaseService<RedistributedCharge, 'redistributed_charge'> {
  constructor(http: HttpClient) {
    super(http);
  }

  getServiceUrl(): string {
    return environment.expenseDataUrl;
  }
  getMetaData(): any {
  }
  getBaseUrl(): string {
    return this.getServiceUrl() + '/redistributedCharges';
  }

  // ************************************ START:CUSTOM METHODS **************************

  // ************************************ END:CUSTOM METHODS **************************

  // ************************************ START:FETCH CHILD METHODS **************************

  // ************************************ END:FETCH CHILD METHODS **************************

  // ************************************ START:COUNT METHODS **************************

  // ************************************ END:COUNT METHODS **************************
}
