import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AllocationInfo } from './allocation-info';
import { BaseService } from '../base/base-service';
import { SimpleFilter } from '../base/base-filter';
import { environment } from '../../environments/environment';
import { Result, DeleteResult } from '../base/result';
import { Observable } from 'rxjs';
import { QueryParam } from '../base/common';
import { SaveAllocationInfoDto } from './save-allocation-info-dto';

@Injectable({
  providedIn: 'root',
})
export class AllocationInfoService extends BaseService<AllocationInfo, 'allocation_info'> {
  constructor(http: HttpClient) {
    super(http);
  }

  getServiceUrl(): string {
    return environment.enterpriseDataUrl;
  }
  getMetaData(): any {
  }

  getBaseUrl(): string {
    return this.getServiceUrl() + '/allocationInfos';
  }

  insert(entity: SaveAllocationInfoDto): Observable<Result<'allocation_info', AllocationInfo>> {
    const headers = this.buildHeaders();
    const url = environment.expenseDataUrl + '/allocations/customAllocationInfoSave';
    return this.http.post<Result<'allocation_info', AllocationInfo>>(url, entity, {
      headers,
    });
  }

  update(entity: AllocationInfo): Observable<Result<'allocation_info', AllocationInfo>> {
    const headers = this.buildHeaders();
    const url = environment.expenseDataUrl + '/allocations/customAllocationInfoSave';
    return this.http.post<Result<'allocation_info', AllocationInfo>>(url, entity, {
      headers,
    });
  }

  // ************************************ START:CUSTOM METHODS **************************

  // Custom method to be used by search and select for StringFormat.
  findStringFormats(
    q?: string,
    include?: string,
    exclude?: string,
    filter?: string
  ): Observable<Result<'allocation_string_format', any[]>> {
    const simpleFilter = new SimpleFilter('name', q, include, exclude);
    const queryParam = new QueryParam();
    queryParam.perPage = 25;
    queryParam.sortAttr = 'name';

    return this.listExternalEntities(
      environment.enterpriseDataUrl + '/allocationStringFormats/list',
      simpleFilter,
      queryParam
    );
  }
  // ************************************ END:CUSTOM METHODS **************************

  // ************************************ START:FETCH CHILD METHODS **************************

  // Methods to handle child records for Allocation String
  findChildAllocationStringRecords(
    parentId: number,
    queryParam: QueryParam,
    q?: string
  ): Observable<Result<'allocation_string', any[]>> {
    const simpleFilter = new SimpleFilter('string_code,string_name', q);

    return this.listExternalEntities(
      environment.enterpriseDataUrl + `/allocationInfos/${parentId}/allocationStrings`,
      simpleFilter,
      queryParam
    );
  }

  deleteChildAllocationStringRecordsById(id: string): Observable<DeleteResult> {
    return this.deleteExternalEntitiesById(environment.enterpriseDataUrl + '/allocationStrings', id);
  }

  insertChildAllocationStringRecords(entity: any): Observable<Result<'allocation_string', any>> {
    return this.insertExternalEntity(environment.enterpriseDataUrl + `/allocationStrings`, entity);
  }

  // ************************************ END:FETCH CHILD METHODS **************************

  // ************************************ START:COUNT METHODS **************************

  // ************************************ END:COUNT METHODS **************************
}
