import { Component, Input, OnInit } from '@angular/core';
import { BaseFieldComponent } from '../field-base';

@Component({
  selector: 'app-field-boolean',
  templateUrl: './field-boolean.component.html',
  styleUrls: ['./field-boolean.component.scss'],
})
export class FieldBooleanComponent extends BaseFieldComponent implements OnInit {
  @Input() items: any[];
  @Input() bindValue: string = 'value';
  @Input() bindLabel: string = 'name';
  @Input() hints: string[];

  simpleYesNoSelectionList = [
    { value: '1', name: 'Yes' },
    { value: '0', name: 'No' },
  ];

  ngOnInit() {
    this.label = this.label + '?';
    this.label = this.required === 'true' ? this.label + '*' : this.label;

    this.placeholder = `Select ${this.label}`;

    this.items = this.simpleYesNoSelectionList;
  }
}
