import { BaseEntity } from '../../base/base-entity';

/**
 * This class represent the TenantVendorCode entity.
 */
export class TenantVendorCode extends BaseEntity {
  tenant_vendor_code_row_id: number;

  tenant_vendor_code: string;
}
