import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from './user';

import { UserTenantRole } from './user';

import { GroupMembership } from './user';

import { BaseService } from '../base/base-service';
import { SimpleFilter } from '../base/base-filter';
import { environment } from '../../environments/environment';
import { Result, DeleteResult, CountResult } from '../base/result';
import { Observable } from 'rxjs';
import { QueryParam } from '../base/common';
import { map } from 'rxjs/operators';
import { Employee } from '../employee/employee';
import { metaData } from './user-metadata';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService<User, 'user'> {
  constructor(http: HttpClient) {
    super(http);
  }

  getServiceUrl(): string {
    return environment.securityServiceUrl;
  }
  getMetaData(): any {
    return metaData;
  }

  getBaseUrl(): string {
    return this.getServiceUrl() + '/users';
  }

  // ************************************ START:CUSTOM METHODS **************************

  // Custom method to be used by search and select for Employees.
  findEmployeeses(
    q?: string,
    include?: string,
    exclude?: string,
    filter?: string
  ): Observable<Result<'employee', any[]>> {
    const simpleFilter = new SimpleFilter('first_name,last_name,email,employee_id', q, include, exclude);
    const queryParam = new QueryParam();
    queryParam.perPage = 25;
    queryParam.sortAttr = 'first_name';

    return this.listExternalEntities(environment.masterDataUrl + '/employees/nousers/list', simpleFilter, queryParam);
  }

  // Custom method to be used by search and select for UserType.
  findUserTypes(
    q?: string,
    include?: string,
    exclude?: string,
    filter?: string
  ): Observable<Result<'user_type', any[]>> {
    const simpleFilter = new SimpleFilter('code,display_name', q, include, exclude);
    const queryParam = new QueryParam();
    queryParam.perPage = 25;
    queryParam.sortAttr = 'code';

    return this.listExternalEntities(environment.extEnterpriseDataUrl + '/userTypes/list', simpleFilter, queryParam);
  }

  // Custom method to be used by search and select for TimeZone.
  findTimeZones(
    q?: string,
    include?: string,
    exclude?: string,
    filter?: string
  ): Observable<Result<'time_zone', any[]>> {
    const simpleFilter = new SimpleFilter('time_zone', q, include, exclude);
    const queryParam = new QueryParam();
    queryParam.perPage = 25;
    queryParam.sortAttr = 'time_zone';

    return this.listExternalEntities(environment.extEnterpriseDataUrl + '/timeZones/list', simpleFilter, queryParam);
  }

  // ************************************ END:CUSTOM METHODS **************************

  // ************************************ START:FETCH CHILD METHODS **************************

  // Methods to handle child records for Assign Role
  findChildUserTenantRoleRecords(
    parentId: number,
    queryParam: QueryParam,
    q?: string
  ): Observable<Result<'user_tenant_role', any[]>> {
    const simpleFilter = new SimpleFilter('role.name,role.description', q);

    return this.listExternalEntities(
      environment.securityServiceUrl + `/users/${parentId}/userTenantRoles`,
      simpleFilter,
      queryParam
    );
  }

  deleteChildUserTenantRoleRecordsById(ids: string, parentId?: string): Observable<DeleteResult> {
    return this.deleteExternalEntitiesById(environment.securityServiceUrl + '/userTenantRoles', ids);
  }

  findUnassignedUserTenantRoleRecords(
    parentId: number,
    queryParam: QueryParam,
    q?: string
  ): Observable<Result<'role', any[]>> {
    const simpleFilter = new SimpleFilter('name,description', q);

    return this.listExternalEntities(
      environment.securityServiceUrl + `/roles/userTenantRoles/unassigned/${parentId}`,
      simpleFilter,
      queryParam
    );
  }

  insertAssignmentUserTenantRoleRecords(assignments: any[], ids?: string, parentId?: string): Observable<any[]> {
    return this.insertExternalEntities(environment.securityServiceUrl + `/userTenantRoles`, assignments);
  }

  // Methods to handle child records for Assign Group
  findChildGroupMembershipRecords(
    parentId: number,
    queryParam: QueryParam,
    q?: string
  ): Observable<Result<'group_membership', any[]>> {
    const simpleFilter = new SimpleFilter('user_group.name,user_group.email,user_group.description', q);

    return this.listExternalEntities(
      environment.securityServiceUrl + `/users/${parentId}/groupMemberships`,
      simpleFilter,
      queryParam
    );
  }

  deleteChildGroupMembershipRecordsById(ids: string, parentId?: string): Observable<DeleteResult> {
    return this.deleteExternalEntitiesById(environment.securityServiceUrl + '/groupMemberships', ids);
  }

  findUnassignedGroupMembershipRecords(
    parentId: number,
    queryParam: QueryParam,
    q?: string
  ): Observable<Result<'group', any[]>> {
    const simpleFilter = new SimpleFilter('name,email', q);

    return this.listExternalEntities(
      environment.securityServiceUrl + `/userGroups/groupMemberships/unassigned/${parentId}`,
      simpleFilter,
      queryParam
    );
  }

  insertAssignmentGroupMembershipRecords(assignments: any[], ids?: string, parentId?: string): Observable<any[]> {
    return this.insertExternalEntities(environment.securityServiceUrl + `/groupMemberships`, assignments);
  }
  updateEmployee(employeeId: number): Observable<Employee> {
    const employee = new Employee();
    employee.id = employeeId;
    employee.addAssociation('user', 'user_row_id', null);
    return this.http.put<Result<'employee', Employee>>(
      environment.masterDataUrl + '/employees/' + employeeId,
      employee,
      {
        headers: this.buildHeaders(),
      },
    ).pipe(
      map((result) => {
        return result.employee;
      })
    );
  }

  // ************************************ END:FETCH CHILD METHODS **************************

  // ************************************ START:COUNT METHODS **************************

  // ************************************ END:COUNT METHODS **************************
}
