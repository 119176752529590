import { Component, OnInit, ViewChild } from '@angular/core';
import { GoToasterService, GoTableComponent, GoModalService } from '@tangoe/goponents';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BaseModalEditComponent } from '../../base/base-modal-edit.component';
import { Result } from '../../base/result';
import { ItemSelector, EmbeddedTableContext } from '../../base/common';
import * as utils from '../../base/utils';
import { CustomValidators } from '../../base/validators';
import { AllocationStatusHistoryService } from './allocation-status-history.service';
import { AllocationStatusHistory } from './allocation-status-history';

@Component({
  selector: 'app-allocation-status-history-modal',
  templateUrl: './allocation-status-history-modal.component.html',
  styleUrls: ['./allocation-status-history-modal.component.scss'],
})
export class AllocationStatusHistoryModalComponent
  extends BaseModalEditComponent<AllocationStatusHistory, 'allocation_status_history'>
  implements OnInit {
  constructor(
    public allocationStatusHistoryService: AllocationStatusHistoryService,
    protected toasterService: GoToasterService,
    protected modalService: GoModalService,
    protected router: Router,
    protected route: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {
    super(allocationStatusHistoryService, toasterService, modalService, router, route);
    this.listContextPath = '/allocation-status-histories';
    this.editContextPath = '/allocation-status-histories';
    this.entityLocator = 'allocation_status_history';
  }

  ngOnInit() {
    this.loadRecord();
  }

  populateForm(): void {
    this.formData = this.formBuilder.group({});
  }

  extractFormData(): AllocationStatusHistory {
    const entity: AllocationStatusHistory = new AllocationStatusHistory();

    const {} = this.formData.value;

    if (this.isAddMode) {
      entity.addAssociation('invoice_header', 'invoice_header_row_id', this.parentId);
    } else {
      entity.id = this.id;
      entity.allocation_status_history_row_id = this.id;
    }

    return entity;
  }
}
