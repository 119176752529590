import { BaseEntity } from '../../base/base-entity';

/**
 * This class represent the EmployeeAddress entity.
 */
export class EmployeeAddress extends BaseEntity {
  employee_address_row_id: number;

  type: { value_id: number; value?: string; dynamic_list_name?: string };
  country: string;
  address_line1: string;
  address_line2: string;
  city: string;
  state_province_region: string;
  zip_postal_code: string;
  is_primary_address: string;
}
