import { BaseEntity } from '../../base/base-entity';

/**
 * This class represent the TaxSummary entity.
 */
export class TaxSummary extends BaseEntity {
  tax_summary_row_id: number;

  account__account_number: string;
  charge_type__name: string;
  tax_rate: string;
  tax_stateprovince_region: string;
  tax_country: string;
  amount: { value: number; indicator: string };
  invoice_header__invoice_currency: string;
  account__account_row_id: string;
}
