import { Component, OnInit, ElementRef } from '@angular/core';
import { GoToasterService, GoModalService } from '@tangoe/goponents';
import { BaseModalListComponent } from '../../base/base-modal-list.component';
import { RoleService } from '../role.service';
import { Role } from '../role';

@Component({
  selector: 'app-assign-privilege-policy-to-role-modal',
  templateUrl: './assign-privilege-policy-to-role-modal.component.html',
  styleUrls: ['./assign-privilege-policy-to-role-modal.component.scss'],
})
export class AssignPrivilegePolicyToRoleModalComponent extends BaseModalListComponent<Role, 'role'> {
  constructor(
    protected toasterService: GoToasterService,
    protected roleService: RoleService,
    protected modalService: GoModalService,
    protected el: ElementRef
  ) {
    super(toasterService, roleService, modalService, el);
  }

  linkedEntity(): string {
    return 'privilege_policy';
  }

  primaryEntity(): string {
    return 'role';
  }

  otherEntity(): string {
    return 'privilege_policy';
  }

  otherEntitySearch(): string {
    return 'name,description';
  }

  otherEntitySort(): string {
    return 'name';
  }
}
