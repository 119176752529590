import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { VendorNote } from './manage-vendor-note';
import { BaseService } from '../../base/base-service';
import { SimpleFilter } from '../../base/base-filter';
import { environment } from '../../../environments/environment';
import { Result, DeleteResult, CountResult } from '../../base/result';
import { Observable } from 'rxjs';
import { QueryParam } from '../../base/common';

@Injectable({
  providedIn: 'root',
})
export class ManageVendorNoteService extends BaseService<VendorNote, 'vendor_note'> {
  constructor(http: HttpClient) {
    super(http);
  }
  getMetaData(): any {
  }
  getServiceUrl(): string {
    return environment.masterDataUrl;
  }

  getBaseUrl(): string {
    return this.getServiceUrl() + '/vendorNotes';
  }

  // ************************************ START:CUSTOM METHODS **************************

  // Custom method to be used by dynamic list values for Notes Type.
  findNotesTypeListValues(): Observable<Result<'dynamic_list_value', any[]>> {
    return this.fetchDynamicListValues('Notes Type');
  }

  // ************************************ END:CUSTOM METHODS **************************

  // ************************************ START:FETCH CHILD METHODS **************************

  // ************************************ END:FETCH CHILD METHODS **************************

  // ************************************ START:COUNT METHODS **************************

  // ************************************ END:COUNT METHODS **************************
}
