import { Component, ElementRef, Input } from '@angular/core';
import { EmbeddedListComponent } from '../base/embedded-list.component';
import { GoModalService, GoToasterService } from '@tangoe/goponents';

@Component({
  selector: 'app-data-history',
  templateUrl: './data-history.component.html',
  styleUrls: ['../embedded-list.component.scss'],
})
export class DataHistoryComponent extends EmbeddedListComponent {
  @Input() boxShadow: boolean = true;
  @Input() renderBoxShadows: boolean = true;

  constructor(protected el: ElementRef, protected modalService: GoModalService, protected toasterService: GoToasterService) {
    super(el, toasterService, modalService);

    this.name = 'history';
    this.title = 'History';
    this.serviceMethod = 'history';
    this.entityLocator = 'history';
    this.defaultSearchAttrs = 'business_action,field_name,old_value,new_value,created_by';
    this.defaultSortAttr = 'id DESC';
    this.isSelectable = false;
  }
  badgeData(): string {
    return this.loading ? '--' : String(this.tableConfig.totalCount);
  }
}
