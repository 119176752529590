import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Contract } from './contract';

import { ContractAccountRef } from './contract';

import { BaseService } from '../base/base-service';
import { SimpleFilter } from '../base/base-filter';
import { environment } from '../../environments/environment';
import { Result, DeleteResult, CountResult } from '../base/result';
import { Observable } from 'rxjs';
import { QueryParam } from '../base/common';
import { metaData } from '../contract/contract-metadata';

@Injectable({
  providedIn: 'root',
})
export class ContractService extends BaseService<Contract, 'contract'> {
  constructor(http: HttpClient) {
    super(http);
  }

  getServiceUrl(): string {
    return environment.assuranceDataUrl;
  }
  getMetaData(): any {
    return metaData;
  }

  getBaseUrl(): string {
    return this.getServiceUrl() + '/contracts';
  }

  // ************************************ START:CUSTOM METHODS **************************

  // Custom method to be used by search and select for PreviousContract.
  findPreviousContracts(
    q?: string,
    include?: string,
    exclude?: string,
    filter?: string
  ): Observable<Result<'contract', any[]>> {
    const simpleFilter = new SimpleFilter('contract_name', q, include, exclude);
    const queryParam = new QueryParam();
    queryParam.perPage = 25;
    queryParam.sortAttr = 'contract_name';

    return this.listExternalEntities(environment.assuranceDataUrl + '/contracts/list', simpleFilter, queryParam);
  }

  // Custom method to be used by search and select for MasterVendor.
  findMasterVendors(
    q?: string,
    include?: string,
    exclude?: string,
    filter?: string
  ): Observable<Result<'vendor', any[]>> {
    const simpleFilter = new SimpleFilter('name', q, include, exclude);
    const queryParam = new QueryParam();
    queryParam.perPage = 25;
    queryParam.sortAttr = 'name';

    return this.listExternalEntities(environment.masterDataUrl + '/vendors/list', simpleFilter, queryParam);
  }

  // Custom method to be used by dynamic list values for Contract Type.
  findContractTypeListValues(): Observable<Result<'dynamic_list_value', any[]>> {
    return this.fetchDynamicListValues('Contract Type');
  }

  // Custom method to be used by dynamic list values for Service Category.
  findServiceCategoryListValues(): Observable<Result<'dynamic_list_value', any[]>> {
    return this.fetchDynamicListValues('Service Category');
  }

  // Custom method to be used by dynamic list values for Waived Late Payment Charges.
  findWaivedLatePaymentChargesListValues(): Observable<Result<'dynamic_list_value', any[]>> {
    return this.fetchDynamicListValues('Waived Late Payment Charges');
  }

  // ************************************ END:CUSTOM METHODS **************************

  // ************************************ START:FETCH CHILD METHODS **************************

  // Methods to handle child records for Associate Account
  findChildContractAccountRefRecords(
    parentId: number,
    queryParam: QueryParam,
    q?: string
  ): Observable<Result<'contract_account_ref', any[]>> {
    const simpleFilter = new SimpleFilter('account.account_number,account.account_name', q);

    return this.listExternalEntities(
      environment.assuranceDataUrl + `/contracts/${parentId}/contractAccountRefs`,
      simpleFilter,
      queryParam
    );
  }

  deleteChildContractAccountRefRecordsById(ids: string, parentId?: string): Observable<DeleteResult> {
    return this.deleteExternalEntitiesById(environment.assuranceDataUrl + '/contractAccountRefs', ids);
  }

  findUnassignedContractAccountRefRecords(
    parentId: number,
    queryParam: QueryParam,
    q?: string
  ): Observable<Result<'account', any[]>> {
    const simpleFilter = new SimpleFilter('account_number,account_name', q);

    return this.listExternalEntities(
      environment.assuranceDataUrl + `/accounts/contractAccountRefs/unassigned/${parentId}`,
      simpleFilter,
      queryParam
    );
  }

  insertAssignmentContractAccountRefRecords(assignments: any[], ids?: string, parentId?: string): Observable<any[]> {
    return this.insertExternalEntities(environment.assuranceDataUrl + `/contractAccountRefs`, assignments);
  }

  // ************************************ END:FETCH CHILD METHODS **************************

  // ************************************ START:COUNT METHODS **************************

  // ************************************ END:COUNT METHODS **************************
}
