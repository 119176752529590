import { BaseEntity } from '../base/base-entity';

export class UpdateInvoiceDto extends BaseEntity {
  invoice_id: number;
  invoice_header_id: number;
  tenant_vendor_code: { tenantVendorCodeRowId: number };
  receiptDate: string;
  po_number: string;
  isUpdateNumberToRI: boolean = false;
}
