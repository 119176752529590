export class SimpleFilter {
  searchAttrs: string;
  searchValue: string;

  includeRows: string;
  excludeRows: string;

  constructor(attr: string, value?: string, include?: string, exclude?: string) {
    this.searchAttrs = attr;
    this.searchValue = value;
    this.includeRows = include;
    this.excludeRows = exclude;
  }
}

export interface AdvanceFilter {
  [key: string]: number | string;
}
