import { Component, OnInit, ViewChild } from '@angular/core';
import { GoToasterService, GoModalService } from '@tangoe/goponents';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseEditComponent } from '../../base/base-edit.component';
import { AllocationInfoService } from '../allocation-info.service';
import { AllocationInfo } from '../allocation-info';
import { AllocationConfigurationEditComponent } from '../../shared/components/allocation-configuration/allocation-configuration-edit/allocation-configuration-edit.component';

@Component({
  selector: 'app-allocation-info-edit',
  templateUrl: './allocation-info-edit.component.html',
  styleUrls: ['./allocation-info-edit.component.scss'],
})
export class AllocationInfoEditComponent extends BaseEditComponent<AllocationInfo, 'allocation_info'>
  implements OnInit {
  constructor(
    public allocationInfoService: AllocationInfoService,
    protected toasterService: GoToasterService,
    protected modalService: GoModalService,
    protected router: Router,
    protected route: ActivatedRoute
  ) {
    super(allocationInfoService, toasterService, modalService, router, route);

    this.listContextPath = '/allocation-info';
    this.editContextPath = '/allocation-info';
    this.entityLocator = 'allocation_info';
  }

  @ViewChild(AllocationConfigurationEditComponent, { static: false })
  allocationConfigurationEdit: AllocationConfigurationEditComponent;

  ngOnInit(): void {
    this.loadRecord();
  }

  extractFormData(): AllocationInfo {
    return this.allocationConfigurationEdit.extractFormData();
  }

  saveRecord() {
    this.formData = this.allocationConfigurationEdit.formData;
    this.allocationConfigurationEdit.saveRecord();
  }

  populateForm(): void {
    if (this.allocationConfigurationEdit) {
      this.allocationConfigurationEdit.populateForm();
      this.formData = this.allocationConfigurationEdit.formData;
    }
  }
}
