import { Injectable } from '@angular/core';
import { Invoice, PaymentDetails, WorkflowActionRequest, WorkflowActionResponse } from './invoice';
import { BaseService } from '../base/base-service';
import { SimpleFilter } from '../base/base-filter';
import { environment } from '../../environments/environment';
import { DeleteResult, Result } from '../base/result';
import { Observable } from 'rxjs';
import { QueryParam } from '../base/common';
import { UpdateInvoiceDto } from './update-invoice-dto';
import { HttpClient } from '@angular/common/http';
import { metaData } from './invoice-metadata';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService extends BaseService<Invoice, 'invoice_header'> {
  constructor(http: HttpClient) {
    super(http);
  }
  updateInvoice(data: UpdateInvoiceDto): Observable<Result<'invoice_header', Invoice>> {
    const headers = this.buildHeaders();

    return this.http.post<Result<'invoice_header', Invoice>>(
      environment.expenseDataUrl + '/updateInvoiceHeaderDetails',
      data,
      {
        headers,
      }
    );
  }

  // Override the list page URL as we need a custom end point for Invoice List Page.
  getListPageUrl(): string {
    return this.getServiceUrl() + '/invoiceHeaderListViews';
  }
  getMetaData(): any {
    return metaData;
  }
  findChildInvoiceLineItemRecords(
    parentId: number,
    queryParam: QueryParam,
    q?: string
  ): Observable<Result<'allocation_string', any[]>> {
    const simpleFilter = new SimpleFilter('charge_type.name,charge_description,invoice_account.account_number', q);

    return this.listExternalEntities(
      environment.expenseDataUrl + `/invoiceHeaders/${parentId}/invoiceLineItems`,
      simpleFilter,
      queryParam
    );
  }

  findChildPreviousInvoiceItemRecords(
    parentId: number,
    queryParam: QueryParam,
    q?: string
  ): Observable<Result<'allocation_string', any[]>> {
    const simpleFilter = new SimpleFilter('invoice_number', q);

    return this.listExternalEntities(
      environment.expenseDataUrl + `/invoiceHeaders/${parentId}/previousInvoices`,
      simpleFilter,
      queryParam
    );
  }

  findVendorCodes(
    q?: string,
    include?: string,
    exclude?: string,
    filter?: string
  ): Observable<Result<'vendor', any[]>> {
    const simpleFilter = new SimpleFilter('tenant_vendor_code', q, include, exclude);
    const queryParam = new QueryParam();
    queryParam.perPage = 25;
    queryParam.sortAttr = 'tenant_vendor_code';

    const url = include
      ? environment.masterDataUrl + `/tenantVendorCodes/list`
      : environment.masterDataUrl + `/vendors/${filter}/tenantVendorCodes`;

    return this.listExternalEntities(url, simpleFilter, queryParam);
  }

  findNoteRecords(
    parentId: number,
    queryParam: QueryParam,
    q?: string
  ): Observable<Result<'invoice_header_note', any[]>> {
    const simpleFilter = new SimpleFilter('info', q);

    return this.listExternalEntities(
      environment.expenseDataUrl + `/invoiceHeaders/${parentId}/notes`,
      simpleFilter,
      queryParam
    );
  }

  deleteNoteRecords(id: string, parentId?: string): Observable<DeleteResult> {
    return this.deleteExternalEntitiesById(environment.expenseDataUrl + `/invoiceHeaderNotes`, id);
  }

  insertNoteRecords(entity: any, parentId: string): Observable<Result<'recurring_invoice_note', any>> {
    entity.addAssociation('invoiceHeader', 'invoice_header_row_id', parentId);
    return this.insertExternalEntity(environment.expenseDataUrl + `/invoiceHeaderNotes`, entity);
  }

  updateNoteRecords(id: string, entity: any, parentId: string): Observable<Result<'recurring_invoice_note', any>> {
    entity.addAssociation('invoiceHeader', 'invoice_header_row_id', parentId);
    return this.updateExternalEntity(environment.expenseDataUrl + `/invoiceHeaderNotes/${id}`, entity);
  }

  getInvoicePaymentSummary(parentId): Observable<Result<'payment_details', PaymentDetails[]>> {
    const url = environment.expenseDataUrl + `/invoiceHeaders/${parentId}/paymentDetails`;
    const headers = this.buildHeaders();

    return this.http.get<Result<'payment_details', PaymentDetails[]>>(url, {
      headers,
    });
  }

  getServiceUrl(): string {
    return environment.expenseDataUrl;
  }

  getBaseUrl(): string {
    return this.getServiceUrl() + '/invoiceHeaders';
  }

  // ************************************ START:CUSTOM METHODS **************************

  // workflow execution
  executeWorkflowAction(
    entity: WorkflowActionRequest
  ): Observable<Result<'workflow_response', WorkflowActionResponse>> {
    const headers = this.buildHeaders();
    const params: any = {};
    const url = environment.workflowServiceUrl + '/executeAction';

    return this.http.post<Result<'workflow_response', WorkflowActionResponse>>(url, entity, {
      headers,
      params,
    });
  }

  // ************************************ END:CUSTOM METHODS **************************

  // ************************************ START:FETCH CHILD METHODS **************************

  // ************************************ END:FETCH CHILD METHODS **************************

  // ************************************ START:COUNT METHODS **************************

  // ************************************ END:COUNT METHODS **************************
}
