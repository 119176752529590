import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserGroup } from './user-group';

import { GroupMembership } from './user-group';

import { BaseService } from '../base/base-service';
import { SimpleFilter } from '../base/base-filter';
import { environment } from '../../environments/environment';
import { Result, DeleteResult, CountResult } from '../base/result';
import { Observable } from 'rxjs';
import { QueryParam } from '../base/common';
import { metaData } from './user-group-metadata';

@Injectable({
  providedIn: 'root',
})
export class UserGroupService extends BaseService<UserGroup, 'group'> {
  constructor(http: HttpClient) {
    super(http);
  }

  getServiceUrl(): string {
    return environment.securityServiceUrl;
  }
  getMetaData(): any {
    return metaData;
  }
  getBaseUrl(): string {
    return this.getServiceUrl() + '/groups';
  }

  // ************************************ START:CUSTOM METHODS **************************

  // ************************************ END:CUSTOM METHODS **************************

  // ************************************ START:FETCH CHILD METHODS **************************

  // Methods to handle child records for Assign User To Group
  findChildGroupMembershipRecords(
    parentId: number,
    queryParam: QueryParam,
    q?: string
  ): Observable<Result<'group_membership', any[]>> {
    const simpleFilter = new SimpleFilter('user.identifier', q);

    return this.listExternalEntities(
      environment.securityServiceUrl + `/userGroups/${parentId}/groupMemberships`,
      simpleFilter,
      queryParam
    );
  }

  deleteChildGroupMembershipRecordsById(ids: string, parentId?: string): Observable<DeleteResult> {
    return this.deleteExternalEntitiesById(environment.securityServiceUrl + '/groupMemberships', ids);
  }

  findUnassignedGroupMembershipRecords(
    parentId: number,
    queryParam: QueryParam,
    q?: string
  ): Observable<Result<'user', any[]>> {
    const simpleFilter = new SimpleFilter('identifier', q);

    return this.listExternalEntities(
      environment.securityServiceUrl + `/users/groupMemberships/unassigned/${parentId}`,
      simpleFilter,
      queryParam
    );
  }

  insertAssignmentGroupMembershipRecords(assignments: any[], ids?: string, parentId?: string): Observable<any[]> {
    return this.insertExternalEntities(environment.securityServiceUrl + `/groupMemberships`, assignments);
  }

  // ************************************ END:FETCH CHILD METHODS **************************

  // ************************************ START:COUNT METHODS **************************

  // ************************************ END:COUNT METHODS **************************
}
