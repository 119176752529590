import { BaseEntity } from '../../base/base-entity';

/**
 * This class represent the VendorRemittanceCode entity.
 */
export class VendorRemittanceCode extends BaseEntity {
  vendor_remittance_code_row_id: number;

  remittance_address: { id: number; address_line1: string };
  vendor_remittance_code: string;
}
