import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, debounceTime } from 'rxjs/operators';
import { BaseFieldComponent } from '../field-base';
import { BaseEntity } from 'src/app/base/base-entity';
import { BaseService } from 'src/app/base/base-service';

@Component({
  selector: 'app-field-lookup',
  templateUrl: './field-lookup.component.html',
  styleUrls: ['./field-lookup.component.scss'],
})
export class FieldLookupComponent extends BaseFieldComponent implements OnChanges, OnInit {
  @Input() bindValue: string;
  @Input() bindLabel: string;
  @Input() entityLocator: string;
  @Input() lookupMethod: string;
  @Input() entityService: BaseService<BaseEntity, 'entity'>;
  @Input() multiple: boolean = false;
  @Input() selectedId: string;
  @Input() excludedId: string;
  @Input() filter: string;
  @Input() placeholder: string;
  @Input() autoLoad: boolean = false;
  @Input() clearable: boolean = true;

  items: Observable<any>;
  loading: boolean = false;
  userInput: Subject<string> = new Subject<string>();
  searchValue: string;

  ngOnInit() {
    super.ngOnInit();

    this.registerUserInput();
    this.lookupInitialSelection();

    if (this.autoLoad) {
      this.loadItems();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedId) {
      if (changes.selectedId.previousValue !== changes.selectedId.currentValue) {
        this.lookupInitialSelection();
        return;
      }
    }

    if (this.autoLoad) {
      this.lookupInitialSelection();
    }
  }

  registerUserInput() {
    this.userInput
      .pipe(
        debounceTime(600), // Delay user input
        map(input => {
          this.searchValue = input;
        })
      )
      .subscribe(() => {
        this.selectedId = undefined;

        this.lookupItemSelections();
      });
  }

  // Load the lookup dropdown with just one value that is currently selected.
  lookupInitialSelection() {
    this.searchValue = undefined;
    this.lookupItemSelections();
    this.clearInitialSelectionItems();
  }

  loadItems() {
    this.loading = true;

    this.items = this.entityService[this.lookupMethod](
      this.searchValue || '',
      this.selectedId || '',
      this.excludedId || '',
      this.filter || ''
    ).pipe(
      map(data => {
        this.loading = false;
        return this.enhanceLookupItems(data[this.entityLocator]);
      })
    );
  }

  lookupItemSelections() {
    if (this.searchValue || this.selectedId) {
      this.loadItems();
    } else {
      this.loading = false;
      this.items = Observable.of([]);
    }
  }

  enhanceLookupItems(list: any[]): any[] {
    if (this.bindLabel.includes('/')) {
      const primaryBind: string = this.bindLabel.split('/')[0];
      const secondaryBind: string = this.bindLabel.split('/')[1];

      list.map(element => {
        element[this.bindLabel] = element[primaryBind] + ' (' + element[secondaryBind] + ')';
        return element;
      });
    }

    return list;
  }

  // To clear the selection items (if any)
  clearInitialSelectionItems(): void {
    if (this.selectedId) {
      // If the items are still loading then retry in 1000 ms
      if (this.loading) {
        setTimeout(() => {
          this.clearInitialSelectionItems();
        }, 1000);
      } else {
        // Items already loaded, wait for 500 ms and clear the selections.
        setTimeout(() => {
          this.items = Observable.of([]);
        }, 500);
      }
    }
  }
}
