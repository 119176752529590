import { Component, OnInit, ElementRef } from '@angular/core';
import { GoToasterService, GoModalService } from '@tangoe/goponents';
import { BaseModalListComponent } from '../../base/base-modal-list.component';
import { CostCenterService } from '../cost-center.service';
import { CostCenter } from '../cost-center';

@Component({
  selector: 'app-assign-employee-to-cost-center-modal',
  templateUrl: './assign-employee-to-cost-center-modal.component.html',
  styleUrls: ['./assign-employee-to-cost-center-modal.component.scss'],
})
export class AssignEmployeeToCostCenterModalComponent extends BaseModalListComponent<CostCenter, 'cost_center'> {
  constructor(
    protected toasterService: GoToasterService,
    protected costCenterService: CostCenterService,
    protected modalService: GoModalService,
    protected el: ElementRef
  ) {
    super(toasterService, costCenterService, modalService, el);
  }

  linkedEntity(): string {
    return 'employee_cost_center';
  }

  primaryEntity(): string {
    return 'cost_center';
  }

  otherEntity(): string {
    return 'employee';
  }

  otherEntitySearch(): string {
    return 'employee_id,first_name,last_name,email';
  }

  otherEntitySort(): string {
    return 'first_name';
  }
}
