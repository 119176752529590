import {
  GoTableConfig,
  GoTableDataSource,
  GoTableSortConfig,
  GoTableComponent,
  GoTableSearchConfig,
  GoToasterService,
  SelectionState,
} from '@tangoe/goponents';
import { SimpleFilter, AdvanceFilter } from './base-filter';
import * as utils from './utils';
import { BaseService } from './base-service';
import fileSaver from 'file-saver';
import { map, delay, retryWhen, mergeMap } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { Result } from './result';

const DEFAULT_MAX_RETRY: number = 2;
const DEFAULT_DELAY_BETWEEN_RETRY: number = 1000;

export enum SORT_DIRECTION {
  DESC,
  ASC,
}

export class QueryParam {
  sortDir: number;
  sortAttr: string;

  perPage: number;
  offset: number;

  refresh: number;
  pageSizes: any = [];

  constructor(tableConfig?: GoTableConfig, defaultSortAttr?: string, defaultSortDirection?: string) {
    this.perPage = 10; // Default 10 records per page.
    this.offset = 0; // Default start with first record.

    this.sortAttr = defaultSortAttr || 'id'; // Default sort order provided as parameter
    this.sortDir = defaultSortDirection ? SORT_DIRECTION[defaultSortDirection] : 1; // Default sort order will be ASC

    this.pageSizes = [10, 25, 50];
    this.refresh = 0;
  }

  sortClause(): string {
    return this.sortAttr + ' ' + SORT_DIRECTION[this.sortDir];
  }

  page(): number {
    return 1 + this.offset / this.perPage;
  }

  goToPrevious(): void {
    if (this.page() > 1) {
      this.offset = this.offset - this.perPage;
    }
  }
}

export class PageContext {
  pageParam: QueryParam;
  simpleFilter: SimpleFilter;
  advanceFilter: AdvanceFilter;

  constructor(defaultSearchAttr: string, defaultSortAttr: string, defaultSortDirection?: string) {
    this.pageParam = new QueryParam(undefined, defaultSortAttr, defaultSortDirection);
    this.simpleFilter = new SimpleFilter(defaultSearchAttr);
    this.advanceFilter = {} as AdvanceFilter;
  }
}

export class EmbeddedTableContext {
  name: string;

  showLoader: boolean;
  tableConfig: GoTableConfig;
  pageContext: PageContext;

  parentId: number;

  entityRef: string;
  entityRowId: string;

  findMethod: string;
  deleteMethod: string;

  tableName: string;
  entityService: BaseService<any, any>;
  private refTable: GoTableComponent;

  constructor(
    name: string,
    protected defaultSearchAttr: string,
    protected defaultSortAttr: string,
    protected isSelectable: boolean,
    protected dataMode?: number
  ) {
    this.name = name;
    this.showLoader = false;
    this.pageContext = new PageContext(defaultSearchAttr, defaultSortAttr);
    this.tableConfig = new GoTableConfig({
      tableData: [],
      dataMode: dataMode || GoTableDataSource.server,
      totalCount: 0,
      selectBy: 'id',
      selectable: isSelectable,
      sortConfig: new GoTableSortConfig({
        column: this.pageContext.pageParam.sortAttr,
        direction: this.pageContext.pageParam.sortDir,
      }),
      searchConfig: new GoTableSearchConfig({
        searchable: false,
        debounce: 1000,
      }),
    });

    this.entityRef = utils.snakeCase(name);
    this.entityRowId = utils.lowerFirst(utils.attributize(name) + 'RowId');

    this.findMethod = 'findChild' + utils.upperFirst(utils.attributize(name)) + 'Records';
    this.deleteMethod = 'deleteChild' + utils.upperFirst(utils.attributize(name)) + 'RecordsById';

    this.tableName = 'child' + utils.upperFirst(utils.attributize(name)) + 'RefTable';
  }

  badgeData(): string {
    return this.showLoader ? '--' : String(this.tableConfig.totalCount);
  }

  load() {
    const entityService: BaseService<any, any> = this.entityService || this.entityService;

    this.showLoader = true;
    this.tableConfig.noDataText = 'No data';

    if (this.parentId === 0) {
      this.showLoader = false;
      this.tableConfig.noDataText = `Could not find any data to load`;

      return;
    }

    if (this.refTable) {
      resetTableRowSelections(this.refTable);
    }

    entityService[this.findMethod](
      this.parentId,
      this.pageContext.pageParam,
      this.pageContext.simpleFilter.searchValue
    ).subscribe(
      result => {
        this.showLoader = false;
        this.tableConfig.totalCount = result.count || result[this.entityRef].length;
        this.tableConfig.tableData = result[this.entityRef];

        if (result[this.entityRef].length > 0) {
          this.tableConfig.searchConfig.searchable = true;
        }

        this.tableConfig = { ...this.tableConfig };
      },
      error => {
        this.showLoader = false;
        this.tableConfig.noDataText = 'Error loading data';
        this.tableConfig = { ...this.tableConfig };
      }
    );
  }

  onTableChange($event: GoTableConfig) {
    if (!hasTableConfigChanged($event, this.pageContext)) {
      return;
    }

    this.tableConfig = $event;

    this.pageContext.pageParam.perPage = this.tableConfig.pageConfig.perPage;
    this.pageContext.pageParam.offset = this.tableConfig.pageConfig.offset;

    if (this.tableConfig.sortConfig) {
      this.pageContext.pageParam.sortDir = this.tableConfig.sortConfig.direction;
      this.pageContext.pageParam.sortAttr = this.tableConfig.sortConfig.column;
    }

    // Reset the offset only if the search term has changed so that we get the searched data from first page.
    if (this.pageContext.simpleFilter.searchValue !== this.tableConfig.searchConfig.searchTerm) {
      this.pageContext.simpleFilter.searchValue = this.tableConfig.searchConfig.searchTerm;

      this.pageContext.pageParam.offset = 0;
      this.tableConfig.pageConfig.offset = 0;
    }

    this.load();
  }

  export(url, exportLayout, toasterService: GoToasterService, fileName?: string) {
    const queryParams = new QueryParam();
    queryParams.sortAttr = this.pageContext.pageParam.sortAttr;
    queryParams.sortDir = this.pageContext.pageParam.sortDir;
    queryParams.offset = this.pageContext.pageParam.offset;
    queryParams.perPage = this.pageContext.pageParam.perPage;

    toasterService.toastInfo({
      header: 'Preparing Export',
      message: 'Your export is currently being prepared. Your download will begin shortly.',
    });

    this.entityService
      .triggerExport(this.pageContext.simpleFilter, this.pageContext.advanceFilter, queryParams, exportLayout, url)
      .pipe(delay(1000))
      .subscribe(
        data => {
          const fileToDownload = data.file_key;
          this.entityService.downloadExport(fileToDownload).subscribe(result => {
            const filename = fileName || result.headers.get('filename') || 'export.xlsx';

            const blob = new Blob([result.body], { type: 'application/vnd.ms-excel' });
            fileSaver.saveAs(blob, filename);

            toasterService.toastSuccess({
              header: 'Success!',
              message: 'Records have been exported successfully.',
            });
          });
        },
        error => {
          toasterService.toastError({
            header: 'Error!',
            message: 'Error occurred while exporting records.',
          });
        }
      );
  }
}

export class ModalTableContext extends EmbeddedTableContext {
  constructor(linkedEntity: string, otherEndDefaultSearchAttr: string, otherEndDefaultSortAttr: string) {
    super(linkedEntity, otherEndDefaultSearchAttr, otherEndDefaultSortAttr, true);
  }
}

export class ErrorItem {
  hasError: boolean;
  message?: string;

  constructor() {
    this.hasError = false;
    this.message = undefined;
  }

  set(msg: string) {
    this.hasError = true;
    this.message = msg;
  }

  clear() {
    this.hasError = false;
    this.message = undefined;
  }
}

export interface ItemSelector {
  loading: boolean;
  items: any[];
}

export interface DynamicListValue {
  id: string;
  value: string;
  description: string;
  dynamic_list: { id: string; name: string };
}

export type Money = {
  value: number;
  indicator?: string;
  date?: string;
  m_base?: number;
  native_indicator?: string;
};

export function selectedTableRows(tableComponent: GoTableComponent) {
  const state: SelectionState = tableComponent.getSelectionState();
  if (state.selectedRows.length > 0) {
    return state.selectedRows.map(element => element.id as number);
  } else if (state.selectionMode === 'deselection') {
    const selectedRows = tableComponent.allData.filter(item => {
      return !state.deselectedRows.some(item1 => {
        return item.id === item1.id;
      });
    });
    return selectedRows.map(element => element.id as number);
  }
}

export function resetTableRowSelections(tableComponent: GoTableComponent) {
  if (tableComponent) {
    const state: SelectionState = tableComponent.getSelectionState();
    const { selectable } = tableComponent.tableConfig;
    state.selectedRows.length = 0;
    state.deselectedRows.length = 0;
    if (selectable) {
      tableComponent.rowSelectForm.reset();
      tableComponent.selectAllControl.reset();
    }
  }
}

export function hasTableConfigChanged(event: GoTableConfig, pageContext: PageContext) {
  return (
    !valuesAreSame(event.searchConfig.searchTerm, pageContext.simpleFilter.searchValue) ||
    !valuesAreSame(event.sortConfig.column, pageContext.pageParam.sortAttr) ||
    !valuesAreSame(event.pageConfig.offset, pageContext.pageParam.offset) ||
    !valuesAreSame(event.pageConfig.perPage, pageContext.pageParam.perPage) ||
    !valuesAreSame(event.sortConfig.direction, pageContext.pageParam.sortDir)
  );
}

export function valuesAreSame(lhs: any, rhs: any): boolean {
  return lhs === rhs;
}

export function retryWithDelay<K extends string>(delayMs?: number, maxRetry?: number) {
  delayMs = delayMs || DEFAULT_DELAY_BETWEEN_RETRY;
  maxRetry = maxRetry || DEFAULT_MAX_RETRY;

  return (src: Observable<Result<K, any[]>>): Observable<Result<K, any[]>> => {
    return src.pipe(
      retryWhen((errors: Observable<any>) =>
        errors.pipe(
          delay(delayMs),
          mergeMap(error => (maxRetry-- > 0 ? of(error) : throwError(error)))
        )
      )
    );
  };
}
