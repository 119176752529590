import { Component, OnInit, ViewChild } from '@angular/core';
import { GoToasterService, GoTableComponent, GoModalService } from '@tangoe/goponents';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BaseModalEditComponent } from '../../base/base-modal-edit.component';
import { Result } from '../../base/result';
import { ItemSelector, EmbeddedTableContext } from '../../base/common';
import * as utils from '../../base/utils';
import { CustomValidators } from '../../base/validators';
import { ManageVendorRemitAddressService } from './manage-vendor-remit-address.service';
import { RemittanceAddress } from './manage-vendor-remit-address';

@Component({
  selector: 'app-manage-vendor-remit-address-modal',
  templateUrl: './manage-vendor-remit-address-modal.component.html',
  styleUrls: ['./manage-vendor-remit-address-modal.component.scss'],
})
export class ManageVendorRemitAddressModalComponent
  extends BaseModalEditComponent<RemittanceAddress, 'remittance_address'>
  implements OnInit {
  country_selection: number;

  state_province_region_selection: string;

  constructor(
    public manageVendorRemitAddressService: ManageVendorRemitAddressService,
    protected toasterService: GoToasterService,
    protected modalService: GoModalService,
    protected router: Router,
    protected route: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {
    super(manageVendorRemitAddressService, toasterService, modalService, router, route);
    this.listContextPath = '/manage-vendor-remit-addresses';
    this.editContextPath = '/manage-vendor-remit-addresses';
    this.entityLocator = 'remittance_address';
  }

  ngOnInit() {
    this.loadRecord();
  }

  populateForm(): void {
    this.formData = this.formBuilder.group({
      payment_type: [utils.extractDynamicValueId(this.entity.payment_type), []],

      country: [utils.extractLookupId(this.entity.country), [Validators.required]],

      address_line1: [this.entity.address_line1, [Validators.required, Validators.maxLength(128)]],

      address_line2: [this.entity.address_line2, [Validators.maxLength(128)]],

      address_line3: [this.entity.address_line3, [Validators.maxLength(128)]],

      city: [this.entity.city, [Validators.maxLength(128)]],

      state_province_region: [this.entity.state_province_region, [Validators.maxLength(128)]],

      zip_postal_code: [this.entity.zip_postal_code, [Validators.maxLength(10), CustomValidators.zipcode]],
    });

    this.country_selection = utils.extractLookupId(this.entity.country);

    this.state_province_region_selection = this.entity.state_province_region;

    this.stateProvinceRegionDisplayHandler(this.country_selection);

    this.formData.get('country').valueChanges.subscribe(value => {
      this.stateProvinceRegionDisplayHandler(value);
    });
  }

  stateProvinceRegionDisplayHandler(value: any) {
    if (value) {
      setTimeout(() => {
        this.formData.get('state_province_region').enable();
        if (value !== this.country_selection) {
          this.country_selection = value;
          this.formData.get('state_province_region').reset();
        }
      }, 100);
    } else {
      this.formData.get('state_province_region').reset();
      this.formData.get('state_province_region').disable();
    }
  }

  extractFormData(): RemittanceAddress {
    const entity: RemittanceAddress = new RemittanceAddress();

    const {
      payment_type,
      country,
      address_line1,
      address_line2,
      address_line3,
      city,
      state_province_region,
      zip_postal_code,
    } = this.formData.value;

    entity.address_line1 = address_line1;
    entity.address_line2 = address_line2;
    entity.address_line3 = address_line3;
    entity.city = city;
    entity.state_province_region = state_province_region;
    entity.zip_postal_code = zip_postal_code;

    entity.addDynamicListValue('payment_type', payment_type);

    entity.addAssociation('country', 'country_row_id', country);

    if (this.isAddMode) {
      entity.addAssociation('vendor', 'vendor_row_id', this.parentId);
    } else {
      entity.id = this.id;
      entity.remittance_address_row_id = this.id;
    }

    return entity;
  }
}
