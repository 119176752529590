export class UserInfo {
  emailAddress: string = '';
  userIdentifier: string = '';
  presentationName: string = '';
  nameInitials: string = '';
  userRowId: number = 0;
  authStrategy: string = '';
  preferredFolder: string = '';
  preferredDashboard: string = '';
  preferredBudgetDashboard: string = '';
  cloudadmin_client_id: string = '';
  cloudadmin_access_token: string = '';
  auth_token: string = '';
  first_name: string = '';
  last_name: string = '';
}

export class AppInformation {
  releaseVersion: string = '';
  buildDate: string = '';
  buildVersion: string = '';
}

export class TenantInfo {
  tenantName: string = '';
  shortName: string = '';
  tenantRowId: number = 0;
  budgetEntity: string = '';
  isIaasOptimized: number = 0;
  isSaaS: boolean = false;
}
export class PartnerInfo {
  partnerName: string = '';
  partnerTenantId: string = '';
}

export class ApplicationInfo {
  name: string = '';
  icon: string = '';
  url: string = '';
}

export const sessionContext: {
  userInfo: UserInfo;
  tenantTheme: any;
  tenantInfo: TenantInfo;
  tenantList: TenantInfo[];
  permissions: string[];
  deniedPermissions: string[];
  userApps: ApplicationInfo[];
  loginSuccess: boolean;
  roleInfo: string[];
  partnerInfo: PartnerInfo;
  privilegePolicies: any;
  appInfo: AppInformation;
} = {
  userInfo: new UserInfo(),
  tenantTheme: {},
  tenantInfo: new TenantInfo(),
  tenantList: [] as TenantInfo[],
  permissions: [] as string[],
  deniedPermissions: [] as string[],
  userApps: [] as ApplicationInfo[],
  loginSuccess: false,
  roleInfo: [] as string[],
  partnerInfo: new PartnerInfo(),
  privilegePolicies: [] as string[],
  appInfo: new AppInformation(),
};

export class SaaSMenuInfo {
  admin: boolean = false;
  integration_admin: boolean = false;
  it_admin: boolean = false;
  finance_admin: boolean = false;
  procurement_admin: boolean = false;
}
