import { Component, OnInit, ElementRef } from '@angular/core';
import { GoToasterService, GoModalService } from '@tangoe/goponents';
import { BaseModalListComponent } from '../../base/base-modal-list.component';
import { UserService } from '../user.service';
import { User } from '../user';

@Component({
  selector: 'app-assign-role-to-user-modal',
  templateUrl: './assign-role-to-user-modal.component.html',
  styleUrls: ['./assign-role-to-user-modal.component.scss'],
})
export class AssignRoleToUserModalComponent extends BaseModalListComponent<User, 'user'> {
  constructor(
    protected toasterService: GoToasterService,
    protected userService: UserService,
    protected modalService: GoModalService,
    protected el: ElementRef
  ) {
    super(toasterService, userService, modalService, el);
  }

  linkedEntity(): string {
    return 'user_tenant_role';
  }

  primaryEntity(): string {
    return 'user';
  }

  otherEntity(): string {
    return 'role';
  }

  otherEntitySearch(): string {
    return 'name,description';
  }

  otherEntitySort(): string {
    return 'name';
  }
}
