import { Component, OnInit, ElementRef } from '@angular/core';
import { GoToasterService, GoModalService } from '@tangoe/goponents';
import { BaseModalListComponent } from '../../base/base-modal-list.component';
import { ContractService } from '../contract.service';
import { Contract } from '../contract';

@Component({
  selector: 'app-assign-account-to-contract-modal',
  templateUrl: './assign-account-to-contract-modal.component.html',
  styleUrls: ['./assign-account-to-contract-modal.component.scss'],
})
export class AssignAccountToContractModalComponent extends BaseModalListComponent<Contract, 'contract'> {
  constructor(
    protected toasterService: GoToasterService,
    protected contractService: ContractService,
    protected modalService: GoModalService,
    protected el: ElementRef
  ) {
    super(toasterService, contractService, modalService, el);
  }

  linkedEntity(): string {
    return 'contract_account_ref';
  }

  primaryEntity(): string {
    return 'contract';
  }

  otherEntity(): string {
    return 'account';
  }

  otherEntitySearch(): string {
    return 'account_number,account_name';
  }

  otherEntitySort(): string {
    return 'account_number';
  }
}
