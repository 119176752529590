import { Component, Input, OnInit } from '@angular/core';
import { BaseFieldComponent } from '../field-base';

@Component({
  selector: 'app-field-input',
  templateUrl: './field-input.component.html',
  styleUrls: ['./field-input.component.scss'],
})
export class FieldInputComponent extends BaseFieldComponent implements OnInit {
  @Input() placeholder: string;
  ngOnInit() {
    super.ngOnInit();
    this.placeholder = this.placeholder || this.label;
  }
}
