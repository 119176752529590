import { ActivatedRouteSnapshot, CanActivate, Params, RouterStateSnapshot, UrlTree } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { AppConstants } from '../app-constants';
import { ISisenseUserAuthorized } from '../shared/models/sisense/ISisenseUserAuthorized';
import { SisenseApiService } from './sisense-api.service';
import { environment } from '../../environments/environment';
import { LogService } from '../logger/log.service';

@Injectable({
  providedIn: 'root',
})
export class CanActivateSisense implements CanActivate {
  constructor(private sisenseApiService: SisenseApiService, private logService: LogService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.savePreferredDashboard(route.queryParams);
    return this.sisenseApiService.isUserAuthorized().pipe(
      map((userAuthorized: ISisenseUserAuthorized) => {
        if (!userAuthorized.isAuthenticated) {
          if (sessionStorage.getItem('tenantSwitched')) {
            sessionStorage.removeItem('tenantSwitched');
          }
          this.logService.error(`CanActivate:: User is not authenicated on Sisense, hence redirecting to SSO.`);
          this.redirectToSSO();
          return false;
        }
        this.logService.info(`CanActivate:: User is successfully authenicated on Sisense. Proceeding further.`);

        return true;
      }),
      catchError(error => {
        this.logService.error(
          `CanActivate:: Error while trying to authenicated the user on Sisense, hence redirecting to SSO.`,
          error
        );
        if (sessionStorage.getItem('tenantSwitched')) {
          sessionStorage.removeItem('tenantSwitched');
        }
        this.redirectToSSO();
        return of(false);
      })
    );
  }

  redirectToSSO(): void {
    // Call the flush method to ensure old logs are flushed before reloading the page.
    this.logService.flushAll();

    window.open(environment.ssoLoginUrl, '_self');
  }

  /**
   * Possibility of dashboard id being passed in. It's apart of the CanActivate service because there is a chance the user might not
   * be logged in, so this will guarantee we can save their preference before they are redirected to SSO.
   */
  savePreferredDashboard(routeParams: Params): void {
    const preferredDashboardId = routeParams ? routeParams.dbid : null;

    if (preferredDashboardId) {
      localStorage.setItem(AppConstants.PREFERRED_DASHBOARD_LOCAL_STORAGE_KEY, preferredDashboardId);
    }
  }
}
