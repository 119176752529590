import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthenticationService } from '../authentication/authentication.service';
import { sessionContext } from '../authentication/session-details';
import { PermissionResourceMapping } from '../shared/models/authentication/PermissionResourceMapping';

@Injectable({
  providedIn: 'root',
})
export class CanActiveSaasOptimization implements CanActivate {
  constructor(private router: Router, private authenticationService: AuthenticationService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const saasOptimizationResource = this.getPermissionResourceForSaas(route.data.resource);
    const resource = this.translateResource(saasOptimizationResource, state.url);

    if (sessionContext.tenantInfo.isSaaS && this.authenticationService.hasAccess(resource)) {
      return true;
    } else {
      this.redirectToDefault();
      return false;
    }
  }

  translateResource(routeResource: string, targetUrl: string): string {
    let resource;
    if (
      routeResource === 'SAAS.ADMIN' ||
      routeResource === 'SAAS.VIEWER' ||
      routeResource === 'SAAS.OWNER' ||
      routeResource === 'SAAS.CUSTOMER' ||
      routeResource === 'SAAS.INTEGRATION_ADMIN' ||
      routeResource === 'SAAS.IT_ADMIN' ||
      routeResource === 'SAAS.FINANCE_ADMIN' ||
      routeResource === 'SAAS.PROCUREMENT_ADMIN'
    ) {
      resource = routeResource;
    } else {
      resource = 'NONE';
    }
    return resource;
  }

  redirectToDefault(): void {
    if (this.authenticationService.hasAccess(PermissionResourceMapping.CloudAnalyticsViewer)) {
      this.router.navigate(['dashboard']);
    } else {
      this.router.navigate(['home']);
    }
  }

  getPermissionResourceForSaas(resource) {
    let permission;
    permission = sessionContext.permissions.filter(s => s.includes(resource));
    if (permission.length > 0) {
      const filterResource = permission[0]
        .split('{')
        .join('')
        .split('}')[0];
      resource = filterResource ? filterResource : '';
    }
    return resource;
  }
}
