import { BaseEntity } from '../../base/base-entity';

/**
 * This class represent the InvoiceBilledAccount entity.
 */
export class InvoiceBilledAccount extends BaseEntity {
  invoice_billed_account_row_id: number;

  account_number: string;
  account_id: string;
  amount_value: { value: number; indicator: string };
  amount_currency: string;
  previous_invoice_amount_value: { value: number; indicator: string };
  diff_amount_value: { value: number; indicator: string };
  diff_amount_currency: string;
  in_discovery: string;
}
