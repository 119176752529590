import { Component, OnInit, ElementRef } from '@angular/core';
import { GoToasterService, GoModalService } from '@tangoe/goponents';
import { BaseModalListComponent } from '../../base/base-modal-list.component';
import { EmployeeService } from '../employee.service';
import { Employee } from '../employee';

@Component({
  selector: 'app-assign-group-to-employee-modal',
  templateUrl: './assign-group-to-employee-modal.component.html',
  styleUrls: ['./assign-group-to-employee-modal.component.scss'],
})
export class AssignGroupToEmployeeModalComponent extends BaseModalListComponent<Employee, 'employee'> {
  constructor(
    protected toasterService: GoToasterService,
    protected employeeService: EmployeeService,
    protected modalService: GoModalService,
    protected el: ElementRef
  ) {
    super(toasterService, employeeService, modalService, el);
  }

  linkedEntity(): string {
    return 'employee_group_membership';
  }

  primaryEntity(): string {
    return 'employee';
  }

  otherEntity(): string {
    return 'employee_group';
  }

  otherEntitySearch(): string {
    return 'email,name';
  }

  otherEntitySort(): string {
    return 'email';
  }
}
