import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import {
  GoHeaderModule,
  GoIconButtonModule,
  GoLayoutModule,
  GoSideNavModule,
  GoIconModule,
  GoCardModule,
  GoSearchModule,
  GoButtonModule,
  GoTableModule,
  GoLoaderModule,
  GoInputModule,
  GoActionSheetModule,
  GoAccordionModule,
  GoCheckboxModule,
  GoSelectModule,
  GoFooterModule,
  GoDatepickerModule,
  GoRadioModule,
  GoToasterModule,
  GoOffCanvasModule,
  GoToastModule,
} from '@tangoe/goponents';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GoPillModule } from './shared/components/go-pill/go-pill.module';
import { FilterOffCanvasComponent } from './shared/list/filter-off-canvas/filter-off-canvas.component';
import { ConfirmActionComponent } from './shared/confirm-action/confirm-action.component';
import { ConfirmInvoiceComponent } from './shared/confirm-invoice/confirm-invoice.component';
import { ListModule } from './shared/list/list.module';
import { ErrorInterceptorService } from './base/interceptor-service';
import { FieldTypesModule } from './shared/field-types/field-types.module';
import { AssignEmployeeToGroupModalComponent } from './employee-group/assign-employee-to-group-modal/assign-employee-to-group-modal.component';
import { AssignCostCenterToEmployeeModalComponent } from './employee/assign-cost-center-to-employee-modal/assign-cost-center-to-employee-modal.component';
import { AssignGroupToEmployeeModalComponent } from './employee/assign-group-to-employee-modal/assign-group-to-employee-modal.component';
import { ManageAddressForEmployeeModalComponent } from './employee/manage-address-for-employee-modal/manage-address-for-employee-modal.component';
import { AssignEmployeeToCostCenterModalComponent } from './cost-center/assign-employee-to-cost-center-modal/assign-employee-to-cost-center-modal.component';
import { AssignUserToGroupModalComponent } from './user-group/assign-user-to-group-modal/assign-user-to-group-modal.component';
import { ManageVendorRemitAddressModalComponent } from './vendor/manage-vendor-remit-address-modal/manage-vendor-remit-address-modal.component';
import { ManageVendorRemitCodeModalComponent } from './vendor/manage-vendor-remit-code-modal/manage-vendor-remit-code-modal.component';
import { ManageVendorCodeModalComponent } from './vendor/manage-vendor-code-modal/manage-vendor-code-modal.component';
import { AssignAccountToContractModalComponent } from './contract/assign-account-to-contract-modal/assign-account-to-contract-modal.component';
import { AssignRoleToUserModalComponent } from './user/assign-role-to-user-modal/assign-role-to-user-modal.component';
import { AssignGroupToUserModalComponent } from './user/assign-group-to-user-modal/assign-group-to-user-modal.component';
import { ManageAddressForLocationModalComponent } from './location/manage-address-for-location-modal/manage-address-for-location-modal.component';
import { CommonPipesModule } from './shared/common-pipes/common-pipes.module';
import { ManageVendorNoteModalComponent } from './vendor/manage-vendor-note-modal/manage-vendor-note-modal.component';
import { AllocationStringPartModalComponent } from './allocation-string-format/allocation-string-part-modal/allocation-string-part-modal.component';
import { ManageGrantPrivilegesModalComponent } from './privilege-policy/manage-grant-privileges-modal/manage-grant-privileges-modal.component';
import { AssignPrivilegePolicyToRoleModalComponent } from './role/assign-privilege-policy-to-role-modal/assign-privilege-policy-to-role-modal.component';
import { AllocationStringModalComponent } from './allocation-info/allocation-string-modal/allocation-string-modal.component';
import { AllocationAmountModalComponent } from './allocation-info/allocation-amount-modal/allocation-amount-modal.component';
import { ManageAssociatedRecurringInvoicesModalComponent } from './recurring-invoice/manage-associated-recurring-invoices-modal/manage-associated-recurring-invoices-modal.component';
import { ManageRemittanceInfoModalComponent } from './shared/remittance-info/manage-remittance-info-modal/manage-remittance-info-modal.component';
import { ManageContactForTenantModalComponent } from './tenant/manage-contact-for-tenant-modal/manage-contact-for-tenant-modal.component';
import { ManagePortalForTenantModalComponent } from './tenant/manage-portal-for-tenant-modal/manage-portal-for-tenant-modal.component';
import { ManagePlatformForTenantModalComponent } from './tenant/manage-platform-for-tenant-modal/manage-platform-for-tenant-modal.component';
import { SisenseAuthInterceptorService } from './service/sisense-auth-interceptor.service';
import { TenantSwitcherComponent } from './shared/tenant-switcher/tenant-switcher.component';
import { PostLoginModule } from './post-login/post-login.module';
import { AppShellModule } from './app-shell/app-shell.module';
import { InvoiceBilledAccountModalComponent } from './invoice-header/invoice-billed-account-modal/invoice-billed-account-modal.component';
import { InvoiceBilledAssetModalComponent } from './invoice-header/invoice-billed-asset-modal/invoice-billed-asset-modal.component';
import { BilledChargeModalComponent } from './invoice-header/billed-charge-modal/billed-charge-modal.component';
import { InvoiceSummaryByChargeTypeModalComponent } from './invoice-header/invoice-summary-by-charge-type-modal/invoice-summary-by-charge-type-modal.component';
import { AllocationInfoModule } from './allocation-info/allocation-info.module';
import { InvoiceHeaderEditComponent } from './invoice-header/invoice-header-edit/invoice-header-edit.component';
import { CurrentChargeModalComponent } from './invoice-header/current-charge-modal/current-charge-modal.component';
import { NonCurrentChargeModalComponent } from './invoice-header/non-current-charge-modal/non-current-charge-modal.component';
import { TaxSummaryModalComponent } from './invoice-header/tax-summary-modal/tax-summary-modal.component';
import { InvoiceUsageDetailModalComponent } from './invoice-header/invoice-usage-detail-modal/invoice-usage-detail-modal.component';
import { InvoiceUsageDataModalComponent } from './invoice-header/invoice-usage-data-modal/invoice-usage-data-modal.component';
import { RedistributedChargeModalComponent } from './invoice-header/redistributed-charge-modal/redistributed-charge-modal.component';
import { AllocationResultDetailViewModalComponent } from './invoice-header/allocation-result-detail-view-modal/allocation-result-detail-view-modal.component';
import { AllocationAdjustmentModalComponent } from './invoice-header/allocation-adjustment-modal/allocation-adjustment-modal.component';
import { InvoiceEditModalComponent } from './invoice/invoice-edit-modal/invoice-edit-modal.component';
import { AllocationStatusHistoryModalComponent } from './invoice-header/allocation-status-history-modal/allocation-status-history-modal.component';
import { ManageApproverModalComponent } from './shared/embedded-list/approver-list/manage-approver-modal/manage-approver-modal.component';
import { AuthenticationService } from './authentication/authentication.service';
import { AuthenticationLocalService } from './authentication/authentication-local.service';
import { environment } from 'src/environments/environment';
import { WorkflowReasonModalComponent } from './invoice/invoice-detail/reason-modal/reason-modal.component';
import { WorkflowStepModalComponent } from './invoice/invoice-detail/step-modal/step-modal.component';
import { AboutModalComponent } from './about-modal/about-modal.component';
import { CustomRouteReuseStrategy } from './shared/customRouteReuseStrategy';
import { RouteReuseStrategy } from '@angular/router';
import { ExportModalModule } from './shared/export-modal/export-modal.module';
import { ErrorPageComponent } from './error-page/error-page.component';
@NgModule({
  declarations: [
    AppComponent,
    ManageAddressForEmployeeModalComponent,
    ManageVendorRemitAddressModalComponent,
    ManageVendorRemitCodeModalComponent,
    ManageVendorCodeModalComponent,
    ManageAddressForLocationModalComponent,
    ManageVendorNoteModalComponent,
    ManageGrantPrivilegesModalComponent,
    ManageAssociatedRecurringInvoicesModalComponent,
    ManageRemittanceInfoModalComponent,
    ManageContactForTenantModalComponent,
    ManagePortalForTenantModalComponent,
    ManagePlatformForTenantModalComponent,
    AssignPrivilegePolicyToRoleModalComponent,
    AssignCostCenterToEmployeeModalComponent,
    AssignGroupToEmployeeModalComponent,
    AssignEmployeeToGroupModalComponent,
    AssignEmployeeToCostCenterModalComponent,
    AssignUserToGroupModalComponent,
    AssignRoleToUserModalComponent,
    AssignGroupToUserModalComponent,
    AssignAccountToContractModalComponent,
    ConfirmActionComponent,
    ConfirmInvoiceComponent,
    AllocationStringPartModalComponent,
    AllocationStringModalComponent,
    AllocationAmountModalComponent,
    TenantSwitcherComponent,
    InvoiceBilledAccountModalComponent,
    InvoiceBilledAssetModalComponent,
    BilledChargeModalComponent,
    InvoiceSummaryByChargeTypeModalComponent,
    CurrentChargeModalComponent,
    NonCurrentChargeModalComponent,
    RedistributedChargeModalComponent,
    TaxSummaryModalComponent,
    InvoiceUsageDetailModalComponent,
    InvoiceUsageDataModalComponent,
    AllocationResultDetailViewModalComponent,
    AllocationAdjustmentModalComponent,
    InvoiceEditModalComponent,
    AllocationStatusHistoryModalComponent,
    ManageApproverModalComponent,
    WorkflowReasonModalComponent,
    WorkflowStepModalComponent,
    AboutModalComponent,
    ErrorPageComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    PostLoginModule,
    AppShellModule,
    GoHeaderModule,
    GoIconButtonModule,
    GoLayoutModule,
    GoSideNavModule,
    GoIconModule,
    GoCardModule,
    GoOffCanvasModule,
    GoSearchModule,
    GoButtonModule,
    GoTableModule,
    GoLoaderModule,
    GoInputModule,
    GoActionSheetModule,
    GoAccordionModule,
    GoCheckboxModule,
    GoSelectModule,
    GoFooterModule,
    GoDatepickerModule,
    GoRadioModule,
    GoToasterModule,
    GoPillModule,
    GoOffCanvasModule,
    GoPillModule,
    GoToastModule,
    ListModule,
    FieldTypesModule,
    CommonPipesModule,
    AllocationInfoModule,
    ExportModalModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SisenseAuthInterceptorService,
      multi: true,
    },
    {
      provide: AuthenticationService,
      useClass: environment.isLocal ? AuthenticationLocalService : AuthenticationService,
    },
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    FilterOffCanvasComponent,
    ManageAddressForEmployeeModalComponent,
    ManageVendorRemitAddressModalComponent,
    ManageVendorRemitCodeModalComponent,
    ManageVendorCodeModalComponent,
    ManageAddressForLocationModalComponent,
    ManageVendorNoteModalComponent,
    ManageGrantPrivilegesModalComponent,
    ManageAssociatedRecurringInvoicesModalComponent,
    ManageRemittanceInfoModalComponent,
    ManageContactForTenantModalComponent,
    ManagePortalForTenantModalComponent,
    ManagePlatformForTenantModalComponent,
    AssignPrivilegePolicyToRoleModalComponent,
    AssignCostCenterToEmployeeModalComponent,
    AssignGroupToEmployeeModalComponent,
    AssignEmployeeToGroupModalComponent,
    AssignEmployeeToCostCenterModalComponent,
    AssignUserToGroupModalComponent,
    AssignRoleToUserModalComponent,
    AssignGroupToUserModalComponent,
    AssignAccountToContractModalComponent,
    ConfirmActionComponent,
    ConfirmInvoiceComponent,
    AllocationStringPartModalComponent,
    AllocationStringModalComponent,
    AllocationAmountModalComponent,
    TenantSwitcherComponent,
    InvoiceBilledAccountModalComponent,
    InvoiceBilledAssetModalComponent,
    BilledChargeModalComponent,
    InvoiceSummaryByChargeTypeModalComponent,
    CurrentChargeModalComponent,
    NonCurrentChargeModalComponent,
    RedistributedChargeModalComponent,
    TaxSummaryModalComponent,
    InvoiceUsageDetailModalComponent,
    InvoiceUsageDataModalComponent,
    AllocationResultDetailViewModalComponent,
    AllocationAdjustmentModalComponent,
    InvoiceEditModalComponent,
    AllocationStatusHistoryModalComponent,
    ManageApproverModalComponent,
    WorkflowReasonModalComponent,
    WorkflowStepModalComponent,
  ],
})
export class AppModule {}
