import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  GoAccordionModule,
  GoActionSheetModule,
  GoButtonModule,
  GoFileUploadModule,
  GoIconButtonModule,
  GoIconModule,
  GoInputModule,
  GoSelectModule,
  GoTableModule,
  GoToastModule,
} from '@tangoe/goponents';
import { FieldTypesModule } from '../../field-types/field-types.module';
import { AllocationConfigurationEditComponent } from './allocation-configuration-edit/allocation-configuration-edit.component';
import { AllocationConfigurationComponent } from './allocation-configuration.component';
import { AllocationConfigurationCreateComponent } from './allocation-configuration-create/allocation-configuration-create.component';

@NgModule({
  declarations: [
    AllocationConfigurationEditComponent,
    AllocationConfigurationComponent,
    AllocationConfigurationCreateComponent,
  ],
  imports: [
    CommonModule,
    GoButtonModule,
    GoFileUploadModule,
    GoTableModule,
    GoInputModule,
    GoIconButtonModule,
    GoActionSheetModule,
    GoSelectModule,
    FieldTypesModule,
    GoIconModule,
    GoAccordionModule,
    GoToastModule,
  ],
  exports: [AllocationConfigurationComponent],
})
export class AllocationConfigurationModule {}
