import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EmployeeAddress } from './manage-address-for-employee';
import { BaseService } from '../../base/base-service';
import { SimpleFilter } from '../../base/base-filter';
import { environment } from '../../../environments/environment';
import { Result, DeleteResult, CountResult } from '../../base/result';
import { Observable } from 'rxjs';
import { QueryParam } from '../../base/common';

@Injectable({
  providedIn: 'root',
})
export class ManageAddressForEmployeeService extends BaseService<EmployeeAddress, 'employee_address'> {
  constructor(http: HttpClient) {
    super(http);
  }

  getServiceUrl(): string {
    return environment.masterDataUrl;
  }

  getMetaData(): any {
  }

  getBaseUrl(): string {
    return this.getServiceUrl() + '/employeeAddresses';
  }

  // ************************************ START:CUSTOM METHODS **************************

  // Custom method to be used by search and select for Country.
  findCountries(q?: string, include?: string, exclude?: string, filter?: string): Observable<Result<'country', any[]>> {
    const simpleFilter = new SimpleFilter('name', q, include, exclude);
    const queryParam = new QueryParam();
    queryParam.perPage = 100;
    queryParam.sortAttr = 'name';

    return this.listExternalEntities(environment.extEnterpriseDataUrl + '/countries/list', simpleFilter, queryParam);
  }

  // Custom method to be used by search and select for StateProvinceRegion.
  findStateProvinceRegions(
    q?: string,
    include?: string,
    exclude?: string,
    filter?: string
  ): Observable<Result<'state_province', any[]>> {
    return super.stateProvinceCustomLookup(q, include, exclude, filter);
  }

  // Custom method to be used by dynamic list values for Employee Address Type.
  findEmployeeAddressTypeListValues(): Observable<Result<'dynamic_list_value', any[]>> {
    return this.fetchDynamicListValues('Employee Address Type');
  }

  // ************************************ END:CUSTOM METHODS **************************

  // ************************************ START:FETCH CHILD METHODS **************************

  // ************************************ END:FETCH CHILD METHODS **************************

  // ************************************ START:COUNT METHODS **************************

  // ************************************ END:COUNT METHODS **************************
}
