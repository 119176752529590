import { Location } from '@angular/common';
import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit {
  @Input() pageTitle: string;
  @Input() showBackArrow = true;
  @Input() defaultGoBackRoute: string;
  @Input() forceNavigateURL: string;
  /**
   * This is used to pass query params to the route when navigating back
   * using this we can pass custom query params to the route as and when required
   */
  @Input() queryParams: any;

  @Output() handleBackButton: EventEmitter<any>;
  @Input() stickyHeader = false;
  @ContentChild('buttonTemplate') buttonTemplate: TemplateRef<any>;
  constructor(private location: Location, private router: Router) { }
  goBack(): void {
    if (this.forceNavigateURL) {
      this.router.navigate([this.forceNavigateURL], {queryParams: this.queryParams});
      return;
    }

    if (window.history.length > 1) {
      this.location.back();
    } else if (this.defaultGoBackRoute) {
      this.router.navigate([this.defaultGoBackRoute]);
    }
  }

  backButtonDisabled(): boolean {
    return window.history.length === 1 && !this.defaultGoBackRoute;
  }
  ngOnInit(): void {
  }

}
