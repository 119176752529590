import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EmployeeGroup } from './employee-group';

import { EmployeeGroupMembership } from './employee-group';

import { BaseService } from '../base/base-service';
import { SimpleFilter } from '../base/base-filter';
import { environment } from '../../environments/environment';
import { Result, DeleteResult, CountResult } from '../base/result';
import { Observable } from 'rxjs';
import { QueryParam } from '../base/common';
import { metaData } from './employeeGroup-metadata';

@Injectable({
  providedIn: 'root',
})
export class EmployeeGroupService extends BaseService<EmployeeGroup, 'employee_group'> {
  constructor(http: HttpClient) {
    super(http);
  }

  getServiceUrl(): string {
    return environment.masterDataUrl;
  }

  getMetaData(): any {
    return metaData;
  }

  getBaseUrl(): string {
    return this.getServiceUrl() + '/employeeGroups';
  }

  // ************************************ START:CUSTOM METHODS **************************

  // ************************************ END:CUSTOM METHODS **************************

  // ************************************ START:FETCH CHILD METHODS **************************

  // Methods to handle child records for Assign Employee
  findChildEmployeeGroupMembershipRecords(
    parentId: number,
    queryParam: QueryParam,
    q?: string
  ): Observable<Result<'employee_group_membership', any[]>> {
    const simpleFilter = new SimpleFilter('employee.employee_id,employee.first_name,employee.last_name', q);

    return this.listExternalEntities(
      environment.masterDataUrl + `/employeeGroups/${parentId}/employeeGroupMemberships`,
      simpleFilter,
      queryParam
    );
  }

  deleteChildEmployeeGroupMembershipRecordsById(ids: string, parentId?: string): Observable<DeleteResult> {
    return this.deleteExternalEntitiesById(environment.masterDataUrl + '/employeeGroupMemberships', ids);
  }

  findUnassignedEmployeeGroupMembershipRecords(
    parentId: number,
    queryParam: QueryParam,
    q?: string
  ): Observable<Result<'employee', any[]>> {
    const simpleFilter = new SimpleFilter('employee_id,first_name,last_name', q);

    return this.listExternalEntities(
      environment.masterDataUrl + `/employees/employeeGroupMemberships/unassigned/${parentId}`,
      simpleFilter,
      queryParam
    );
  }

  insertAssignmentEmployeeGroupMembershipRecords(
    assignments: any[],
    ids?: string,
    parentId?: string
  ): Observable<any[]> {
    return this.insertExternalEntities(environment.masterDataUrl + `/employeeGroupMemberships`, assignments);
  }

  // ************************************ END:FETCH CHILD METHODS **************************

  // ************************************ START:COUNT METHODS **************************

  // ************************************ END:COUNT METHODS **************************
}
