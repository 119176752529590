import { Component, OnInit, Input } from '@angular/core';
import { GoModalService } from '@tangoe/goponents';
import { BaseModal } from 'src/app/base/base-modal';
@Component({
  selector: 'app-confirm-action',
  templateUrl: './confirm-action.component.html',
  styleUrls: ['./confirm-action.component.scss'],
})
export class ConfirmActionComponent extends BaseModal implements OnInit {
  @Input() message: string;
  @Input() question: string;
  @Input() cancelAction: string;
  @Input() confirmAction: string;
  @Input() appendTo: string;
  @Input() confirmCallback: () => void;
  @Input() cancelCallback: () => void;

  constructor(protected modalService: GoModalService) {
    super(modalService);
  }

  ngOnInit() {}

  cancel() {
    this.cancelCallback ? this.cancelCallback() : this.defaultCallback();
  }

  confirm() {
    this.confirmCallback ? this.confirmCallback() : this.defaultCallback();
  }

  defaultCallback() {
    this.modalService.toggleModal(false);
  }
}
