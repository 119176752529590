import { Component, Input, OnInit } from '@angular/core';
import { DynamicListService } from './dynamiclist.service';
import { BaseFieldComponent } from '../field-base';

@Component({
  selector: 'app-field-dynamiclist',
  templateUrl: './field-dynamiclist.component.html',
  styleUrls: ['./field-dynamiclist.component.scss'],
})
export class FieldDynamicListComponent extends BaseFieldComponent implements OnInit {
  @Input() listName: string;
  @Input() clearable: boolean = true;

  items: any[];
  loading: boolean = false;
  placeholder: string;

  constructor(protected dynamicListService: DynamicListService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadDynamicListValues();
  }

  loadDynamicListValues() {
    this.loading = true;
    this.dynamicListService.fetchDynamicListValues(this.listName).subscribe(data => {
      this.items = data.dynamic_list_value;
      this.loading = false;
    });
  }
}
