import { BaseEntity } from '../../../../base/base-entity';

/**
 * This class represent the Approver entity.
 */
export class Approver extends BaseEntity {
  employees: { id: number; presentation_name: string };
  from_amount: { value: number; indicator: string };
  to_amount: { value: number; indicator: string };
  approver_type: { value_id: number; value?: string; dynamic_list_name?: string };
}
