import { AbstractControl, ValidationErrors, Validators, ValidatorFn } from '@angular/forms';
import { isNumber } from 'util';

export class CustomValidators {
  static hasContent(control: AbstractControl): ValidationErrors | null {
    const hasContent = (control.value || '').trim().length > 0;
    return hasContent ? null : { hasContent: false, message: 'This cannot be blank' };
  }

  static percentage(control: AbstractControl): ValidationErrors | null {
    const DIGITS: RegExp = /^[0-9]+$/i;

    if (!control.value) {
      return null;
    }

    if (!DIGITS.test(control.value)) {
      return { digits: false, message: 'This accepts only digits.' };
    }

    const num = control.value.toString().replace(/[ ]/g, '');

    if (Number(num) > 100 || Number(num) < 0) {
      return {
        currencyrange: false,
        message: 'This value is outside the range 0 and 100',
      };
    }

    return null;
  }

  static alphanumeric(control: AbstractControl): ValidationErrors | null {
    const ALPHANUMERIC: RegExp = /^[a-z0-9]+$/i;

    if (!ALPHANUMERIC.test(control.value)) {
      return { currency: false, message: `This in an alpha-numeric field.` };
    }

    return null;
  }

  static date(control: AbstractControl): ValidationErrors | null {
    const DATE_FORMAT: RegExp = /^\d{1,2}\/\d{1,2}\/\d{4}$/;

    // First check for the pattern mm/dd/yyyy
    if (!DATE_FORMAT.test(control.value)) {
      return { date: false, message: `This is not a valid date format.` };
    }

    // Now break the input and check for the parts.
    const parts: string[] = control.value.split('/');

    const month: number = Number(parts[0]);
    const day: number = Number(parts[1]);
    const year: number = Number(parts[2]);

    if (year < 1900 || year > 2600 || month === 0 || month > 12) {
      return { date: false, message: `This is not a valid date format.` };
    }

    const daysInEachMonth: number[] = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    // Adjust for leap years
    if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
      daysInEachMonth[1] = 29;
    }

    if (day < 1 || day > daysInEachMonth[month - 1]) {
      return { date: false, message: `This is not a valid date format.` };
    }

    return null;
  }

  static currency(control: AbstractControl): ValidationErrors | null {
    const CURRENCY: RegExp = /^[$]?[\s+]?[-]?[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$/;

    if (!control.value) {
      return null;
    }

    if (!CURRENCY.test(control.value)) {
      return { currency: false, message: 'This is not a valid currency format.' };
    }

    const num = control.value.replace(/[, $]/g, '');

    if (Number(num) > 999999999.99 || Number(num) < -999999999.99) {
      return {
        currencyrange: false,
        message: 'This value is outside the range -$999,999,999.99 and $999,999,999.99',
      };
    }

    return null;
  }

  static digits(control: AbstractControl): ValidationErrors | null {
    const DIGITS: RegExp = /^[0-9]+$/i;

    if (!control.value) {
      return null;
    }

    if (!DIGITS.test(control.value)) {
      return { digits: false, message: 'This accepts only digits.' };
    }

    return null;
  }

  static zipcode(control: AbstractControl): ValidationErrors | null {
    return null; // TODO later
  }

  static phoneNumber(control: AbstractControl): ValidationErrors | null {
    return null; // TODO later
  }

  static alpha(control: AbstractControl): ValidationErrors | null {
    const ALPHA: RegExp = /^[a-z]+$/i;

    if (!ALPHA.test(control.value)) {
      return { alpha: false, message: 'This accepts only alpha characters.' };
    }

    return null;
  }

  static translateError(errors: any, field?: string): [{}] {
    let msg: string;
    field = field || 'This';

    if (errors.required) {
      msg = `${field} is a required field.`;
    } else if (errors.minlength) {
      msg = `${field} must have minimum ${errors.minlength.requiredLength} characters.`;
    } else if (errors.maxlength) {
      msg = `${field} cannot have more than ${errors.maxlength.requiredLength} characters.`;
    } else if (errors.min) {
      msg = `${field} value cannot be less than ${errors.min.min}.`;
    } else if (errors.max) {
      msg = `${field} value cannot be more than ${errors.max.max}.`;
    } else if (errors.email) {
      msg = `${field} must be a valid email format.`;
    } else if (errors.pattern) {
      msg = `${field} is an invalid input`;
    } else if (errors.message) {
      msg = errors.message.replace('This', field); // All custom validators must have a message property.
    } else if (Array.isArray(errors)) {
      msg = errors[0].message; // Fallback to the previously set errors object.
    }

    return [
      {
        type: 'Error:',
        message: msg,
      },
    ];
  }
}
