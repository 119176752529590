import { BaseEntity } from '../../base/base-entity';
import { Money } from '../../base/common';

export class AllocationString extends BaseEntity {
  allocation_string_row_id: number;

  string_code: string = '';
  string_name: string = '';
  amount: Money;
  percentage: number;
}
