import { Component, OnInit, ViewChild } from '@angular/core';
import { GoToasterService, GoTableComponent, GoModalService } from '@tangoe/goponents';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BaseModalEditComponent } from '../../base/base-modal-edit.component';
import { Result } from '../../base/result';
import { ItemSelector, EmbeddedTableContext } from '../../base/common';
import * as utils from '../../base/utils';
import { CustomValidators } from '../../base/validators';
import { BilledChargeService } from './billed-charge.service';
import { BilledCharge } from './billed-charge';

@Component({
  selector: 'app-billed-charge-modal',
  templateUrl: './billed-charge-modal.component.html',
  styleUrls: ['./billed-charge-modal.component.scss'],
})
export class BilledChargeModalComponent extends BaseModalEditComponent<BilledCharge, 'billed_charge'>
  implements OnInit {
  constructor(
    public billedChargeService: BilledChargeService,
    protected toasterService: GoToasterService,
    protected modalService: GoModalService,
    protected router: Router,
    protected route: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {
    super(billedChargeService, toasterService, modalService, router, route);
    this.listContextPath = '/billed-charges';
    this.editContextPath = '/billed-charges';
    this.entityLocator = 'billed_charge';
  }

  ngOnInit() {
    this.loadRecord();
  }

  populateForm(): void {
    this.formData = this.formBuilder.group({});
  }

  extractFormData(): BilledCharge {
    const entity: BilledCharge = new BilledCharge();

    const {} = this.formData.value;

    if (this.isAddMode) {
      entity.addAssociation('invoice_header', 'invoice_header_row_id', this.parentId);
    } else {
      entity.id = this.id;
      entity.billed_charge_row_id = this.id;
    }

    return entity;
  }
}
