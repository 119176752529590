import { BaseEntity } from '../base/base-entity';

/**
 * This class represent the AllocationInfo entity.
 */
export class AllocationInfo extends BaseEntity {
  allocation_info_row_id: number | string;

  string_format: {
    id: number;
    name: string;
    separator: string;
  };
  allocation_type: string;
  allocation_method: string;
  allocation_charge_elements: string | string[];
  remain_amt_config: string;
  remain_amt_string: string;
}

// Class representing AllocationString  Child entity.
export class AllocationString extends BaseEntity {
  allocation_string_row_id: number;

  string_code: string;
  string_name: string;
  amount: string;
  percentage: string;
  alloc_string: { id: number };
}
