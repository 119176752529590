import { GoModalService } from '@tangoe/goponents';
import { Input, AfterViewInit, Component } from '@angular/core';

@Component({
  template: '',
})

// tslint:disable-next-line:component-class-suffix
export abstract class BaseModal implements AfterViewInit {
  @Input() hideCloseButton: boolean = false;

  constructor(protected modalService: GoModalService) {
    this.preventClosureWhenClickedOutsideModal();
  }

  ngAfterViewInit(): void {
    this.handleCloseButtonDisplay();
  }

  preventClosureWhenClickedOutsideModal() {
    const base = document.querySelector('body');
    const that = this;
    base.addEventListener('click', event => {
      if (event.target['className'] === 'go-modal') {
        that.modalService.toggleModal(true);
      }
    });
  }

  handleCloseButtonDisplay() {
    const element = document.getElementsByClassName('go-modal__close')[0];
    if (this.hideCloseButton) {
      element['style'].display = 'none';
    } else {
      element['style'].display = 'block';
    }
  }
}
