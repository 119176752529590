import { BaseEntity } from '../../base/base-entity';

/**
 * This class represent the AllocationStringPart entity.
 */
export class AllocationStringPart extends BaseEntity {
  allocation_string_part_row_id: number;
  allow_any_value: boolean;
  field_mapping: string;
  length: number;
  place_holder_string: string;
  position: number;
}
