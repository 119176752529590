import { BaseEntity } from '../base/base-entity';

/**
 * This class represent the Vendor entity.
 */
export class Vendor extends BaseEntity {
  vendor_row_id: number;

  name: string;
  country: { id: number; name: string };
  master_vendor: { id: number; name: string };
  short_name: string;
  reference_code: string;
  reporting_name: string;
  in_discovery: string;
  supports_unbilled_usage: string;
  reset_unbilled_usage_daily: string;
  unbilled_usage_reset_days: string;
  sms_email_suffix: string;
}

// Class representing RemittanceAddress  Child entity.
export class RemittanceAddress extends BaseEntity {
  remittance_address_row_id: number;

  address_line1: string;
  address_line2: string;
  address_line3: string;
  city: string;
  country: { id: number; name: string };
  state_province_region: string;
  zip_postal_code: string;
  payment_type: { value_id: number; value?: string; dynamic_list_name?: string };
}

// Class representing VendorRemittanceCode  Child entity.
export class VendorRemittanceCode extends BaseEntity {
  vendor_remittance_code_row_id: number;

  remittance_address: { id: number; address_line1: string };
  vendor_remittance_code: string;
}

// Class representing TenantVendorCode  Child entity.
export class TenantVendorCode extends BaseEntity {
  tenant_vendor_code_row_id: number;

  tenant_vendor_code: string;
}
