import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AboutResponse } from './about';
import { GoConfigService } from '@tangoe/goponents';

@Component({
  selector: 'app-about-modal',
  templateUrl: './about-modal.component.html',
  styleUrls: ['./about-modal.component.scss'],
})
export class AboutModalComponent implements OnInit {
  buildDate: string = environment.buildDate;
  revision: string = environment.revision;
  textColor: any;
  @Input() buildInfo: AboutResponse;

  constructor(private goConfigService: GoConfigService) {
    const CONFIGLOC = this.goConfigService.getConfig();
    this.textColor = CONFIGLOC.brandColor;
  }

  ngOnInit(): void {}

  navigateToPrivacypolicy(): void {
    window.open(environment.privacyPolicyUrl, '_blank');
  }
}
