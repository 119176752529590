import { Component, Input, OnInit } from '@angular/core';
import { isObservable, Observable } from 'rxjs';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-filter-select',
  templateUrl: './filter-select.component.html',
  styleUrls: ['./filter-select.component.scss'],
})
export class FilterSelectComponent implements OnInit {
  @Input() name: string;
  @Input() label: string;
  @Input() items: { name: string; id: string }[];
  @Input() bindValue = 'id';
  @Input() bindLabel = 'name';
  @Input() loading = false;

  placeholder: string;

  control = new FormControl();
  isObservable = isObservable;

  constructor() {}

  ngOnInit() {
    this.placeholder = `Select ${this.label}`;
  }
}
