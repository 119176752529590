import { throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '../base/base-service';
import { IaasOptimization, UserRecord } from './iaas-optimization';
import { environment } from '../../environments/environment';
import { sessionContext } from '../authentication/session-details';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class IaasOptimizationService extends BaseService<IaasOptimization, 'iaas_optimization'> {
  clientId = sessionContext.userInfo.cloudadmin_client_id;
  userEmail = sessionContext.userInfo.emailAddress;
  sessionKey: string;
  sessionId: string;

  constructor(http: HttpClient, private authenticationService: AuthenticationService) {
    super(http);
    this.sessionId = environment.x_tngo_direct_cookie || sessionContext.userInfo.auth_token;
  }

  getServiceUrl(): string {
    return environment.iaasOptimizationUrl;
  }

  getBaseUrl(): string {
    return this.getServiceUrl() + '/v2';
  }

  getMetaData(): any {
  }

  removeCloudadminAccessToken(): void {
    // Remove IAAS token from localStorage
    const cloudadminAccessToken = localStorage.getItem('cloudadminAccessToken');
    if (cloudadminAccessToken) {
      localStorage.removeItem('cloudadminAccessToken');
    }
  }

  updateTenantInCloudAdmin(selectedTenant, selectedUserRecord) {
    const accessToken = localStorage.getItem('cloudadminAccessToken');
    const userRole = selectedUserRecord ? this.authenticationService.getRoleNameBasedOnUser(selectedUserRecord) : '';
    if (accessToken) {
      const url = this.getBaseUrl() + '/tangoe/user';
      let params: any = {};

      const userDetails = new UserRecord();
      const fullName = sessionContext.userInfo.presentationName.split(' ');
      const tenantList = sessionContext.tenantList;
      const tenantInfo = sessionContext.tenantInfo;
      const allTenants = [];

      for (const tenant of tenantList) {
        allTenants.push({ short_name: tenant.shortName, id: tenant.tenantRowId, selected: false });
      }
      allTenants.push({ short_name: tenantInfo.shortName, id: tenantInfo.tenantRowId, selected: false });

      for (const t of allTenants) {
        if (t.short_name === selectedTenant.shortName) {
          t.selected = true;
          t.role = userRole;
        }
      }

      userDetails.email = sessionContext.userInfo.emailAddress;
      userDetails.user_id = sessionContext.userInfo.userRowId;
      userDetails.first_name = fullName[0] ? fullName[0] : '';
      userDetails.last_name = fullName[1] ? fullName[1] : '';
      userDetails.tenants = allTenants;
      params = userDetails;
      params.email = this.userEmail;
      params.session_id = this.sessionId;

      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      });
      return this.http.put(url, params, {
        headers,
      });
    } else {
      return throwError('Cloud Admin Access Token is not available');
    }
  }

  findIaasToken(userDetails) {
    let params: any = {};
    params = userDetails;
    params.client_id = this.clientId;
    params.email = this.userEmail;
    params.session_id = this.sessionId;

    const headers = this.buildHeaders();

    const url = this.getBaseUrl() + '/tangoe/user/token';

    return this.http.post(url, params, {
      headers,
    });
  }

  validate(cloudadminAccessToken) {
    const validateUrl = `${this.getBaseUrl()}/token/validate`;

    const params: any = {};
    params.client_id = this.clientId;
    params.access_token = cloudadminAccessToken;

    const headers = this.buildHeaders();

    return this.http.post(validateUrl, params, {
      headers,
    });
  }

  // cloud admin server ping
  async checkCloudAdminServer() {
    const url = environment.cloudAdminHealthUrl;
    try {
      const response: any = await fetch(url, {
        method: 'GET',
        mode: 'no-cors',
      });
      // console.log(response);
      return true;
    } catch (error) {
      return false;
    }
  }

  // Build the Headers
  protected buildHeaders(): HttpHeaders {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return headers;
  }
}
