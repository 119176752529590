import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-edit-button',
  templateUrl: './edit-button.component.html',
  styleUrls: ['./edit-button.component.scss'],
})
export class EditButtonComponent implements OnInit {
  @Output() editRecordEvent = new EventEmitter();
  @ViewChild('editButton', { static: false }) template: TemplateRef<any>;
  constructor() {}

  ngOnInit() {}

  editRecord(editItem) {
    this.editRecordEvent.emit(editItem);
  }
}
