import { GoToasterService, GoModalService } from '@tangoe/goponents';
import { Router, ActivatedRoute } from '@angular/router';

import { BaseEntity } from './base-entity';
import { BaseService } from './base-service';
import { Result } from './result';
import { EmbeddedTableContext, ErrorItem } from './common';
import { FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from './validators';
import { Observable } from 'rxjs';
import 'rxjs/add/observable/of';
import { Input, AfterViewInit, Component } from '@angular/core';
import { BaseModal } from './base-modal';
import { ConfirmActionComponent } from '../shared/confirm-action/confirm-action.component';

/**
 * This component should be extended by any of the Add/Edit Pages.
 */
@Component({
  template: '',
})
export abstract class BaseModalEditComponent<T extends BaseEntity, K extends string> extends BaseModal
  implements AfterViewInit {
  constructor(
    protected entityService: BaseService<T, K>,
    protected toasterService: GoToasterService,
    protected modalService: GoModalService,
    protected router: Router,
    protected route: ActivatedRoute
  ) {
    super(modalService);
  }

  protected id: number;
  protected entity: T;

  protected listContextPath: string;
  protected editContextPath: string;
  protected entityLocator: string;

  public formData: FormGroup;
  public isTopLevel: boolean = false;

  public embeddedTableContexts: EmbeddedTableContext[] = [];
  public errors: ErrorItem = new ErrorItem();

  public isAddMode: boolean = false;

  public showButtonLoading: boolean = false;

  @Input() parentId: number;
  @Input() mode: string;
  @Input() rowData: any;
  @Input() appendTo: string;
  @Input() isParentReadOnly: boolean;
  @Input() modalCallback: (item?: any) => void;

  ngAfterViewInit() {
    super.ngAfterViewInit();

    const scrollPage = document.getElementsByClassName('go-modal__container-xl')[0];
    if (scrollPage) {
      scrollPage.scrollTop = 0;
    }
  }

  // ------------------ START: Cancel and go to List Page  --------------

  listRecords() {
    this.router.navigate([this.listContextPath]);
  }
  // ------------------ END: Cancel and go to List Page  --------------

  // ------------------ START: Read record ----------------------------
  abstract populateForm(): void;

  buildAnyStaticDropdowns(): void {}

  checkIfRecordIsTopLevel(): void {}

  checkNoRecordExists(): Observable<boolean> {
    return Observable.of(false);
  }

  loadRecord() {
    this.id = this.rowData ? this.rowData.id : 0;
    this.isAddMode = this.id === 0 ? true : false;

    this.entity = {} as T;
    this.populateForm();

    if (!this.isAddMode) {
      this.entityService.findById(this.id).subscribe(
        data => {
          if (this.extractEntity(data)) {
            this.entity = this.extractEntity(data);
            this.populateForm();
          } else {
            this.raiseReadError();
          }
        },
        error => {
          this.raiseReadError();
        }
      );
    }

    this.buildAnyStaticDropdowns();
  }
  // ------------------ START: Save record ----------------------------

  abstract extractFormData(): T;

  extractEntity(result: Result<K, T>): T {
    return result[this.entityLocator];
  }

  saveRecord() {
    this.showButtonLoading = true;

    if (!this.validateFormData()) {
      this.raiseValidationError();
      this.showButtonLoading = false;

      return;
    }

    this.entity = this.extractFormData();

    if (this.isAddMode) {
      this.entityService.insert(this.entity).subscribe(
        data => {
          this.entity = this.extractEntity(data);
          this.id = this.entity.id;

          this.clearErrors();
          this.showButtonLoading = false;

          this.raiseSaveSuccess();
          this.closeAndRefresh();
        },
        error => {
          this.showButtonLoading = false;
          this.raiseSaveError(error);
        }
      );
    } else {
      this.entityService.update(this.entity).subscribe(
        data => {
          this.clearErrors();
          this.showButtonLoading = false;

          this.entity = this.extractEntity(data);
          this.id = this.entity.id;

          this.raiseSaveSuccess();
          this.closeAndRefresh();
        },
        error => {
          this.showButtonLoading = false;
          this.raiseSaveError(error);
        }
      );
    }
  }
  // ------------------ END: Save record ------------------------------

  // ------------------ START: Validations ----------------------------

  validateFormData(): boolean {
    this.validateFormControls();
    return this.formData.valid;
  }

  validateFormControls() {
    Object.keys(this.formData.controls).forEach(controlKey => {
      if (this.formData.controls[controlKey].invalid) {
        this.formData.controls[controlKey].setErrors(
          CustomValidators.translateError(this.formData.controls[controlKey].errors)
        );
      }
    });
  }

  addOnlyControl(fieldValue: string | number, validators: Validators[]): any[] {
    if (this.isAddMode) {
      return [fieldValue, validators];
    } else {
      return [{ value: fieldValue, disabled: true }];
    }
  }

  readOnlyControl(fieldValue: string | number, validators: Validators[]): any[] {
    return [{ value: fieldValue, disabled: true }];
  }

  childOnlyControl(fieldValue: string | number, validators: Validators[]): any[] {
    if (this.isTopLevel) {
      return [{ value: fieldValue, disabled: true }];
    } else {
      return [fieldValue, validators];
    }
  }

  // ------------------ START: Raise various messages  --------------
  raiseSaveSuccess(): void {
    if (this.toasterService.toasts.length === 0) {
      this.toasterService.toastSuccess({
        header: 'Success!',
        message: 'Record has been saved successfully',
      });
    } else {
    }
  }

  raiseValidationError(): void {
    this.scrollToTopOnError();
    this.errors.hasError = true;
    this.errors.message = 'Please review and confirm that all required fields are complete and correct.';
  }

  raiseSaveError(msg: string): void {
    this.scrollToTopOnError();
    this.errors.hasError = true;
    this.errors.message = msg || 'Error occurred while saving the record.';
  }

  raiseReadError(): void {
    this.scrollToTopOnError();
    this.errors.hasError = true;
    this.errors.message = 'Error occurred while opening the record.';
  }

  requiredFieldError(field?: string): [{}] {
    const msg = `${field || 'This'} is required.`;
    return [
      {
        type: 'Error:',
        message: msg,
      },
    ];
  }

  isDirty(): boolean {
    return false;
  }

  clearErrors(): void {
    this.errors.hasError = false;
    this.errors.message = '';
  }

  cancel(item?: any) {
    if (this.isDirty()) {
      this.modalService.openModal(ConfirmActionComponent, {
        modalTitle: 'Alert',
        message: 'You have unsaved changes.',
        question: 'Are you sure you want to close?',
        cancelAction: 'No',
        confirmAction: 'Yes',
        confirmCallback: () => {
          this.modalService.toggleModal(false);
          this.modalCallback(item);
        },
      });
    } else {
      this.modalService.toggleModal(false);
      this.modalCallback(item);
    }
  }

  closeAndRefresh(item?: any) {
    this.modalService.toggleModal(false);
    this.modalCallback(item);
  }

  scrollToTopOnError(): void {
    const scrollPage = document.getElementsByClassName('go-modal__container-xl')[0];
    if (scrollPage) {
      scrollPage.scrollTop = 0;
    }
  }

  valueOf(name: string): any {
    return this.formData.get(name) ? this.formData.get(name).value : undefined;
  }
}
