import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../../../base/base-service';
import { environment } from '../../../../environments/environment';
import { DynamicListValue } from './dynamiclist';

@Injectable({
  providedIn: 'root',
})
export class DynamicListService extends BaseService<DynamicListValue, 'dynamic_list_value'> {
  constructor(http: HttpClient) {
    super(http);
  }
  getMetaData(): any {
  }

  getServiceUrl(): string {
    return environment.dynamicListUrl;
  }

  getBaseUrl(): string {
    return this.getServiceUrl() + '/dynamicLists';
  }
}
