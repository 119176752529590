import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  template: '',
})
export class BaseFieldComponent implements OnInit {
  @Input() name: string;
  @Input() label: string;
  @Input() control: FormControl;
  @Input() required: string;
  @Input() appendTo;
  @Input() placeholder: string;
  @Input() hints: Array<string>;

  constructor() {}

  ngOnInit() {
    this.placeholder = this.label && !this.placeholder ? `Select ${this.label}` : this.placeholder;
  }
}
