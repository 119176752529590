import { BaseEntity } from '../base/base-entity';

export class IaasOptimization extends BaseEntity {
  access_token: string;
}

export interface IaasResponse {
  data: any;
}

export interface Tenants {
    short_name: string;
    id: number;
    selected: boolean;
}

export class UserRecord {
  email: string;
  session_id: string;
  client_id: string;
  user_id: number;
  first_name: string;
  last_name: string;
  role: string;
  tenants: Tenants[];
}
