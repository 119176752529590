import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Role } from './role';

import { PrivilegePolicy } from './role';

import { BaseService } from '../base/base-service';
import { SimpleFilter } from '../base/base-filter';
import { environment } from '../../environments/environment';
import { Result, DeleteResult, CountResult } from '../base/result';
import { Observable } from 'rxjs';
import { QueryParam } from '../base/common';
import { metaData } from './role-metadata';

@Injectable({
  providedIn: 'root',
})
export class RoleService extends BaseService<Role, 'role'> {
  constructor(http: HttpClient) {
    super(http);
  }

  getServiceUrl(): string {
    return environment.securityServiceUrl;
  }
  getMetaData(): any {
    return metaData;
  }

  getBaseUrl(): string {
    return this.getServiceUrl() + '/roles';
  }

  // ************************************ START:CUSTOM METHODS **************************

  // ************************************ END:CUSTOM METHODS **************************

  // ************************************ START:FETCH CHILD METHODS **************************

  // Methods to handle child records for Assign Privilege Policies
  findChildPrivilegePolicyRecords(
    parentId: number,
    queryParam: QueryParam,
    q?: string
  ): Observable<Result<'privilege_policy', any[]>> {
    const simpleFilter = new SimpleFilter('name,description', q);

    return this.listExternalEntities(
      environment.securityServiceUrl + `/roles/${parentId}/privilegePolicy2Roles`,
      simpleFilter,
      queryParam
    );
  }

  deleteChildPrivilegePolicyRecordsById(ids: string, parentId?: string): Observable<DeleteResult> {
    return this.deleteExternalEntitiesById(
      environment.securityServiceUrl + `/roles/${parentId}/privilegePolicies`,
      ids
    );
  }

  findUnassignedPrivilegePolicyRecords(
    parentId: number,
    queryParam: QueryParam,
    q?: string
  ): Observable<Result<'privilege_policy', any[]>> {
    const simpleFilter = new SimpleFilter('name,description', q);

    return this.listExternalEntities(
      environment.securityServiceUrl + `/privilegePolicies/privilegePolicy2Roles/unassigned/${parentId}`,
      simpleFilter,
      queryParam
    );
  }

  insertAssignmentPrivilegePolicyRecords(assignments: any[], ids?: string, parentId?: string): Observable<any[]> {
    return this.simplePost(environment.securityServiceUrl + `/roles/${parentId}/privilegePolicies/${ids}`, null);
  }

  // ************************************ END:FETCH CHILD METHODS **************************

  // ************************************ START:COUNT METHODS **************************

  // ************************************ END:COUNT METHODS **************************
}
