import { Component, OnInit } from '@angular/core';
import { isNotNull } from '../base/utils';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent implements OnInit {
  logo = '../../assets/img/logo-main.png';
  errorMessage = 'Error Occurred, Problem with Authentication.';
  errorCode: string = '500';
  constructor() {}

  ngOnInit(): void {
    const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
    const messageParam = urlParams.get('token');
    if (isNotNull(messageParam)) {
      const val = JSON.parse(atob(messageParam));
      this.errorCode = val.code;
      this.errorMessage = val.message;
    }
  }
}
