import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'stringToDate' })
export class StringToDateTimePipe implements PipeTransform {
  transform(input: string) {
    // Assume the incoming date is in these formats - YYYY-MM-DDTHH:mm:ss.ZZZZ OR YYYY-MM-DD
    if (input) {
      const dateTimeParts: string[] = input.split('T');
      const dateParts: string[] = dateTimeParts[0].split('-');

      if (dateTimeParts.length === 2) {
        const timeParts: string[] = dateTimeParts[1].split(':');

        // YYYY-MM-DDTHH:mm:ss.ZZZ
        return new Date(
          Number(dateParts[0]),
          Number(dateParts[1]) - 1, // Month index starts from 0.
          Number(dateParts[2]),
          Number(timeParts[0]),
          Number(timeParts[1]),
          Number(timeParts[2].split('.')[0])
        );
      } else {
        // YYYY-MM-DD
        return new Date(Number(dateParts[0]), Number(dateParts[1]) - 1, Number(dateParts[2]));
      }
    }
  }
}
