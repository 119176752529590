import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  GoTableModule,
  GoInputModule,
  GoAccordionModule,
  GoBadgeModule,
  GoButtonModule,
  GoCardModule,
  GoActionSheetModule,
  GoIconButtonModule,
} from '@tangoe/goponents';
import { CommonPipesModule } from '../common-pipes/common-pipes.module';
import { EmbeddedListComponent } from './base/embedded-list.component';
import { DataHistoryComponent } from './data-history/data-history.component';
import { ApproverListComponent } from './approver-list/approver-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
@NgModule({
  declarations: [EmbeddedListComponent, DataHistoryComponent, ApproverListComponent],
  imports: [
    CommonModule,
    GoTableModule,
    GoInputModule,
    GoAccordionModule,
    CommonPipesModule,
    GoBadgeModule,
    GoButtonModule,
    GoCardModule,
    ReactiveFormsModule,
    GoActionSheetModule,
    GoIconButtonModule,
  ],
  exports: [EmbeddedListComponent, DataHistoryComponent, ApproverListComponent],
})
export class EmbeddedListModule {}
