import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { BaseModalEditComponent } from '../../base/base-modal-edit.component';
import { Invoice } from '../invoice';
import { GoModalService, GoToasterService } from '@tangoe/goponents';
import { ActivatedRoute, Router } from '@angular/router';
import { InvoiceService } from '../invoice.service';
import { FormBuilder } from '@angular/forms';
import * as utils from '../../base/utils';
import { FieldLookupComponent } from '../../shared/field-types/field-lookup/field-lookup.component';
import { UpdateInvoiceDto } from '../update-invoice-dto';
import format from 'date-fns/format';

@Component({
  selector: 'app-invoice-edit-modal',
  templateUrl: './invoice-edit-modal.component.html',
  styleUrls: ['./invoice-edit-modal.component.scss'],
})
export class InvoiceEditModalComponent extends BaseModalEditComponent<Invoice, 'invoice_header'> implements OnInit {
  selected_vendor_id: string;

  @ViewChildren('vendorCodeLookupField') vendorCodeLookupField: FieldLookupComponent[];

  constructor(
    public entityService: InvoiceService,
    protected toasterService: GoToasterService,
    protected modalService: GoModalService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected formBuilder: FormBuilder
  ) {
    super(entityService, toasterService, modalService, router, route);

    this.formData = this.formBuilder.group({
      vendor_code: [],
      po_number: [],
      receipt_date: [],
    });
  }

  ngOnInit(): void {
    this.entity = this.rowData;
    this.populateForm();
  }

  extractFormData(): Invoice {
    return undefined;
  }

  populateForm(): void {
    const receiptDate = this.entity.bill && this.entity.bill.receipt_date ? this.entity.bill.receipt_date : null;
    const poNumber = this.entity.po_number ? this.entity.po_number : null;
    const tenantVendorCodeId = this.entity.tenant_vendor_code
      ? utils.extractLookupId(this.entity.tenant_vendor_code)
      : null;

    this.formData.setValue({
      receipt_date: receiptDate,
      po_number: poNumber,
      vendor_code: tenantVendorCodeId,
    });

    this.formData = this.formBuilder.group({
      receipt_date: receiptDate,
      po_number: poNumber,
      vendor_code: tenantVendorCodeId,
    });

    this.selected_vendor_id = tenantVendorCodeId ? tenantVendorCodeId.toString() : undefined;
  }

  saveRecord() {
    const data = new UpdateInvoiceDto();
    const { vendor_code, po_number, receipt_date } = this.formData.value;

    data.invoice_header_id = this.rowData.id;
    data.invoice_id = this.rowData.id;
    data.po_number = po_number;
    data.receiptDate = receipt_date ? format(receipt_date, 'yyyy-MM-dd') : undefined;

    if (vendor_code) {
      data.tenant_vendor_code = { tenantVendorCodeRowId: vendor_code };
    }

    this.errors.hasError = false;

    this.showButtonLoading = true;

    this.entityService.updateInvoice(data).subscribe(
      result => {
        this.showButtonLoading = false;

        this.modalCallback(result);

        this.modalService.toggleModal(false);
        if (result.code === 200) {
          this.toasterService.toastSuccess({ header: 'Saved', message: 'Invoice Updated' });
        } else {
          this.toasterService.toastError({ header: 'Error', message: result.message });
        }
      },
      error => {
        this.errors.hasError = true;
        this.errors.message = error;
        this.showButtonLoading = false;
        this.toasterService.toastError({ header: 'Error', message: error.message });
      }
    );
  }
}
