import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PostLoginComponent } from './post-login.component';
import { SharedModule } from '../shared/shared.module';
import { GoLoaderModule } from '@tangoe/goponents';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [PostLoginComponent],
  imports: [CommonModule, SharedModule, GoLoaderModule, RouterModule],
})
export class PostLoginModule {}
