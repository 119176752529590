import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CostCenter } from './cost-center';

import { EmployeeCostCenter } from './cost-center';

import { BaseService } from '../base/base-service';
import { SimpleFilter } from '../base/base-filter';
import { environment } from '../../environments/environment';
import { Result, DeleteResult, CountResult } from '../base/result';
import { Observable } from 'rxjs';
import { QueryParam } from '../base/common';
import { metaData } from './costCenter-metadata';

@Injectable({
  providedIn: 'root',
})
export class CostCenterService extends BaseService<CostCenter, 'cost_center'> {
  constructor(http: HttpClient) {
    super(http);
  }

  getServiceUrl(): string {
    return environment.masterDataUrl;
  }
  getMetaData(): any {
    return metaData;
  }

  getBaseUrl(): string {
    return this.getServiceUrl() + '/costCenters';
  }

  // ************************************ START:CUSTOM METHODS **************************

  // Custom method to be used by search and select for Parent.
  findParents(
    q?: string,
    include?: string,
    exclude?: string,
    filter?: string
  ): Observable<Result<'cost_center', any[]>> {
    const simpleFilter = new SimpleFilter('name', q, include, exclude);
    const queryParam = new QueryParam();
    queryParam.perPage = 25;
    queryParam.sortAttr = 'name';

    return this.listExternalEntities(environment.masterDataUrl + '/costCenters/list', simpleFilter, queryParam);
  }

  // Custom method to be used by dynamic list values for Cost Center Type.
  findCostCenterTypeListValues(): Observable<Result<'dynamic_list_value', any[]>> {
    return this.fetchDynamicListValues('Cost Center Type');
  }

  // ************************************ END:CUSTOM METHODS **************************

  // ************************************ START:FETCH CHILD METHODS **************************

  // Methods to handle child records for Assign Employee To Cost Center
  findChildEmployeeCostCenterRecords(
    parentId: number,
    queryParam: QueryParam,
    q?: string
  ): Observable<Result<'employee_cost_center', any[]>> {
    const simpleFilter = new SimpleFilter(
      'employee.employee_id,employee.first_name,employee.last_name,employee.email',
      q
    );

    return this.listExternalEntities(
      environment.masterDataUrl + `/costCenters/${parentId}/employeeCostCenters`,
      simpleFilter,
      queryParam
    );
  }

  deleteChildEmployeeCostCenterRecordsById(ids: string, parentId?: string): Observable<DeleteResult> {
    return this.deleteExternalEntitiesById(environment.masterDataUrl + '/employeeCostCenters', ids);
  }

  findUnassignedEmployeeCostCenterRecords(
    parentId: number,
    queryParam: QueryParam,
    q?: string
  ): Observable<Result<'employee', any[]>> {
    const simpleFilter = new SimpleFilter('employee_id,first_name,last_name,email', q);

    return this.listExternalEntities(
      environment.masterDataUrl + `/employees/employeeCostCenters/unassigned/${parentId}`,
      simpleFilter,
      queryParam
    );
  }

  insertAssignmentEmployeeCostCenterRecords(assignments: any[], ids?: string, parentId?: string): Observable<any[]> {
    return this.insertExternalEntities(environment.masterDataUrl + `/employeeCostCenters`, assignments);
  }

  // ************************************ END:FETCH CHILD METHODS **************************

  // ************************************ START:COUNT METHODS **************************

  // Custom method to be used to count parent records.
  countParents(q?: string): Observable<CountResult> {
    const simpleFilter = new SimpleFilter('name', q);
    const queryParam = new QueryParam();

    return this.countExternalEntities(environment.masterDataUrl + '/costCenters/count', simpleFilter, queryParam);
  }

    // Delete a record by ID
  deleteById(id: string): Observable<DeleteResult> {
    const headers = this.buildHeaders();
    const params: any = {};

    const url = this.getBaseUrl() + '/' + id + '?h=true';

    return this.http.delete<DeleteResult>(url, { headers, params });
  }

  // ************************************ END:COUNT METHODS **************************
}
