import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PostLoginComponent } from './post-login/post-login.component';
import { AppShellComponent } from './app-shell/app-shell.component';
import { CanActivateSisense } from './service/can-activate-sisense.service';
import { CanActivateMenu } from './authentication/guard-menu-routes.service';
import { CanActivateApp } from './authentication/can-activate-app.service';
import { PermissionResourceMapping } from './shared/models/authentication/PermissionResourceMapping';
import { CanActiveIaasOptimization } from './service/can-active-iaas-optimization.service';
import { sessionContext } from './authentication/session-details';
import { CanActiveSaasOptimization } from './service/can-active-saas-optimization.service';
import { CanDeactivateGuard } from './can-deactivate.guard';
import { ErrorPageComponent } from './error-page/error-page.component';

const routes: Routes = [
  { path: '', redirectTo: 'post-login', pathMatch: 'full' },
  { path: 'post-login', component: PostLoginComponent },
  { path: 'error', component: ErrorPageComponent },
  {
    path: '',
    canActivate: [CanActivateApp],
    component: AppShellComponent,
    children: [
      {
        path: 'dashboard',
        canActivate: [CanActivateSisense],
        loadChildren: () =>
          import('./sisense-dashboards/sisense-dashboards.module').then(m => m.SisenseDashboardsModule),
      },
      {
        path: 'designer',
        canActivate: [CanActivateSisense],
        loadChildren: () => import('./sisense-designer/sisense-designer.module').then(m => m.SisenseDesignerModule),
      },
      {
        path: 'notification',
        canActivate: [CanActivateSisense],
        loadChildren: () => import('./sisense-pulse/sisense-pulse.module').then(m => m.SisensePulseModule),
      },
      {
        path: 'home',
        canActivate: [CanActivateMenu],
        data: { resource: 'ALWAYS_ALLOW' },
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
      },
      {
        path: 'business-units',
        canActivate: [CanActivateMenu],
        data: { resource: 'BUSINESS_UNIT_USER' },
        loadChildren: () => import('./business-unit/business-unit.module').then(m => m.BusinessUnitModule),
      },
      {
        path: 'employees',
        canActivate: [CanActivateMenu],
        data: { resource: 'EMPLOYEES' },
        loadChildren: () => import('./employee/employee.module').then(m => m.EmployeeModule),
      },
      {
        path: 'hr-roles',
        canActivate: [CanActivateMenu],
        data: { resource: 'HR_ROLE' },
        loadChildren: () => import('./hr-role/hr-role.module').then(m => m.HrRoleModule),
      },
      {
        path: 'companies',
        canActivate: [CanActivateMenu],
        data: { resource: 'COMPANY' },
        loadChildren: () => import('./company/company.module').then(m => m.CompanyModule),
      },
      {
        path: 'accounting-systems',
        canActivate: [CanActivateMenu],
        data: { resource: 'ACCOUNTING_SYSTEM' },
        loadChildren: () => import('./accounting-system/accounting-system.module').then(m => m.AccountingSystemModule),
      },
      {
        path: 'departments',
        canActivate: [CanActivateMenu],
        data: { resource: 'DEPARTMENTS_USER' },
        loadChildren: () => import('./department/department.module').then(m => m.DepartmentModule),
      },
      {
        path: 'invoices',
        canActivate: [CanActivateMenu],
        data: { resource: 'BILL_VIEWS' },
        loadChildren: () => import('./invoice/invoice.module').then(m => m.InvoiceModule),
      },
      {
        path: 'gl-expense-codes',
        canActivate: [CanActivateMenu],
        data: { resource: 'GL_EXPENSE_CODE' },
        loadChildren: () => import('./gl-expense-code/gl-expense-code.module').then(m => m.GlExpenseCodeModule),
      },
      {
        path: 'projects',
        canActivate: [CanActivateMenu],
        data: { resource: 'PROJECT' },
        loadChildren: () => import('./project/project.module').then(m => m.ProjectModule),
      },
      {
        path: 'vendor-groups',
        canActivate: [CanActivateMenu],
        data: { resource: 'VENDOR_GROUP' },
        loadChildren: () => import('./vendor-group/vendor-group.module').then(m => m.VendorGroupModule),
      },
      {
        path: 'employee-groups',
        canActivate: [CanActivateMenu],
        data: { resource: 'EMPLOYEE_GROUPS' },
        loadChildren: () => import('./employee-group/employee-group.module').then(m => m.EmployeeGroupModule),
      },
      {
        path: 'cost-centers',
        canActivate: [CanActivateMenu],
        data: { resource: 'COST_CENTER' },
        loadChildren: () => import('./cost-center/cost-center.module').then(m => m.CostCenterModule),
      },
      {
        path: 'cost-center-owners',
        canActivate: [CanActivateMenu],
        data: { resource: 'COST_CENTER' },
        loadChildren: () => import('./cost-center-owner/cost-center-owner.module').then(m => m.CostCenterOwnerModule),
      },
      {
        path: 'user-groups',
        canActivate: [CanActivateMenu],
        data: { resource: 'GROUP' },
        loadChildren: () => import('./user-group/user-group.module').then(m => m.UserGroupModule),
      },
      {
        path: 'accounts',
        canActivate: [CanActivateMenu],
        data: { resource: 'ACCOUNTS' },
        loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
      },
      {
        path: 'users',
        canActivate: [CanActivateMenu],
        data: { resource: 'USER' },
        loadChildren: () => import('./user/user.module').then(m => m.UserModule),
      },
      {
        path: 'addresses',
        canActivate: [CanActivateMenu],
        data: { resource: 'ADDRESSES' },
        loadChildren: () => import('./address/address.module').then(m => m.AddressModule),
      },
      {
        path: 'tenant-vendors',
        canActivate: [CanActivateMenu],
        data: { resource: 'GLOBAL_VENDOR' },
        loadChildren: () => import('./vendor/vendor.module').then(m => m.VendorModule),
      },
      {
        path: 'allocation-string-formats',
        canActivate: [CanActivateMenu],
        data: { resource: 'ALLOCATION_CONFIGURATIONS' },
        loadChildren: () =>
          import('./allocation-string-format/allocation-string-format.module').then(
            m => m.AllocationStringFormatModule
          ),
      },
      {
        path: 'contracts',
        canActivate: [CanActivateMenu],
        data: { resource: 'CONTRACT' },
        loadChildren: () => import('./contract/contract.module').then(m => m.ContractModule),
      },
      {
        path: 'locations',
        canActivate: [CanActivateMenu],
        data: { resource: 'LOCATIONS' },
        loadChildren: () => import('./location/location.module').then(m => m.LocationModule),
      },
      {
        path: 'roles',
        canActivate: [CanActivateMenu],
        data: { resource: 'SC_ROLE' },
        loadChildren: () => import('./role/role.module').then(m => m.RoleModule),
      },
      {
        path: 'privilege-policies',
        canActivate: [CanActivateMenu],
        data: { resource: 'PRIVILEGE_POLICY' },
        loadChildren: () => import('./privilege-policy/privilege-policy.module').then(m => m.PrivilegePolicyModule),
      },
      {
        path: 'allocation-info',
        canActivate: [CanActivateMenu],
        data: { resource: 'ALLOCATION_CONFIGURATIONS' },
        loadChildren: () => import('./allocation-info/allocation-info.module').then(m => m.AllocationInfoModule),
      },
      {
        path: 'recurring-invoices',
        canActivate: [CanActivateMenu],
        data: { resource: 'RECURRING_INVOICE' },
        loadChildren: () => import('./recurring-invoice/recurring-invoice.module').then(m => m.RecurringInvoiceModule),
      },
      {
        path: 'tenants',
        canActivate: [CanActivateMenu],
        data: { resource: 'GLOBAL_TENANT' },
        loadChildren: () => import('./tenant/tenant.module').then(m => m.TenantModule),
      },
      {
        path: 'allocation-business-rule',
        canActivate: [CanActivateMenu],
        data: { resource: 'ALLOCATION_BUSINESS_RULE' },
        canDeactivate: [CanDeactivateGuard],
        loadChildren: () =>
          import('./allocation-business-rule/allocation-business-rule.module').then(
            m => m.AllocationBusinessRuleModule
          ),
      },
      {
        path: 'private-invoice-pricing',
        canActivate: [CanActivateMenu],
        data: { resource: 'PRIVATE_CLOUD_PRICING' },
        loadChildren: () =>
          import('./private-cloud-pricing/private-cloud-pricing.module').then(m => m.PrivateCloudPricingModule),
      },
      {
        path: 'vendor-onboarding',
        canActivate: [CanActivateMenu],
        data: { resource: 'ALWAYS_ALLOW' },
        loadChildren: () =>
          import('./vendor-onboarding/vendor-onboarding.module').then(m => m.VendorOnboardingModule),
      },
      {
        path: 'user-preferences',
        canActivate: [CanActivateMenu],
        data: { resource: 'ALWAYS_ALLOW' },
        loadChildren: () => import('./user-preference/user-preference.module').then(m => m.UserPreferenceModule),
      },
      {
        path: 'invoice-headers',
        canActivate: [CanActivateMenu],
        data: { resource: 'BILL_VIEWS' },
        loadChildren: () => import('./invoice-header/invoice-header.module').then(m => m.InvoiceHeaderModule),
      },
      {
        path: 'external-resource',
        canActivate: [CanActivateMenu],
        data: { resource: 'EXTERNAL_RESOURCE' },
        loadChildren: () => import('./external-resource/external-resource.module').then(m => m.ExternalResourceModule),
      },
      {
        path: 'budget-configuration',
        canActivate: [CanActivateMenu],
        data: { resource: 'BUDGET_CONFIGURATION' },
        loadChildren: () => import('./budget/budget-configuration.module').then(m => m.BudgetConfigurationModule),
      },
      {
        path: 'budget-dashboard',
        canActivate: [CanActivateMenu],
        data: { resource: 'BUDGET_ANALYSIS' },
        loadChildren: () => import('./budget-dashboard/budget-dashboard.module').then(m => m.BudgetDashboardModule),
      },
      {
        path: 'metadata-details',
        canActivate: [CanActivateMenu],
        data: { resource: 'METADATA' },
        loadChildren: () => import('./meta-data/meta-data.module').then(m => m.MetaDataModule),
      },
      {
        path: 'iaas',
        canActivate: [CanActiveIaasOptimization],
        data: { resource: PermissionResourceMapping.IaasOptimization },
        loadChildren: () => import('./iaas-optimization/iaas-optimization.module').then(m => m.IaasOptimizationModule),
      },
      // Saas Menu external-resource
      {
        path: 'saas-external-resource',
        canActivate: [CanActiveSaasOptimization],
        data: { resource: PermissionResourceMapping.SaasOptimization, reuse: true },
        loadChildren: () => import('./saas-optimization/saas-optimization.module').then(m => m.SaasOptimizationModule),
      },
    ],
  },
  { path: '**', redirectTo: 'post-login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
