import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { PermissionResourceMapping } from '../shared/models/authentication/PermissionResourceMapping';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthenticationLocalService } from './authentication-local.service';
import { LogService } from '../logger/log.service';
import { sessionContext } from './session-details';

@Injectable({
  providedIn: 'root',
})
export class CanActivateMenu implements CanActivate {
  constructor(private router: Router, private authenticationService: AuthenticationService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const resource = this.translateResource(route.data.resource, state.url);

    if (this.authenticationService.hasAccess(resource)) {
      return true;
    } else {
      this.redirectToDefault();
      return false;
    }
  }

  translateResource(routeResource: string, targetUrl: string): string {
    let resource = routeResource;
    if (routeResource === 'EXTERNAL_RESOURCE') {
      switch (targetUrl) {
        case '/external-resource/dmu-jobs': {
          resource = 'DMU';
          break;
        }
        case '/external-resource/dmu-job-templates': {
          resource = 'TEMPLATE';
          break;
        }
        case '/external-resource/recurring-bills': {
          resource = 'E_RECURRING_BILL';
          break;
        }
        case '/external-resource/self-service-bill-imports': {
          resource = 'E_BILL_SELF_SERVICE_FILE_IMPORT';
          break;
        }
        case '/external-resource/bill-loads': {
          resource = 'BILL_IMPORT_LIST';
          break;
        }
        case '/external-resource/charge-rules-extract': {
          resource = 'CHARGE_RULES_EXTRACT';
          break;
        }
        case '/external-resource/unclassified-charges': {
          resource = 'RULE_MISSES_SUB';
          break;
        }
        case '/external-resource/journal-entries': {
          resource = 'JOURNAL_ENTRIES';
          break;
        }
        case '/external-resource/management-details': {
          resource = 'WORKFLOW_MANAGEMENT_DETAILS';
          break;
        }
        case '/external-resource/templates': {
          resource = 'WORKFLOW_TEMPLATE';
          break;
        }
        default: {
          resource = 'NONE';
          break;
        }
      }
    }
    return resource;
  }

  redirectToDefault(): void {
    const userRole = sessionContext.roleInfo;
    const path = userRole ? this.authenticationService.getDefaultLandingPath(userRole) : '';
    this.router.navigate([path]);
  }
}
