import { BaseEntity } from '../../base/base-entity';

/**
 * This class represent the AllocationResultDetailView entity.
 */
export class AllocationResultDetailView extends BaseEntity {
  allocation_result_detail_view_row_id: number;

  result_code: string;
  total_amount_allocated_value: { value: number; indicator: string };
  allocation_type: string;
  line_item_id: string;
  line_item_asset: string;
  line_item_amount_value: { value: number; indicator: string };
}
