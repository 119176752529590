import { Component, OnInit, QueryList, ViewChildren, Input } from '@angular/core';
import { GoToasterService, GoModalService } from '@tangoe/goponents';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { BaseModalEditComponent } from '../../base/base-modal-edit.component';
import { AllocationStringService } from '../allocation-string-modal/allocation-string.service';
import { AllocationString } from '../allocation-string-modal/allocation-string';
import { HttpClient } from '@angular/common/http';
import { AllocationStringFormatService } from '../../allocation-string-format/allocation-string-format.service';
import { QueryParam } from '../../base/common';
import { AllocationStringPart } from '../../allocation-string-format/allocation-string-format';
import { map } from 'rxjs/operators';
import { FieldLookupComponent } from '../../shared/field-types/field-lookup/field-lookup.component';
import { AllocationInfo } from '../allocation-info';
import { environment } from '../../../environments/environment';
import * as utils from '../../base/utils';
import { SaveAllocationStringDto } from '../../allocation-string/save-allocation-string-dto';

@Component({
  selector: 'app-allocation-amount-modal',
  templateUrl: './allocation-amount-modal.component.html',
  styleUrls: ['./allocation-amount-modal.component.scss']
})
export class AllocationAmountModalComponent extends BaseModalEditComponent<AllocationString, 'allocation_string'>
  implements OnInit {

  constructor(
    public allocationStringService: AllocationStringService,
    public allocationStringFormatService: AllocationStringFormatService,
    protected toasterService: GoToasterService,
    protected modalService: GoModalService,
    protected router: Router,
    protected route: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {
    super(allocationStringService, toasterService, modalService, router, route);
    this.listContextPath = '/allocation-strings';
    this.editContextPath = '/allocation-strings';
    this.entityLocator = 'allocation_string';
  }
  @ViewChildren('allocationStringPartFields') allocationStringPartLookups: QueryList<FieldLookupComponent>;
  loading = true;
  parent: AllocationInfo;
  childAllocationStringParts: AllocationStringPart[] = [];
  storeDataOfIndex: any = new Array();
  stringCodeParts: Array<string>;
  stringNameParts: Array<string>;
  @Input() modalCallback: (data) => void;
  @Input() isParentReadOnly: boolean;
  serviceDefinitions: {
    [key: string]: {
      entityLocator: any;
      lookupMethod: string;
      url: string;
    };
  } = {
      ACCOUNTING_SYSTEM: {
        lookupMethod: 'findAccountingSystems',
        entityLocator: 'accounting_system',
        url: 'accountingSystems',
      },
      BUSINESS_UNIT: {
        lookupMethod: 'findBusinessUnits',
        entityLocator: 'business_unit',
        url: 'businessUnits',
      },
      COMPANY: {
        lookupMethod: 'findCompanies',
        entityLocator: 'company',
        url: 'companies',
      },
      GL_EXPENSE_CODE: {
        lookupMethod: 'findGlExpenseCodes',
        entityLocator: 'gl_expense_code',
        url: 'glExpenseCodes',
      },
      COST_CENTER: {
        lookupMethod: 'findCostCenters',
        entityLocator: 'cost_center',
        url: 'costCenters',
      },
      DEPARTMENT: {
        lookupMethod: 'findDepartments',
        entityLocator: 'department',
        url: 'departments',
      },
      LOCATION: {
        lookupMethod: 'findLocations',
        entityLocator: 'location',
        url: 'locations',
      },
    };

  ngOnInit() {
    this.isAddMode = this.rowData ? false : true;

    this.formData = this.formBuilder.group({
      ACCOUNTING_SYSTEM: [undefined, []],
      BUSINESS_UNIT: [undefined, []],
      COMPANY: [undefined, []],
      GL_EXPENSE_CODE: [undefined, []],
      COST_CENTER: [undefined, []],
      DEPARTMENT: [undefined, []],
      LOCATION: [undefined, []],
    });
    this.allocationStringFormatService
      .findChildAllocationStringPartRecords(this.parent.string_format.id, new QueryParam())
      .subscribe(d => {
        this.populateForm();
        this.childAllocationStringParts = d.allocation_string_part;
        this.stringCodeParts = new Array(d.allocation_string_part.length);
        this.stringNameParts = new Array(d.allocation_string_part.length);
        // sort by allocation position
        this.childAllocationStringParts.sort((a, b) => {
          return a.position - b.position;
        });
        this.childAllocationStringParts.forEach(a => {
          let fc;
          const textLength: number = Number(a['length']);
          if (!a.allow_any_value) {
            fc = new FormControl(utils.extractLookupId(undefined), [Validators.required, Validators.maxLength(textLength)]);
          } else {
            fc = new FormControl('', [Validators.required, Validators.maxLength(textLength)]);
          }

          this.formData.addControl(a.field_mapping + '_' + a.id, fc);
          const control = this.formData.get(a.field_mapping + '_' + a.id);
          const def = this.serviceDefinitions[a.field_mapping];

          control.valueChanges.subscribe(v => {
            this.showButtonLoading = true;
            if (a.allow_any_value) {
              if (a.position) {
                this.stringCodeParts[a.position - 1] = v;
                this.stringNameParts[a.position - 1] = null;
              } else {
                this.stringCodeParts[a.position - 1] = v;
                this.stringNameParts[a.position - 1] = v;
              }
              this.showButtonLoading = false;
              return;
            }

            this.allocationStringService
              .simplePost(environment.masterDataUrl + '/' + def.url + '/query', { code: v })
              .pipe(
                map(r => r[def.entityLocator]),
                map((r: Array<any>) => r.shift())
              )
              .subscribe(r => {
                if (a.position) {
                  const code = r ? r.code : null;
                  const name = r ? r.name : null;
                  this.stringCodeParts[a.position - 1] = code;
                  this.stringNameParts[a.position - 1] = name;
                  if (!a.allow_any_value) {
                    a['selectedId'] = r ? r.id : a['selectedId'];
                  }
                } else {
                  this.stringCodeParts[a.position - 1] = r.code;
                  this.stringNameParts[a.position - 1] = r.name;
                }
                this.showButtonLoading = false;
              });
          });
        });

        this.loading = false;
      });
  }
  extractFormData(): AllocationString {
    const entity: SaveAllocationStringDto = new SaveAllocationStringDto();

    entity.string_code = this.stringCodeParts.filter(i => !!i).join(this.parent.string_format.separator);
    entity.string_name = this.stringNameParts.filter(i => !!i).join(this.parent.string_format.separator);
    entity.allocation_info_row_id = this.parent.id;
    entity.amount_ind = '';
    entity.amount_val = 0;
    entity.line_item_row_id = 0;
    entity.percentage = 0;
    if (!this.isAddMode) {
      entity.id = this.id;
      entity.allocation_string_row_id = this.id;
    }
    return entity;
  }
  customselectedId(value) {
    return value;
  }
  populateForm(): void {
    if (this.rowData) {
      const queryParam = new QueryParam();
      queryParam.sortAttr = 'position';
      const stringCode = this.rowData || '';

      const codes = stringCode.split(this.parent.string_format.separator);

      this.allocationStringFormatService
        .findChildAllocationStringPartRecords(this.parent.string_format.id, queryParam)
        .subscribe(stringPart => {
          const data: any = {};
          this.storeDataOfIndex = [];
          stringPart.allocation_string_part.forEach((part, index) => {
            const fl = this.allocationStringPartLookups.find(
              cmp => cmp.entityLocator === part.field_mapping.toLowerCase()
            );
            if (!this.isAddMode) {
              [...Array(part.position)].forEach((_, i) => {
                if (part.position === i + 1) {
                  this.storeDataOfIndex.push(codes[index]);
                } else if (!this.storeDataOfIndex[i]) {
                  this.storeDataOfIndex.push('No Data');
                }
              });
            }

            const code = this.storeDataOfIndex[part.position - 1];
            if (fl) {
              fl.searchValue = code;
              fl.loadItems();
            }

            data[part.field_mapping + '_' + part.id] = code;
          });
          this.formData.patchValue(data);
        });
    }
  }
  addRecord() {
    this.showButtonLoading = true;

    if (!this.validateFormData()) {
      this.raiseValidationError();
      this.showButtonLoading = false;

      return;
    }
    const string_code = this.stringCodeParts.filter(i => !!i).join(this.parent.string_format.separator);
    this.modalCallback(string_code);
  }
  cancel() {
    this.modalService.toggleModal(false);
  }

}
