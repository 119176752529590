import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderBarComponent } from './header-bar.component';
import { GoHeaderBarModule, GoButtonModule } from '@tangoe/goponents';

@NgModule({
  declarations: [HeaderBarComponent],
  imports: [CommonModule, GoHeaderBarModule, GoButtonModule],
  exports: [HeaderBarComponent],
})
export class HeaderBarModule {}
