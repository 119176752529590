import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { GoTableColumnComponent } from '@tangoe/goponents';

@Component({
  selector: 'app-field',
  template: '',
})
export class FieldComponent extends GoTableColumnComponent {
  @Input() field: string;
  @Input() title: string;
  @Input() width: number;
  @Input() sortable?: boolean = true;
  @Input() exportable?: boolean = true;
  @Input() static?: boolean;
  @Input() staticColumn?: boolean = false;
  @Input() hiddenColumn?: boolean = false;
  @ContentChild('goTableCell', { static: false }) template: TemplateRef<any>;
}
