import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { FormControl } from '@angular/forms';
import { sortBy } from 'lodash';

export type ColumnConfig = {
  field: string;
  title?: string;
  sortable?: boolean;
  searchable?: boolean;
  static?: boolean;
  template?: TemplateRef<any>;
  width?: number;
  staticColumn?: boolean;
  hiddenColumn?: boolean;
  exportable?: boolean;
};

export type ColumnsConfig = {
  static: ColumnConfig[];
  added: ColumnConfig[];
  removed: ColumnConfig[];
};

@Component({
  selector: 'app-column-configuration',
  templateUrl: './column-configuration.component.html',
  styleUrls: ['./column-configuration.component.scss'],
})
export class ColumnConfigurationComponent implements OnInit {
  @Input() columns: ColumnsConfig;
  @Input() preSaveColumns: ColumnsConfig;
  @Input() onSubmit: (c: any) => void;

  workingColumns: ColumnsConfig;
  filterControl = new FormControl();
  loading = true;

  constructor() {}

  ngOnInit(): void {
    this.workingColumns = {
      added: Object.assign([], this.columns.added),
      static: Object.assign([], this.columns.static),
      removed: Object.assign([], this.columns.removed),
    };
    this.loading = false;
  }

  onDropped($event: CdkDragDrop<any, any>) {
    moveItemInArray(this.workingColumns.added, $event.previousIndex, $event.currentIndex);
  }

  onUpdateColumns() {
    this.onSubmit(this.workingColumns);
  }

  onResetColumns() {
    this.workingColumns = {
      added: Object.assign([], this.preSaveColumns.added),
      static: Object.assign([], this.preSaveColumns.static),
      removed: Object.assign([], this.preSaveColumns.removed),
    };
  }

  onRemove(index: number) {
    transferArrayItem(
      this.workingColumns.added,
      this.workingColumns.removed,
      index,
      this.workingColumns.removed.length
    );
    this.workingColumns.removed = sortBy(this.workingColumns.removed, 'title');
  }

  onAdd(index: number) {
    transferArrayItem(this.workingColumns.removed, this.workingColumns.added, index, this.workingColumns.added.length);
  }
}
