import { Component, OnInit, ElementRef } from '@angular/core';
import { GoToasterService, GoModalService } from '@tangoe/goponents';
import { BaseModalListComponent } from '../../base/base-modal-list.component';
import { UserService } from '../user.service';
import { User } from '../user';

@Component({
  selector: 'app-assign-group-to-user-modal',
  templateUrl: './assign-group-to-user-modal.component.html',
  styleUrls: ['./assign-group-to-user-modal.component.scss'],
})
export class AssignGroupToUserModalComponent extends BaseModalListComponent<User, 'user'> {
  constructor(
    protected toasterService: GoToasterService,
    protected userService: UserService,
    protected modalService: GoModalService,
    protected el: ElementRef
  ) {
    super(toasterService, userService, modalService, el);
  }

  linkedEntity(): string {
    return 'group_membership';
  }

  primaryEntity(): string {
    return 'user';
  }

  otherEntity(): string {
    return 'group';
  }

  otherEntityName(): string {
    return 'userGroup';
  }

  otherEntitySearch(): string {
    return 'name,email';
  }

  otherEntitySort(): string {
    return 'name';
  }
}
