import { BaseEntity } from '../../base/base-entity';

/**
 * This class represent the RemittanceAddress entity.
 */
export class RemittanceInfo extends BaseEntity {
  remittance_address: { id: number; address_line1: string };
  vendor_remittance_code: { id: number; vendor_remittance_code: string };
  vendor: { id: number; name: string };
  invoice_id: number;
}
