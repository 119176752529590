import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { GoModalService } from '@tangoe/goponents';
import { FormControl, Validators } from '@angular/forms';
@Component({
  selector: 'app-reason-modal',
  templateUrl: './reason-modal.component.html',
  styleUrls: ['./reason-modal.component.scss'],
})
export class WorkflowReasonModalComponent implements OnInit, AfterViewInit {
  @Input() modalTitle: string;
  @Input() appendTo: string;
  @Input() hideCloseButton: boolean = false;
  @Input() confirmCallback: ($event) => void;
  @Input() cancelCallback: () => void;

  reason = new FormControl('', [Validators.required]);

  constructor(protected modalService: GoModalService) {}

  ngOnInit() {}

  confirm() {
    if (this.reason.status === 'INVALID') {
      this.reason.setErrors([
        {
          type: 'ERROR:',
          message: 'This is a required field.',
        },
      ]);
      return;
    }
    this.confirmCallback(this.reason.value);
  }

  ngAfterViewInit() {
    this.preventClosureWhenClickedOutsideModal();
    this.handleCloseButtonDisplay();
  }

  cancel() {
    this.cancelCallback();
    this.modalService.toggleModal(false);
  }

  handleCloseButtonDisplay() {
    const element = document.getElementsByClassName('go-modal__close')[0];
    if (this.hideCloseButton) {
      element['style'].display = 'none';
    } else {
      element['style'].display = 'block';
    }
  }
  preventClosureWhenClickedOutsideModal() {
    const base = document.querySelector('body');
    const that = this;
    base.addEventListener('click', event => {
      if (event.target['className'] === 'go-modal') {
        that.modalService.toggleModal(true);
      }
    });
  }
}
