import { GoToasterService, GoTableConfig, GoTableComponent, GoModalService, hexToRgb } from '@tangoe/goponents';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseEntity } from './base-entity';
import { BaseService } from './base-service';
import { Result } from './result';
import { isActionDenied } from 'src/app/base/entity-access';
import { sessionContext } from '../../app/authentication/session-details';
import {
  EmbeddedTableContext,
  ErrorItem,
  selectedTableRows,
  resetTableRowSelections,
  hasTableConfigChanged,
} from './common';
import { FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from './validators';
import { Observable } from 'rxjs';
import 'rxjs/add/observable/of';
import { ConfirmActionComponent } from '../shared/confirm-action/confirm-action.component';
import { LogService } from '../logger/log.service';
import { AfterViewChecked, AfterViewInit, OnDestroy } from '@angular/core';
import { RGBInterfaceToRGBString, isNotNull } from './utils';

export abstract class BaseEditComponent<T extends BaseEntity, K extends string> implements OnDestroy, AfterViewChecked {
  constructor(
    public entityService: BaseService<T, K>,
    protected toasterService: GoToasterService,
    protected modalService: GoModalService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected logService?: LogService
  ) {}

  public id: number;
  public entity: T;

  protected listContextPath: string;
  protected editContextPath: string;
  protected entityLocator: string;

  public formData: FormGroup;
  public isTopLevel: boolean = false;
  public isThemeApplied: boolean = false;

  public isPageEditable: boolean = true;

  public embeddedTableContexts: EmbeddedTableContext[] = [];
  public errors: ErrorItem = new ErrorItem();

  public isAddMode: boolean = false;

  public showButtonLoading: boolean = false;

  // ------------------ START: Cancel and go to List Page  --------------

  listRecords() {
    this.router.navigate([this.listContextPath]);
  }
  // ------------------ END: Cancel and go to List Page  --------------

  // ------------------ START: Read record ----------------------------
  abstract populateForm(): void;

  buildAnyStaticDropdowns(): void {}

  checkIfRecordIsTopLevel(): void {}

  checkNoRecordExists(): Observable<boolean> {
    return Observable.of(false);
  }

  loadRecord(id?: number) {
    this.id = id || (this.route.snapshot.paramMap ? Number(this.route.snapshot.paramMap.get('id')) : 0);
    this.isAddMode = this.id === 0 ? true : false;

    this.entity = {} as T;
    this.populateForm();

    if (this.isAddMode) {
      this.checkNoRecordExists().subscribe(flag => {
        this.isTopLevel = flag;
        this.populateForm();
      });
    } else {
      this.isPageEditable = !isActionDenied(this.entityLocator, 'UPDATE', this.entityService.getMetaData());
      this.entityService.findById(this.id).subscribe(
        data => {
          if (this.extractEntity(data)) {
            this.entity = this.extractEntity(data);
            this.checkIfRecordIsTopLevel();
            this.populateForm();
          } else {
            this.raiseReadError();
          }
        },
        error => {
          this.raiseReadError();
        }
      );
    }

    this.buildAnyStaticDropdowns();
    this.buildAnyChildTables();
  }
  // ------------------ END: Read record ------------------------------

  // ------------------ START: Child tables ------------------------------

  buildAnyChildTables(): void {
    if (!this.isAddMode) {
      this.embeddedTableContexts.forEach(child => {
        child.parentId = child.parentId || this.id;
        this.loadChildTableData(child);
      });
    }
  }

  loadChildTableData(child: EmbeddedTableContext) {
    const entityService: BaseService<any, any> = child.entityService || this.entityService;

    child.showLoader = true;
    child.tableConfig.noDataText = 'No data';

    if (child.parentId === 0) {
      child.showLoader = false;
      child.tableConfig.noDataText = `Could not find any data to load`;

      return;
    }

    resetTableRowSelections(this[child.tableName]);

    entityService[child.findMethod](
      child.parentId,
      child.pageContext.pageParam,
      child.pageContext.simpleFilter.searchValue
    ).subscribe(
      result => {
        child.showLoader = false;
        child.tableConfig.totalCount = result.count || result[child.entityRef].length;
        child.tableConfig.tableData = result[child.entityRef];

        if (result[child.entityRef].length > 0) {
          child.tableConfig.searchConfig.searchable = true;
        }
        child.tableConfig = { ...child.tableConfig };
      },
      error => {
        child.showLoader = false;
        child.tableConfig.noDataText = 'Error loading data';

        child.tableConfig = { ...child.tableConfig };
      }
    );
  }

  onChildTableChange($event: GoTableConfig, child: EmbeddedTableContext) {
    if (!hasTableConfigChanged($event, child.pageContext)) {
      return;
    }

    child.tableConfig = $event;

    child.pageContext.pageParam.perPage = child.tableConfig.pageConfig.perPage;
    child.pageContext.pageParam.offset = child.tableConfig.pageConfig.offset;

    if (child.tableConfig.sortConfig) {
      child.pageContext.pageParam.sortDir = child.tableConfig.sortConfig.direction;
      child.pageContext.pageParam.sortAttr = child.tableConfig.sortConfig.column;
    }

    // Reset the offset only if the search term has changed so that we get the searched data from first page.
    if (child.pageContext.simpleFilter.searchValue !== child.tableConfig.searchConfig.searchTerm) {
      child.pageContext.simpleFilter.searchValue = child.tableConfig.searchConfig.searchTerm;

      child.pageContext.pageParam.offset = 0;
      child.tableConfig.pageConfig.offset = 0;
    }

    this.loadChildTableData(child);
  }

  // ------------------ START: Selected Child Rows Selection Logic -------

  selectedChildRows(tableComponent: GoTableComponent) {
    return selectedTableRows(tableComponent);
  }

  hasRecords(context: EmbeddedTableContext): boolean {
    return context.tableConfig && context.tableConfig.tableData && context.tableConfig.tableData.length > 0;
  }

  deleteChildRecord(tableComponent: GoTableComponent, child: EmbeddedTableContext) {
    const idsToDelete = this.selectedChildRows(tableComponent);
    if (idsToDelete.length === 0) {
      this.toasterService.toastInfo({
        header: 'Alert!',
        message: 'No records selected to delete.',
      });
      return;
    }

    this.modalService.openModal(ConfirmActionComponent, {
      modalTitle: 'Delete',
      message: 'You are about to delete the selected records.',
      question: 'Are you sure?',
      cancelAction: 'No',
      confirmAction: 'Yes',
      confirmCallback: () => {
        this.entityService[child.deleteMethod](idsToDelete.join(','), child.parentId).subscribe(
          data => {
            this.toasterService.toastSuccess({
              header: 'Success!',
              message: 'Successfully deleted records.',
            });

            // If on last page and deleting all records then move to previous page.
            if (tableComponent.isLastPage()) {
              if (idsToDelete.length === tableComponent.tableConfig.tableData.length) {
                child.pageContext.pageParam.goToPrevious();
                child.tableConfig.pageConfig.offset = child.pageContext.pageParam.offset;
              }
            }

            resetTableRowSelections(tableComponent);

            this.modalService.toggleModal(false);
            this.loadChildTableData(child);
          },
          error => {
            this.modalService.toggleModal(false);

            this.toasterService.toastError({
              header: 'Error!',
              message: 'Error occurred while deleting records.',
            });
          }
        );
      },
    });
  }

  // ------------------ END: Selected Child Rows Selection Logion -------

  // ------------------ END: Child tables ------------------------------

  // ------------------ START: Save record ----------------------------

  abstract extractFormData(): T;

  extractEntity(result: Result<K, T>): T {
    return result[this.entityLocator];
  }

  saveRecord(stayOnEditScreen: boolean = false, callbackFn?: (data: any, error?: any) => void) {
    this.showButtonLoading = true;

    if (!this.validateFormData()) {
      this.raiseValidationError();
      this.showButtonLoading = false;

      return;
    }

    const validationErrorMSg = this.customValidations();
    if (validationErrorMSg) {
      this.raiseSaveError(validationErrorMSg);
      this.showButtonLoading = false;
      return;
    }

    this.entity = this.extractFormData();

    if (this.isAddMode) {
      this.entityService.insert(this.entity).subscribe(
        data => {
          if (callbackFn) {
            callbackFn(data);
          }

          this.clearErrors();
          this.showButtonLoading = false;

          this.entity = this.extractEntity(data);
          this.id = this.entity.id;

          this.raiseSaveSuccess();

          if (!stayOnEditScreen) {
            this.router.navigate([this.editContextPath + '/', this.id]);
          }

          this.isAddMode = false;
          this.populateForm();
          this.buildAnyChildTables();
        },
        error => {
          if (this.logService) {
            this.logService.error(`Error occurred performing insert operation:`, this.entity);
          }

          if (callbackFn) {
            callbackFn({}, error);
          }

          this.showButtonLoading = false;
          this.raiseSaveError(error);
        }
      );
    } else {
      this.entityService.update(this.entity).subscribe(
        data => {
          if (callbackFn) {
            callbackFn(data);
          }

          this.clearErrors();
          this.showButtonLoading = false;

          this.entity = this.extractEntity(data);
          this.id = this.entity.id;

          this.raiseSaveSuccess();
          this.populateForm();

          if (!stayOnEditScreen) {
            this.router.navigate([this.listContextPath]);
          }
        },
        error => {
          if (this.logService) {
            this.logService.error(`Error occurred performing update operation:`, this.entity);
          }

          if (callbackFn) {
            callbackFn({}, error);
          }

          this.showButtonLoading = false;
          this.raiseSaveError(error);
        }
      );
    }
  }

  // ------------------ END: Save record ------------------------------

  // ------------------ START: Validations ----------------------------

  validateFormData(): boolean {
    this.validateFormControls();
    return this.formData.valid;
  }

  nestedPropertyValue(attrName: string): any {
    const attrList = attrName.split('.');
    let value = this.entity;
    attrList.forEach(attr => {
      if (value) {
        value = value[attr];
      }
    });
    return value;
  }

  valueOf(name: string): any {
    return this.formData.get(name) ? this.formData.get(name).value : undefined;
  }

  // Implement this method in the derived class for any custom validations.
  // Must return error message that needs to be displayed on the screen.
  customValidations(): string {
    return '';
  }

  validateFormControls() {
    Object.keys(this.formData.controls).forEach(controlKey => {
      if (this.formData.controls[controlKey].invalid) {
        this.formData.controls[controlKey].setErrors(
          CustomValidators.translateError(this.formData.controls[controlKey].errors)
        );
      }
    });
  }

  addOnlyControl(fieldValue: string | number, validators: Validators[]): any[] {
    if (this.isAddMode) {
      return [fieldValue, validators];
    } else {
      return [{ value: fieldValue, disabled: true }];
    }
  }

  readOnlyControl(fieldValue: string | number | Date, validators: Validators[]): any[] {
    return [{ value: fieldValue, disabled: true }];
  }

  childOnlyControl(fieldValue: string | number, validators: Validators[]): any[] {
    if (this.isTopLevel) {
      return [{ value: fieldValue, disabled: true }];
    } else {
      return [fieldValue, validators];
    }
  }

  readOnlyPersistValueControl(fieldValue: string | number, validators: Validators[]): any[] {
    return [{ value: fieldValue, disabled: true }];
  }

  // ------------------ START: Raise various messages  --------------
  raiseSaveSuccess(): void {
    if (this.toasterService.toasts.length === 0) {
      this.toasterService.toastSuccess({
        header: 'Success!',
        message: 'Record has been saved successfully',
      });
    } else {
    }
  }

  raiseValidationError(): void {
    this.scrollToTopOnError();
    this.errors.hasError = true;
    this.errors.message = 'Please review and confirm that all required fields are complete and correct.';
  }

  raiseSaveError(msg: string): void {
    this.scrollToTopOnError();
    this.errors.hasError = true;
    this.errors.message = msg || 'Error occurred while saving the record.';
  }

  raiseReadError(): void {
    this.errors.hasError = true;
    this.errors.message = 'Error occurred while opening the record.';
  }

  requiredFieldError(field?: string): [{}] {
    const msg = `${field || 'This'} is required.`;
    return [
      {
        type: 'Error:',
        message: msg,
      },
    ];
  }

  clearErrors(): void {
    this.errors.hasError = false;
    this.errors.message = '';
  }
  // ------------------ END: Raise various messages  --------------

  scrollToTopOnError(): void {
    const scrollPage = document.getElementsByClassName('go-layout__route-container')[0];
    if (scrollPage) {
      scrollPage.scrollTop = 0;
    }
  }

  ngAfterViewChecked(): void {
    this.applyDynamicTheme();
  }

  applyDynamicTheme(): void {
    if (!this.isThemeApplied) {
      const accordians: any = document.getElementsByClassName('go-accordion-panel--selected');
      if (accordians.length > 0) {
        const theme = sessionContext.tenantTheme;
        if (
          theme != null &&
          Object.keys(theme).length > 0 &&
          isNotNull(theme.brandingSets) &&
          isNotNull(theme.brandingSets.find(brandingSet => brandingSet.name === 'Desktop'))
        ) {
          const brandingSetElements = theme.brandingSets.find(brandingSet => brandingSet.name === 'Desktop')
            .brandingSetElements;
          const brandColor = brandingSetElements.find(
            brandingSetElement => brandingSetElement.brandingElement.elementType === 'Brand Color'
          ).elementValue;
          if (isNotNull(brandColor)) {
            // For loop to change theme for all accordions on edit page.
            for (const accordian of accordians) {
              if (accordian) {
                const rgbColor = RGBInterfaceToRGBString(hexToRgb(brandColor));
                // Dynamic theme for accordian option highlight
                accordian.style.background = isNotNull(rgbColor) ? rgbColor : accordian.style.background;
              }
            }
          }
        }
      }
    }
  }
  ngOnDestroy(): void {
    setTimeout(() => {
      const element: any = document.getElementsByClassName('go-accordion-panel--selected');
      if (element[0]) {
        element[0].style.background = 'unset';
      }
    });
  }
}
