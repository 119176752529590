import { BaseEntity } from '../../base/base-entity';

/**
 * This class represent the VendorPortal entity.
 */
export class VendorPortal extends BaseEntity {
  vendor_portal_row_id: number;

  master_vendor: { id: number; name: string };
  security_question: string;
  security_answer: string;
  vendor_portalusername: string;
  vendor_portalpassword: string;
  web_address: string;
  access_type: { value_id: number; value?: string; dynamic_list_name?: string };
}
