import { Component, Input } from '@angular/core';
import { BaseFieldComponent } from '../field-base';

@Component({
  selector: 'app-field-date',
  templateUrl: './field-date.component.html',
  styleUrls: ['./field-date.component.scss'],
})
export class FieldDateComponent extends BaseFieldComponent {
  @Input() observe: string;
}
