import { Component, OnInit, ElementRef } from '@angular/core';
import { GoToasterService, GoModalService } from '@tangoe/goponents';
import { BaseModalListComponent } from '../../base/base-modal-list.component';
import { UserGroupService } from '../user-group.service';
import { UserGroup } from '../user-group';

@Component({
  selector: 'app-assign-user-to-group-modal',
  templateUrl: './assign-user-to-group-modal.component.html',
  styleUrls: ['./assign-user-to-group-modal.component.scss'],
})
export class AssignUserToGroupModalComponent extends BaseModalListComponent<UserGroup, 'group'> {
  constructor(
    protected toasterService: GoToasterService,
    protected userGroupService: UserGroupService,
    protected modalService: GoModalService,
    protected el: ElementRef
  ) {
    super(toasterService, userGroupService, modalService, el);
  }

  linkedEntity(): string {
    return 'group_membership';
  }

  primaryEntity(): string {
    return 'userGroup';
  }

  primaryEntityRowId(): string {
    return 'groupRowId';
  }

  otherEntity(): string {
    return 'user';
  }

  otherEntitySearch(): string {
    return 'identifier';
  }

  otherEntitySort(): string {
    return 'identifier';
  }
}
