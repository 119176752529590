import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-filter-input',
  templateUrl: './filter-input.component.html',
  styleUrls: ['./filter-input.component.scss'],
})
export class FilterInputComponent implements OnInit {
  @Input() name: string;
  @Input() label: string;
  @Input() placeholder: string;

  control = new FormControl('');

  constructor() {}

  ngOnInit() {}
}
