import { BaseEntity } from '../../base/base-entity';

/**
 * This class represent the InvoiceHeader entity.
 */
export class InvoiceHeader extends BaseEntity {
  invoice_header_row_id: number;

  vendor: string;
  invoice_number: string;
  invoice_date: string;
  current_charges: { value: number; indicator: string };
  total_amount_due: { value: number; indicator: string };
  invoice_status: string;
  account_number: string;
}
