import { Component, OnInit } from '@angular/core';
import { AllocationInfoService } from '../allocation-info.service';

@Component({
  selector: 'app-allocation-info',
  templateUrl: './allocation-info-list.component.html',
  styleUrls: ['./allocation-info-list.component.scss'],
})
export class AllocationInfoListComponent implements OnInit {
  allocationTypeSelectionList: any = [];

  constructor(public allocationInfoService: AllocationInfoService) {}

  ngOnInit(): void {
    this.buildAnySearchAndSelects();
    this.buildAnyStaticDropdowns();
  }

  buildAnySearchAndSelects(): void {}

  buildAnyStaticDropdowns(): void {
    this.allocationTypeSelectionList = [
      { value: 'AMOUNT', name: 'AMOUNT' },
      { value: 'PERCENTAGE', name: 'PERCENTAGE' },
    ];
  }
}
