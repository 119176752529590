import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';

import { AuthenticationService } from './authentication.service';
import { sessionContext, TenantInfo, ApplicationInfo } from './session-details';
import { environment } from '../../environments/environment';
import { isNotNull } from '../base/utils';
import { BrandingMode, GoConfigInterface, ThemeColors, GoConfigService } from '@tangoe/goponents';
import { catchError } from 'rxjs/operators';
const LOGO = '../../assets/img/logo-main.png';
const ALT_LOGO = '../../assets/img/logo.png';

export const CONFIG: GoConfigInterface = {
  brandColor: ThemeColors.dark,
  brandingMode: BrandingMode.company,
  logoConfig: {
    altText: 'Tangoe Logo',
    logo: ALT_LOGO,
    logoCollapsed: LOGO,
  },
};

@Injectable({
  providedIn: 'root',
})
export class CanActivateApp implements CanActivate {
  tenantShortName: string;
  textColor: any;

  constructor(protected authenticationService: AuthenticationService, private goConfigService: GoConfigService) {
    this.goConfigService.setLogo({
      altText: 'Tangoe Logo',
      logo: ALT_LOGO,
      logoCollapsed: LOGO,
    });
  }

  // TODO: results within this method really need interfaces...
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise((resolve, reject) => {
      this.handleLoggedinTenant().then(tenantList => {
        this.loadTenantTheme();
        this.switchCurrentTenant(tenantList).then(() => {
          let loggedUser;
          if (this.tenantShortName) {
            loggedUser = this.authenticationService.loggedInUserDetails();
          } else {
            loggedUser = sessionContext.userInfo.userIdentifier
              ? Observable.of(null)
              : this.authenticationService.loggedInUserDetails();
          }
          const source = [
            loggedUser,
            this.authenticationService.loggedInUserPermissions(),
            this.authenticationService.loggedInUserTenants(),
            this.authenticationService.loggedInUserApplications(),
            this.authenticationService.buildInfo(),
          ];
          return forkJoin(...source).subscribe(
            (results: any) => {
              const userDetails: any = results[0];
              const userPermissions: any = results[1];
              const userTenants: any = results[2];
              const userApplications: any = results[3];
              const appInforamtion: any = results[4];

              this.handleUserDetails(userDetails);
              this.handleUserPermissions(userPermissions);
              this.handleDeclinedUserPermissions(userPermissions);
              this.handlePrivilegePolicy(userPermissions);
              this.handleUserTenants(userTenants);
              this.handleUserApplications(userApplications);
              this.authenticationService.removeTenantFromSession();
              this.authenticationService.removeQuickLinkPathFromSession();
              this.updateAppInforamtion(appInforamtion);
              resolve(true);
            },
            error => {
              this.redirectToSsoPage();
              return of(false);
            }
          );
        });
      });
    });
  }
  updateAppInforamtion(appInforamtion: any) {
    sessionContext.appInfo = appInforamtion.build_info;
  }

  loadTenantTheme() {
    this.authenticationService.loggedInUserTenantTheme().subscribe(
      data => {
        this.handleloggedInUserTenantTheme(data);

        this.applyPartnerBranding(sessionContext.tenantTheme);
      },
      error => {
        console.log(error);
        this.applyPartnerBranding(sessionContext.tenantTheme);
      }
    );
  }

  applyPartnerBranding(brand): void {
    if (
      brand != null &&
      Object.keys(brand).length > 0 &&
      isNotNull(brand.brandingSets) &&
      isNotNull(brand.brandingSets.find(brandingSet => brandingSet.name === 'Desktop'))
    ) {
      const brandingSetElements = brand.brandingSets.find(brandingSet => brandingSet.name === 'Desktop')
        .brandingSetElements;
      if (isNotNull(brandingSetElements)) {
        const brandColor = brandingSetElements.find(
          brandingSetElement => brandingSetElement.brandingElement.elementType === 'Brand Color'
        ).elementValue;
        const enableBrandColorHeader = brandingSetElements.find(
          brandingSetElement => brandingSetElement.brandingElement.elementType === 'Enable Brand Color in Header'
        ).elementValue;
        const logo = brandingSetElements.find(
          brandingSetElement => brandingSetElement.brandingElement.elementType === 'Logo'
        ).elementValue;
        const inverseLogo = brandingSetElements.find(
          brandingSetElement => brandingSetElement.brandingElement.elementType === 'Inverse Logo'
        ).elementValue;
        CONFIG.brandColor = brandColor;
        if (enableBrandColorHeader === 'true') {
          CONFIG.brandingMode = BrandingMode.company;
          CONFIG.logoConfig.logo = inverseLogo;
        } else {
          CONFIG.brandingMode = BrandingMode.client;
          CONFIG.logoConfig.logo = logo;
        }
        CONFIG.logoConfig.logoCollapsed = logo;
        this.goConfigService.setConfig(CONFIG);
      }
    }
  }

  redirectToSsoPage() {
    // Save path for deep linking
    const pathName = window.location.pathname;
    if (pathName !== '/post-login') {
      sessionStorage.setItem('redirectPathName', pathName);
      this.authenticationService.handleTenantShortName();
    }
    window.open(environment.ssoLoginUrl, '_self');
  }

  switchCurrentTenant(tenantList) {
    return new Promise((resolve, reject) => {
      if (!this.tenantShortName) {
        resolve(false);
      }
      const switchTenantData = tenantList.tenant
        ? tenantList.tenant.filter(item => item.short_name === this.tenantShortName)
        : [];
      if (switchTenantData.length) {
        const tenantInfo: TenantInfo = new TenantInfo();
        tenantInfo.shortName = switchTenantData[0].short_name;
        tenantInfo.tenantName = switchTenantData[0].name;
        tenantInfo.tenantRowId = switchTenantData[0].id;
        this.authenticationService.switchTenant(tenantInfo).subscribe(
          () => {
            resolve(true);
          },
          error => {
            resolve(false);
          }
        );
      } else {
        resolve(false);
      }
    });
  }

  handleLoggedinTenant() {
    return new Promise((resolve, reject) => {
      this.tenantShortName = this.authenticationService.handleTenantShortName();
      if (this.tenantShortName) {
        this.authenticationService.loggedInUserTenants().subscribe(
          tenantData => {
            resolve(tenantData);
          },
          error => {
            this.redirectToSsoPage();
            resolve(false);
          }
        );
      } else {
        resolve(false);
      }
    });
  }

  handleUserApplications(userApplications: any): void {
    sessionContext.userApps.length = 0;

    if (userApplications && userApplications.items) {
      userApplications.items.forEach(item => {
        const applicationInfo: ApplicationInfo = new ApplicationInfo();
        applicationInfo.name = item.application.displayName;
        applicationInfo.icon = item.application.iconName;
        applicationInfo.url = item.accessUrl;

        sessionContext.userApps.push(applicationInfo);
      });
    }
  }

  handleUserDetails(userDetails: any): void {
    if (userDetails) {
      this.authenticationService.handleUserDetails(userDetails);
    }
  }

  handleUserPermissions(userPermissions: any): void {
    sessionContext.permissions = userPermissions.privilege_details
      .map(item => item.allowed_list)
      .filter((value, index, self) => self.indexOf(value) === index && value !== undefined);
  }

  handlePrivilegePolicy(userPermissions: any): void {
    sessionContext.privilegePolicies = userPermissions.privilege_details.filter(item =>
      item.hasOwnProperty('allowed_list')
    );
  }

  handleDeclinedUserPermissions(userPermissions: any): void {
    sessionContext.deniedPermissions = userPermissions.privilege_details.filter(item =>
      item.hasOwnProperty('denied_list')
    );
  }

  handleUserTenants(userTenants: any): void {
    // clear accessible tenants
    sessionContext.tenantList.length = 0;

    userTenants.tenant.forEach(item => {
      this.addAccessibleTenant(item);
    });
  }

  handleloggedInUserTenantTheme(userTenantTheme: any): void {
    const tenantTheme =
      userTenantTheme.tenant_product_configuration.length > 0 &&
      isNotNull(userTenantTheme.tenant_product_configuration[0].attribute_value_json)
        ? JSON.parse(userTenantTheme.tenant_product_configuration[0].attribute_value_json)
        : {};
    sessionContext.tenantTheme = tenantTheme;
  }

  addAccessibleTenant(tenant: any) {
    const tenantInfo: TenantInfo = new TenantInfo();
    tenantInfo.shortName = tenant.short_name;
    tenantInfo.tenantName = tenant.name;
    tenantInfo.tenantRowId = tenant.id;

    sessionContext.tenantList.push(tenantInfo);
  }

  parseEmailAndGetName(email: string, part: string): string {
    const name: string = email.substring(0, email.lastIndexOf('@'));
    const nameParts: string[] = name.split('.');

    if (part === 'FIRST_NAME') {
      if (nameParts.length > 0) {
        return nameParts[0];
      }
    }

    if (part === 'LAST_NAME') {
      if (nameParts.length > 1) {
        return nameParts[1];
      }
    }

    return '';
  }
}
