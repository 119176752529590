export class ApiErrors {
  public static mappings: { key: string; message: string }[] = [
    {
      key: 'CAN_NOT_INSERT_DUPLICATE_RECORD',
      message: 'Cannot save a duplicate record.',
    },
    {
      key: 'CONSTRAINT_VOILATION_EXCEPTION',
      message: 'Cannot save record due to constraint error.',
    },
    {
      key: 'CANNOT_PROTECTED_INACTIVE_EMPLOYEE',
      message: 'Inactive Employees cannot be set to Protected.',
    },

    {
      key: 'CANNOT_INACTIVATE_PROTECTED_EMPLOYEE',
      message: 'Protected Employees cannot have an Employee Status set to Inactive.',
    },
  ];
}
