import { Directive, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

@Directive({
  selector: '[appDisableField]'
})
export class DisableFieldDirective implements OnInit, OnChanges {

  @Input() disableField: boolean = true;
  @Input() control: FormControl;

  constructor(private el: ElementRef) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.disableField) {
      return;
    }

    const operation = changes.disableField.currentValue === true ? 'disable' : 'enable' ;

    this.control[operation]();
  }
}
