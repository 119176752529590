import { BaseEntity } from '../../base/base-entity';

/**
 * This class represent the NonCurrentCharge entity.
 */
export class NonCurrentCharge extends BaseEntity {
  non_current_charge_row_id: number;

  line_item_id: string;
  charge_type_name: string;
  charge_description: string;
  asset_type: string;
  asset_identifier: string;
  unit_price_value: { value: number; indicator: string };
  unit_of_measure: string;
}
