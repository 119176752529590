import { Component, OnInit, ViewChild } from '@angular/core';
import { GoToasterService, GoTableComponent, GoModalService } from '@tangoe/goponents';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BaseModalEditComponent } from '../../base/base-modal-edit.component';
import { Result } from '../../base/result';
import { ItemSelector, EmbeddedTableContext } from '../../base/common';
import * as utils from '../../base/utils';
import { CustomValidators } from '../../base/validators';
import { ManagePlatformForTenantService } from './manage-platform-for-tenant.service';
import { SourceSystem } from './manage-platform-for-tenant';

@Component({
  selector: 'app-manage-platform-for-tenant-modal',
  templateUrl: './manage-platform-for-tenant-modal.component.html',
  styleUrls: ['./manage-platform-for-tenant-modal.component.scss'],
})
export class ManagePlatformForTenantModalComponent extends BaseModalEditComponent<SourceSystem, 'source_system'>
  implements OnInit {
  constructor(
    public managePlatformForTenantService: ManagePlatformForTenantService,
    protected toasterService: GoToasterService,
    protected modalService: GoModalService,
    protected router: Router,
    protected route: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {
    super(managePlatformForTenantService, toasterService, modalService, router, route);
    this.listContextPath = '/manage-platform-for-tenants';
    this.editContextPath = '/manage-platform-for-tenants';
    this.entityLocator = 'source_system';
  }

  ngOnInit() {
    this.loadRecord();
  }

  populateForm(): void {
    this.formData = this.formBuilder.group({
      platform_name: [this.entity.platform_name, []],

      environment_name: [this.entity.environment_name, []],

      tenant_number: [this.entity.tenant_number, []],
    });
  }

  extractFormData(): SourceSystem {
    const entity: SourceSystem = new SourceSystem();

    const { platform_name, environment_name, tenant_number } = this.formData.value;

    entity.platform_name = platform_name;
    entity.environment_name = environment_name;
    entity.tenant_number = tenant_number;

    if (this.isAddMode) {
      entity.addAssociation('tenant', 'tenant_row_id', this.parentId);
    } else {
      entity.id = this.id;
      entity.source_system_row_id = this.id;
    }

    return entity;
  }
}
