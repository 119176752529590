import { Component, Input, OnInit } from '@angular/core';
import { EmbeddedTableContext } from '../../../base/common';
import { GoTableConfig } from '@tangoe/goponents';

@Component({
  selector: 'app-empty-table',
  templateUrl: './empty-table.component.html',
  styleUrls: ['./empty-table.component.scss']
})
export class EmptyTableComponent implements OnInit {
  @Input() title: string;

  emptyTableContext: EmbeddedTableContext;

  ngOnInit() {
    this.emptyTableContext = new EmbeddedTableContext(
      'no_data',
      'id',
      'id',
      false
    );
  }

  onChildTableChange($event: GoTableConfig, emptyTableContext: EmbeddedTableContext) {

  }

  exportNonCurrentChargeToExcel(emptyTableContext: EmbeddedTableContext) {

  }
}
