import { Injectable } from '@angular/core';
import { BaseService } from 'src/app/base/base-service';
import { Export } from './export';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AdvanceFilter, SimpleFilter } from 'src/app/base/base-filter';
import { QueryParam } from 'src/app/base/common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ExportModalService extends BaseService<Export, 'export'> {
  baseUrl: string;

  constructor(http: HttpClient) {
    super(http);
  }
  getMetaData(): any {
  }

  getServiceUrl(): string {
    return environment.masterDataUrl;
  }

  getBaseUrl(): string {
    return this.getServiceUrl() + '/' + this.baseUrl;
  }

  triggerExport(
    simpleFilter: SimpleFilter,
    advanceFilter: AdvanceFilter,
    queryParams: QueryParam,
    exportLayout: any,
    entity: any
  ): Observable<any> {
    const headers = this.buildHeaders();

    const params: any = {};
    params.o = queryParams.sortClause();

    const exportRequest = {};
    exportRequest['export_layout'] = exportLayout;

    // Advance Filter available then use it, else it will be simple search.
    // if (Object.keys(advanceFilter).length !== 0) {
    //   exportRequest['query'] = advanceFilter;
    // }

    params.t = entity.fileType;
    params.q = entity.single_processing_Date;
    params.f = entity.defaultExportAttr;
    this.baseUrl = entity.baseUrl;

    const url = this.getBaseUrl() + '/async-export';

    return this.http.post<any>(url, exportRequest, {
      headers,
      params,
    });
  }
}
