import { BaseEntity } from '../base/base-entity';

/**
 * This class represent the Employee entity.
 */
export class Employee extends BaseEntity {
  employee_row_id: number;

  title: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  email: string;
  cellphone_number: string;
  presentation_name: string;
  employee_id: string;
  employee_status: { value_id: number; value?: string; dynamic_list_name?: string };
  hire_date: string;
  termination_date: string;
  manager: { id: number; presentation_name: string };
  group: { value_id: number; value?: string; dynamic_list_name?: string };
  employee_type: string;
  is_protected: string;
  country_of_citizenship: { id: number; name: string };
  resident_location: { id: number; name: string };
  location: { id: number; name: string };
  hr_role: { id: number; name: string };
  business_unit: { id: number; name: string };
  company: { id: number; name: string };
  cost_center: { id: number; name: string };
  department: { id: number; name: string };
  gl_expense_code: { id: number; code: string };
  user: { id: number; identifier: string };
}

// Class representing EmployeeAddress  Child entity.
export class EmployeeAddress extends BaseEntity {
  employee_address_row_id: number;

  type: { value_id: number; value?: string; dynamic_list_name?: string };
  country: string;
  address_line1: string;
  address_line2: string;
  city: string;
  state_province_region: string;
  zip_postal_code: string;
  is_primary_address: string;
}

// Class representing EmployeeCostCenter  Child entity.
export class EmployeeCostCenter extends BaseEntity {
  employee_cost_center_row_id: number;

  code: string;
  name: string;
  status: string;
}

// Class representing EmployeeGroupMembership  Child entity.
export class EmployeeGroupMembership extends BaseEntity {
  employee_group_membership_row_id: number;

  employee: string;
  email: string;
  name: string;
  description: string;
}
