import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AllocationConfigurationCreateComponent } from './allocation-configuration-create/allocation-configuration-create.component';
import { AllocationConfigurationEditComponent } from './allocation-configuration-edit/allocation-configuration-edit.component';
import { SharedPageStateService } from 'src/app/service/shared-page-state.service';

@Component({
  selector: 'app-allocation-configuration',
  templateUrl: './allocation-configuration.component.html',
  styleUrls: ['./allocation-configuration.component.scss'],
})
export class AllocationConfigurationComponent implements OnInit {
  @Input() allocationInfoId: number;
  @Input() invoiceId: number;
  @Input() showAllocateButtons: boolean = false;
  @Input() showHandleRemainingAmounts: boolean;
  @Input() showAutomaticAllocationOption: boolean = true;
  @Input() showChargeAllocationOption: boolean = true;
  @Input() assocDetailAttr: string;
  @Input() assocDetailField: string;
  @Input() assocDetailId: number;
  @Input() expanded: boolean = true;
  @Input() boxShadow: boolean = true;
  @Input() renderBoxShadows: boolean = true;
  @Input() inTab: boolean = false;
  @Input() isAddMode: boolean = false;
  @Input() pageStateService: SharedPageStateService;
  @Input() invoiceStatus: string;
  @Input() disableAllocateButtons: boolean;
  @Input() showBanner: boolean;
  @Input() isAllocConfigEditable: boolean = true;


  @Output() getAllocationConfig = new EventEmitter();

  @ViewChild(AllocationConfigurationCreateComponent, { static: false })
  createComponent: AllocationConfigurationCreateComponent;
  @ViewChild(AllocationConfigurationEditComponent, { static: false })
  editComponent: AllocationConfigurationEditComponent;

  constructor() {}

  ngOnInit() {}

  onCreated($event: any) {
    this.allocationInfoId = $event.allocation_info.id;
  }

  refreshAllocationList() {
    this.getAllocationConfig.emit();
  }
}
