import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LogService } from '../logger/log.service';
import { ExternalResourceEventData } from './event-data';
import { isTrue, isNotNull } from '../base/utils';
import { environment } from 'src/environments/environment';
import { SaasMenuProvider } from '../app-shell/saas.menus';

@Injectable({
  providedIn: 'root',
})
export class ExternalResourceEventHandler {
  saveSaasPath: string;

  constructor(
    protected router: Router,
    protected logService: LogService,
    private saasMenuProvider: SaasMenuProvider,
    private authService: AuthenticationService
  ) {}

  registerEventHandler() {
    this.logService.debug('External-Resource: Registrying event listener');
    addEventListener('message', event => {
      this.handleEvent(event);
    });
  }

  handleEvent(event: MessageEvent) {
    try {
      this.saasMenuHandle(event);
      const eventData: ExternalResourceEventData = JSON.parse(event.data);

      if (!this.canHandleEvent(eventData)) {
        return;
      }

      this.logService.debug('External-Resource: Processing event data ', eventData);

      let targetUrl = '';
      targetUrl += isTrue(eventData.isOldUI) ? '/external-resource/' : '/'; // Old UI served by external-resource.
      targetUrl += eventData.menu_id; // Screen name to be opened.
      targetUrl += isNotNull(eventData.id) ? '/' + eventData.id : ''; // Row Id in case detail page is being opened.

      this.router.navigate([targetUrl, isNotNull(eventData.url) ? { url: eventData.url } : {}]);
    } catch (objError) {}
  }

  saasMenuHandle(event) {
    if (event.origin !== environment.saasIframeUrl) {
      return;
    }
    if (event.data.type === 'navigate' && event.data.to === '/login' && this.authService.logoutEvent) {
      this.logService.info(
        `Event-Handle:: SaaS Partner log out event responded user will be logged out from Cloud application`
      );
      this.authService.saasPartnerLogout = true;
      console.log('zluri logout event  received: ' + JSON.stringify(event.data));
      return;
    }

    console.log('zluri event received: ' + JSON.stringify(event.data));
    if (sessionStorage.getItem('tenant_switch') && event.data.action === 'tenantChanged') {
      sessionStorage.removeItem('tenant_switch');
      console.log('removed tenant switch');
    }
    if (event.data.type === 'event') {
      const saasModalContent = document.getElementById('saas-modal-backdrop');
      const saasDashboardContent = document.getElementById('saas-dashboard-content');
      if (event.data.listener === 'modal_open') {
        if (saasModalContent) {
          saasModalContent.classList.add('saas-backdrop');
          saasDashboardContent.classList.add('saas-dashboard-position');
        }
      } else {
        saasModalContent.classList.remove('saas-backdrop');
        saasDashboardContent.classList.remove('saas-dashboard-position');
      }
    } else if (event.data.type === 'navigate') {
      let path = 'saas-external-resource';

      const navigationTo = event.data.to.split('/');
      const intialPath = navigationTo[1];
      const ext_path = navigationTo[2];

      if (intialPath && intialPath !== 'checkAuth') {
        if (intialPath === 'licenses' && !navigationTo[2]) {
          path = `saas-external-resource/saas-${navigationTo[1]}/${navigationTo[1]}`;
        } else {
          for (let i = 1; i < navigationTo.length; i++) {
            if (i === 1) {
              path += '/saas-' + navigationTo[i];
            } else {
              path += '/' + navigationTo[i];
            }
          }
        }
        // if (intialPath === 'logged out' || intialPath === 'logout' || intialPath === 'login') {
        //   this.saveSaasPath = this.saveSaasPath + '/notFound';
        //   this.router.navigate([this.saveSaasPath]);
        // } else
        if (path) {
          this.saveSaasPath = path;
          const isSaasPath = window.location.pathname.includes('saas-external-resource');
          if (this.checkLatestSaasPath(intialPath, ext_path, path) && isSaasPath) {
            this.router.navigate([path]);
          }
        }
      }
    }
  }

  checkLatestSaasPath(intialPath, ext_path, actualPath) {
    let status = false;
    const saasMenuList: any = this.saasMenuProvider.getSaasMenusBasedOnRole();
    const currentPathDetails: any = saasMenuList.filter(
      selectMenu => selectMenu.routeTitle.toUpperCase() === intialPath.toUpperCase()
    );
    if (currentPathDetails[0].subRoutes) {
      const childPathDetails = currentPathDetails[0].subRoutes.filter(
        selectMenu => selectMenu.routeTitle.toUpperCase() === ext_path.toUpperCase()
      );
      const latestPath = childPathDetails[0].route.replace(/\//, '');
      status = latestPath.split('/')[1] ? latestPath.split('/')[1] === actualPath.split('/')[1] : false;
    } else if (currentPathDetails.length) {
      const latestPath = currentPathDetails[0].route.replace(/\//, '');
      status = latestPath.split('/')[1] ? latestPath.split('/')[1] === actualPath.split('/')[1] : false;
    }
    return status;
  }

  canHandleEvent(eventData: ExternalResourceEventData): boolean {
    if (eventData && eventData.event_id && eventData.event_id === 'child-frame-event') {
      return true;
    }
    return false;
  }
}
