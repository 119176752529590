import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExportModalComponent } from './export-modal/export-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoButtonModule, GoToasterModule } from '@tangoe/goponents';
import { FieldTypesModule } from '../field-types/field-types.module';

@NgModule({
  declarations: [ExportModalComponent],
  imports: [CommonModule, ReactiveFormsModule, FormsModule, FieldTypesModule, GoButtonModule, GoToasterModule],
  entryComponents: [ExportModalComponent],
})
export class ExportModalModule {}
