import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../../../base/base-service';
import { SimpleFilter } from '../../../base/base-filter';
import { environment } from '../../../../environments/environment';
import { Result } from '../../../base/result';
import { Observable } from 'rxjs';
import { QueryParam } from '../../../base/common';
import { Approver } from './manage-approver-modal/manage-approver';

@Injectable({
  providedIn: 'root',
})
export class ApproverService extends BaseService<Approver, 'approver'> {
  constructor(http: HttpClient) {
    super(http);
  }
  getMetaData(): any {
  }

  getServiceUrl(): string {
    return environment.masterDataUrl;
  }

  getBaseUrl(): string {
    return this.getServiceUrl();
  }

  // ************************************ START:CUSTOM METHODS **************************

  // Custom method to be used by search and select for Approver Name.
  findApproverName(
    q?: string,
    include?: string,
    exclude?: string,
    filter?: string
  ): Observable<Result<'employee', any[]>> {
    const simpleFilter = new SimpleFilter('first_name,last_name,email,employee_id', q, include, exclude);
    const queryParam = new QueryParam();
    queryParam.perPage = 25;
    queryParam.sortAttr = 'first_name';

    return this.listExternalEntities(environment.masterDataUrl + '/employees/list', simpleFilter, queryParam);
  }
}
