import { Pipe, PipeTransform } from '@angular/core';

/**
 * This pipe should be able to handle the following:
 *  a) ALL UPPERCASE  (e.g. - ACTIVE)
 *  b) ALL UPPERCASE WITH UNDERSCORE AS SEPARATOR (e.g. - PENDING_ACTIVATION)
 *  c) SNAKE CASE (e.g. invoice_number)
 *  d) CAMEL CASE (e.g. invoiceNumber)
 */
@Pipe({ name: 'labelize' })
export class LabelizePipe implements PipeTransform {
  transform(text: string) {
    // if text is null or undefined, return an empty string
    // This avoids type error: Cannot call toUpperCase on undefined
    if (!text) {
      return '';
    }

    // If the text is all CAPS then it is in the form of ABC_DEF so convert it to snake-case
    if (text.toUpperCase() === text) {
      text = text.toLowerCase();
    }

    // If the text is in camelCase
    if (text.toLowerCase() !== text && !text.includes('_')) {
      text = text.charAt(0).toLowerCase() + text.slice(1);
      text = text.replace(/([A-Z])/g, '_$1').toLowerCase();
    }

    // Drop anything after . (cases to handle complext attribute names specifically for history field names)
    text = text.split('.')[0];

    // Assuming everything by now reaching here is in snake_case.
    const result = text.toLowerCase().replace(/([-_][a-z])/gi, $1 => {
      return $1
        .toUpperCase()
        .replace('-', ' ')
        .replace('_', ' ');
    });

    return result.charAt(0).toUpperCase() + result.slice(1);
  }
}
