import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabelizePipe } from './labelize-pipe';
import { StringToDateTimePipe } from './date-converter-pipe';
import { MonetizePipe, MonetizeWithIndicatorPipe } from './monetize-pipe';

@NgModule({
  declarations: [LabelizePipe, StringToDateTimePipe, MonetizePipe, MonetizeWithIndicatorPipe],
  imports: [CommonModule],
  exports: [LabelizePipe, StringToDateTimePipe, MonetizePipe, MonetizeWithIndicatorPipe],
})
export class CommonPipesModule {}
