import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-error-message-page',
  templateUrl: './error-message-page.component.html',
  styleUrls: ['./error-message-page.component.scss'],
})
export class ErrorMessagePageComponent implements OnInit {
  logo = '../../../assets/img/logo-main.png';
  @Input() displayStatusCode = false;
  @Input() errorTitle: string;
  @Input() errorMessage: string;

  constructor() {}

  ngOnInit(): void {}
}
